import React, { useState, useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'

import { getMainElement } from 'components/utils/dom-manipulation'

import ScssConstants from 'styles/shared.module.scss'

import './style.scss'

/**
 * Displays an offline data item row with expand and collapse
 * @param {Object} params React Params
 * @param {Boolean} [params.defaultCollapsed] Whether the component starts off as collapsed or not
 * @param {Node | String} params.header Header to display
 * @param {Node | Array<Node>} params.children Children components
 * @param {String} [params.className] Additional className(s) for section body
 * @param {Boolean} [params.uncollapsible] True if we dont' want it to collapse
 * @param {Function} [params.onCollapseListener] Function to be executed when item is collapsed
 * @param {Function} [params.onRemove] Function to be executed when item is removed
 * @param {Boolean} [params.isEditMode]
 * @param {Boolean} [params.isViewMode]
 */
const OfflineDataItem = React.forwardRef(
  (
    {
      defaultCollapsed = true,
      header,
      children,
      className,
      uncollapsible,
      onCollapseListener,
      onRemove,
      setEditMode,
      isEditMode,
      isViewMode,
    },
    ref
  ) => {
    const self = useRef()
    const [isOpen, setIsOpen] = useState(
      uncollapsible ? true : !defaultCollapsed
    )
    const [isDisabled, setIsDisabled] = useState(false)

    useImperativeHandle(ref, () => ({
      isSectionOpen() {
        return isOpen
      },

      onCollapse(bool) {
        setIsOpen(!bool)
        setIsDisabled(false)
      },

      focus() {
        setIsOpen(true)

        setTimeout(() => {
          const main = getMainElement()
          main &&
            main.scrollTo({
              top:
                main.scrollTop +
                self.current.getBoundingClientRect().y -
                parseInt(ScssConstants.headerHeight),
              behavior: 'smooth',
            })
        })
      },
    }))

    const onEditClicked = () => {
      setIsDisabled(true)
      setIsOpen((current) => {
        if (onCollapseListener) {
          onCollapseListener(!current)
        }
        return !current
      })
      setEditMode(true)
    }

    const onRemoveClicked = () => {
      onRemove()
    }

    return (
      <>
        {header ? (
          <section
            ref={self}
            className={cx('collapsible-row', {
              'collapsible-row--open': isOpen,
            })}
          >
            <div
              className={cx('collapsible-row__body', {
                [className]: className,
              })}
            >
              {children}
            </div>
            <div
              className={cx('collapsible-row__heading', {
                'collapsible-row__heading__disabled': isDisabled || isEditMode,
              })}
            >
              {isViewMode ? (
                <div style={{ marginTop: '10px' }}>{header}</div>
              ) : (
                <>
                  <h3>
                    <EditIcon
                      className={cx('collapsible-row__row-edit', {
                        'collapsible-row__disabled': isDisabled || isEditMode,
                      })}
                      onClick={
                        !isDisabled && !isEditMode ? onEditClicked : null
                      }
                    />
                    {header}
                  </h3>
                  <DeleteIcon
                    onClick={
                      !isDisabled && !isEditMode ? onRemoveClicked : null
                    }
                    className={cx('table__delete fill-red', {
                      'collapsible-row__disabled': isDisabled && isEditMode,
                    })}
                  />
                </>
              )}
            </div>
          </section>
        ) : (
          <>{isOpen && <section ref={self}>{children}</section>}</>
        )}
      </>
    )
  }
)

OfflineDataItem.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  defaultCollapsed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  uncollapsible: PropTypes.bool,
  onCollapseListener: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  isViewMode: PropTypes.bool,
}

OfflineDataItem.defaultProps = {
  defaultCollapsed: true,
}

export default OfflineDataItem
