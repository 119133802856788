import { ACTIONS as SESSION_ACTIONS } from 'modules/session/actions'
import { ACTIONS } from './actions'

/** Name of the reducer */
const name = 'accounts'

/** Initial state of the reducer */
const initialState = {
  bulkEditList: [],
  conversions: [],
}

/** The reduce method (matches action to a method) */
const reduce = (state, action) => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state
}

const actionsMap = {
  [SESSION_ACTIONS.LOGOUT]: () => {
    return { ...initialState }
  },
  [ACTIONS.SET_BULK_ACCOUNTS]: (state, { bulkEditList }) => {
    return { ...state, bulkEditList }
  },
  [ACTIONS.SET_CUSTOM_CONVERSIONS]: (state, { conversions }) => {
    return { ...state, conversions }
  },
}

export default { name, initialState, reduce }
