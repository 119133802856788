import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as SaveIcon } from 'assets/save.svg'
import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'
import Button from 'components/button'
import EntitySelectorActions from 'components/entity-selector/action-bar'
import { closeModal, openModal } from 'components/modal/actions'
import cx from 'classnames'
import {
  bulkEditDimensions,
  deleteDimensions,
  getAllCategories,
  getAllDimensions,
  selectDimensions,
  toggleBulkEdit,
} from 'modules/data-store-global/actions'
import { useStore } from 'store'
import {
  showErrorMessage,
  showSuccessMessage,
} from 'modules/notifications/actions'

/**
 * Dimensions/metrics table action bar
 * @returns {React.FunctionComponent}
 */
const DimensionsTableActions = () => {
  const { dispatch, state } = useStore()
  const {
    dataStoreGlobal: {
      editedDimension,
      selectedDimensions,
      loading,
      bulkEditActive,
    },
  } = state

  const onBulkEdit = (enabled) => () => {
    if (enabled === bulkEditActive) {
      return
    }
    toggleBulkEdit(dispatch, enabled)
  }

  const onSave = () => {
    const edits = Object.entries(editedDimension).reduce(
      (acc, [key, value]) => {
        if (!value) {
          return acc
        }
        return { ...acc, [key]: value }
      },
      {}
    )
    bulkEditDimensions(selectedDimensions, edits).then(() => {
      toggleBulkEdit(dispatch, false)
      selectDimensions(dispatch, [])
      getAllCategories(dispatch)
      getAllDimensions(dispatch)
    })
  }

  const onBulkDeleteConfirmation = () => {
    const deleteCategoryModal = openModal(dispatch, {
      heading: 'Delete Dimensions/Metrics',
      icon: <WarningIcon />,
      contentSeparator: true,
      rightAlignButtons: true,
      children: (
        <div className="max-width-500">
          <p>
            You are about to delete the selected Data Store Dimensions/Metrics.
            This action cannot be undone.
          </p>
          <p>
            Click the confirm button to continue. Or click the Cancel button to
            close this modal.
          </p>
        </div>
      ),
      button: (
        <Button
          green
          data-cy="data-store-category-index-delete-confirm"
          value="Confirm"
          onClick={() => {
            onBulkDelete()
            closeModal(dispatch, deleteCategoryModal)
          }}
        />
      ),
      buttonSecondary: (
        <Button
          secondaryGray
          data-cy="data-store-category-index-delete-cancel"
          value="Cancel"
          onClick={() => {
            closeModal(dispatch, deleteCategoryModal)
          }}
        />
      ),
    })
  }

  const onBulkDelete = () => {
    deleteDimensions(selectedDimensions.map((dimension) => dimension.id))
      .then(() => {
        showSuccessMessage('Deleted selected dimesnions', dispatch)
        selectDimensions(dispatch, [])
        getAllCategories(dispatch)
        getAllDimensions(dispatch)
      })
      .catch((error) => {
        showErrorMessage(
          `Unable to delete selected dimensions: ${error}`,
          dispatch
        )
      })
  }

  return (
    <EntitySelectorActions
      actions={
        <div className="align-row space-between" style={{ flexGrow: 1 }}>
          <div className="align-row">
            <Button
              data-cy="data-store-dimension-bulk-edit"
              disabled={!selectedDimensions.length || loading || bulkEditActive}
              compact
              secondary={!bulkEditActive}
              onClick={onBulkEdit(true)}
              value={
                <div className="align-row gap-10">
                  <EditIcon width={16} height={16} className="fill-red" />
                  <span>EDIT SELECTED</span>
                </div>
              }
              className={cx('dimensions__edit-button', {
                'dimensions__edit-button--active': bulkEditActive,
              })}
            />
            <Button
              data-cy="data-store-dimension-bulk-delete"
              disabled={!selectedDimensions.length || loading || bulkEditActive}
              compact
              secondaryRed
              onClick={onBulkDeleteConfirmation}
              value={
                <div className="align-row gap-10">
                  <DeleteIcon width={16} height={16} className="fill-red" />
                  <span>REMOVE SELECTED</span>
                </div>
              }
            />
          </div>
          {bulkEditActive && (
            <div className="align-row">
              <Button
                data-cy="data-store-dimension-bulk-save"
                disabled={!selectedDimensions.length || loading}
                compact
                green
                onClick={onSave}
                className="clear-filters margin-left-auto"
                value={
                  <div className="align-row gap-10">
                    <SaveIcon width={16} height={16} />
                    <span>SAVE</span>
                  </div>
                }
              />
              <Button
                data-cy="data-store-dimension-bulk-cancel"
                disabled={!selectedDimensions.length || loading}
                compact
                secondaryGray
                onClick={onBulkEdit(false)}
                className="clear-filters margin-left-auto"
                value={'CANCEL'}
              />
            </div>
          )}
        </div>
      }
    />
  )
}

export default DimensionsTableActions
