import Loader from 'components/loader/index'
import { getCategory, setCategory } from 'modules/data-store-global/actions'
import CategoryForm from 'modules/data-store-global/components/categories/form'
import { DEFAULT_CATEGORY } from 'modules/data-store-global/constants'
import { showErrorMessage } from 'modules/notifications/actions'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useStore } from 'store'

/**
 * Page which handles creating or editing a Data Store Category
 * @returns {React.FunctionComponent}
 */
const DataStoreManageCategory = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const { dispatch, state } = useStore()

  const {
    dataStoreGlobal: { loading, editedCategory },
  } = state

  const isCreate = !id

  useEffect(() => {
    if (isCreate) {
      setCategory(dispatch, { ...DEFAULT_CATEGORY })
    } else {
      getCategory(dispatch, id).catch((error) => {
        showErrorMessage(error, dispatch)
        navigate('/global-settings/data-store')
      })
    }

    return () => {
      setCategory(dispatch, null)
    }
  }, [isCreate])

  if (loading || !editedCategory) {
    return <Loader />
  }

  return (
    <div className="data-store-global">
      <div className="heading">
        {isCreate ? 'Add' : 'Edit'} Data Store Category
      </div>
      <CategoryForm />
    </div>
  )
}

export default DataStoreManageCategory
