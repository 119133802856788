import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  utils,
  globalReports,
  publisherFieldMap,
} from '@decision-sciences/qontrol-common'

/* Components */
import TextTag from 'components/text-tag/index'
import { Dropdown } from 'components/dropdown'
import RoundRectangleButton from 'components/round-rectangle-button'
import WidgetSelection from 'components/custom-saved-filters/widget-selection'
import DropdownWithButton from 'components/dropdown-with-button/index'
import DropdownPreviewSelected from 'components/dropdown-preview-selected/index'
import { CheckboxNoHooks } from 'components/checkbox'

/* Icons */
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg'
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'
import { ReactComponent as CloseIcon } from 'assets/icon_close_small.svg'

/* Utils & Constants */
import {
  SECTIONS,
  getStyling,
  SectionIcon,
  getOperation,
} from 'components/custom-saved-filters/view-edit-filters/utils'

import './style.scss'
const { SAVED_VIEW_TYPES_WITH_LABELS } = globalReports
const { ALL_OPERATIONS } = publisherFieldMap.operations
const { getDateRange } = utils.date

const ViewEditFilters = ({
  customEntity,
  setCustomEntity = () => {},
  dateFilter,
  darkMode = false,
  viewMode = false,
  dimensions = [],
  widgets = [],
  highLightedSections = [],
  displayedSections = [],
}) => {
  const [selectedDimension, setSelectedDimension] = useState(null)
  const [openedSection, setOpenedSection] = useState({
    filters: false,
    visuals: false,
    conversions: false,
  })

  const { options } = customEntity
  const { filters, visuals, conversions } = options

  const onSectionChanged = (section) => {
    setOpenedSection({ [section]: !openedSection[section] })
    selectedDimension && setSelectedDimension(null)
  }

  const onAddFilter = (option) => {
    const existingOptions = options?.filters?.map((opt) => opt.name) || []
    const newOption = {
      name: option.value,
      label: option.label,
      key: existingOptions.length + 1,
      condition: {
        dimensions: [],
        operation: option.isSingleSelection
          ? ALL_OPERATIONS.EQ
          : ALL_OPERATIONS.CT,
      },
    }
    const newOptions = [...(options?.filters || []), newOption]
    setCustomEntity({
      ...customEntity,
      options: { ...customEntity.options, filters: newOptions },
    })
  }

  const onRemoveFilter = (option) => {
    const newOptions = options?.filters?.filter((opt) => opt.key !== option.key)
    setCustomEntity({
      ...customEntity,
      options: { ...customEntity.options, filters: newOptions },
    })

    // If dimension is selected, deselect it
    if (selectedDimension && selectedDimension.key === option.key) {
      setSelectedDimension(null)
    }
  }

  const editFilter = (updatedDimension) => {
    const newFilters = options?.filters.map((opt) =>
      opt.name === updatedDimension.name ? updatedDimension : opt
    )
    setSelectedDimension(updatedDimension)
    setCustomEntity({
      ...customEntity,
      options: { ...customEntity.options, filters: newFilters },
    })
  }

  const onChangeOperation = (newOperation) => {
    const updatedDimension = { ...selectedDimension }
    updatedDimension.condition.operation = newOperation
    editFilter(updatedDimension)
  }

  const onAddDimensions = (newDimensions) => {
    const updatedDimension = { ...selectedDimension }
    updatedDimension.condition.dimensions = newDimensions
    editFilter(updatedDimension)
  }

  const onRemoveDimension = (dimension) => {
    const updatedDimension = { ...selectedDimension }
    const newDimensions = updatedDimension.condition.dimensions.filter(
      (dim) => dim !== dimension
    )
    updatedDimension.condition.dimensions = newDimensions
    editFilter(updatedDimension)
  }

  const onChangeVisuals = (editedVisuals) => {
    const newCustomEntity = {
      ...customEntity,
      options: { ...customEntity.options, visuals: editedVisuals },
    }
    setCustomEntity(newCustomEntity)
  }

  return (
    <div
      className={cx('view-edit-filters', {
        'view-edit-filters--dark': darkMode,
      })}
    >
      <div className="display-flex dimensions-section padding-20">
        {displayedSections.map((section) => {
          const highlighted = highLightedSections.includes(section)
          return (
            <RoundRectangleButton
              key={section}
              onClick={() => onSectionChanged(section)}
              className={getStyling(
                openedSection[section],
                darkMode,
                highlighted ? 'highlight' : '',
                highlighted
              )}
              contentRender={() => (
                <SectionIcon
                  section={section}
                  sectionOptions={options[section]}
                  hasCount={section === SECTIONS.FILTERS}
                />
              )}
              width={'fit-content'}
            />
          )
        })}
        {dateFilter ? (
          <div className="filter-box border-white">
            <CalendarIcon
              width={20}
              height={20}
              className="margin-right-5 calendar"
            />
            {getDateRange(dateFilter)}
          </div>
        ) : null}
      </div>
      {openedSection.filters && (
        <FilterOptions
          options={dimensions.map((dim) => ({
            ...dim,
            label: dim.label,
            value: dim.name,
          }))}
          filtersOnEntity={filters}
          selectedFilter={selectedDimension?.key}
          setSelectedFilter={setSelectedDimension}
          darkMode={darkMode}
          viewMode={viewMode}
          onAddFilter={onAddFilter}
          onRemoveFilter={onRemoveFilter}
        />
      )}
      {openedSection.visuals && (
        <WidgetSelection
          widgetName={SAVED_VIEW_TYPES_WITH_LABELS.VISUALS.label}
          widgetOptions={widgets}
          selectedWidgets={visuals || []}
          setSelectedWidgets={onChangeVisuals}
          viewMode={viewMode}
          darkMode={darkMode}
          showClear={false}
        />
      )}
      {openedSection.conversions && (
        <WidgetSelection
          widgetName={SAVED_VIEW_TYPES_WITH_LABELS.CONVERSIONS.label}
          widgetOptions={[]}
          selectedWidgets={conversions || []}
          setSelectedWidgets={() => {}}
          viewMode={viewMode}
          darkMode={darkMode}
          showClear={false}
        />
      )}
      {openedSection.filters && selectedDimension && (
        <FilterProperties
          filter={selectedDimension}
          dimensionOptions={dimensions
            .find(({ name }) => name === selectedDimension.name)
            ?.appliedValues?.map((option) => ({
              ...option,
              label: option.formattedValue,
            }))}
          viewMode={viewMode}
          darkMode={darkMode}
          onChangeOperation={onChangeOperation}
          onAddDimensions={onAddDimensions}
          onRemoveDimension={onRemoveDimension}
        />
      )}
    </div>
  )
}

const FilterOptions = ({
  options = [],
  filtersOnEntity = [],
  selectedFilter,
  setSelectedFilter,
  onAddFilter,
  onRemoveFilter,
  darkMode,
  viewMode,
}) => {
  const addDisabled = filtersOnEntity.length >= 10
  return (
    <div
      className={cx('display-flex dimensions-section border', {
        'border--dark': darkMode,
      })}
    >
      {!viewMode ? (
        <DropdownWithButton
          search
          secondary={darkMode}
          customIcon={
            <RoundRectangleButton
              disabled={addDisabled}
              contentRender={() => <PlusIcon />}
            />
          }
          disabled={addDisabled}
          options={options}
          onSelect={onAddFilter}
        />
      ) : null}
      {filtersOnEntity.map((opt, idx) => {
        let displayLabel = opt.label
        if (!displayLabel) {
          displayLabel =
            options.find((dim) => dim.name === opt.name)?.label || opt.name
        }
        return (
          <div
            key={idx}
            className={getStyling(
              selectedFilter === opt.key,
              darkMode,
              'dimension-option'
            )}
          >
            <div
              onClick={() =>
                selectedFilter === opt.key
                  ? setSelectedFilter(null)
                  : setSelectedFilter({ ...opt, label: displayLabel })
              }
            >
              {displayLabel}
            </div>
            {!viewMode ? (
              <CloseIcon onClick={() => onRemoveFilter(opt)} />
            ) : null}
          </div>
        )
      })}
    </div>
  )
}

const FilterProperties = ({
  filter,
  dimensionOptions = [],
  onAddDimensions,
  onRemoveDimension,
  viewMode,
  darkMode,
  onChangeOperation,
}) => {
  const {
    name: dimensionName,
    condition: { operation, dimensions: selectedDimensions },
    label: dimensionLabel,
  } = filter

  const [allOperations, defaultOperation] = getOperation(filter, operation)
  const displayLabel = dimensionLabel || dimensionName
  const isSingleSelection = filter.isSingleSelection

  return (
    <div
      className={cx('dimension-properties', {
        'dimension-properties--dark': darkMode,
      })}
    >
      <div className={cx('blue-box', { 'blue-box--dark': darkMode })}>
        {displayLabel}
      </div>
      <Dropdown
        defaultState={defaultOperation}
        disabled={viewMode}
        options={allOperations}
        onChange={onChangeOperation}
        dark={darkMode}
      />
      {!viewMode ? (
        <DropdownPreviewSelected
          options={dimensionOptions || []}
          dark={darkMode}
          customHeader
          labelHeader={displayLabel}
          selectAll={!isSingleSelection}
          isSingleSelection={isSingleSelection}
          optionRenderer={(option, selectedItems) => (
            <CheckboxNoHooks
              label={option.label}
              isChecked={selectedItems.indexOf(option.value) > -1}
            />
          )}
          onChange={onAddDimensions}
          selectedItems={selectedDimensions}
        />
      ) : null}
      {selectedDimensions.length ? (
        <div className="selected-dimensions">
          {selectedDimensions.map((dim, index) => {
            return (
              <TextTag
                label={dim}
                key={index}
                onClose={!viewMode ? () => onRemoveDimension(dim) : undefined}
              />
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default ViewEditFilters
ViewEditFilters.propTypes = {
  customEntity: PropTypes.object.isRequired,
  setCustomEntity: PropTypes.func,
  darkMode: PropTypes.bool,
  viewMode: PropTypes.bool,
  dateFilter: PropTypes.string,
  dimensions: PropTypes.array,
  highLightedSections: PropTypes.array,
  displayedSections: PropTypes.array,
  widgets: PropTypes.array,
}
FilterOptions.propTypes = {
  options: PropTypes.array.isRequired,
  filtersOnEntity: PropTypes.array,
  selectedFilter: PropTypes.object,
  setSelectedFilter: PropTypes.func.isRequired,
  onAddFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  darkMode: PropTypes.bool,
  viewMode: PropTypes.bool,
}

FilterProperties.propTypes = {
  filter: PropTypes.object.isRequired,
  dimensionOptions: PropTypes.array.isRequired,
  onAddDimensions: PropTypes.func,
  onRemoveDimension: PropTypes.func,
  onChangeOperation: PropTypes.func,
  darkMode: PropTypes.bool,
  viewMode: PropTypes.bool,
}
