import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Input from 'components/input'
import Loader from 'components/loader'
import NamingDimensions from 'modules/naming-conventions/components/naming-dimensions'
import { fillWithDefaultConventions } from 'modules/naming-conventions/utils'
import {
  granularities,
  namingConventions,
} from '@decision-sciences/qontrol-common'

import './style.scss'

const { GRANULARITIES } = granularities
const { DEFAULT_STATES } = namingConventions

const { CAMPAIGN, AD_GROUP } = GRANULARITIES

const NamingConventions = ({
  isGlobal,
  conventions = DEFAULT_STATES,
  setConventions,
  globalConventions,
  clientId,
  isViewMode,
}) => {
  const completeGlobalConventions = useMemo(
    () => fillWithDefaultConventions(globalConventions),
    [JSON.stringify(globalConventions)]
  )

  const completeConventions = useMemo(() => {
    const filledConventions = fillWithDefaultConventions(
      conventions,
      globalConventions
    )

    // If there's a client and there are global defaults, strip the _id and isGlobal values
    if (clientId) {
      Object.keys(filledConventions).forEach((granularity) => {
        if (filledConventions[granularity].clientId !== clientId) {
          delete filledConventions[granularity]._id
          delete filledConventions[granularity].isGlobal
          filledConventions[granularity].clientId = clientId
        }
      })
    }

    return filledConventions
  }, [JSON.stringify(conventions), completeGlobalConventions])

  // Gather all dimension names in one place for all granularities
  const allDimensionNames = useMemo(
    () =>
      Object.values(completeConventions || {}).reduce(
        (prev, current) => [
          ...prev,
          ...current.dimensions.map(({ dimensionName }) => dimensionName),
        ],
        []
      ),

    [JSON.stringify(completeConventions)]
  )

  const onChangeDimensions = (granularity) => (dimensions) => {
    if (clientId) {
      completeConventions[granularity].clientId = clientId
    }
    completeConventions[granularity].dimensions = dimensions
    setConventions({
      ...(conventions || {}),
      [granularity]: completeConventions[granularity],
    })
  }

  const onChangeDelimiter = (delimiter) => {
    const newConventions = structuredClone(completeConventions)
    Object.keys(newConventions).forEach((granularity) => {
      newConventions[granularity].delimiter = delimiter
    })

    setConventions(newConventions)
  }

  const defaultConventions = isGlobal
    ? DEFAULT_STATES
    : completeGlobalConventions

  if (!globalConventions) {
    return <Loader />
  }

  const setIsCustom = (granularity) => (value) => {
    if (value) {
      setConventions({
        ...(conventions || {}),
        [granularity]: completeConventions[granularity],
      })
    } else {
      setConventions({
        ...(conventions || {}),
        [granularity]: isGlobal ? DEFAULT_STATES[granularity] : null,
      })
    }
  }

  const isCustom = (granularity) => {
    return Boolean(isGlobal || conventions[granularity])
  }

  return (
    <section data-cy="naming-conventions">
      <div
        data-cy="naming-conventions-delimiter"
        className="form__section__body"
      >
        <h3 className="naming-conventions__heading__title">Delimiter</h3>
        <div className="general-description">
          This is the character that is entered between each dimension within
          the naming convention.
        </div>
        <Input
          disabled={isViewMode}
          className="naming-conventions__delimiter input-wrapper--label-top margin-top-15"
          value={completeConventions?.[CAMPAIGN]?.delimiter}
          label="Delimiter"
          maxLength={1}
          blur={() => {
            if (!completeConventions?.[CAMPAIGN]?.delimiter) {
              onChangeDelimiter(
                completeGlobalConventions[CAMPAIGN].delimiter ||
                  DEFAULT_STATES[CAMPAIGN].delimiter
              )
            }
          }}
          onChange={onChangeDelimiter}
        />
      </div>
      <div
        data-cy="campaign-naming-conventions"
        className="form__section__body"
      >
        <NamingDimensions
          title="Campaign Naming Conventions"
          setDimensions={onChangeDimensions(CAMPAIGN)}
          dimensions={completeConventions[CAMPAIGN].dimensions}
          defaultDimensions={defaultConventions[CAMPAIGN].dimensions}
          delimiter={completeConventions?.[CAMPAIGN]?.delimiter}
          isGlobal={isGlobal}
          isViewMode={isViewMode}
          allDimensionNames={allDimensionNames}
          isCustom={isCustom(CAMPAIGN)}
          setIsCustom={setIsCustom(CAMPAIGN)}
        />
      </div>
      <div data-cy="ad-set-naming-conventions" className="form__section__body">
        <NamingDimensions
          title="Ad Set Naming Conventions"
          setDimensions={onChangeDimensions(AD_GROUP)}
          dimensions={completeConventions[AD_GROUP].dimensions}
          defaultDimensions={defaultConventions[AD_GROUP].dimensions}
          delimiter={completeConventions?.[AD_GROUP]?.delimiter}
          isGlobal={isGlobal}
          isViewMode={isViewMode}
          allDimensionNames={allDimensionNames}
          isCustom={isCustom(AD_GROUP)}
          setIsCustom={setIsCustom(AD_GROUP)}
        />
      </div>
    </section>
  )
}

NamingConventions.propTypes = {
  globalConventions: PropTypes.object.isRequired,
  setConventions: PropTypes.func.isRequired,
  isGlobal: PropTypes.bool,
  conventions: PropTypes.object,
  clientId: PropTypes.string,
  isViewMode: PropTypes.bool,
}

export default NamingConventions
