import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

/* Hooks */
import useSession from 'modules/session'

/* Components */
import ImportUsersModule from 'modules/users/import-users/index'

const ImportUsers = () => {
  const navigate = useNavigate()
  const [, user] = useSession()
  const isAdmin = user.isSuperAdmin

  /* On mount or permission change, check if user has correct permission */
  // TO DO: Add check for permission AP-
  useEffect(() => {
    if (!isAdmin) {
      navigate('/unauthorized', { replace: true })
    }
  }, [isAdmin])

  return isAdmin ? <ImportUsersModule /> : null
}

export default ImportUsers
