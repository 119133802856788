import React, { useState, useEffect } from 'react'
import { useStore } from 'store'
import PropTypes from 'prop-types'
import uniqBy from 'lodash.uniqby'
import isEqual from 'lodash.isequal'

/* Actions */
import {
  fetchEntitiesForGranularity,
  getPublishers,
  getAlertTypes,
} from 'modules/traq-templates/actions'

/* Components */
import Filters from 'components/filters'
import { Dropdown } from 'components/dropdown'
import { DropdownMultiSelect } from 'components/dropdown-multi-select'
import Input from 'components/input'
import { CheckboxNoHooks } from 'components/checkbox'
import Button from 'components/button'
import { openModal, closeModal } from 'components/modal/actions'

/* Assets */
import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'

/* Constants */
import { accounts } from '@decision-sciences/qontrol-common'
import { GRANULARITIES } from './constants'

/* Styling */
import './style.scss'

const { ACCOUNT_TYPE_NAMES } = accounts

/**
 *
 * @param {Object} props props
 * @param {Function} props.resetItemsToLoad function to reset number of items to load
 * @param {Object} props.filterState state of filters
 * @param {Function} props.setFilterState function to set state of filters
 * @param {Object} props.initialFilterState initial state of filters
 * @param {Function} props.resetCards function to reset cards
 * @param {Function} props.setCardsLoading function to set the global loading state
 * @param {String} props.traqTemplateId all entities grouped per granularity
 * @param {String} props.selectedClient client
 * @returns {React.Component}
 */
export const AlertResultFilters = ({
  resetItemsToLoad = () => {},
  filterState,
  setFilterState,
  initialFilterState,
  resetCards = () => {},
  setCardsLoading = () => {},
  traqTemplateId,
  selectedClient,
}) => {
  /**
   * Filter State
    {
      -> Top search bar: searched for alert name or message
      search: null,
      -> ALert type filter
      alertType: null,
      -> Publisher filter
      channel: null,
      -> Account filter - enabled then this.granularity contains 'Account'
      account: null,
      -> List of selected granularities
      granularity: [],
      -> Campaign filter - enabled then this.granularity contains 'Campaign'
      campaigns: null,
      -> AdGroup filter - enabled then this.granularity contains 'Ad Group / Ad Set'
      adGroups: null,
      -> Ad filter - enabled then this.granularity contains 'Ad'
      ads: null,
      -> Keyword filter - enabled then this.granularity contains 'Keyword'
      keywords: null,
      -> Entities to fill the filtering drop-downs per granularity
      allEntitiesPerGranularity: {},
    }
   */

  const { dispatch } = useStore()
  const [loading, setLoading] = useState(false)
  const [loadingGranularity, setLoadingGranularity] = useState()
  const [localFilterState, setLocalFilterState] = useState(filterState)

  /* Publishers that have alert-results */
  const [publishers, setPublishers] = useState(null)
  /* Alert Types that have alert-results */
  const [alertTypes, setAlertTypes] = useState(null)

  const fetchPublishers = async (newLocalFilterState = localFilterState) => {
    setLoading(true)
    const results = await getPublishers(
      traqTemplateId,
      selectedClient,
      newLocalFilterState.alertType
    )

    if (results) {
      setPublishers(results)
      setLoading(false)
      return results
    }

    setLoading(false)

    return null
  }

  const fetchAlertTypes = async (newLocalFilterState = localFilterState) => {
    setLoading(true)
    const results = await getAlertTypes(
      traqTemplateId,
      selectedClient,
      newLocalFilterState.channel
    )
    if (results) {
      setAlertTypes(results)
      setLoading(false)
      return results
    }

    setLoading(false)
    return null
  }

  // Fetch Publishers and alert-types
  useEffect(() => {
    if (traqTemplateId && selectedClient) {
      fetchPublishers()
      fetchAlertTypes()
    }
  }, [traqTemplateId, selectedClient])

  // Allow updating local filter state from outside
  useEffect(() => {
    setLocalFilterState({ ...localFilterState, ...filterState })
  }, [filterState])

  /* Fetch filtered alert-results */
  const applyFilters = () => {
    // Only apply if local filters differ from global (previously applied) filters
    if (!isEqual(localFilterState, filterState)) {
      setLoading(true)
      resetCards()
      setCardsLoading(true)
      setFilterState(localFilterState)
      resetItemsToLoad()
      setLoading(false)
    }
  }

  /* Clear filters and refetch all alert-results */
  const clearFilters = () => {
    // Only clear if local filters differ from initial filter state
    if (!isEqual(localFilterState, initialFilterState)) {
      setLoading(true)
      resetCards()
      setCardsLoading(true)
      setLocalFilterState({ ...initialFilterState })
      setFilterState({ ...initialFilterState })
      resetItemsToLoad()
      fetchAlertTypes({ ...initialFilterState })
      fetchPublishers({ ...initialFilterState })
      setLoading(false)
    }
  }

  /**
   * Fetch entities for filtering for a specific granularity
   * @param {Object} options options
   * @param {String} options.granularity
   * @param {String} options.searchValue granularity search value
   * @param {Object} options.newLocalFilterState pending local filter state
   * @param {Boolean} options.all fetching for all selected granularities. In this case return the fetched granularities instead of setting them into the state
   */
  const fetchForGranularity = async ({
    granularity,
    searchValue = null,
    newLocalFilterState = localFilterState,
    all = false,
  }) => {
    const { hierarchy } = GRANULARITIES[granularity]

    setLoadingGranularity(granularity)
    const res = await fetchEntitiesForGranularity(
      traqTemplateId,
      granularity,
      selectedClient,
      // All selected parent entities per granularities
      Object.values(GRANULARITIES).reduce(
        (acc, { key, self, hierarchy: parentHierarchy }) =>
          newLocalFilterState[key]?.length && parentHierarchy < hierarchy
            ? {
                ...acc,
                [self]: newLocalFilterState[key],
              }
            : acc,
        {}
      ),
      searchValue,
      newLocalFilterState.channel
    ).catch((e) => {
      console.error(e)
      newLocalFilterState.allEntitiesPerGranularity = {
        ...newLocalFilterState.allEntitiesPerGranularity,
        [granularity]: [],
      }
    })

    if (!all) {
      newLocalFilterState.allEntitiesPerGranularity = {
        ...newLocalFilterState.allEntitiesPerGranularity,
        [granularity]: res,
      }

      setLocalFilterState(newLocalFilterState)
      setLoadingGranularity(null)
    } else {
      return { [granularity]: res }
    }
  }

  /**
   * Fetch filtering entities for all selected granularities
   * @param {Object} newLocalFilterState updated pending filter state
   * @param {String} skipGranularity Skip refetching for this granularity. Used for auto-refeetching when cross-filtering so the current granularity is kept as is
   */
  const fetchForAllSelectedGranularities = async (
    newLocalFilterState = localFilterState,
    skipGranularity = null
  ) => {
    const allResults = await Promise.all(
      newLocalFilterState?.granularity
        .filter((granularity) => granularity !== skipGranularity)
        .map((granularity) =>
          fetchForGranularity({ granularity, newLocalFilterState, all: true })
        )
    )

    newLocalFilterState.allEntitiesPerGranularity = {
      ...newLocalFilterState.allEntitiesPerGranularity,
      ...allResults.reduce(
        (acc, granularityWithResults) => ({
          ...acc,
          ...granularityWithResults,
        }),
        {}
      ),
    }

    setLocalFilterState(newLocalFilterState)
    setLoadingGranularity(null)
  }

  /**
   * Add / remove a granularity from the selections. Refetch values for granularity in case of sleecting
   * @param {String} granularity
   * @param {String} value
   */
  const onGranularityCheck = (granularity, value) => {
    const { key } = GRANULARITIES[granularity]

    const newLocalFilterState = {
      ...localFilterState,
      granularity: value
        ? [...localFilterState.granularity, granularity]
        : localFilterState.granularity.filter((gran) => gran !== granularity),
      allEntitiesPerGranularity: {
        ...localFilterState.allEntitiesPerGranularity,
        [granularity]: [],
      },
      ...(!value ? { [key]: null } : {}),
    }

    if (!value) {
      setLocalFilterState(newLocalFilterState)
      return
    }

    // Fetch entities for newly selected granularity
    fetchForGranularity({ granularity, newLocalFilterState })
  }

  /**
   * There are orphan children selected
   * Return false when removing the last element from a granularity, since "All" would remain after, and it doesn;t impact selected children
   * @param {String} granularity
   * @param {Array} parentValues
   * @param {Object} newLocalFilterState
   * @returns {Boolean}
   */
  const thereAreOrphans = (granularity, parentValues, newLocalFilterState) => {
    const { id, hierarchy } = GRANULARITIES[granularity]

    if (!parentValues?.length) {
      return false
    }

    let thereAreOrphansSelected = false
    for (const [
      childGranularity,
      { key: childGranularityKey, hierarchy: childHierarchy },
    ] of Object.entries(GRANULARITIES)) {
      if (
        childHierarchy > hierarchy &&
        newLocalFilterState[childGranularityKey]?.some(({ value: childId }) =>
          newLocalFilterState.allEntitiesPerGranularity[childGranularity].some(
            (childEntity) =>
              childEntity.id === childId &&
              !parentValues.some(({ value }) => value === childEntity[id])
          )
        )
      ) {
        thereAreOrphansSelected = true
        break
      }
    }

    return thereAreOrphansSelected
  }

  /**
   * Warning modal when selecting a parent that doesn't contain the already selected children
   * @param {Array} selectedValues
   * @param {String} granularity
   * @returns {Promise<Object>} The new state of filters
   */
  const waitForWarningModal = (selectedValues, granularity) =>
    new Promise((resolve) => {
      const warningModal = openModal(dispatch, {
        heading: 'Granularity Warning',
        icon: <WarningIcon />,
        rightAlignButtons: true,
        children: (
          <p className="placements-disable-edit-modal__text">
            Some of the selected child granularities are not associated with the
            selected parent granularities. Do you want to keep the selected
            parent granularities? This will remove child granularities not
            associated with the selected parent granularities.
          </p>
        ),
        button: (
          <Button
            green
            value="Confirm"
            onClick={() => {
              const newLocalFilterState = { ...localFilterState }
              const { id, key } = GRANULARITIES[granularity]

              // Elliminate children that no longer belong to the selected parents
              Object.entries(GRANULARITIES).forEach(
                ([childGranularity, { key: childGranularityKey }]) => {
                  if (newLocalFilterState[childGranularityKey]?.length) {
                    const newList = newLocalFilterState[
                      childGranularityKey
                    ].filter(({ value: childId }) =>
                      newLocalFilterState.allEntitiesPerGranularity[
                        childGranularity
                      ].some(
                        (childEntity) =>
                          childEntity.id === childId &&
                          selectedValues.some(
                            ({ value }) => value === childEntity[id]
                          )
                      )
                    )
                    newLocalFilterState[childGranularityKey] = newList.length
                      ? newList
                      : null
                  }
                }
              )

              resolve({
                ...newLocalFilterState,
                [key]: selectedValues.length ? selectedValues : null,
              })
              closeModal(dispatch, warningModal)
            }}
          />
        ),
        buttonSecondary: (
          <Button
            secondaryGray
            value="Cancel"
            onClick={() => {
              resolve(localFilterState)
              closeModal(dispatch, warningModal)
            }}
          />
        ),
      })
    })

  /**
   * Update all other entity filters based on the selection for the current granularity
   * In case of selecting a parent and there are children selected that no longer belong to it, show a warning, then deselect them in case the user choses to
   * @param {Array<String>} values new state of edited dropdown
   * @param {String} granularity
   * @param {Boolean} withSearch Selection was made after a search. In this case refetch the original values
   */
  const onGranularityFilterChange = async (
    values,
    granularity,
    withSearch = false
  ) => {
    const { key } = GRANULARITIES[granularity]

    const showWarningModal = thereAreOrphans(
      granularity,
      values,
      localFilterState
    )

    const newLocalFilterState = showWarningModal
      ? // If there are orphans, make the warning modal appear
        await waitForWarningModal(values, granularity)
      : { ...localFilterState, [key]: values?.length ? values : null }

    // Refetch children when selecting parents
    await fetchForAllSelectedGranularities(
      newLocalFilterState,
      withSearch ? null : granularity
    )

    setLocalFilterState(newLocalFilterState)
  }

  const onPublisherChange = async (channel) => {
    const newLocalFilterState = { ...localFilterState, channel }
    const newAlertTypes = await fetchAlertTypes(newLocalFilterState)

    if (!newAlertTypes?.includes(localFilterState.alertType)) {
      newLocalFilterState.alertType = null
    }
    await fetchForAllSelectedGranularities({ ...newLocalFilterState }, null)
  }

  const onAlertTypeChange = async (alertType) => {
    const newLocalFilterState = { ...localFilterState, alertType }
    const newPublishers = await fetchPublishers(newLocalFilterState)

    // Remove selected publishers that are no longer available
    const newStatePublishers = localFilterState.channel?.filter((val) =>
      newPublishers?.some((newVal) => newVal === val)
    )
    newLocalFilterState.channel = newStatePublishers?.length
      ? newStatePublishers
      : null

    setLocalFilterState(newLocalFilterState)
  }

  return (
    <Filters
      loading={loading || loadingGranularity}
      onApply={applyFilters}
      onClear={clearFilters}
      disableApply={Object.values(localFilterState).every(
        (entry) => !entry || !Object.keys(entry).length || !entry.length
      )}
    >
      {/* Search */}
      <Input
        onChange={(search) =>
          setLocalFilterState({ ...localFilterState, search })
        }
        value={localFilterState.search}
        placeholder="Search..."
        searchBlue
      />

      {/* Alert type */}
      <div className="label">Alert Type(s)</div>
      <Dropdown
        defaultOptionText="All Alert Types"
        options={alertTypes?.map((c) => ({
          label: c,
          value: c,
        }))}
        defaultState={localFilterState.alertType}
        deselectLabel="All Alert Types"
        onChange={onAlertTypeChange}
        loading={loading}
      />

      {/* Publisher */}
      <div className="label">Publisher(s)</div>
      <Dropdown
        defaultOptionText="All"
        options={publishers?.map((publisher) => ({
          label: ACCOUNT_TYPE_NAMES[publisher],
          value: publisher,
        }))}
        selectedItems={localFilterState.channel || []}
        deselectLabel="All"
        onChange={onPublisherChange}
        multiSelect
        loading={loading}
      />

      <p className="general-label__description">
        Filter by granularity below. Selected parent granularities will
        respectively reduce the options in the selected children granuliarities.
        Only the first 50 options in alphabetic order will be displayed per
        field. However, searching within the dropdown will search all options
        for that granularity.
      </p>

      {/* Granularity selection */}
      <div className="label">Granularity</div>
      {Object.keys(GRANULARITIES).map((granularity) => (
        <CheckboxNoHooks
          key={granularity}
          label={granularity}
          defaultValue={localFilterState.granularity?.includes(granularity)}
          className="checkbox-first"
          onChange={(value) => onGranularityCheck(granularity, value)}
          disabled={loadingGranularity || loading}
        />
      ))}

      {/* Granularity Filters */}
      {localFilterState?.granularity
        .sort((granularityA, granularityB) => {
          const { order: orderA } = GRANULARITIES[granularityA]
          const { order: orderB } = GRANULARITIES[granularityB]

          return orderA > orderB ? 1 : -1
        })
        .map((granularity, index) => {
          const { label, key } = GRANULARITIES[granularity]

          const andLabel = index ? (
            <div className="button-tag button-tag--and">AND</div>
          ) : null

          return (
            <>
              {andLabel}
              <DropdownMultiSelect
                key={granularity}
                options={uniqBy(
                  localFilterState?.allEntitiesPerGranularity?.[
                    granularity
                  ]?.map(({ name, id }) => ({
                    value: id,
                    label: name || id,
                  })) || [],
                  'value'
                )}
                selectedItems={localFilterState[key] || []}
                onChange={async (values, withSearch) =>
                  onGranularityFilterChange(values, granularity, withSearch)
                }
                disabled={loadingGranularity || loading}
                label={label}
                onSearch={(searchValue) =>
                  fetchForGranularity({ granularity, searchValue })
                }
                loading={loadingGranularity || loading}
                error={null}
              />
            </>
          )
        })}

      {/* Blank space */}
      <div className="blank"></div>
    </Filters>
  )
}

AlertResultFilters.propTypes = {
  resetItemsToLoad: PropTypes.func,
  filterState: PropTypes.object.isRequired,
  setFilterState: PropTypes.func.isRequired,
  initialFilterState: PropTypes.object.isRequired,
  resetCards: PropTypes.func,
  setCardsLoading: PropTypes.func,
  traqTemplateId: PropTypes.string,
  selectedClient: PropTypes.string,
}
