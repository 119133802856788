import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import PropTypes from 'prop-types'
import { socket } from '@decision-sciences/qontrol-common'

import CollapsibleSection from 'components/collapsible-section'
import Button from 'components/button'
import Loader from 'components/loader'
import { CheckboxNoHooks } from 'components/checkbox'
import Icon from 'components/icon/index'
import ConfirmationModal from 'components/confirmation-modal'

import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'

import CampaignComparisonTable from './components/campaign-comparison-table'
import CampaignComparisonSettings from './components/campaign-comparison-create'
import {
  deleteCampaignComparison,
  editCampaignComparison,
  getCampaignComparisonsByCompanyId,
  saveCampaignComparison,
} from '../../actions'

import './style.scss'

const { CACHING_DONE_FOR } = socket

/**
 * Campaign Comparison group Section and all its related business logic
 * @param {Object} props
 * @param {Boolean} props.isViewMode
 * @param {Object} props.company - Company for which we render the campaign group section
 */
const CampaignComparisonSection = ({
  isViewMode,
  company,
  inactiveCampaignsFlag,
  onChangeInactiveCampaignsFlag,
}) => {
  const { flags, setFlags } = useOutletContext()
  const [campaignComparison, setCampaignComparison] = useState({})
  const [availableCampaigns, setAvailableCampaigns] = useState([])
  const [isComparisonSettingsPanelOpened, setIsComparisonSettingsPanelOpened] =
    useState(false)
  const [isComparisonPanelPending, setIsComparisonPanelPending] =
    useState(false)
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false)
  const [editedGroup, setEditedGroup] = useState(null)
  const hasComparisonGroups = campaignComparison.comparisonGroups?.length
  const [groupIdToBeDeleted, setGroupIdToBeDeleted] = useState('')

  const [loadingInactiveCheck, setLoadingInactiveCheck] = useState(false)

  const companyId = company._id || company.id

  useEffect(() => {
    // Fetch the campaign comparison groups on mount
    fetchCampaignComparisons()
  }, [])

  useEffect(() => {
    if (
      inactiveCampaignsFlag &&
      flags[companyId]?.[CACHING_DONE_FOR.COMPARISON_GROUPS_INACTIVE_CAMPAIGNS]
    ) {
      fetchCampaignComparisons()
      setFlags(companyId, {
        [CACHING_DONE_FOR.COMPARISON_GROUPS_INACTIVE_CAMPAIGNS]: false,
      })
    }
  }, [
    flags[companyId]?.[CACHING_DONE_FOR.COMPARISON_GROUPS_INACTIVE_CAMPAIGNS],
  ])

  const cleanStates = () => {
    setIsComparisonSettingsPanelOpened(false)
    setIsDeleteInProgress(false)
    setIsComparisonPanelPending(false)
  }

  const handleError = (error) => {
    console.error(error.message || JSON.stringify(error))
    cleanStates()
  }

  const fetchCampaignComparisons = () => {
    if (company.new) {
      return
    }
    getCampaignComparisonsByCompanyId(companyId).then((res) => {
      if (res) {
        const { comparisonGroups, campaigns } = res
        setAvailableCampaigns(campaigns)
        setCampaignComparison({ comparisonGroups: comparisonGroups || [] })
        cleanStates()
      } else {
        setCampaignComparison({ comparisonGroups: [] })
      }
    })
  }

  const onEditClicked = (group) => {
    setEditedGroup(group)
    setIsComparisonSettingsPanelOpened(true)
  }

  const onCancelClicked = () => {
    setIsComparisonSettingsPanelOpened(false)
    setEditedGroup(null)
  }

  const onDeleteClicked = (comparisonId) => {
    setIsDeleteInProgress(true)
    deleteCampaignComparison(companyId, comparisonId)
      .then((response) => {
        if (response.success) {
          setCampaignComparison({})
          fetchCampaignComparisons()
        }
      })
      .catch(handleError)
  }

  const onSaveClicked = (newComparion) => {
    let action = saveCampaignComparison

    // If the comparison group already has an _id, then it's the edit scenario
    if (newComparion._id) {
      action = editCampaignComparison
    }

    setIsComparisonPanelPending(true)
    action(companyId, newComparion)
      .then((response) => {
        if (response.success) {
          fetchCampaignComparisons()
        }
      })
      .catch(handleError)
  }

  const _renderAddHeader = (inactiveCampaignsFlag, loadingInactiveCheck) => {
    if (isViewMode) {
      return null
    }
    return (
      <div className="display-flex  comparison-group-header-button">
        <Button
          className="comparison-group-inactive-check"
          secondary
          onClick={() => {
            setLoadingInactiveCheck(true)
            onChangeInactiveCampaignsFlag(!inactiveCampaignsFlag).finally(
              () => {
                fetchCampaignComparisons()
                setLoadingInactiveCheck(false)
              }
            )
          }}
          disabled={loadingInactiveCheck}
          value={
            <CheckboxNoHooks
              reversed
              defaultValue={inactiveCampaignsFlag}
              disabled={loadingInactiveCheck}
              label={
                <div className="display-flex">
                  <div className="comparison-group-inactive-label">
                    INCLUDE INACTIVE CAMPAIGNS
                  </div>
                  <Icon
                    width={24}
                    height={24}
                    tooltip={
                      <div className="comparison-group-inactive-check__tooltip">
                        <Icon>
                          <WarningIcon />
                        </Icon>
                        <div>
                          <p className="first-paragraph comparison-group-inactive-check__tooltip-text">
                            Check to include inactive campaigns in the Select
                            Campaigns dropdown for all comparison groups.
                          </p>
                          <p className="comparison-group-inactive-check__tooltip-text">
                            Checking this can take several minutes to process.
                          </p>
                        </div>
                      </div>
                    }
                    tooltipClass="comparison-group-inactive-check__tooltip"
                    className="include-inactive-campaigns__info"
                  >
                    <InfoIcon width={18} height={18} />
                  </Icon>
                </div>
              }
            />
          }
        />

        <Button
          value={
            <div className="action-button">
              <PlusIcon />
              Add Comparison Group
            </div>
          }
          onClick={() => setIsComparisonSettingsPanelOpened(true)}
          className="comparison-group-header-button"
          disabled={
            isComparisonSettingsPanelOpened ||
            !campaignComparison.comparisonGroups
          }
          secondary
        />
      </div>
    )
  }

  const _renderCreateGroupPanel = () => {
    if (!isComparisonSettingsPanelOpened) {
      return null
    }

    if (isComparisonPanelPending) {
      return <Loader />
    }

    return (
      <div className="campaign-exclusions-section">
        <CampaignComparisonSettings
          onSaveClicked={onSaveClicked}
          onCancelClicked={onCancelClicked}
          editedGroup={editedGroup}
          comparisons={campaignComparison.comparisonGroups}
          availableCampaigns={availableCampaigns}
          setGroupIdToBeDeleted={setGroupIdToBeDeleted}
        />
      </div>
    )
  }

  const _renderGroupsTable = () => {
    if (!hasComparisonGroups) {
      return
    }

    if (isDeleteInProgress) {
      return <Loader />
    }

    return (
      <div className="campaign-exclusions-section">
        <CampaignComparisonTable
          isViewMode={isViewMode}
          disabledActions={isComparisonSettingsPanelOpened}
          comparisons={campaignComparison.comparisonGroups}
          setGroupIdToBeDeleted={setGroupIdToBeDeleted}
          onEditClicked={onEditClicked}
        />
      </div>
    )
  }

  return (
    <CollapsibleSection
      header="Comparison Groups"
      id="comparison-groups"
      uncollapsible={!campaignComparison.comparisonGroups}
      defaultCollapsed={!isComparisonSettingsPanelOpened}
      extras={_renderAddHeader(inactiveCampaignsFlag, loadingInactiveCheck)}
    >
      {!campaignComparison.comparisonGroups ? (
        <Loader />
      ) : (
        <div data-cy="comparison-groups">
          {_renderCreateGroupPanel()}
          {_renderGroupsTable()}

          <ConfirmationModal
            heading="Delete Comparison Group"
            message={
              <>
                <p>
                  You are about to delete a comparison group. This action <br />
                  cannot be undone.
                </p>
                <p>Click the Confirm button to continue.</p>
              </>
            }
            showModal={Boolean(groupIdToBeDeleted)}
            onCancelClicked={() => setGroupIdToBeDeleted('')}
            onConfirmClicked={() => {
              onDeleteClicked(groupIdToBeDeleted)
              setGroupIdToBeDeleted('')
            }}
          />
        </div>
      )}
    </CollapsibleSection>
  )
}

export default CampaignComparisonSection

CampaignComparisonSection.propTypes = {
  isViewMode: PropTypes.bool,
  company: PropTypes.object.isRequired,
  inactiveCampaignsFlag: PropTypes.bool,
  onChangeInactiveCampaignsFlag: PropTypes.func,
}
