import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import CollapsibleSection from 'components/collapsible-section'
import ConfirmationModal from 'components/confirmation-modal'
import OfflineData from 'modules/offline-data'

import {
  getGlobalOfflineData,
  deleteOfflineData,
  deleteAllOfflineData,
  getAllOfflineDataByCompanyId,
  saveOfflineData,
  updateOfflineData,
} from 'modules/offline-data/actions'

import Loader from 'components/loader'
import Button from 'components/button'
import Icon from 'components/icon'
import { useLoading } from 'components/utils/custom-hooks'
import Toggle from 'components/toggle'

import { ReactComponent as FileIcon } from 'assets/icon_file_upload.svg'
import { offlineData } from '@decision-sciences/qontrol-common'

import './style.scss'

const { DEFAULT_STATE, OFFLINE_DATA_DIMENSION_TYPE } = offlineData

const OfflineDataSection = ({ clientId, isViewMode, errors, setErrors }) => {
  const [offlineData, setOfflineData] = useState(null)
  const [globalOfflineData, setGlobalOfflineData] = useState(DEFAULT_STATE)
  const [openSection, setOpenSection] = useState(null)
  const [offlineDataEnabled, setOfflineDataEnabled] = useState(
    Boolean(offlineData?.length)
  )
  const [isEditableMode, setIsEditableMode] = useState(false)
  const [offlineIdToBeDeleted, setOfflineIdToBeDeleted] = useState()
  const [offlineDataDeleteAll, setOfflineDataDeleteAll] = useState()

  const [loading, toggleLoading] = useLoading()

  const fetchOfflineData = () => {
    getAllOfflineDataByCompanyId(clientId)
      .then((res) => {
        if (res && res?.success) {
          const { result } = res
          setOfflineData(result)
          setOfflineDataEnabled(Boolean(result?.length))
        } else {
          setOfflineData([])
        }
      })
      .catch((error) => {
        setOfflineData([])
        console.error(error)
      })
  }

  useEffect(() => {
    if (offlineData === null) {
      fetchOfflineData()
    }
  }, [offlineData])

  useEffect(() => {
    toggleLoading('GLOBAL', true)
    getGlobalOfflineData()
      .then((result) => {
        if (result) {
          setGlobalOfflineData(result)
        }
      })
      .catch(console.error)
      .finally(() => {
        toggleLoading('GLOBAL', false)
      })
  }, [])

  const _addConversionId = (dimensions) =>
    dimensions.map(({ _id, dimensionType, ...rest }) => {
      const dimension = {
        dimensionType,
        ...rest,
      }

      if (dimensionType === OFFLINE_DATA_DIMENSION_TYPE.conversion.key) {
        dimension.conversion_id = uuidv4()
      }

      return dimension
    })

  const onNew = () => {
    const newOfflineDataItem = {
      id: uuidv4(),
      clientId,
      isGlobal: false,
      dimensions: _addConversionId([
        ...globalOfflineData.dimensions.map(({ _id, ...rest }) => ({
          ...rest,
        })),
      ]),
      isNew: true,
    }

    const newOfflineData = Array.isArray(offlineData)
      ? structuredClone(offlineData)
      : []
    newOfflineData.push(newOfflineDataItem)

    setOfflineData(newOfflineData)
    setIsEditableMode(true)
  }

  const onChange = (_data) => {
    const newOfflineData = Array.isArray(offlineData)
      ? structuredClone(offlineData)
      : []

    const offlineDataIndex = (offlineData || []).findIndex((data) =>
      _data._id ? _data._id === data._id : data.id === _data.id
    )

    if (offlineDataIndex !== -1) {
      newOfflineData[offlineDataIndex] = _data
    } else {
      newOfflineData.push(_data)
    }

    if (_data.filename) {
      setOpenSection(_data.filename)
    }

    setOfflineData(newOfflineData)
  }

  const onToggleOfflineData = (value) => {
    if (!value && offlineData?.length) {
      setOfflineDataDeleteAll(true)
    } else {
      setErrors({})
      setOfflineDataEnabled(value)
    }
  }

  const onRemove = (data) => () => {
    setOfflineIdToBeDeleted(data._id)
  }

  const onCancel = (data) => () => {
    if (data.isNew) {
      const newOfflineData = (
        Array.isArray(offlineData) ? structuredClone(offlineData) : []
      ).filter((el) => !el.isNew)
      setOfflineData(newOfflineData)
    }
    setOpenSection(null)
    setIsEditableMode(false)
  }

  const onSave = (data) => {
    if (data.isNew) {
      saveOfflineData(clientId, data)
        .then((result) => {
          if (result.success) {
            fetchOfflineData()
          }
        })
        .catch(console.error)
    } else {
      updateOfflineData(clientId, data)
        .then((result) => {
          if (result.success) {
            fetchOfflineData()
          }
        })
        .catch(console.error)
    }
    setOpenSection(null)
    setIsEditableMode(false)
  }

  return (
    <CollapsibleSection
      header="Offline Data"
      defaultCollapsed={false}
      wrapperClassName="form__section"
    >
      {loading || !globalOfflineData ? (
        <Loader />
      ) : (
        <div
          data-cy="offline-data-section"
          className="form__section__body offline-data__section-body"
        >
          <Toggle
            className="offline-data-section__toggle"
            label="Import Offline Data"
            disabled={isViewMode}
            defaultChecked={offlineDataEnabled}
            value={offlineDataEnabled}
            onChange={onToggleOfflineData}
            manualToggle={true}
          />
          {offlineDataEnabled && (
            <div className="offline-data-section__description">
              Enter the core filename. Then, place the dimensions in the order
              of the client’s file.
            </div>
          )}
          {!isViewMode && offlineDataEnabled ? (
            <div className="offline-data-section__add">
              <Button
                disabled={isEditableMode}
                onClick={onNew}
                value={
                  <Icon>
                    <FileIcon /> Import File Setting
                  </Icon>
                }
                secondary
              />
            </div>
          ) : null}
          {(offlineData || []).map((data, index) => (
            <OfflineData
              key={index}
              clientId={clientId}
              fileName={data.filename}
              isOpen={
                data.filename === openSection || (!data.fileName && data.isNew)
              }
              onCollapse={(collapsed) => {
                if (!collapsed) {
                  setOpenSection(null)
                } else if (data.filename !== openSection) {
                  setOpenSection(data.filename)
                }
              }}
              globalOfflineData={globalOfflineData}
              setOfflineData={onChange}
              offlineData={data}
              isViewMode={isViewMode}
              isEditMode={isEditableMode}
              setEditMode={setIsEditableMode}
              errors={errors?.[data.filename] || {}}
              setErrors={(offlineDataErrors) => {
                const newErrors = { ...errors }
                newErrors[data.filename] = offlineDataErrors
                setErrors(newErrors)
              }}
              allFilenames={offlineData.reduce(
                (prev, { filename }) => (filename ? [...prev, filename] : prev),
                []
              )}
              onRemove={onRemove(data)}
              onCancel={onCancel(data)}
              onSave={onSave}
            />
          ))}
        </div>
      )}
      <ConfirmationModal
        heading="Delete Imported File"
        message={
          <div>
            You are about to delete an imported file. <br />
            Click the Confirm button to continue with this action. This <br />
            action cannot be undone.
          </div>
        }
        showModal={Boolean(offlineIdToBeDeleted)}
        onCancelClicked={() => setOfflineIdToBeDeleted('')}
        onConfirmClicked={() => {
          deleteOfflineData(clientId, offlineIdToBeDeleted)
            .then((result) => {
              if (result.success) {
                fetchOfflineData()
              }
            })
            .catch(console.error)
          setOfflineIdToBeDeleted('')
          setIsEditableMode(false)
        }}
      />
      <ConfirmationModal
        heading="Turn Off Import Offline Data"
        message={
          <div>
            You are about to turn off the Import Offline Data feature,
            <br />
            which will delete all imported settings.
            <br />
            Click the Confirm button to continue with this action. This action
            <br />
            cannot be undone.
          </div>
        }
        showModal={Boolean(offlineDataDeleteAll)}
        onCancelClicked={() => {
          setOfflineDataDeleteAll(false)
          setOfflineDataEnabled(true)
        }}
        onConfirmClicked={() => {
          deleteAllOfflineData(clientId)
            .then((result) => {
              if (result.success) {
                fetchOfflineData()
                setOfflineDataEnabled(false)
                setOfflineDataDeleteAll(false)
              }
            })
            .catch(console.error)
        }}
      />
    </CollapsibleSection>
  )
}

OfflineDataSection.propTypes = {
  clientId: PropTypes.string.isRequired,
  currentCompany: PropTypes.object.isRequired,
  isViewMode: PropTypes.bool,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
}

export default OfflineDataSection
