import { ERRORS } from 'components/validator'

/**
 * Function which validates a Data Store > Category
 * @param {Object} category Category to validate
 * @returns {[Boolean, Object]} Validation outcome
 */
export const validateCategory = (category) => {
  let isValid = true
  const errors = {}

  if (!category.name) {
    isValid = false
    errors.name = ERRORS.REQUIRED
  }

  return [isValid, errors]
}

/**
 * Function which validates a Data Store > Dimension
 * @param {Object} dimension Dimension to validate
 * @returns {[Boolean, Object]} Validation outcome
 */
export const validateDimension = (dimension) => {
  let isValid = true
  const errors = {}

  if (!dimension.dimension) {
    isValid = false
    errors.dimension = ERRORS.REQUIRED
  }

  if (!dimension.placement) {
    isValid = false
    errors.placement = ERRORS.REQUIRED
  }

  return [isValid, errors]
}
