import React, { useMemo, useState } from 'react'
import { useStore } from 'store'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import cx from 'classnames'
import { Link } from 'react-router-dom'

// Hooks
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

// Components
import Button from 'components/button'
import { getTooltipList } from 'components/utils/tooltip'
import Table from 'components/table/beta'
import Spacer from 'components/spacer'

// Icons
import blueArrowIcon from 'assets/icon_arrow_blue.svg'
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'

// Actions
import { setBulkEditCompanies } from 'modules/companies/actions'
import {
  utils,
  entityStatus,
  accounts,
} from '@decision-sciences/qontrol-common'

const { capitalize } = utils.string
const { ENTITY_STATUS_LABEL } = entityStatus
const { ACCOUNT_TYPE_NAMES } = accounts

/** Business Units section sub-component */
const BusinessUnitsSection = ({
  company,
  editing,
  openedSubSection,
  setOpenedSubSection,
  isViewMode,
  showInheritClientModal,
}) => {
  const { dispatch } = useStore()
  const { businessUnits } = company
  const companyHasBusinessUnits = businessUnits && businessUnits.length > 0
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([])
  const hasBUReadAccess = useAccess({
    feature: PERMISSIONS.BUSINESS_UNIT_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const hasBUCreateAccess = useAccess({
    feature: PERMISSIONS.BUSINESS_UNIT_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasBUEditAccess = useAccess({
    feature: PERMISSIONS.BUSINESS_UNIT_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })

  const columns = [
    {
      header: ' ',
      cell: (cell) => {
        const { clientId } = cell.row.original
        if (!hasBUReadAccess || isViewMode) {
          return null
        }
        return (
          <div className="table__actions align-center">
            <Link
              className={cx({
                table__edit: hasBUEditAccess,
                table__view: !hasBUEditAccess,
              })}
              to={`/company/${company.clientId}/business-unit/${clientId}`}
            />
          </div>
        )
      },
      size: '30',
    },
    {
      header: 'Name',
      accessorFn: (row) => row.name,
      textAlign: 'left',
      cellTextAlign: 'left',
      sortingFn: 'name',
    },
    {
      header: 'Accounts',
      accessorFn: (row) => row.accounts.length,
      textAlign: 'left',
      cellTextAlign: 'left',
      tooltip: (row) => {
        const accounts = row.accounts
        return (
          accounts &&
          getTooltipList(
            'Accounts',
            accounts.map((account) => {
              return capitalize(ACCOUNT_TYPE_NAMES[account.type]).concat(
                ' - ',
                account.name,
                ' - ',
                account.externalAccountId
              )
            })
          )
        )
      },
    },
    {
      header: 'Primary Contact',
      accessorFn: (row) => row.contact.name,
      textAlign: 'left',
      cellTextAlign: 'left',
    },
    {
      header: 'Contact Phone',
      accessorFn: (row) => row.contact.primaryContactPhone,
      textAlign: 'left',
      cellTextAlign: 'left',
    },
    {
      header: 'Contact Email',
      accessorFn: (row) => row.contact.email,
      textAlign: 'left',
      cellTextAlign: 'left',
    },
    {
      header: 'Date Added',
      accessorKey: 'updatedAt',
      cell: (cell) => {
        const updatedAt = cell.row.original.updatedAt
        return updatedAt
          ? format(new Date(updatedAt), 'MM/dd/yyyy')
          : format(new Date(Date.now()), 'MM/dd/yyyy')
      },
      tooltip: (row) =>
        row.updatedAt ? format(new Date(row.updatedAt), 'HH:mm a') : null,
    },
    {
      header: 'Status',
      id: 'status',
      cell: (cell) => (
        <span>{ENTITY_STATUS_LABEL[cell.row.original.active]}</span>
      ),
      accessorFn: (row) => (row.active ? 1 : 0),
    },
  ]

  const _renderBusinessUnitsTable = useMemo(() => {
    return companyHasBusinessUnits ? (
      <Table
        id="business-units-table"
        columns={columns}
        data={businessUnits}
        className="custom-height__400"
        // onSelect={hasBUEditAccess ? setSelectedBusinessUnits : null}
      />
    ) : (
      <></>
    )
  }, [
    JSON.stringify(businessUnits),
    company.clientId,
    isViewMode,
    JSON.stringify(company.accounts),
  ])

  const _renderCreateButton = () => {
    if (!hasBUCreateAccess) {
      return
    }
    return (
      <Button
        data-cy="add-business-unit-button"
        value={
          <div className="action-button">
            <PlusIcon />
            Add Business Unit
          </div>
        }
        onClick={showInheritClientModal}
        secondary
        className="fixed-height align-right"
        disabled={isViewMode}
      />
    )
  }

  // const _renderEditButton = () => {
  //   if (!selectedBusinessUnits.length) {
  //     return
  //   }
  //   return (
  //     <Button
  //       data-cy="edit-business-unit-button"
  //       value="Edit Selected"
  //       onClick={() => setBulkEditCompanies(dispatch, selectedBusinessUnits)}
  //       secondary
  //       className="fixed-height align-right"
  //     />
  //   )
  // }

  return (
    <>
      <Spacer />
      <div className="align-row space-between">
        <div
          data-cy="business-units-section-title"
          className="heading-secondary"
        >
          Business Units
        </div>
        <div className="heading__buttons">
          {/* {_renderEditButton()} */}
          {_renderCreateButton()}
        </div>
        {editing && companyHasBusinessUnits && (
          <div
            className={`sub-section align-center ${
              openedSubSection['businessUnits'] ? ' opened' : ''
            }`}
            onClick={() =>
              setOpenedSubSection({
                ...openedSubSection,
                businessUnits: !openedSubSection['businessUnits'],
              })
            }
          >
            <img src={blueArrowIcon} alt="open table" />
          </div>
        )}
      </div>
      {openedSubSection['businessUnits'] && _renderBusinessUnitsTable}
    </>
  )
}

export default BusinessUnitsSection

BusinessUnitsSection.propTypes = {
  company: PropTypes.object,
  editing: PropTypes.bool,
  openedSubSection: PropTypes.object.isRequired,
  setOpenedSubSection: PropTypes.func.isRequired,
  isViewMode: PropTypes.bool,
  showInheritClientModal: PropTypes.func.isRequired,
}
