import Input from 'components/input/index'
import useLeaveConfirm from 'components/leave-confirm'
import StickyFooter from 'components/sticky-footer/index'
import {
  editCategoryField,
  upsertCategory,
} from 'modules/data-store-global/actions'
import CategoryDimensionLink from 'modules/data-store-global/components/categories/form/dimension-link'
import { validateCategory } from 'modules/data-store-global/validate'
import {
  showErrorMessage,
  showSuccessMessage,
} from 'modules/notifications/actions'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'
import { utils } from '@decision-sciences/qontrol-common'

const { isEmpty } = utils.object

/**
 * Form which allows creating or editing a Data Store category.
 * @param {Object} props Component props
 * @param {Boolean} [props.disabled] Disabled flag
 * @returns {React.FunctionComponent}
 */
const CategoryForm = ({ disabled }) => {
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const {
    dataStoreGlobal: { editedCategory: category },
  } = state

  const [setDirty, LeaveConfirm, isDirty] = useLeaveConfirm()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const updateCategoryField = (fieldName) => (value) => {
    if (!isDirty) {
      setDirty(true)
    }
    if (!isEmpty(errors)) {
      setErrors({})
    }
    editCategoryField(dispatch, { [fieldName]: value })
  }

  const onSave = () => {
    const [isValid, errors] = validateCategory(category)
    setLoading(true)
    if (!isValid) {
      setErrors(errors)
      setLoading(false)
      return
    }
    setDirty(false)
    upsertCategory(category)
      .then(() => {
        showSuccessMessage(
          `Category ${category._id ? 'updated' : 'created'}`,
          dispatch
        )
        navigate('/global-settings/data-store')
      })
      .catch((error) => {
        showErrorMessage(`Unable to save category: ${error}`, dispatch)
        setDirty(true)
        setLoading(false)
      })
  }

  const onCancel = () => {
    navigate('/global-settings/data-store')
  }

  return (
    <>
      <LeaveConfirm />
      <div className="section section__body">
        <div className="width-50">
          <Input
            data-cy="data-store-category-form-name"
            label="Category name"
            error={errors.name}
            value={category?.name || ''}
            placeholder="Enter Category Name"
            className="input-wrapper--uppercase"
            onChange={updateCategoryField('name')}
          />
        </div>
      </div>
      <CategoryDimensionLink
        disabled={disabled}
        isDirty={isDirty}
        setDirty={setDirty}
      />
      <StickyFooter
        buttons={[
          {
            'data-cy': 'data-store-category-form-save',
            value: 'Save',
            disabled: disabled || loading || !isDirty,
            onClick: onSave,
          },
          {
            'data-cy': 'data-store-category-form-cancel',
            value: 'Cancel',
            disabled: disabled || loading,
            onClick: onCancel,
            secondaryGray: true,
          },
        ]}
      />
    </>
  )
}

CategoryForm.propTypes = {
  disabled: PropTypes.bool,
}

export default CategoryForm

