import React, { useEffect, useState } from 'react'
import Api from 'easy-fetch-api'
import PropTypes from 'prop-types'
import InputText from 'components/input'
import { CheckboxNoHooks } from 'components/checkbox'
import InformationBlock from 'components/information-block'
import Loader from 'components/loader'
import { getConfigByClient } from 'modules/config/actions'

const GoogleMCCIDOverride = ({
  company,
  onGoogleMccUpdate,
  error,
  setError,
}) => {
  const [checked, setChecked] = useState(false)
  const [value, setValue] = useState('')
  const [initialValue, setInitialValue] = useState('')
  const [loading, setLoading] = useState(true)
  const [companyConfig, setCompanyConfig] = useState(null)

  useEffect(() => {
    setLoading(true)
    getConfigByClient(company.clientId)
      .then((res) => {
        const mccId = res.google?.manager_account_id
        if (mccId) {
          setCompanyConfig(res.config)
          setValue(mccId)
          setInitialValue(mccId)
        }
      })
      .finally(() => setLoading(false))
  }, [company])

  const validate = () => {
    setError(null)
    const regex = /^\d{10}$|^\d{3}-\d{3}-\d{4}$/
    if (!value.trim()) {
      setError('Please enter a value')
      return false
    }
    if (!regex.test(value)) {
      setError(
        'MCC ID must include only numbers with ten digits. Can be entered with or without hyphens.'
      )
      return false
    }
    return true
  }

  const onSubmit = () => {
    if (initialValue === value) {
      setError(null)
      return
    }
    if (validate()) {
      setLoading(true)
      Api.post({
        url: `/api/config/mcc-id/${company.clientId}`,
        data: {
          fields: {
            google: {
              ...(companyConfig?.google || {}),
              manager_account_id: value,
            },
          },
        },
      })
        .then(onGoogleMccUpdate)
        .catch(console.error)
        .finally(() => setLoading(false))
    }
  }

  return (
    <div>
      <CheckboxNoHooks
        reversed={true}
        label="Different MCC ID"
        isChecked={checked}
        onChange={() => {
          setChecked(!checked)
          setError(null)
        }}
        className="mcc-id"
        disabled={loading}
      />
      {checked && (
        <div
          style={{
            marginBottom: '15px',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <InputText
            label="MCC ID"
            value={value}
            onChange={setValue}
            onEnterKeyPressed={onSubmit}
            onBlur={onSubmit}
            className="half-width"
            placeholder="Enter Correct MCC ID"
            disabled={loading}
          />
          {loading && (
            <Loader
              size={60}
              loading={loading}
              style={{ top: '12px', position: 'relative' }}
            />
          )}
          <div className="half-width"></div>
          {error && (
            <InformationBlock
              info={error}
              style={{
                marginTop: '10px',
                width: 'auto',
                display: 'inline-flex',
              }}
            />
          )}
        </div>
      )}
    </div>
  )
}

GoogleMCCIDOverride.propTypes = {
  company: PropTypes.object.isRequired,
  onGoogleMccUpdate: PropTypes.func.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
}

export default GoogleMCCIDOverride
