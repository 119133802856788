import PropTypes from 'prop-types'

// Constants
import { FREQUENCY_COLUMNS } from 'modules/alerts/alerts-thresholds-statistics/utils'

// Components
import { useTooltip } from 'components/tooltip'
import LoadingOverlay from 'components/loading-overlay/index'
import Table from 'components/table/beta'

// Icons
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import { ReactComponent as LoadingIcon } from 'assets/icon_ring_loader.svg'

import '../style.scss'

const TriggerFrequencyLayout = ({
  header,
  tooltipContent,
  showLoadingOverlay,
  customOverlay,
  triggerFrequencyColumns = FREQUENCY_COLUMNS,
  triggerFrequencyData = [],
}) => {
  const [showTooltip, hideTooltip, Tooltip] = useTooltip(tooltipContent)

  return (
    <div className="frequency-table-wrapper">
      <div className="general-label display-flex">
        {header}
        {tooltipContent && (
          <>
            <InfoIcon onMouseEnter={showTooltip} onMouseLeave={hideTooltip} />
            <Tooltip />
          </>
        )}
      </div>
      <div className="frequency-table">
        <LoadingOverlay visible={showLoadingOverlay}>
          {customOverlay ? (
            customOverlay
          ) : (
            <div className="frequency-table__overlay-text frequency-table__overlay-text--lower">
              <div className="frequency-table__overlay-text-first-row">
                <LoadingIcon />
              </div>
            </div>
          )}
        </LoadingOverlay>
        <Table columns={triggerFrequencyColumns} data={triggerFrequencyData} />
      </div>
    </div>
  )
}

TriggerFrequencyLayout.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showLoadingOverlay: PropTypes.bool,
  loadingOverlay: PropTypes.bool,
  customOverlay: PropTypes.node,
  triggerFrequencyColumns: PropTypes.array.isRequired,
  triggerFrequencyData: PropTypes.array,
}

export { TriggerFrequencyLayout }
