import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import InputText from 'components/input'
import Button from 'components/button'
import { Dropdown } from 'components/dropdown'
import { ReactComponent as SearchIcon } from 'assets/icon_search.svg'
import { REPORT_STATUS_OPTIONS, REPORT_TYPE_OPTIONS } from '../constants'

/**
 * Report Edit Section
 */
const TeamReportFilters = ({
  filter = {},
  setFilter,
  onApplyFilter,
  reports,
}) => {
  const onChangeProperty = (property) => (value) =>
    setFilter({ ...filter, [property]: value })

  const users = useMemo(() => {
    return reports.reduce((acc, report) => {
      if (!acc.find((el) => el._id === report.owner._id)) {
        acc.push(report.owner)
      }
      return acc
    }, [])
  }, [reports])

  const areAllCreatorsSelected = filter.users?.length === users.length
  const areAllReportTypesSelected =
    filter.reportType?.length === REPORT_TYPE_OPTIONS.length
  const areAllStatusesSelected =
    filter.status?.length === REPORT_STATUS_OPTIONS.length

  return (
    <>
      <div className="reports-edit-section">
        <div className="reports-edit-section__column">
          <div className="general-label">Search </div>
          <InputText
            value={filter.name || ''}
            placeholder="Search"
            search
            onChange={onChangeProperty('name')}
            className={'width-50'}
          />
          <div className="general-label">Creator</div>
          <Dropdown
            options={users?.map((user) => ({
              label: `${user.firstName} ${user.lastName}`,
              value: user._id,
            }))}
            onChange={onChangeProperty('users')}
            selectedItems={filter.users || []}
            multiSelect={true}
            selectAll="All"
            overwriteSelectedText={areAllCreatorsSelected ? 'All' : ''}
            defaultOptionText={areAllCreatorsSelected ? null : 'All'}
          />
        </div>

        <div className="reports-edit-section__column">
          <div className="general-label">Report Type</div>
          <Dropdown
            options={REPORT_TYPE_OPTIONS}
            onChange={onChangeProperty('reportType')}
            selectedItems={filter.reportType || []}
            multiSelect={true}
            selectAll="All"
            overwriteSelectedText={areAllReportTypesSelected ? 'All' : ''}
            defaultOptionText={areAllReportTypesSelected ? null : 'All'}
          />
          <div className="general-label">Status</div>
          <Dropdown
            options={REPORT_STATUS_OPTIONS}
            onChange={onChangeProperty('status')}
            selectedItems={filter.status || []}
            multiSelect={true}
            selectAll="All"
            overwriteSelectedText={areAllStatusesSelected ? 'All' : ''}
            defaultOptionText={areAllStatusesSelected ? null : 'All'}
          />
        </div>
      </div>
      <div className="borders-top-bottom">
        <Button
          secondary
          value={
            <div className="action-button">
              <SearchIcon /> Apply Search
            </div>
          }
          onClick={onApplyFilter}
        />
      </div>
    </>
  )
}
export default TeamReportFilters

TeamReportFilters.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
}
