import React, { useMemo, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Loader from 'components/loader'
import Toggle from 'components/toggle'
import WarningIcon from 'components/warning-icon'
import Button from 'components/button'
import Modal from 'components/modal'
import Icon from 'components/icon'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block'

import NamingDimensions from 'modules/offline-data/components/naming-dimensions'
import FilenameInput from 'modules/offline-data/components/filename-input'
import OfflineDataItem from 'modules/offline-data/components/offline-data-item/index'

import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import { offlineData } from '@decision-sciences/qontrol-common'

import './style.scss'

const { DEFAULT_STATE } = offlineData

const OfflineData = ({
  isGlobal,
  offlineData = DEFAULT_STATE,
  setOfflineData,
  globalOfflineData,
  clientId,
  fileName,
  isOpen,
  onCollapse,
  isViewMode,
  allFilenames,
  onCancel,
  onRemove,
  onSave,
  isEditMode,
  setEditMode,
  errors,
  setErrors,
}) => {
  const [overwriteModal, setOverwriteModal] = useState(false)
  const [inputFileName, setInputFilenName] = useState(fileName)

  const completeGlobalOfflineData = useMemo(
    () => globalOfflineData || DEFAULT_STATE,
    [JSON.stringify(globalOfflineData)]
  )

  const collapsibleSectionRef = useRef()

  useEffect(() => {
    if (collapsibleSectionRef.current) {
      const { current } = collapsibleSectionRef
      if (isOpen && !current.isSectionOpen()) {
        current.focus()
      } else if (!isOpen && current.isSectionOpen()) {
        current.onCollapse(true)
      }
    }
  }, [isOpen])

  const completeOfflineData = useMemo(() => {
    let actualData = structuredClone(offlineData || completeGlobalOfflineData)
    if (Array.isArray(actualData)) {
      actualData = actualData[0]
    }

    if (!actualData.dimensions) {
      actualData.dimensions = completeGlobalOfflineData.dimensions
    }

    // If there's a client and there are global defaults, strip the _id and isGlobal values
    if (clientId) {
      if (actualData.clientId !== clientId) {
        delete actualData._id
        delete actualData.isGlobal
        actualData.clientId = clientId
      }
    }

    return actualData
  }, [JSON.stringify(offlineData), completeGlobalOfflineData])

  const onChangeDimensions = (dimensions) => {
    const newData = structuredClone(completeOfflineData)
    if (clientId) {
      newData.clientId = clientId
    }
    newData.dimensions = dimensions
    setOfflineData(newData)
  }

  const defaultOfflineData = isGlobal
    ? DEFAULT_STATE
    : completeGlobalOfflineData

  if (!completeGlobalOfflineData) {
    return <Loader />
  }

  const renderTitle = () => {
    if (isGlobal) {
      return 'Import Offline Data'
    } else {
      return (
        <>
          <Modal
            className="offline-data__modal"
            icon={<WarningIcon />}
            rightAlignButtons
            opened={!!overwriteModal}
            contentSeparator
            button={
              <Button
                value="Confirm"
                green
                onClick={() => {
                  setOfflineData({ ...offlineData, overwrite: true })
                  setOverwriteModal(false)
                }}
              />
            }
            buttonSecondary={
              <Button
                value={'Cancel'}
                onClick={() => {
                  setOverwriteModal(false)
                }}
                secondaryGray
              />
            }
            heading="Overwrite Historical Records"
          >
            <div>
              With this setting turned on, the historical data will be
              overwritten.
            </div>
            <div style={{marginTop: '16px'}}>
              Confirm if you want to continue with this action. This action
              cannot be undone.
            </div>
          </Modal>
          <Button
            secondary
            className={cx({
              'offline-data__overwrite__toggle_disabled':
                !completeOfflineData.overwrite,
            })}
            onClick={() => {
              if (completeOfflineData.overwrite) {
                setOfflineData({ ...offlineData, overwrite: false })
              } else {
                setOverwriteModal(true)
              }
            }}
            value={
              <Toggle
                defaultChecked={completeOfflineData.overwrite}
                value={completeOfflineData.overwrite}
                manualToggle
                disabled={isViewMode}
                label={
                  <div className="offline-data__overwrite">
                    <span className="offline-data__overwrite__text">
                      Overwrite Historical Records
                    </span>
                    <Icon
                      className="offline-data__overwrite__icon"
                      tooltip="With this setting turned on, the historical data will be overwritten."
                    >
                      <InfoIcon
                        width={13.3}
                        height={13.3}
                        className="fill-blue"
                      />
                    </Icon>
                  </div>
                }
              />
            }
          ></Button>
        </>
      )
    }
  }

  const saveOfflineData = () => {
    onSave({ ...offlineData, filename: inputFileName })
  }

  const renderNamingDimensions = () => (
    <>
      <NamingDimensions
        title={renderTitle()}
        setDimensions={onChangeDimensions}
        dimensions={completeOfflineData.dimensions}
        defaultDimensions={defaultOfflineData.dimensions}
        fileName={inputFileName}
        isGlobal={isGlobal}
        errors={errors || {}}
        setErrors={(dimensionErrors) => {
          const newErrors = { ...errors, dimensions: dimensionErrors }
          if (Object.values(dimensionErrors || {}).every((error) => !error)) {
            newErrors.general = null
          }
          setErrors(newErrors)
        }}
        isViewMode={isViewMode}
        isNew={Boolean(offlineData.isNew)}
        onCancel={onCancel}
        onRemove={onRemove}
        onSave={saveOfflineData}
      />
      {errors?.general && (
        <InformationBlock
          className="offline-data__info-block"
          type={INFORMATION_BLOCK_TYPE.ERROR}
          info={errors?.general}
        />
      )}
    </>
  )

  return (
    <section>
      {isGlobal ? (
        renderNamingDimensions()
      ) : (
        <div className="offline-data__section">
          <OfflineDataItem
            ref={collapsibleSectionRef}
            defaultCollapsed
            header={fileName}
            isOpen={isOpen}
            onCollapseListener={onCollapse}
            isTableRowStyle={true}
            onRemove={onRemove}
            setEditMode={setEditMode}
            isEditMode={isEditMode}
            isViewMode={isViewMode}
          >
            <FilenameInput
              offlineDataFileName={fileName}
              onChangeFilename={setInputFilenName}
              error={errors?.filename}
              setError={(filenameError) => {
                setErrors({ ...errors, filename: filenameError })
              }}
              allFilenames={allFilenames}
            />
            {renderNamingDimensions()}
          </OfflineDataItem>
        </div>
      )}
    </section>
  )
}

OfflineData.propTypes = {
  globalOfflineData: PropTypes.object.isRequired,
  setOfflineData: PropTypes.func.isRequired,
  fileName: PropTypes.string,
  isGlobal: PropTypes.bool,
  offlineData: PropTypes.object,
  clientId: PropTypes.string,
  isViewMode: PropTypes.bool,
  isEditMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
  allFilenames: PropTypes.array,
  isOpen: PropTypes.bool,
  onCollapse: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default OfflineData
