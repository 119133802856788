import { globalReports } from '@decision-sciences/qontrol-common'

const {
  PERMISSIONS_LABELS,
  SAVED_VIEW_TYPES_LABELS,
  SAVED_VIEW_TYPES,
  SAVED_VIEW_TYPES_WITH_LABELS,
} = globalReports

export const DISPLAY_LABELS = {
  [true]: 'On',
  [false]: 'Off',
}

export const EDIT_DEFAULT_LABELS = {
  [true]: 'Yes',
  [false]: 'No',
}

export const DISPLAY_DEFAULT_OPTIONS = {
  YES: {
    value: true,
    label: 'Yes',
  },
  NO: {
    value: false,
    label: 'No',
  },
}

export const EMPTY_FILTER = {
  creators: [],
  permissions: [],
  display: [],
  filters: [],
  name: '',
  viewType: [],
}

const ALL_SECTION_TYPES = [
  SAVED_VIEW_TYPES.FILTERS,
  SAVED_VIEW_TYPES.VISUALS,
  SAVED_VIEW_TYPES.CONVERSIONS,
]

export const getTypeOptions = (type) => {
  let result
  switch (type) {
    case SAVED_VIEW_TYPES.VIEW:
      result = Object.values(SAVED_VIEW_TYPES_WITH_LABELS)
      break
    default:
      result = Object.values(SAVED_VIEW_TYPES_WITH_LABELS).filter(({ value }) =>
        [type, SAVED_VIEW_TYPES.VIEW].includes(value)
      )
  }
  return result.sort((a, b) => a.label.localeCompare(b.label))
}

export const getDisplayedSections = (type, initialType) => {
  if (initialType !== SAVED_VIEW_TYPES.VIEW) {
    switch (type) {
      case SAVED_VIEW_TYPES.VIEW:
        return ALL_SECTION_TYPES
      default:
        return [type]
    }
  }
  return ALL_SECTION_TYPES
}

export const getHighlightedSections = (type, initialType) => {
  let highlights = []
  if (initialType === SAVED_VIEW_TYPES.VIEW && type !== initialType) {
    highlights = ALL_SECTION_TYPES.filter((section) => section !== type)
  }
  return highlights
}

export const includesValues = (array, value) =>
  array.map((obj) => obj.value).includes(value)

export const filterReportingViews = (views, filter) => {
  const { name, viewType, creators, permissions, filters, display } = filter
  return views.filter((view) => {
    const {
      type,
      name: viewName,
      permission: { creatorName, creator, type: permissionType },
      options: { filters: viewFilters },
      displayed,
    } = view
    if (name) {
      const nameMatch = viewName.toLowerCase().includes(name.toLowerCase())
      const creatorMatch = creatorName
        .trim()
        .toLowerCase()
        .includes(name.trim().toLowerCase())
      if (!nameMatch && !creatorMatch) {
        return false
      }
    }
    if (viewType?.length && !viewType.includes(type)) {
      return false
    }
    if (creators?.length && !creators.includes(creator)) {
      return false
    }
    if (permissions?.length && !permissions.includes(permissionType)) {
      return false
    }
    if (
      filters?.length &&
      !viewFilters.some(({ name }) => filters.includes(name))
    ) {
      return false
    }
    if (display?.length && !display.includes(displayed)) {
      return false
    }
    return true
  })
}

export const getFilterOptions = (reportingViews) =>
  reportingViews.reduce(
    (acc, curr) => {
      const {
        permission: { creator, type, creatorName },
        displayed,
        options: { filters },
        type: viewType,
      } = curr

      if (!includesValues(acc.viewType, viewType)) {
        acc.viewType.push({
          value: viewType,
          label: SAVED_VIEW_TYPES_LABELS[viewType],
        })
      }

      if (!includesValues(acc.creators, creator)) {
        acc.creators.push({ value: creator, label: creatorName })
      }

      if (!includesValues(acc.permissions, type)) {
        acc.permissions.push({ value: type, label: PERMISSIONS_LABELS[type] })
      }

      if (!includesValues(acc.display, displayed)) {
        acc.display.push({
          label: DISPLAY_LABELS[displayed],
          value: displayed,
        })
      }
      const currentFilters = acc.filters.map(({ value }) => value)
      filters.forEach(
        ({ name }) =>
          !currentFilters.includes(name) &&
          acc.filters.push({
            label: name,
            value: name,
          })
      )

      return acc
    },
    {
      creators: [],
      permissions: [],
      display: [],
      filters: [],
      name: '',
      viewType: [],
    }
  )
