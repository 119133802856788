import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/* Components */
import Modal from 'components/modal'
import Spacer from 'components/spacer'
import Button from 'components/button'
import CollapsibleSection from 'components/collapsible-section'
import RoundRectangleButton from 'components/round-rectangle-button'
import CalculatedMetricsTable from 'modules/companies/subsections/calculated-metrics/table'
import CalculatedMetricsAddEditSection from 'modules/companies/subsections/calculated-metrics/add-edit-section'
import CalculatedMetricsFilterSection from 'modules/companies/subsections/calculated-metrics/filter-section'

/* Icons */
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'
import { ReactComponent as IconFilter } from 'assets/filters.svg'
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as RemoveIcon } from 'assets/icon_minus_red.svg'
import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'

/* Utils */
import {
  PAGE_MODE,
  GET_DEFAULT_CALCULATED_METRIC,
  filterCalculatedMetrics,
} from 'modules/companies/subsections/calculated-metrics/utils'

/* Session && Access*/
import useSession from 'modules/session'

import './style.scss'

/** CalculatedMetrics Section sub-component */
const CalculatedMetricsSection = ({
  viewMode,
  company,
  calculatedMetrics,
  setCompany,
}) => {
  const [mode, setMode] = useState(PAGE_MODE.DEFAULT)
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(true)
  const [filtersOpened, setFiltersOpened] = useState(false)
  const [calculatedMetricsToDelete, setCalculatedMetricsToDelete] = useState([])
  const [selectedCalculatedMetrics, setSelectedCalculatedMetrics] = useState([])
  const [filter, setFilter] = useState({})
  const [visibleCalculatedMetrics, setVisibleCalculatedMetrics] =
    useState(calculatedMetrics)

  useEffect(() => {
    setVisibleCalculatedMetrics(
      calculatedMetrics.filter((metric) => !metric.deleted)
    )
  }, [JSON.stringify(calculatedMetrics)])

  const [, currentUserData] = useSession()
  const { users } = company
  const { isSuperAdmin, _id: currentUserId } = currentUserData
  const isAddOrEditMode = [PAGE_MODE.EDIT, PAGE_MODE.ADD].includes(mode)

  if (!users.find(({ _id }) => _id === currentUserData._id)) {
    users.push(currentUserData)
  }

  const saveEditCalculatedMetrics = (calculatedMetricToSave) => {
    let newCalculatedMetrics
    if (mode === PAGE_MODE.EDIT) {
      newCalculatedMetrics = [...calculatedMetrics].map((calculatedMetric) => {
        if (
          selectedCalculatedMetrics
            .map((c) => c._id || c.id)
            .includes(calculatedMetric._id || calculatedMetric.id)
        ) {
          const newCalculatedMetric = {
            ...calculatedMetric,
            ...calculatedMetricToSave,
          }
          if (calculatedMetric._id) {
            newCalculatedMetric.changed = true
          }
          return newCalculatedMetric
        }
        return calculatedMetric
      })
    } else {
      const newCalculatedMetric = {
        ...calculatedMetricToSave,
        updatedAt: new Date(),
      }
      newCalculatedMetrics = [...calculatedMetrics, newCalculatedMetric]
    }
    setCompany({ calculatedMetrics: newCalculatedMetrics })
    setMode(PAGE_MODE.DEFAULT)
    setSelectedCalculatedMetrics([])
  }

  const onDelete = () => {
    const newCalculatedMetrics = [...calculatedMetrics]
    const newCalculatedMetricsIds = newCalculatedMetrics.map(
      (c) => c._id || c.id
    )

    calculatedMetricsToDelete.forEach((calculatedMetricToDelete) => {
      const idField = calculatedMetricToDelete._id ? '_id' : 'id'
      const indexOfCalculatedMetric = newCalculatedMetricsIds.indexOf(
        calculatedMetricToDelete[idField]
      )
      // Existing calculatedMetrics case
      if (calculatedMetricToDelete._id) {
        const calculatedMetric = newCalculatedMetrics.find(
          (c) => c[idField] === calculatedMetricToDelete[idField]
        )
        newCalculatedMetrics.splice(indexOfCalculatedMetric, 1, {
          ...calculatedMetric,
          deleted: true,
        })
      } else {
        // Newly added calculatedMetrics case
        newCalculatedMetrics.splice(indexOfCalculatedMetric, 1)
        newCalculatedMetricsIds.splice(indexOfCalculatedMetric, 1)
      }
    })
    setCompany({ calculatedMetrics: newCalculatedMetrics })
    setCalculatedMetricsToDelete([])
    setSelectedCalculatedMetrics([])
  }

  const onApplyFilters = () => {
    const newVisibleCalculatedMetrics = filterCalculatedMetrics({
      calculatedMetrics,
      filter,
      users: users,
    })
    setVisibleCalculatedMetrics(newVisibleCalculatedMetrics)
  }

  const _renderAddCalculatedMetricsButton = () => {
    return (
      <Button
        value={
          <div className="action-button">
            <PlusIcon />
            Add Metric
          </div>
        }
        onClick={() => {
          setSelectedCalculatedMetrics([
            GET_DEFAULT_CALCULATED_METRIC(currentUserId),
          ])
          setMode(PAGE_MODE.ADD)
        }}
        disabled={
          isAddOrEditMode ||
          Boolean(selectedCalculatedMetrics.length) ||
          isPanelCollapsed ||
          filtersOpened ||
          viewMode
        }
        className="fixed-height"
        secondary
      />
    )
  }

  const _renderAddEditSection = () => {
    if (isAddOrEditMode) {
      return (
        <CalculatedMetricsAddEditSection
          calculatedMetrics={selectedCalculatedMetrics}
          allCalculatedMetrics={calculatedMetrics}
          setCalculatedMetrics={setSelectedCalculatedMetrics}
          onCancel={() => {
            setMode(PAGE_MODE.DEFAULT)
            setSelectedCalculatedMetrics([])
          }}
          onSave={saveEditCalculatedMetrics}
          users={users}
          mode={mode}
          companyId={company._id}
        />
      )
    }
  }

  const getHeader = () => {
    if (mode === PAGE_MODE.ADD) {
      return 'Add Metric'
    } else if (mode === PAGE_MODE.EDIT) {
      return selectedCalculatedMetrics.length > 1
        ? 'Edit Metrics'
        : 'Edit Metric'
    }
    return 'Manage Calculated Metrics'
  }

  const _renderHeader = () => {
    return (
      <div
        data-cy="calculated-metrics-section-header"
        className="calculated-metrics-headers calculated-metrics-section__header"
      >
        {getHeader()}
        <RoundRectangleButton
          className={cx('margin-left-16', {
            'filter-active': filtersOpened,
          })}
          disabled={isAddOrEditMode}
          contentClassName="filter-icon"
          onClick={() => {
            if (!isAddOrEditMode) {
              setFiltersOpened(!filtersOpened)
              selectedCalculatedMetrics.length &&
                setSelectedCalculatedMetrics([])
            }
          }}
          contentRender={() => (
            <IconFilter
              className={filtersOpened ? 'fill-white' : 'fill-light-blue'}
            />
          )}
        />
      </div>
    )
  }

  const _renderActions = () => {
    if (isAddOrEditMode || filtersOpened || !isSuperAdmin) {
      return null
    }
    return (
      <div
        data-cy="calculated-metrics-actions"
        className="calculated-metrics-headers calculated-metrics-section__actions"
      >
        <Button
          value={
            <div
              className={cx('action-button dark-text', {
                'action-button--disabled': !selectedCalculatedMetrics.length,
              })}
            >
              <EditIcon /> Edit selected
            </div>
          }
          disabled={!selectedCalculatedMetrics.length}
          onClick={() => setMode(PAGE_MODE.EDIT)}
          className="fixed-height"
          secondary
        />
        <Button
          value={
            <div
              className={cx('action-button dark-text', {
                'action-button--disabled': !selectedCalculatedMetrics.length,
              })}
            >
              <RemoveIcon /> Delete selected
            </div>
          }
          disabled={!selectedCalculatedMetrics.length}
          onClick={() =>
            setCalculatedMetricsToDelete([...selectedCalculatedMetrics])
          }
          className="fixed-height margin-left-10"
          secondaryRed
        />
      </div>
    )
  }

  const _renderDeleteModal = () => {
    if (!calculatedMetricsToDelete.length) {
      return null
    }
    return (
      <Modal
        contentSeparator
        rightAlignButtons
        opened={Boolean(calculatedMetricsToDelete.length)}
        button={<Button value="Confirm" onClick={onDelete} green />}
        buttonSecondary={
          <Button
            value="Cancel"
            onClick={() => setCalculatedMetricsToDelete([])}
            secondaryGray
          />
        }
        heading={
          <div className="delete-calculated-metric-heading">
            Delete Metric <WarningIcon width={30} height={30} />
          </div>
        }
        className="delete-calculated-metric-modal"
      >
        <div>
          <p>You are about to delete a calculated metric.</p>
          <p>
            Click the Confirm button to continue. This action cannot be undone.
          </p>
        </div>
      </Modal>
    )
  }

  const _renderFilterSection = () => {
    if (!filtersOpened) {
      return null
    }
    return (
      <CalculatedMetricsFilterSection
        filter={filter}
        setFilter={setFilter}
        users={users}
        applyFilter={onApplyFilters}
      />
    )
  }

  return (
    <>
      {_renderDeleteModal()}
      <CollapsibleSection
        id="calculated-metrics-section"
        header="Calculated Metrics"
        defaultCollapsed={isPanelCollapsed}
        onCollapseListener={(value) => setIsPanelCollapsed(!value)}
        extras={isSuperAdmin ? _renderAddCalculatedMetricsButton() : null}
        wrapperClassName="form__section"
      >
        <div className="calculated-metrics-section">
          {_renderHeader()}
          {_renderActions()}
          {_renderAddEditSection()}
          {_renderFilterSection()}
          <CalculatedMetricsTable
            calculatedMetrics={visibleCalculatedMetrics}
            selectedCalculatedMetrics={selectedCalculatedMetrics}
            setSelectedCalculatedMetrics={setSelectedCalculatedMetrics}
            setCalculatedMetricsToDelete={setCalculatedMetricsToDelete}
            setMode={setMode}
            mode={mode}
            disableActions={isAddOrEditMode}
            viewMode={!isSuperAdmin || viewMode}
            users={users}
          />
        </div>
      </CollapsibleSection>
    </>
  )
}

CalculatedMetricsSection.propTypes = {
  viewMode: PropTypes.bool,
  calculatedMetrics: PropTypes.array.isRequired,
  setCompany: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
}

export default CalculatedMetricsSection
