import { useNavigate } from 'react-router-dom'
import { entityStatus, companies } from '@decision-sciences/qontrol-common'
import {
  showErrorMessage,
  showSuccessMessage,
} from 'modules/notifications/actions'
import { saveFilter } from 'modules/table-filter-sort/actions'
import { useStore } from 'store'
import {
  getCompanies,
  getCompaniesWithFilterOptions,
  restoreClient,
} from './actions'

const { ENTITY_STATUS_FOR_FILTERS } = entityStatus
const { COMPANY_FILTERS } = companies

export const useRestoreClient = ({
  tableContainer,
  restoreInfo,
  onSuccess,
  onError,
}) => {
  const { state, dispatch } = useStore()

  const filterSort = state.tableFilterSort.filterSort[tableContainer]

  const restoreClientHandler = async (resolve) => {
    const { clientId, isBusinessUnit } = restoreInfo
    const entityName = isBusinessUnit ? 'Business unit' : 'Client'
    try {
      const { parentCompanyClientId } = await restoreClient(clientId)
      showSuccessMessage(`${entityName} restored successfully`, dispatch)
      // Must re-fetch whole background stored company list so it contains the newly restored company
      await getCompanies(dispatch, { deleted: false })
      // Must re-fetch visible company filters which dictate the list of visible companies in the table
      try {
        const { options, companyIds } = await getCompaniesWithFilterOptions(
          filterSort.filter
        )
        saveFilter(dispatch, tableContainer, {
          ...filterSort.filters,
          options,
          companyIds,
        })
        onSuccess()
      } catch (err) {
        showErrorMessage(`Error when refreshing client list: ${err}`, dispatch)
      } finally {
        resolve()
        // Force refresh because states are not updated properly when :companyId changes
        if (isBusinessUnit) {
          window.location.href = `/company/${parentCompanyClientId}/business-unit/${clientId}`
        } else {
          window.location.href = `/company/${clientId}`
        }
      }
    } catch (err) {
      showErrorMessage(`${entityName} failed to restore. ${err}`, dispatch)
      onError()
      resolve()
    }
  }

  return [restoreClientHandler]
}

export const useSeeArchivedClients = ({
  tableContainer,
  setDirty,
  closeRestoreNotification,
}) => {
  const navigate = useNavigate()

  const { state, dispatch } = useStore()

  const filterSort = state.tableFilterSort.filterSort[tableContainer]

  const seeArchivedClients = async () => {
    return new Promise((resolve) => {
      setDirty(false)
      const field = COMPANY_FILTERS.STATUS
      const newFilters = { ...filterSort.filter }
      newFilters[field] = [ENTITY_STATUS_FOR_FILTERS.ARCHIVED]
      getCompaniesWithFilterOptions(newFilters)
        .then((res) => {
          if (!res.success) {
            return resolve()
          }
          const { options, companyIds } = res
          saveFilter(dispatch, tableContainer, {
            ...newFilters,
            options,
            companyIds,
          })
          closeRestoreNotification()
          navigate('/client-management')
          resolve()
        })
        .catch(resolve)
    })
  }

  return seeArchivedClients
}
