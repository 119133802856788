import React, { useMemo, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import cx from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import { offlineData } from '@decision-sciences/qontrol-common'

import { DragAndDrop } from 'components/utils/drag-drop'
import InputGroup from 'components/input-group'
import { Dropdown } from 'components/dropdown'
import { compareDimensionsOrder } from 'modules/offline-data/utils'
import Input from 'components/input'
import Icon from 'components/icon'
import Toggle from 'components/toggle'
import WarningIcon from 'components/warning-icon'
import Button from 'components/button'
import Modal from 'components/modal'
import InformationBlock from 'components/information-block'

import { ReactComponent as IconDragDrop } from 'assets/icon_drag_drop.svg'
import { ReactComponent as IconEdit } from 'assets/icon_edit.svg'
import { ReactComponent as IconClose } from 'assets/icon_clear_red.svg'
import { ReactComponent as IconConfirm } from 'assets/icon_check_green.svg'
import { ReactComponent as IconRemove } from 'assets/icon_table_remove.svg'

import '../style.scss'

const { OFFLINE_DATA_DIMENSION_TYPE } = offlineData
const DND_ITEM_TYPE = 'dimension'

/**
 * Renders an editable / reorderable dimension list.
 * @param {Object} params React Parameters
 * @param {Array} params.dimensions Dimensions list
 * @param {Function} params.setDimensions Dimensions setter
 * @param {Boolean} params.isGlobal
 * @param {Boolean} params.isViewMode
 * @param {Object} params.errors
 * @param {Function} params.setErrors
 */
const DimensionsList = ({
  dimensions,
  setDimensions,
  isGlobal,
  isViewMode,
  errors,
  setErrors,
}) => {
  const onReorder = (from, to) => {
    // We sort the list based on the dimensionOrder, so we're certain that we're working with
    const orderedDimensions = dimensions.sort(compareDimensionsOrder)
    const itemToMove = orderedDimensions[from - 1]
    orderedDimensions.splice(from - 1, 1)
    orderedDimensions.splice(to - 1, 0, itemToMove)

    setDimensions(
      orderedDimensions.map((dimension, index) => ({
        ...dimension,
        dimensionOrder: index + 1,
      }))
    )
  }

  /**
   * On change dimension callback
   * @param {Object} dimension Dimension object
   * @param {String} [newId] New id to attribute to the dimension
   */
  const onChange = (dimension, newId) => {
    setDimensions(
      dimensions.sort(compareDimensionsOrder).map((_dimension) => {
        let newDimension = _dimension
        if (dimension.dimensionId === _dimension.dimensionId) {
          setErrors({ ...errors, [dimension.dimensionId]: null })

          newDimension = dimension
          if (newId) {
            newDimension.dimensionId = newId
          }
        }
        return newDimension
      })
    )
  }

  const onRemove = (dimension) => {
    setDimensions(
      dimensions
        .filter(({ dimensionId }) => dimension.dimensionId !== dimensionId)
        .sort(compareDimensionsOrder)
        .map((dimension, index) => ({
          ...dimension,
          dimensionOrder: index + 1,
        }))
    )
  }

  return (
    <DragAndDrop>
      <div className="offline-data__dimensions">
        {dimensions.map((dimension) => {
          return (
            <Dimension
              key={dimension.dimensionId}
              dimension={dimension}
              onReorder={onReorder}
              onChange={onChange}
              onRemove={onRemove}
              allDimensions={dimensions}
              isGlobal={isGlobal}
              isViewMode={isViewMode}
              error={errors?.[dimension.dimensionId]}
            />
          )
        })}
      </div>
    </DragAndDrop>
  )
}

/**
 * Dimension Render
 * The Drag and Drop functionality has been custom-tailored to show a little animation when dragging dimensions.
 * @param {Object} params React Params
 * @param {Object} params.dimension Dimension object to render
 * @param {Array} params.allDimensions Dimensions List
 * @param {Function} params.onReorder Reorder callback (when using drag and drop or order dropdown). onReorder({Number} from, {Number} to), where from is the initial order of the dimension, and to is the destination order
 * @param {Function} params.onChange OnChange callback. onChange({Object} newDimension) where newDimension is the edited version of what you're changing
 * @param {Function} params.onRemove Remove callback. onRemove({Object} dimension). Removes a dimension
 */
const Dimension = ({
  dimension,
  allDimensions,
  onReorder,
  onChange,
  onRemove,
  isGlobal,
  isViewMode,
  error,
}) => {
  const [editedValue, setEditedValue] = useState(null)
  const [shifted, setShifted] = useState(null)
  const [animationsActive, setAnimationsActive] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)

  const editInputRef = useRef(null)
  const dropRef = useRef(null)
  const dragRef = useRef(null)

  /**
   * Function to shift a dimension up
   * @param {number} size Number of pixels (Height of moved element)
   */
  const shiftUp = (size) => {
    const isFirstElement =
      dimension.dimensionId === allDimensions[0].dimensionId

    if (!shifted && !isFirstElement) {
      setShifted(-size)
    }
    if (shifted > 0) {
      setShifted(0)
    }
  }

  /**
   * Function to shift a dimension down
   * @param {number} size Number of pixels (Height of moved element)
   */
  const shiftDown = (size) => {
    const isLastElement =
      dimension.dimensionId ===
      allDimensions[allDimensions.length - 1].dimensionId

    if (!shifted && !isLastElement) {
      setShifted(size)
    }
    if (shifted < 0) {
      setShifted(0)
    }
  }

  /**
   * Array of available dimensionOrders
   * The current dimension is set to disabled so that we can't select it in the dropdown.
   * @type {{disabled: boolean, label: *, value: *}[]}
   */
  const availablePositions = useMemo(() => {
    return allDimensions.map(({ dimensionOrder }) => ({
      value: dimensionOrder,
      label: dimensionOrder.toString(),
      disabled: dimension.dimensionOrder === dimensionOrder,
    }))
  }, [JSON.stringify(allDimensions)])

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = dimension.dimensionOrder

      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      const height = hoverBoundingRect.height + 16 // 16 is the gap between elements

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (item.lastIndex === hoverIndex) {
        // If the moved element was initially below the current one, it means that the element was shifted downwards.
        const firstShiftIsDown = item.index > hoverIndex

        // If that's the case, we need to ensure that the element can't be shifted upwards, an element will toggle between shifted downwards and not shifted
        // The reverse is also applicable. If the element was shifted downwards, it can't be shifted upwards during this "drag" session.
        if (hoverClientY < hoverMiddleY) {
          firstShiftIsDown ? shiftDown(height) : setShifted(0)
        } else {
          firstShiftIsDown ? setShifted(0) : shiftUp(height)
        }
        return
      }

      // Dragging downwards
      if (dragIndex < hoverIndex) {
        if (hoverClientY < hoverMiddleY) {
          return
        }

        // When past the middle point, we consider the hovered element as the last element touched.
        item.lastIndex = hoverIndex

        // We also shift the element upwards, to mimic what will happen after the drag is done.
        shiftUp(height)
      }
      // Dragging upwards
      if (dragIndex > hoverIndex) {
        if (hoverClientY > hoverMiddleY) {
          return
        }

        // When past the middle point, we consider the hovered element as the last element touched.
        item.lastIndex = hoverIndex
        // We also shift the element downwards, to mimic what will happen after the drag is done.
        shiftDown(height)
      }
    },
    drop(item, monitor) {
      // When the drop is done, we actually make the changes
      onReorder(item.index, dimension.dimensionOrder)
    },
    collect(monitor) {
      return {
        isHovered: monitor.isOver(),
      }
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: {
      index: dimension.dimensionOrder,
      lastIndex: dimension.dimensionOrder,
      editedValue: editedValue,
    },
    end: (item, monitor) => {
      // We're moving the element back to its original position, as the dimensions array will reset.
      setShifted(0)

      // This checks whether the drop was made correctly
      if (!monitor.didDrop()) {
        // If the element was dropped elsewhere, we move the hovered item to the last shifted position
        onReorder(item.index, item.lastIndex)
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !isViewMode,
  })

  /**
   * This ensures that whenever dimensions change, the dimension moves to its original position and animations are inactive.
   */
  useEffect(() => {
    setShifted(0)
    setAnimationsActive(false)
  }, [JSON.stringify(allDimensions)])

  /**
   * Animations are disabled temporarily on component mount, so that post-drag-and-drop the moved cards don't move around to "settle"
   */
  useEffect(() => {
    if (!animationsActive) {
      setTimeout(() => {
        setAnimationsActive(true)
      }, 500)
    }
  }, [animationsActive])
  preview(drop(dropRef))
  drag(dragRef)

  const duplicateName = useMemo(
    () =>
      allDimensions.some(
        ({ dimensionName, dimensionOrder, dimensionId }) =>
          dimension.dimensionId !== dimensionId &&
          dimensionOrder !== dimension.dimensionOrder &&
          dimensionName === (editedValue || dimension.dimensionName)
      ),
    [editedValue, JSON.stringify(allDimensions)]
  )

  const dragDropElement = () => (
    <div
      ref={dragRef}
      className="offline-data__dimensions__dimension__dnd"
      style={{
        cursor: isDragging ? 'grabbing' : 'grab',
        width: '24px',
        maxWidth: '24px',
      }}
    >
      <Icon>
        <IconDragDrop />
      </Icon>
    </div>
  )

  const renderOrder = () => {
    return (
      <Dropdown
        defaultState={dimension.dimensionOrder}
        disableSearch
        options={availablePositions}
        onChange={(value) => onReorder(dimension.dimensionOrder, value)}
        disabled={isViewMode}
      />
    )
  }

  const renderType = () => {
    return (
      <Dropdown
        defaultState={dimension.dimensionType}
        defaultOptionText="Select Type"
        disableSearch
        options={Object.values(OFFLINE_DATA_DIMENSION_TYPE).map(
          ({ key, label }) => ({ label, value: key })
        )}
        onChange={(value) => {
          const newDimension = {
            ...dimension,
            dimensionType: value,
          }

          // Add conversion ID to offline conversions. Don't remove it in case their type changes later, as changing it back to conversion should keep the same ID. Leaving the conversion ID there for non-conversion ones does not bother anything
          if (value === OFFLINE_DATA_DIMENSION_TYPE.conversion.key) {
            newDimension.conversion_id = uuidv4()
          }

          onChange({ ...newDimension })
        }}
        disabled={isViewMode}
        error={error?.dimensionType}
      />
    )
  }

  const renderName = () => {
    const onConfirmName = () => {
      if (
        editedValue &&
        !duplicateName &&
        editedValue !== dimension.dimensionName
      ) {
        // If we're changing the name non-globally, the dimension must diverge from the global configuration.
        const newId = !isGlobal && !dimension.required ? uuidv4() : undefined

        const _dimension = {
          ...dimension,
          dimensionName: editedValue,
          oldName: dimension.dimensionName,
        }

        onChange(_dimension, newId)
        setEditedValue(null)
      }
    }

    return (
      <div className="offline-data__dimensions__dimension__name">
        <Input
          ref={editInputRef}
          value={editedValue !== null ? editedValue : dimension.dimensionName}
          onChange={setEditedValue}
          disabled={editedValue === null}
          onEnterKeyPressed={onConfirmName}
        />

        {editedValue !== null ? (
          <>
            <Icon onClick={() => setEditedValue(null)}>
              <IconClose width={20} height={20} />
            </Icon>
            <Icon
              tooltip={duplicateName && `${editedValue} already exists`}
              disabled={duplicateName || !editedValue}
              onClick={onConfirmName}
            >
              <IconConfirm width={20} height={20} />
            </Icon>
          </>
        ) : (
          <>
            {!isViewMode && (
              <Icon
                onClick={() => {
                  setEditedValue(dimension.dimensionName)
                  if (editInputRef.current) {
                    setTimeout(() => {
                      editInputRef.current.focus()
                    }, 100)
                  }
                }}
                disabled={isViewMode}
              >
                <IconEdit width={20} height={20} />
              </Icon>
            )}
          </>
        )}
      </div>
    )
  }

  const renderRemove = () => (
    <Icon
      className="offline-data__dimensions__dimension__remove"
      disabled={dimension.required || isViewMode}
      onClick={() => {
        setRemoveModal(true)
      }}
    >
      <IconRemove />
    </Icon>
  )

  const style = shifted ? { transform: `translateY(${shifted}px)` } : null
  return (
    <div
      ref={dropRef}
      style={{
        opacity: isDragging ? 0 : 1,
      }}
    >
      {removeModal && (
        <Modal
          className="offline-data__modal"
          icon={<WarningIcon />}
          rightAlignButtons
          opened={!!removeModal}
          contentSeparator
          button={
            <Button
              value="Confirm"
              green
              onClick={() => {
                onRemove(dimension)
                setRemoveModal(false)
              }}
            />
          }
          buttonSecondary={
            <Button
              value={'Cancel'}
              onClick={() => setRemoveModal(false)}
              secondaryGray
            />
          }
          heading="Remove Dimension"
        >
          <div>
            You are about to remove a dimension.
            <br />
            Click the Confirm button to continue with this action. This action
            cannot be undone.
          </div>
        </Modal>
      )}
      <InputGroup
        style={style}
        className={cx('offline-data__dimensions__dimension', {
          'animations-disabled': !animationsActive,
        })}
        options={[
          { render: dragDropElement(), width: 48, condition: !isViewMode },
          { render: renderOrder(), width: 84 },
          { render: renderName(), error: duplicateName },
          {
            render: renderType(),
            width: 169,
          },
          {
            render: (
              <Toggle
                label="Required"
                defaultChecked={dimension.required}
                disabled={!isGlobal}
                onChange={(value) =>
                  onChange({
                    ...dimension,
                    required: value,
                  })
                }
              />
            ),
            condition: !!isGlobal,
            width: 169,
          },
          {
            render: (
              <Toggle
                label="Ignore"
                defaultChecked={dimension.ignore}
                disabled={isViewMode}
                onChange={(value) => onChange({ ...dimension, ignore: value })}
              />
            ),
            condition: !isGlobal,
            width: 169,
          },
          { render: renderRemove(), width: 48, condition: !isViewMode },
        ]}
      />
      {duplicateName && (
        <InformationBlock
          info="Dimension name already exists."
          style={{ marginTop: '15px', maxWidth: '456px' }}
        />
      )}
    </div>
  )
}

Dimension.propTypes = {
  dimension: PropTypes.object.isRequired,
  allDimensions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isGlobal: PropTypes.bool,
  isViewMode: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

DimensionsList.propTypes = {
  dimensions: PropTypes.array,
  setDimensions: PropTypes.func,
  isGlobal: PropTypes.bool,
  isViewMode: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
}

export default DimensionsList
