import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

/* Third party */
import { v4 as uuidv4 } from 'uuid'
import cx from 'classnames'

/* Components */
import { CheckboxNoHooks } from 'components/checkbox'
import Loader from 'components/loader'
import Tooltip from 'components/tooltip/index'
import Icon from 'components/icon'
import CheckboxSelectAll, {
  computeCheckboxState,
} from 'components/checkbox-select-all/index'

/* Hooks */
import { useOnClickOutside } from 'hooks/outside-click'

/* Icons */
import closeIcon from 'assets/icon_x_close.svg'
import { ReactComponent as SearchIcon } from 'assets/icon_search.svg'
import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'

/* Utils */
import { calculateListPosition } from 'components/utils/list-position'
import { preventScrollingBody } from 'components/utils/dom-manipulation'
import { utils } from '@decision-sciences/qontrol-common'

/* Styles */
import ScssConstants from 'styles/shared.module.scss'
import './style.scss'

const { escapeRegExp } = utils.string

/**
 * Use DropDown hook for building Select elements
 * @param {Object} params
 * @param {Array} params.options Can be array of Strings or Array of objects: {value: String, label: String}}
 * @param {String} [params.label] label to show
 * @param {String} [params.defaultState] default element selected
 * @param {Array} [params.selectedItems] used for multi dropdown
 * @param {Function} [params.onChange] Optional onChange callback
 * @param {String} [params.error] error
 * @param {String} [params.className]
 * @param {Boolean} [params.disabled]
 * @param {Boolean} [params.disableSearch]
 * @param {String} [params.defaultOptionText] Default text to show (placeholder)
 * @param {Boolean} [params.multiSelect] Multiple-select dropdown
 * @param {Boolean} [params.selectAll] Show selectAll/deselectAll
 * @param {Boolean} [params.hasSearch] Show search
 * @param {Boolean} [params.displaySearchInOptions] Show search in expanded options instead
 * @param {String} [params.deselectLabel] Label for option that resets the input
 * @param {Boolean} [params.showAsFilters] Show selected elements as dismissible filter tiles
 * @param {String} [params.overwriteSelectedText] Text to show, defaults to selected items
 * @param {Boolean} [params.autoSelectFirst] Auto-select first item on render
 * @param {Function} [params.optionRenderer] Custom renderer method for the Options
 * @param {Boolean} [params.labelTop] If true, label is positioned at the top
 * @param {Boolean} [params.loading] If true, display loading spinner
 * @param {Boolean} [params.open] If true, the dropdown would be forcefully open
 * @param {Function} [params.onOpened] callback to signal the dropdown has been opened from the inside
 * @param {String | Node} [params.warning] Display a warning icon with a tooltip containing the params.warning content.
 * @param {Boolean} [params.showErrorMessage] Display a error message under dropdown.
 * @param {React.ReactNode} [params.icon] Custom icon prefix for the base input
 * @param {React.ReactNode} [params.isConversionDropdown] Used for conversion dropdowns
 * @param {Boolean} [params.showOptionsInPlaceholder] Show selected options in placeholder
 * @param {Number} [params.optionsHeight] Max height of selectable option section in pixels
 * @param {Number} [params.characterToJoinSelected] Character to join selected items when these are displayed in the placeholder
 * @param {Boolean} [params.reorderSelectedOnTop = true] Flag to reorder selected options on top - applies only when {@link multiSelect} is `true`
 * @param {Array} [params.groups = null] List of groups to split the options into with the ability to (de)select subsets of options.
 * @param {Boolean} [params.getSelectedItemsByKey = false] Show Selected text by options key
 *  Each option must have the field group set when this is used.
 * @returns {any[]}
 */
export const Dropdown = ({
  options = [],
  label,
  characterToJoinSelected = ', ',
  defaultState = '',
  selectedItems = [],
  onChange,
  error,
  textOnlyError,
  disabled,
  defaultOptionText = '',
  multiSelect = false,
  selectAll,
  labelClassName,
  className,
  hasSearch,
  disableSearch = false,
  displaySearchInOptions,
  deselectLabel,
  showAsFilters,
  overwriteSelectedText,
  autoSelectFirst = false,
  optionRenderer,
  labelTop,
  loading,
  warning,
  open = false,
  onOpened = () => {},
  sections = [],
  showErrorMessage = true,
  icon = null,
  isConversionDropdown = false,
  dark = false,
  white = false,
  showOptionsInPlaceholder = true,
  sortOptions,
  reorderSelectedOnTop = true,
  children,
  optionsHeight,
  groups = null,
  getSelectedItemsByKey = false,
  ...other
}) => {
  const optionsToRender = useMemo(
    () =>
      deselectLabel
        ? [{ label: deselectLabel, value: null }, ...options]
        : options,
    [deselectLabel, JSON.stringify(options)]
  )

  const getOptionValue = (option) => {
    if (option?.extraValue) {
      return `${option.value}-${option.extraValue}`
    }
    if (option?.value !== undefined) {
      return option.value
    }
    return option
  }

  /**
   * Map of selected options that can be easily referenced. Eg. selectedOptionsMap[value] // true or falsy
   */
  const selectedOptionsMap = useMemo(() => {
    if (multiSelect) {
      return selectedItems.reduce(
        (prev, current) => ({
          ...prev,
          [getOptionValue(current)]: true,
        }),
        {}
      )
    } else if (defaultState) {
      return { [defaultState]: true }
    }

    return {}
  }, [JSON.stringify(selectedItems), defaultState])

  const [listOpened, setListOpened] = useState(open || false)
  const [displayedOptions, setDisplayedOptions] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [highlightedElIndex, setHighlightedElIndex] = useState(0)
  const [optionsDisplayedUpwards, setOptionsDisplayedUpwards] = useState(null)
  const searchRef = useRef()
  const selectedRef = useRef()
  const optionsRef = useRef()
  const dropdownRef = useRef()
  const useObjectOptions =
    optionsToRender &&
    optionsToRender[0] &&
    typeof optionsToRender[0] === 'object'
  const searchable =
    !disableSearch && (hasSearch || optionsToRender?.length >= 6)
  const searchInOptions =
    !disableSearch && (displaySearchInOptions || optionsToRender?.length >= 6)
  const [showTooltip, setShowTooltip] = useState({})
  /** Create a unique ID */
  const id = useCallback(`use-dropdown-${uuidv4()}`, [])
  const isRequiredError =
    typeof error === 'string'
      ? (error || '').toLowerCase().includes('required')
      : false

  const someSelected = useMemo(() => {
    if (!displayedOptions.length) {
      return false
    }
    return Object.keys(selectedOptionsMap).length > 0
  }, [displayedOptions, selectedOptionsMap])

  const allSelected = useMemo(() => {
    if (
      !multiSelect ||
      !selectAll ||
      selectedItems.length < displayedOptions.length
    ) {
      return false
    }
    if (!displayedOptions.length) {
      return false
    }
    return (
      displayedOptions.length > 0 &&
      displayedOptions.every((item) => selectedOptionsMap[getOptionValue(item)])
    )
  }, [
    multiSelect,
    selectAll,
    selectedItems.length,
    displayedOptions.length,
    selectedOptionsMap,
  ])

  const groupStatusMap = useMemo(() => {
    if (multiSelect && groups) {
      return groups.reduce((acc, group) => {
        const groupOptions = displayedOptions.filter(
          (option) => option.group === group.id
        )
        return {
          ...acc,
          [group.id]: {
            options: groupOptions,
            allSelected: groupOptions.every(
              (option) => selectedOptionsMap[getOptionValue(option)]
            ),
            someSelected: groupOptions.some(
              (option) => selectedOptionsMap[getOptionValue(option)]
            ),
          },
        }
      }, {})
    }
    return {}
  }, [
    JSON.stringify(groups),
    JSON.stringify(displayedOptions),
    selectedOptionsMap,
  ])

  const checkboxState = computeCheckboxState(someSelected, allSelected)

  /** On mount - if autoSelectFirst - select first option */
  useEffect(() => {
    if (autoSelectFirst && optionsToRender.length && !defaultState) {
      const firstAvailableOption = optionsToRender.find(
        (option) =>
          !useObjectOptions || (!option?.disabled && !option?.unselectable)
      )
      let value = firstAvailableOption

      if (useObjectOptions) {
        value = getOptionValue(firstAvailableOption)
      }

      if (multiSelect) {
        onChange([value])
      } else {
        onChange(value)
      }
    }
  }, [autoSelectFirst])

  const sortBasedOnSelectedStatus = (options) => {
    return [
      ...options.sort((o1, o2) => {
        const o1Selected = selectedOptionsMap[getOptionValue(o1)]
        const o2Selected = selectedOptionsMap[getOptionValue(o2)]

        if (o1Selected && !o2Selected) {
          return -1
        } else if (!o1Selected && o2Selected) {
          return 1
        }
        return 0
      }),
    ]
  }

  const sortBasedOnGroupIndex = (options) => {
    return [
      ...options.sort((o1, o2) => {
        const o1GroupIndex = groups.findIndex((group) => group === o1.group)
        const o2GroupIndex = groups.findIndex((group) => group === o2.group)
        return o1GroupIndex - o2GroupIndex
      }),
    ]
  }

  const sortDisplayedOptions = (options) => {
    // In case it's a multiselect dropdown and reorder on top is enabled, then bypass any other sorting config
    if (multiSelect && reorderSelectedOnTop) {
      if (groups) {
        return sortBasedOnGroupIndex(sortBasedOnSelectedStatus(options))
      }
      return sortBasedOnSelectedStatus(options)
    }

    if (!sortOptions) {
      return options
    }

    return [
      ...options.sort((o1, o2) => {
        if (o1.sortTop) {
          return -1
        }
        if (o2.sortTop) {
          return 1
        }

        if ((o1.label || o1) > (o2.label || o2)) {
          return 1
        }
        return -1
      }),
    ]
  }

  /** On selected options change - sort displayed options */
  useEffect(() => {
    setDisplayedOptions(sortDisplayedOptions)
  }, [selectedOptionsMap])

  useEffect(() => {
    setDisplayedOptions(() =>
      sortDisplayedOptions(
        !displayedOptions?.length || !optionsToRender.length
          ? optionsToRender
          : displayedOptions
      )
    )
  }, [optionsToRender])

  /** On list opened/closed */
  useEffect(() => {
    // Prevent scrolling body
    preventScrollingBody(listOpened)
  }, [listOpened])

  useEffect(() => {
    setSearchValue(searchValue)

    let newOptions = optionsToRender
    setHighlightedElIndex(
      (defaultState || selectedItems.length) && deselectLabel ? 1 : 0
    )
    if (searchValue?.length) {
      newOptions = optionsToRender.filter((option) => {
        if (typeof option === 'string') {
          return option.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        }

        const regExp = new RegExp(`.*${escapeRegExp(searchValue)}.*`, 'ig')
        // Check if searchValue appears in label
        if (option.label && option.label.match(regExp)) {
          return true
        }

        // Check if searchValue appears in description
        if (option.description && option.description.match(regExp)) {
          return true
        }

        return false
      })
    }
    setDisplayedOptions(() => sortDisplayedOptions(newOptions))
  }, [searchValue, listOpened])

  const toggleListOpened = (value = null) => {
    if (disabled) {
      return
    }

    if (!listOpened) {
      setSearchValue('')
      setHighlightedElIndex(
        (defaultState || selectedItems.length) && deselectLabel ? 1 : 0
      )
      setTimeout(() => {
        if (selectedRef.current) {
          optionsRef.current &&
            optionsRef.current.scrollTo({
              top: selectedRef.current.offsetTop,
            })
          setHighlightedElIndex(
            parseInt(selectedRef.current.getAttribute('data-index') - 1)
          )
        }

        if (searchable) {
          searchRef && searchRef.current?.focus()
        }
      })
    } else {
      setSearchValue('')
    }

    setOptionsDisplayedUpwards(
      calculateListPosition(
        listOpened,
        dropdownRef,
        optionsRef.current?.clientHeight ||
          optionsHeight ||
          ScssConstants.dropdownHeight
      )
    )

    if (value !== null) {
      setListOpened(value)

      if (value) {
        onOpened()
      }
    } else {
      setListOpened(!listOpened)

      if (!listOpened) {
        onOpened()
      }
    }
  }

  /** Key-down handler */
  const onKeyDown = (e) => {
    const { keyCode } = e
    const isSearchFocused = document.activeElement === searchRef.current
    if (isSearchFocused) {
      return
    }

    /** Space key */
    if (keyCode === 32) {
      e.preventDefault()
      return toggleListOpened()
    }
    let nextIndex = 0
    /** Down arrow */
    if (keyCode === 40) {
      e.preventDefault()
      if (highlightedElIndex < displayedOptions.length - 1) {
        nextIndex = highlightedElIndex + 1
      } else {
        nextIndex = 0
      }
    }
    /** Up arrow */
    if (keyCode === 38) {
      e.preventDefault()
      if (highlightedElIndex === 0) {
        nextIndex = displayedOptions.length - 1
      } else {
        nextIndex = highlightedElIndex - 1
      }
    }
    setHighlightedElIndex(nextIndex)
    const selected =
      optionsRef.current &&
      optionsRef.current.querySelector(`[data-index='${nextIndex + 1}']`)
    if (selected && optionsRef.current) {
      if (
        selected.offsetTop + selected.clientHeight >
        optionsRef.current.scrollTop + optionsRef.current.clientHeight
      ) {
        optionsRef.current.scrollTo({ top: selected.offsetTop })
      }
      if (selected.offsetTop < optionsRef.current.scrollTop) {
        optionsRef.current.scrollTo({ top: selected.offsetTop })
      }
    }
    if (listOpened) {
      /** Enter key */
      if (keyCode === 13) {
        if (listOpened && displayedOptions) {
          onOptionSelected(displayedOptions[highlightedElIndex || 0].value, e)
        }
        e.preventDefault()
        return toggleListOpened()
      }
    }
  }

  /** On item changed handler */
  const onOptionSelected = (value, e) => {
    if (e) {
      e.preventDefault()
      showAsFilters && e.stopPropagation()
    }

    if (!multiSelect) {
      onChange(value)
      setListOpened(false)
    } else {
      const newArray = [...selectedItems]
      const index = newArray.indexOf(value)
      // For multi-select, if option is not selected, select it
      if (index === -1) {
        newArray.push(value)
      } else {
        // Else remove it
        newArray.splice(index, 1)
      }

      onChange(newArray)
    }
  }

  /** Helper method that renders the selected item(s) as Dismissible Tiles - used for Filters */
  const _renderAsFilterTiles = (items) => {
    return (
      <div className="select__tiles">
        {items
          .filter((value) =>
            optionsToRender.find((el) =>
              useObjectOptions ? el.value === value : el === value
            )
          )
          .map((value, key) => {
            const label = useObjectOptions
              ? optionsToRender.find((el) => el.value === value).label
              : value
            return (
              <div className="select__tile" key={key}>
                {defaultOptionText}: {label}
                <img
                  src={closeIcon}
                  alt="x"
                  onClick={(e) => onOptionSelected(multiSelect ? value : '', e)}
                />
              </div>
            )
          })}
      </div>
    )
  }

  let selectedElement = defaultState
  if (selectedElement !== null && useObjectOptions) {
    const found = optionsToRender.find((el) => el.value === selectedElement)
    if (found) {
      selectedElement = found
    }
  }

  // Compute what to show in the select element
  let visibleOption
  if (!multiSelect) {
    // Single-select case
    if (selectedElement && showOptionsInPlaceholder) {
      if (overwriteSelectedText) {
        visibleOption = overwriteSelectedText
      } else {
        // Show dismissible selected element
        if (showAsFilters) {
          visibleOption = _renderAsFilterTiles([
            useObjectOptions ? selectedElement.value : selectedElement,
          ])
        } else {
          visibleOption = useObjectOptions
            ? selectedElement.label
            : selectedElement

          if (!visibleOption || visibleOption === deselectLabel) {
            visibleOption = defaultOptionText
          }
        }
      }
    } else {
      visibleOption = defaultOptionText
    }
  } else {
    // Multi-select case
    if (
      selectedItems.length &&
      optionsToRender.length &&
      showOptionsInPlaceholder
    ) {
      if (overwriteSelectedText) {
        visibleOption = overwriteSelectedText
      } else {
        // Show dismissible selected elements
        if (showAsFilters) {
          visibleOption = _renderAsFilterTiles(selectedItems)
        } else {
          const keyMap = new Map(
            optionsToRender.map((el) => [
              getSelectedItemsByKey
                ? typeof el.key === 'boolean'
                  ? el.key
                  : el.key || el
                : typeof el.value === 'boolean'
                ? el.value
                : el.value || el,
              el.label || el,
            ])
          )

          visibleOption = selectedItems
            .filter((value) => keyMap.has(value))
            .map((value) => keyMap.get(value))
            .join(characterToJoinSelected)
          if (!visibleOption) {
            visibleOption = defaultOptionText
          }
        }
      }
    } else {
      // Multi-select case with nothing selected
      visibleOption = defaultOptionText
    }
  }

  const classes = cx('drop-down input-wrapper', className, {
    'input-wrapper--error': !!error,
    'drop-down--dark': dark,
    'drop-down--white': white,
    'drop-down--sorted': sortOptions || reorderSelectedOnTop,
    'drop-down--conversion': isConversionDropdown,
    'drop-down--empty':
      !Object.values(selectedOptionsMap).some((option) => option) ||
      overwriteSelectedText,
    [`input-wrapper--label-top`]: labelTop,
  })

  useOnClickOutside(dropdownRef, () => {
    listOpened && toggleListOpened(false)
  })

  /**
   * Maps the selected options to the required format to send to the parent {@link onChange} component
   * @param {Array} optionSet Selected options to map
   */
  const getNewSelectedItems = (optionSet) => {
    return optionSet.map((item) =>
      typeof item.value === 'boolean' ? item.value : item.value || item
    )
  }

  const onSelectAll = () => {
    let newSelected = []

    if (allSelected) {
      // Keep already selected elements that are not currently visible
      const alreadySelectedItems = optionsToRender.reduce(
        (acc, item) =>
          selectedOptionsMap[getOptionValue(item)] &&
          !displayedOptions.some(
            (displayed) =>
              (displayed.value || displayed) === (item.value || item)
          )
            ? [...acc, item.value || item]
            : acc,
        []
      )

      newSelected.push(...alreadySelectedItems)
    } else {
      newSelected = getNewSelectedItems(displayedOptions)

      // Keep already selected elements that are not currently visible
      const alreadySelectedItems = optionsToRender.reduce(
        (acc, item) =>
          selectedOptionsMap[getOptionValue(item)] &&
          !newSelected.some((selected) => selected === (item.value || item))
            ? [...acc, item.value || item]
            : acc,
        []
      )

      newSelected.push(...alreadySelectedItems)
    }

    onChange(newSelected)
  }

  const onSelectGroup = (groupId) => () => {
    const { allSelected, options } = groupStatusMap[groupId]
    let newSelected = []
    if (allSelected) {
      const alreadySelectedItems = optionsToRender.reduce(
        (acc, item) =>
          selectedOptionsMap[getOptionValue(item)] && item.group !== groupId
            ? [...acc, item.value || item]
            : acc,
        []
      )
      newSelected.push(...alreadySelectedItems)
    } else {
      newSelected = getNewSelectedItems(options)
      const alreadySelectedItems = optionsToRender.reduce(
        (acc, item) =>
          selectedOptionsMap[getOptionValue(item)] &&
          !newSelected.some((selected) => selected === (item.value || item))
            ? [...acc, item.value || item]
            : acc,
        []
      )
      newSelected.push(...alreadySelectedItems)
    }
    onChange(newSelected)
  }

  const renderOption = (option, index) => {
    // Skip disabled options.
    if (useObjectOptions && option.disabled) {
      return null
    }
    let value = option

    if (useObjectOptions) {
      value = getOptionValue(option)
    }

    const label = useObjectOptions ? option.label : option
    const unselectable = useObjectOptions ? option.unselectable : false
    const tooltip = option?.tooltip
    // Default Option HTML is its label
    // If we have a custom renderer method, use that
    let optionHtml = !optionRenderer
      ? label
      : optionRenderer(option, selectedItems)

    // For multi-select we render Checkboxes
    if (multiSelect && !optionRenderer) {
      optionHtml = (
        <CheckboxNoHooks
          label={label}
          disabled={option?.isDisabled}
          isChecked={selectedItems.indexOf(value) > -1}
        />
      )
    }

    const isSelected = selectedOptionsMap[getOptionValue(option)]
    const key =
      typeof option === 'object'
        ? option.key || option.value || option.label
        : option

    return (
      <React.Fragment key={key.toString()}>
        {/* Displays a section label based on given positions */}
        {/* TODO [Titus]: Check if these are still necessary */}
        {sections[index] ? (
          <div className="select__dropdown-section-label">
            {sections[index]}
          </div>
        ) : null}

        <div
          tabIndex="0"
          role="option"
          id={`${id}-${index + 1}`}
          data-index={index + 1}
          ref={isSelected ? selectedRef : undefined}
          onMouseEnter={() =>
            tooltip && !showTooltip[key] && setShowTooltip({ [key]: true })
          }
          onMouseLeave={() =>
            tooltip && showTooltip[key] && setShowTooltip({ [key]: false })
          }
          className={cx({
            // Used when we want a special row that only serves as a group label for the following options
            selected: isSelected,
            'select--disabled': option?.isDisabled,
            highlighted: index === highlightedElIndex,
            unselect: deselectLabel && index === 0 && !searchValue,
            hidden:
              deselectLabel === (option.label ? option.label : option) &&
              !selectedElement &&
              index === 0 &&
              !searchValue,
            hasLabel: option.sectionLabel,
            unselectable,
            'sorted-to-top': option.sortTop,
          })}
          onClick={(e) => {
            e.preventDefault()
            !unselectable && !option.isDisabled && onOptionSelected(value, e)
          }}
        >
          <React.Fragment>
            <div
              data-cy="dropdown-option-value"
              title={!optionRenderer ? label : undefined}
              className={cx({
                'text-ellipsis': !optionRenderer && !multiSelect,
              })}
            >
              {optionHtml}
            </div>
            {option.content && <div>{option.content}</div>}
          </React.Fragment>
          {option.description && (
            <div
              className={cx('select__description', {
                'select--disabled': option?.isDisabled,
                'margin-left-30': multiSelect,
              })}
            >
              {option.description}
            </div>
          )}
          {option.sectionLabel && (
            <div className="select__section-label">{option.sectionLabel}</div>
          )}
          {tooltip && (
            <Tooltip
              content={tooltip}
              show={showTooltip[key]}
              className={dark ? 'dark-tooltip' : ''}
            />
          )}
        </div>
      </React.Fragment>
    )
  }

  const renderGroupHeader = (group) => {
    const { id, label, tooltip } = group
    const groupStatus = groupStatusMap[id]
    const groupCheckboxState = computeCheckboxState(
      groupStatus.someSelected,
      groupStatus.allSelected
    )
    return (
      <div role="option" className="select__group select__toggles">
        <CheckboxSelectAll
          isBlue
          disabled={!groupStatus.options?.length}
          value={groupCheckboxState}
          onClick={onSelectGroup(id)}
        />
        <span className="general-label general-label--no-bottom-margin">
          {label}
        </span>
        {tooltip && (
          <>
            <InfoIcon
              width={18}
              height={18}
              onMouseEnter={() =>
                tooltip && !showTooltip[id] && setShowTooltip({ [id]: true })
              }
              onMouseLeave={() =>
                tooltip && showTooltip[id] && setShowTooltip({ [id]: false })
              }
            />
            <Tooltip
              content={tooltip}
              show={showTooltip[id]}
              className={dark ? 'dark-tooltip' : ''}
            />
          </>
        )}
      </div>
    )
  }

  const renderList = () => {
    if (!displayedOptions.length) {
      return null
    }
    if (groups) {
      return groups.map((group) => {
        const groupOptions = displayedOptions.filter(
          (option) => option.group === group.id
        )
        return (
          <div key={group.id}>
            {renderGroupHeader(group)}
            {groupOptions.map(renderOption)}
          </div>
        )
      })
    }
    return displayedOptions.map(renderOption)
  }

  return (
    <label data-testid="dropdown" htmlFor={id} className={classes} {...other}>
      {label && (
        <label
          data-cy="dropdown-label"
          className={cx(labelClassName ? labelClassName : 'label-wrapper', {
            'color-red-important': (isRequiredError && error) || textOnlyError,
          })}
        >
          {label}
        </label>
      )}

      {/* Select */}
      <div
        data-cy="dropdown-block"
        data-testid="dropdown-block"
        className={cx('select', {
          'select--opened': listOpened,
          'select--disabled': disabled,
          'select--no-options': optionsToRender.length === 0,
          'select--showTiles': showAsFilters,
          'select--searchInOptions': searchInOptions,
          'select--loading': loading,
          'select--hasWarning': warning,
          'select--opened-upwards': listOpened && optionsDisplayedUpwards,
        })}
        ref={dropdownRef}
        role="listbox"
        tabIndex={0}
        id={id}
        onClick={(e) => {
          if (!multiSelect) {
            // For regular select, toggle open/close
            if (e.target === dropdownRef.current) {
              toggleListOpened()
            }
          } else {
            // For multi-select just toggle OPEN
            if (!listOpened) {
              toggleListOpened()
            }
            // Toggle close list if is open and click on dropdown
            if (listOpened && e.target === dropdownRef.current) {
              toggleListOpened()
            }
          }
        }}
        onKeyDown={onKeyDown}
      >
        {/* Visible option */}
        <div
          role="option"
          data-testid="dropdown-option"
          id={`${id}-0`}
          data-index="0"
          aria-selected="true"
          className={cx({
            'dropdown--pointer': !showAsFilters,
            'dropdown--placeholder': visibleOption === defaultOptionText,
            'dropdown--icon': !!icon,
            'dropdown--bold': selectedElement?.isGroup,
            'dropdown--placeholder--error': !!error,
            'color-red-important': (isRequiredError && error) || textOnlyError,
          })}
        >
          {icon}
          {searchable && !searchInOptions ? (
            <>
              <input
                data-cy="dropdown-search-input"
                ref={searchRef}
                className={cx({ select__search: true, hidden: !listOpened })}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                }}
                value={searchValue}
              />
              {!listOpened && visibleOption}
            </>
          ) : (
            visibleOption
          )}
        </div>
        {loading && <Loader className="select__loader" />}
        {warning && (
          <Icon tooltip={warning} className="select__warning">
            <WarningIcon />
          </Icon>
        )}
        {React.Children.map(children, (child, index) => (
          <div
            key={index}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {child}
          </div>
        ))}
        <div
          data-cy="dropdown-options"
          ref={optionsRef}
          data-testid="dropdown-options"
          className={cx('select__options', {
            'select__options--open': listOpened,
            'select__options--up': listOpened && optionsDisplayedUpwards,
          })}
          style={optionsHeight ? { maxHeight: `${optionsHeight}px` } : {}}
        >
          {searchable && searchInOptions && (
            <>
              <div
                className="select__search--bottom"
                onClick={(e) => e.stopPropagation()}
              >
                <SearchIcon className="fill-light-blue search-icon" alt="" />
                <input
                  placeholder="Search..."
                  ref={searchRef}
                  className={cx({ select__search: true, hidden: !listOpened })}
                  onChange={(e) => {
                    setSearchValue(e.target.value)
                  }}
                  value={searchValue}
                />
              </div>
              <div
                className={cx('select__separator', {
                  'select__separator--with-deselect': deselectLabel,
                })}
              />
            </>
          )}

          {/* Select All toggles */}
          {multiSelect && selectAll && (
            <div
              role="option"
              className="select__toggles select__toggles--select-all"
            >
              <CheckboxSelectAll
                isBlue
                label={typeof selectAll === 'string' ? selectAll : 'Select All'}
                value={checkboxState}
                onClick={onSelectAll}
              />
            </div>
          )}

          {/* Other options */}
          {listOpened && renderList()}

          {optionsToRender.length && !displayedOptions.length ? (
            <div
              className="select__no-results"
              onClick={(e) => e.stopPropagation()}
            >
              No Results
            </div>
          ) : null}
        </div>
      </div>

      {error && showErrorMessage && (
        <div className="error-wrapper">{!isRequiredError ? error : ''}</div>
      )}
    </label>
  )
}

const propTypes = {
  options: PropTypes.array,
  label: PropTypes.node,
  defaultState: PropTypes.any, // Used for single type dropdown
  selectedItems: PropTypes.array, // Used for multi type dropdown
  onChange: PropTypes.func,
  error: PropTypes.any,
  textOnlyError: PropTypes.bool,
  disabled: PropTypes.bool,
  dark: PropTypes.bool,
  defaultOptionText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  multiSelect: PropTypes.bool,
  reorderSelectedOnTop: PropTypes.bool,
  selectAll: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  hasSearch: PropTypes.bool,
  disableSearch: PropTypes.bool,
  displaySearchInOptions: PropTypes.bool,
  deselectLabel: PropTypes.string, // Label for option that resets the input
  showAsFilters: PropTypes.bool,
  overwriteSelectedText: PropTypes.node, // Overwrite display text shown (defaults to selected item/items)
  optionRenderer: PropTypes.func, // Overwrite how each option renders via a function
  autoSelectFirst: PropTypes.bool, // Auto-select first value
  labelTop: PropTypes.bool,
  loading: PropTypes.bool,
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  onOpened: PropTypes.func,
  sections: PropTypes.array, // Offers the possibility to have sections labeled within the dropdown options
  showErrorMessage: PropTypes.bool,
  icon: PropTypes.node,
  showOptionsInPlaceholder: PropTypes.bool,
  white: PropTypes.bool,
  sortOptions: PropTypes.bool,
  children: PropTypes.node,
  optionsHeight: PropTypes.number,
  characterToJoinSelected: PropTypes.string,
  labelClassName: PropTypes.string,
  isConversionDropdown: PropTypes.bool,
  groups: PropTypes.array,
  getSelectedItemsByKey: PropTypes.bool,
}

Dropdown.propTypes = propTypes
