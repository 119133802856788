import { useEffect, useState, useRef } from 'react'

import { useStore } from 'store'

import useLeaveConfirm from 'components/leave-confirm'
import StickyFooter from 'components/sticky-footer'

import NamingConventions from 'modules/naming-conventions'
import SaveConventionModal from 'modules/naming-conventions/components/save-convention-modal'

import 'modules/platform-settings/global-settings.scss'
import {
  getGlobalConventions,
  setGlobalConventions,
} from 'modules/naming-conventions/actions'
import { checkForChanges } from 'modules/naming-conventions/utils'
import { getCompanies } from 'modules/companies/actions'

/**
 * Naming Conventions Global Settings Page
 */
const NamingConventionsGlobal = () => {
  const [conventions, setConventions] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showNameEditModal, setShowNameEditModal] = useState(false)

  const { dispatch } = useStore()

  const initialConventions = useRef(null)

  const [setDirty, LeaveConfirm, isDirty] = useLeaveConfirm()

  useEffect(() => {
    setLoading(true)
    getGlobalConventions()
      .then((result) => {
        initialConventions.current = result
        setConventions(result)
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const preSave = () => {
    if (checkForChanges(conventions, initialConventions.current)) {
      setShowNameEditModal(true)
      return
    }

    onSave()
  }

  /**
   * Save colors callback
   */
  const onSave = () => {
    if (!conventions) {
      return
    }

    setLoading(true)

    setGlobalConventions(conventions)
      .then(async (result) => {
        initialConventions.current = result
        setDirty(false)
        // Just in case Company-Specific Naming Conventions changed
        getCompanies(dispatch, { deleted: false })
          .then(() => {
            setLoading(false)
          })
          .catch(console.error)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  const onCancel = () => {
    setDirty(false)
    setConventions(initialConventions.current)
    setShowNameEditModal(false)
  }

  const onChangeConventions = (conventions) => {
    setConventions(conventions)
    setDirty(true)
  }

  return (
    <section className="naming-conventions">
      <LeaveConfirm />
      <SaveConventionModal
        onCancel={onCancel}
        onConfirm={() => {
          onSave()
          setShowNameEditModal(false)
        }}
        open={showNameEditModal}
      />
      <NamingConventions
        isGlobal
        conventions={conventions}
        setConventions={onChangeConventions}
        globalConventions={conventions}
      />
      {isDirty && (
        <StickyFooter
          buttons={[
            {
              value: 'Save Changes',
              onClick: preSave,
              disabled: loading,
            },
            {
              value: 'Cancel',
              onClick: onCancel,
              secondaryGray: true,
            },
          ]}
        />
      )}
    </section>
  )
}

export default NamingConventionsGlobal
