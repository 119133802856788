import { useEffect, useState, useRef } from 'react'

import { useStore } from 'store'

import useLeaveConfirm from 'components/leave-confirm'
import StickyFooter from 'components/sticky-footer'
import OfflineData from 'modules/offline-data'
import SaveOfflineDataModal from 'modules/offline-data/components/save-convention-modal'

import Loader from 'components/loader'

import {
  getGlobalOfflineData,
  setGlobalOfflineData,
} from 'modules/offline-data/actions'
import { getCompanies } from 'modules/companies/actions'

import { validateOfflineData } from 'modules/offline-data/utils'

import 'modules/platform-settings/global-settings.scss'

/**
 * Naming Conventions Global Settings Page
 */
const OfflineDataGlobal = () => {
  const [offlineData, setOfflineData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [offlineDataModal, setOfflineDataModal] = useState(false)
  const [errors, setErrors] = useState({})

  const { dispatch } = useStore()

  const initialOfflineData = useRef(null)

  const [setDirty, LeaveConfirm, isDirty] = useLeaveConfirm()

  useEffect(() => {
    setLoading(true)
    getGlobalOfflineData()
      .then((result) => {
        initialOfflineData.current = result
        setOfflineData(result)
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
        setInitialized(true)
      })
  }, [])

  const onSave = () => {
    if (!offlineData) {
      return
    }

    setLoading(true)

    setGlobalOfflineData(offlineData)
      .then((result) => {
        initialOfflineData.current = result
        setDirty(false)
        // Just in case Company-Specific Offline Data changed
        getCompanies(dispatch, { deleted: false })
          .then(() => {
            setLoading(false)
          })
          .catch(console.error)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
      .finally(() => {
        setOfflineDataModal(false)
      })
  }

  const _preSave = () => {
    if (isDirty) {
      const [isValid, errors] = validateOfflineData(offlineData)
      if (isValid) {
        setOfflineDataModal(true)
      } else {
        setErrors(errors)
      }
    } else {
      onSave()
    }
  }
  const onCancel = () => {
    setDirty(false)
    setOfflineData(initialOfflineData.current)
  }

  const onChangeOfflineData = (offlineData) => {
    setOfflineData(structuredClone(offlineData))
    setDirty(true)
  }

  if (!initialized) {
    return <Loader />
  }

  return (
    <section>
      <LeaveConfirm />
      <div
        data-cy="offline-data"
        className="form__section__body offline-data__section-body"
      >
        <OfflineData
          isGlobal
          offlineData={offlineData}
          setOfflineData={onChangeOfflineData}
          globalOfflineData={offlineData}
          errors={errors}
          setErrors={setErrors}
        />
      </div>

      <SaveOfflineDataModal
        onCancel={() => {
          setOfflineDataModal(false)
        }}
        onConfirm={onSave}
        open={offlineDataModal}
      />
      {isDirty && (
        <StickyFooter
          buttons={[
            {
              value: 'Save Changes',
              onClick: _preSave,
              disabled: loading,
            },
            {
              value: 'Cancel',
              onClick: onCancel,
              secondaryGray: true,
            },
          ]}
        />
      )}
    </section>
  )
}

export default OfflineDataGlobal
