import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { utils, granularities } from '@decision-sciences/qontrol-common'
import { useStore } from 'store'

import useSession from 'modules/session'

/* Custom Hooks */
import { useLoading } from 'components/utils/custom-hooks'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

/* Components */
import CollapsibleSection from 'components/collapsible-section'
import NamingConventions from 'modules/naming-conventions'
import { CopyControl } from 'modules/companies/section-copy-components'
import Loader from 'components/loader'
import { SectionLinkControl } from 'modules/companies/section-link-components'

/* Actions */
import { getGlobalConventions } from 'modules/naming-conventions/actions'
import {
  initCopySettingsSection,
  undoCopySettingsSection,
} from 'modules/companies/actions'
import {
  COMPANY_SECTIONS,
  COPY_SETTINGS_STATUS,
} from 'modules/companies/constants'

const { keyBy } = utils.array

const NamingConventionsSection = ({
  client,
  parentClient,
  openedSubSection,
  setOpenedSubSection,
  onChangeConventions,
  isViewMode,
  links,
  onChangeLinks,
}) => {
  const { state, dispatch } = useStore()
  const [, user] = useSession()
  const { copySettings } = state.companies
  const copyNamingConventionSection =
    copySettings[COMPANY_SECTIONS.NAMING_CONVENTIONS]
  const ref = useRef()

  useEffect(() => {
    if (!ref.current) {
      return
    }

    if (openedSubSection !== ref.current.isSectionOpen()) {
      ref.current.onCollapse(openedSubSection)
    }
  }, [openedSubSection])

  const [globalConventions, setGlobalConventions] = useState(null)
  const [loading, toggleLoading] = useLoading()
  const [copyFromCompany, setCopyFromCompany] = useState(null)

  const isLinked = links[client._id]
  const namingConventions = isLinked
    ? parentClient.namingConventions
    : client.namingConventions

  const conventions = Array.isArray(namingConventions)
    ? keyBy(namingConventions, 'granularity')
    : namingConventions

  useEffect(() => {
    toggleLoading('GLOBAL', true)
    getGlobalConventions()
      .then((result) => {
        setGlobalConventions(result)
      })
      .catch(console.error)
      .finally(() => {
        toggleLoading('GLOBAL', false)
      })
  }, [])

  useEffect(() => {
    if (copyFromCompany) {
      initCopySettingsSection(
        dispatch,
        COMPANY_SECTIONS.NAMING_CONVENTIONS,
        conventions
      )
      let namingConventionsToCopy = []
      if (copyFromCompany.companyId === parentClient._id.toString()) {
        namingConventionsToCopy = parentClient.namingConventions
      } else {
        const flattenedCompanies = state.companies.list.flatMap((company) => [
          company,
          ...company.businessUnits,
        ])
        const copyFromCompanyObject = flattenedCompanies.find(
          (el) => el._id.toString() === copyFromCompany.companyId
        )
        if (copyFromCompanyObject) {
          namingConventionsToCopy = copyFromCompanyObject.namingConventions
        }
      }
      const newConventions = Array.isArray(namingConventionsToCopy)
        ? keyBy(namingConventionsToCopy, 'granularity')
        : namingConventionsToCopy
      for (const convention of Object.values(newConventions)) {
        convention.clientId = client._id.toString()
        delete convention._id
        delete convention.createdAt
        delete convention.updatedAt
      }
      onChangeConventions(
        Object.keys(newConventions).length
          ? newConventions
          : {
              [granularities.GRANULARITIES.CAMPAIGN]: null,
              [granularities.GRANULARITIES.AD_GROUP]: null,
            }
      )
    }
  }, [copyFromCompany])

  useEffect(() => {
    if (
      copyNamingConventionSection?.status === COPY_SETTINGS_STATUS.REVERTING
    ) {
      if (
        copyNamingConventionSection?.currentIndex >= 0 &&
        copyNamingConventionSection?.history?.[
          copyNamingConventionSection.currentIndex
        ]?.data
      ) {
        onChangeConventions(
          copyNamingConventionSection.history[
            copyNamingConventionSection.currentIndex
          ].data
        )
        undoCopySettingsSection(dispatch, COMPANY_SECTIONS.NAMING_CONVENTIONS)
      }
    }
  }, [JSON.stringify(copyNamingConventionSection)])

  const readCopySettingsAccess = useAccess({
    feature: PERMISSIONS.COPY_CLIENT_SETTINGS_BUSINESS_UNIT,
    type: PERMISSION_TYPES.READ,
  })

  const editCopySettingsAccess = useAccess({
    feature: PERMISSIONS.COPY_CLIENT_SETTINGS_BUSINESS_UNIT,
    type: PERMISSION_TYPES.EDIT,
  })
  const isEditCopySettingsMode = user.isSuperAdmin || editCopySettingsAccess

  return (
    <CollapsibleSection
      id="naming-conventions-section"
      header={
        <div className="display-flex align-row gap-8">
          <div>Naming Conventions</div>
          {!isViewMode && (
            <div className="display-flex align-row  gap-8">
              <SectionLinkControl
                client={client}
                links={links}
                onChangeLinks={onChangeLinks}
                isSectionOpen={openedSubSection}
              />
              {parentClient && readCopySettingsAccess && (
                <div className="display-flex align-row gap-8">
                  <CopyControl
                    company={client}
                    parentCompany={parentClient}
                    onChangeCopyCompany={(companyId) =>
                      setCopyFromCompany({ companyId: companyId })
                    }
                    disabled={!isEditCopySettingsMode}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      }
      wrapperClassName="form__section"
      onCollapseListener={setOpenedSubSection}
      defaultCollapsed={!openedSubSection}
    >
      {loading || !globalConventions ? (
        <Loader />
      ) : (
        <NamingConventions
          clientId={client._id}
          isGlobal={false}
          globalConventions={globalConventions}
          setConventions={onChangeConventions}
          conventions={conventions || {}}
          isViewMode={isViewMode || isLinked}
        />
      )}
    </CollapsibleSection>
  )
}

NamingConventionsSection.propTypes = {
  client: PropTypes.object.isRequired,
  parentClient: PropTypes.object,
  onChangeConventions: PropTypes.func.isRequired,
  isViewMode: PropTypes.bool,
  links: PropTypes.object,
  onChangeLinks: PropTypes.func.isRequired,
  openedSubSection: PropTypes.object.isRequired,
  setOpenedSubSection: PropTypes.func.isRequired,
}

export default NamingConventionsSection
