import { utils } from '@decision-sciences/qontrol-common'
import { validateOfflineData } from 'modules/offline-data/utils'
import { containsLogicalOperator } from 'components/panel-with-highlighted-content/utils'
import { virtualToNamingConvention } from 'modules/naming-conventions/utils'
import { KPI_ERROR_MESSAGE } from './constants'

const { removeEmptyKeys } = utils.object

export const validateFacebookAttribution = (facebookAccounts, entity) => {
  const errors = {}
  let isValid = true
  const { facebookKPIAttribution, primaryKPIs, secondaryKPIs } = entity

  const hasAccPrimaryKpis = primaryKPIs?.some((kpi) =>
    facebookAccounts.includes(kpi.accountId)
  )
  const hasAccSecondaryKpis = secondaryKPIs?.some((kpi) =>
    facebookAccounts.includes(kpi.accountId)
  )

  if (hasAccPrimaryKpis || hasAccSecondaryKpis) {
    const { dynamicMetrics, primaryKPIs, secondaryKPIs } =
      facebookKPIAttribution

    const hasDynamic =
      dynamicMetrics &&
      Object.values(dynamicMetrics).some((val) => val !== null)
    const hasPrimary =
      primaryKPIs && Object.values(primaryKPIs).some((val) => val !== null)
    const hasSecondary =
      secondaryKPIs && Object.values(secondaryKPIs).some((val) => val !== null)

    if (!hasDynamic) {
      errors.dynamicMetrics = KPI_ERROR_MESSAGE
      isValid = false
    }
    if (!hasPrimary) {
      errors.primaryKPIs = KPI_ERROR_MESSAGE
      isValid = false
    }
    if (!hasSecondary) {
      errors.secondaryKPIs = KPI_ERROR_MESSAGE
      isValid = false
    }
  }
  return [isValid, errors]
}

export const validateDataBeforeConfirm = (company) => {
  let isValid = true
  let errors = {}
  const { offlineData } = company
  if (offlineData && offlineData.length) {
    let isValidOfflineData = true
    const offlineDataErrors = {}

    offlineData.forEach((data) => {
      const [isValid, errors] = validateOfflineData(data)
      if (!isValid) {
        isValidOfflineData = false
        offlineDataErrors[data.filename] = errors
      }
    })

    if (!isValidOfflineData) {
      isValid = false
      if (!errors) {
        errors = {}
      }
      errors = offlineDataErrors
    }
  }
  return [isValid, errors]
}

export const getErrors = (errorMessage) => {
  if (!errorMessage || typeof errorMessage !== 'string') {
    return {}
  }
  const newErrors = {}
  Object.values(ERROR_FIELDS_WITH_MESSAGE).forEach(
    ({ field, matchString, notInclude }) => {
      const regex = new RegExp(matchString, 'i')
      let notIncludeRegex
      if (notInclude) {
        notIncludeRegex = new RegExp(notInclude, 'i')
        if (
          errorMessage.match(regex)?.length &&
          !errorMessage.match(notIncludeRegex)?.length
        ) {
          newErrors[field] = errorMessage
        }
      } else {
        if (errorMessage.match(regex)?.length) {
          newErrors[field] = errorMessage
        }
      }
    }
  )
  if (!Object.keys(newErrors).length) {
    newErrors.general = errorMessage
  }
  return newErrors
}

export const ERROR_FIELDS_WITH_MESSAGE = {
  NAME: { field: 'name', matchString: 'name', notInclude: 'business unit' },
  CLIENT_ID: {
    field: 'clientId',
    matchString: 'Client id',
    notInclude: 'business unit',
  },
}

export const applyBulkEditChanges = (companies = [], changes = {}) => {
  const clearedChanges = removeEmptyKeys(changes)
  // Apply changes to selected companies
  return companies.map((comp) => {
    Object.entries(clearedChanges).forEach(([clientId, change]) => {
      if (typeof change === 'object' && !Array.isArray(change)) {
        Object.keys(change).forEach((subKey) => {
          if (!comp[clientId]) {
            comp[clientId] = {}
          }
          comp[clientId][subKey] = change[subKey]
        })
      } else {
        comp[clientId] = change
      }
    })
    return comp
  })
}

/**
 * Pull relevant data from the client to use on the business unit
 * @param {String} newBusinessUnitId New ID for the Business Unit
 * @returns {Object}
 */
export const getCompanySettings = ({
  newBusinessUnitId,
  companyToExtractDataFrom,
  userData,
  accountsToInclude = [],
  clientId = '',
  newName = null,
  editExistingBU = false,
}) => {
  if (!companyToExtractDataFrom) {
    return {}
  }
  const {
    contact,
    name,
    active,
    defaultApprover,
    backupApprover,
    accountLead,
    primaryCurrency,
    slack,
    tableauConfig,
    alertThresholds,
    users,
    reportings,
    namingConventions,
    offlineData,
    primaryKPIs,
    secondaryKPIs,
    reportingKPIs,
    accounts,
  } = companyToExtractDataFrom

  const extraFieldsToInclude = {}
  if (accountsToInclude.length) {
    extraFieldsToInclude.primaryKPIs = primaryKPIs.filter(({ accountId }) =>
      accountsToInclude.includes(accountId)
    )
    extraFieldsToInclude.secondaryKPIs = secondaryKPIs.filter(({ accountId }) =>
      accountsToInclude.includes(accountId)
    )
    extraFieldsToInclude.reportingKPIs = (reportingKPIs || []).filter(
      ({ accountId }) => accountsToInclude.includes(accountId)
    )
    extraFieldsToInclude.accounts = accounts
      .filter(({ externalAccountId }) =>
        accountsToInclude.includes(externalAccountId)
      )
      .map((account) => ({ ...account, company: newBusinessUnitId }))
  }
  if (editExistingBU) {
    extraFieldsToInclude._id = newBusinessUnitId
  }

  // This is to ensure that the new naming conventions have the correct structure of {GRANULARITY: {...conventions}}
  const newNamingConventions = Object.entries(
    virtualToNamingConvention(namingConventions || [])
  ).reduce(
    (prev, [granularity, convention]) => ({
      ...prev,
      [granularity]: {
        clientId: newBusinessUnitId,
        dimensions: convention.dimensions,
        granularity,
        delimiter: convention.delimiter,
        isGlobal: convention.isGlobal,
      },
    }),
    {}
  )

  return {
    contact,
    name: newName ?? `${name} Copy`,
    clientId,
    active,
    defaultApprover: defaultApprover?._id || defaultApprover,
    backupApprover: backupApprover?._id || backupApprover,
    accountLead: accountLead?._id || accountLead,
    primaryCurrency,
    slack,
    tableauConfig,
    alertThresholds: (alertThresholds || []).map(({ _id, ...other }) => other),
    users,
    namingConventions: newNamingConventions,
    offlineData: (offlineData || []).map(({ _id, ...other }) => ({
      clientId: newBusinessUnitId,
      ...other,
    })),
    reportings: (reportings || []).map(({ _id, owner, ...other }) => ({
      ...other,
      owner: {
        _id: userData._id,
        firstName: userData.firstName,
        lastName: userData.lastName,
      },
    })),
    ...extraFieldsToInclude,
  }
}

const substractNumberRegExp = /(-\s*[0-9]+)/

const substractedFromNumberRegExp = /([0-9]+\s*-)/

export const findNextThresholdAndEcuationParameters = ({
  alertThresholdValues,
  currentWordIndex,
  words,
}) => {
  let nextThrValue = undefined
  let substractOne = false
  let isSubstractedFromOne = false
  // Parse words in expression before metric
  let idx = currentWordIndex - 1
  let isOnRightSideOfComparison = true
  while (idx >= 0 && !containsLogicalOperator(words[idx])) {
    if (substractedFromNumberRegExp.test(words[idx])) {
      // Currently only '1-' is handled not other number
      isSubstractedFromOne = true
    }
    idx--
  }
  const thrSign = isSubstractedFromOne ? -1 : 1
  let incrementSing = thrSign
  // Parse words in expression after metric
  idx = currentWordIndex + 1
  while (
    idx <= words.length - 1 &&
    isNaN(nextThrValue) &&
    !containsLogicalOperator(words[idx])
  ) {
    Object.entries(alertThresholdValues).forEach(
      ([thresholdName, thresholdValue]) => {
        if (words[idx].includes(thresholdName)) {
          nextThrValue = parseFloat(thresholdValue)
        }
      }
    )
    if (words[idx].includes('>')) {
      isOnRightSideOfComparison = false
    } else if (words[idx].includes('<')) {
      isOnRightSideOfComparison = false
      incrementSing = incrementSing * -1
    } else if (substractNumberRegExp.test(words[idx])) {
      // Currently only '-1' is handled not other number
      substractOne = true
    }
    idx++
  }
  const addToThr = !isSubstractedFromOne && !substractOne ? 0 : 1
  return {
    nextThrValue,
    addToThr,
    thrSign,
    incrementSing,
    isOnRightSideOfComparison,
  }
}
