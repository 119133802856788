import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/button'
import Overlay from 'components/overlay'
import { ReactComponent as FiltersSvg } from 'assets/filters.svg'
import { ReactComponent as Check } from 'assets/icon_check_black.svg'
import { ReactComponent as Clear } from 'assets/icon_remove_circle_outline.svg'
import cx from 'classnames'
import './style.scss'

/**
 * Renders a filters pane
 * @param {Object} props React Props
 * @param {Array.<React.ComponentElement>} props.children React Children
 * @param {Function} props.onApply On Applying filters
 * @param {Function} props.onClear On clearing filters
 * @param {Boolean} props.loading Whether the filtered items are loading or not
 * @param {Boolean | null} [props.initialApplied = null] Flag to externally set if there are some filters pre-set
 * @param {Boolean} props.disableApply Disable "Apply button"
 * @param {Boolean} props.disableClearOnUnmount Disable clearing the filters when component is unmounted
 */
const Filters = ({
  children,
  onApply,
  onClear,
  loading,
  initialApplied = null,
  disableApply = false,
  disableClearOnUnmount = false,
}) => {
  /**
   * Whenever we navigate away from a page with filters,
   * run the onClear() function, so that we have unfiltered data when we return.
   */
  useEffect(() => {
    if (!disableClearOnUnmount) {
      return () => {
        onClear && onClear()
      }
    }
  }, [])

  useEffect(() => {
    if (initialApplied !== null && initialApplied !== filtersApplied) {
      setFiltersApplied(initialApplied)
    }
  }, [initialApplied])

  const [opened, setOpened] = useState(false)
  const [filtersApplied, setFiltersApplied] = useState(false)
  const filtersContainerClasses = cx(
    'filters form',
    { 'filters--opened': opened },
    { 'form--loading': loading }
  )

  const onKeyDown = (event) => {
    const keyCode = event.keyCode || event.which
    if (keyCode === 13) {
      onApply()
      setFiltersApplied(true)
      setOpened(false)
    }
  }

  return (
    <>
      <div
        data-testid="filters-toggle"
        className={cx('filters-toggle', {
          'filters-toggle--applied': filtersApplied,
        })}
        onClick={() => setOpened(!opened)}
      >
        <FiltersSvg className="fill-light-blue" alt={'filters icon'} />
      </div>

      <div
        data-testid="filters-container"
        className={filtersContainerClasses}
        onKeyDown={onKeyDown}
      >
        <div className="filters__heading-box">
          <p className="heading">Filters</p>
          <div className="filters__heading-box__buttons">
            <Button
              data-testid="apply-button"
              value={
                <div className="filters-button">
                  <Check className="margin-right-10" /> Apply
                </div>
              }
              onClick={(...args) => {
                onApply(...args)
                setFiltersApplied(true)
                setOpened(false)
              }}
              disabled={loading || disableApply || !filtersApplied}
              compact
              secondary
            />
            <Button
              data-testid="clear-button"
              value={
                <div className="filters-button ">
                  <Clear className="margin-right-10" />
                  Clear
                </div>
              }
              compact
              secondaryRed
              onClick={(...args) => {
                onClear(...args)
                setFiltersApplied(false)
                setOpened(false)
              }}
              disabled={loading || !filtersApplied}
            />
          </div>
        </div>

        <div className="filters--container">{children}</div>
      </div>

      <Overlay
        data-testid="filters-overlay"
        expanded={opened}
        onClick={() => setOpened(false)}
      />
    </>
  )
}

/**
 * Renders a Filter Sections (meant for an input or group of inputs)
 * @param {Object} params React Params
 * @param {String} [params.label] Label to display
 * @param {*} params.children React Children
 * @param {String} [params.className] React Children
 * @returns {React.ComponentElement}
 */
const FilterSection = ({ label, children, className }) => {
  return (
    <div className="filters__section">
      <label className="filters__section__label">{label}</label>
      <div
        data-testid="filters-section-body"
        className={cx('filters__section__body', { [className]: className })}
      >
        {children}
      </div>
    </div>
  )
}

FilterSection.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Filters.propTypes = {
  children: PropTypes.node.isRequired,
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  initialApplied: PropTypes.bool,
  disableApply: PropTypes.bool,
  disableClearOnUnmount: PropTypes.bool,
}

export default Filters
export { FilterSection }
