import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import isEqual from 'lodash.isequal'

import Icon from 'components/icon'
import Button from 'components/button'
import Modal from 'components/modal'
import WarningIcon from 'components/warning-icon'

import DimensionsList from 'modules/offline-data/components/dimensions-list'
import { compareDimensionsOrder } from 'modules/offline-data/utils'

import { ReactComponent as ResetIcon } from 'assets/icon_reset.svg'
import { ReactComponent as AddIcon } from 'assets/icon_plus_blue.svg'
import CreateActions from 'modules/companies/subsections/campaign-exclusions-section/components/campaign-exclusions-create/create-actions/index'

import '../style.scss'

const DESCRIPTION_DEFAULT =
  'Place the dimensions in the order of the client’s file. '

/**
 * Naming Dimensions Render
 * Displays a naming conventions section.
 * @param {Object} params React Params
 * @param {String} [params.className] Extra classname to give to the section
 * @param {String} params.title Section title
 * @param {String} [params.description] Section description (has default)
 * @param {Array} [params.defaultDimensions] Default Dimensions array
 * @param {Array} params.dimensions Dimensions
 * @param {Function} params.setDimensions Dimensions setter
 */
const NamingDimensions = ({
  className,
  title,
  description = DESCRIPTION_DEFAULT,
  defaultDimensions,
  dimensions = [],
  setDimensions,
  isGlobal,
  isViewMode,
  errors,
  setErrors,
  fileName,
  isNew,
  onCancel,
  onSave,
  onRemove,
}) => {
  const [resetDefaultModal, setResetDefaultModal] = useState(false)
  const unifiedDimensions = useMemo(() => {
    const dimensionsArray = dimensions || defaultDimensions
    return dimensionsArray.sort(compareDimensionsOrder)
  }, [defaultDimensions, JSON.stringify(dimensions)])

  const sameAsDefaultData = useMemo(() => {
    return (
      defaultDimensions.length === dimensions.length &&
      dimensions.every((_dimension) => {
        delete _dimension._id
        delete _dimension.conversion_id
        return defaultDimensions.some((dimension) => {
          delete dimension._id
          delete dimension.conversion_id
          return (
            dimension.dimensionId === _dimension.dimensionId &&
            isEqual(_dimension, dimension)
          )
        })
      })
    )
  }, [JSON.stringify(defaultDimensions), JSON.stringify(dimensions)])

  const resetToDefault = () => {
    setResetDefaultModal(true)
  }

  const onAddDimension = () => {
    // returns a copy number that's not taken. Ensures that there are no duplicate names generated by adding a dimension.
    const getCopyNumber = () => {
      const takenNumbers = []

      // We're chucking all copy numbers into an array Eg. "Copy1" and "Copy3" gets us [1, 3]
      dimensions.forEach(({ dimensionName }) => {
        if (dimensionName.match(new RegExp(`^(Custom*[0123456789]*$)+`))) {
          takenNumbers.push(parseInt(dimensionName.split('Custom')[1]))
        }
      })

      // We're going through the sorted list of numbers, if we find an "unoccupied" spot, we return it as our copy number.
      // Eg: [1,2,4,5] returns 3
      let previousNumber = 0

      takenNumbers
        .sort((a, b) => (a > b ? 1 : -1))
        .forEach((number) => {
          if (previousNumber + 1 !== number) {
            return previousNumber + 1
          }
          previousNumber = number
        })

      return previousNumber + 1
    }

    const newDimension = {
      required: false,
      dimensionId: uuidv4(),
      dimensionOrder: dimensions.length + 1,
      dimensionName: `Custom${getCopyNumber()}`,
      dimensionType: null,
      isNew: true,
    }

    setDimensions([...dimensions, newDimension])
  }

  const _renderExtraOptions = () => {
    return (
      <div
        className={cx('offline-data__heading', {
          'offline-data__heading__global': isGlobal,
        })}
      >
        <h3 className="offline-data__heading__title">{title}</h3>
        {!isViewMode && (
          <Button
            onClick={resetToDefault}
            secondaryRed
            disabled={sameAsDefaultData}
            value={
              <Icon
                className="offline-data__heading__reset"
                disabled={sameAsDefaultData}
              >
                <ResetIcon width={18} height={18} className="svg--red" />
                Reset To Default
              </Icon>
            }
          ></Button>
        )}
      </div>
    )
  }

  const areValidDimensions = () => {
    return dimensions?.every((el) => el.dimensionType)
  }

  return (
    <section className={cx('offline-data', { [className]: className })}>
      <Modal
        className="offline-data__modal"
        icon={<WarningIcon />}
        rightAlignButtons
        opened={!!resetDefaultModal}
        contentSeparator
        button={
          <Button
            value="Confirm"
            green
            onClick={() => {
              setDimensions([...defaultDimensions])
              setErrors({})
              setResetDefaultModal(false)
            }}
          />
        }
        buttonSecondary={
          <Button
            value={'Cancel'}
            onClick={() => setResetDefaultModal(false)}
            secondaryGray
          />
        }
        heading="Reset Offline Data Settings to Default"
      >
        <div>
          You are about to reset the Import Offline Data settings to default.{' '}
          <br />
          Click the Confirm button to continue with this action. This action
          cannot be undone.
        </div>
      </Modal>
      {isGlobal && _renderExtraOptions()}
      {isGlobal && (
        <div className="offline-data__description">{description}</div>
      )}
      <DimensionsList
        dimensions={unifiedDimensions}
        setDimensions={setDimensions}
        isGlobal={isGlobal}
        isViewMode={isViewMode}
        errors={errors?.dimensions || {}}
        setErrors={setErrors}
      />
      {!isViewMode && (
        <div className="offline-data__add">
          <Button
            onClick={onAddDimension}
            value={
              <div className="offline-data-dimensions-button offline-data-dimensions-button--content">
                <AddIcon />
                <span>Add New Dimension</span>
              </div>
            }
            secondary
          />
        </div>
      )}
      {!isGlobal && (
        <CreateActions
          loading={false}
          saveDisabled={errors?.filename || !fileName || !areValidDimensions()}
          onSaveClicked={onSave}
          onCancelClicked={onCancel}
          hasDelete={!isNew}
          onDeleteClicked={onRemove}
          extra={_renderExtraOptions()}
        />
      )}
    </section>
  )
}

NamingDimensions.propTypes = {
  dimensions: PropTypes.array.isRequired,
  setDimensions: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  defaultDimensions: PropTypes.array,
  delimiter: PropTypes.string,
  isGlobal: PropTypes.bool,
  isViewMode: PropTypes.bool,
  allDimensionNames: PropTypes.array,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
  fileName: PropTypes.string,
  isNew: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default NamingDimensions
