import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { utils, globalReports } from '@decision-sciences/qontrol-common'

/* Components */
import Table from 'components/table/beta'

/* Utils & Constants */
import { format } from 'date-fns'
import isEqual from 'lodash.isequal'
import cx from 'classnames'
import {
  TABLE_CONTAINER,
  defaultFilterSortConfig,
} from 'modules/table-filter-sort/constants'
import {
  resetFilterSort,
  saveFilter,
  saveSort,
  savePagination,
} from 'modules/table-filter-sort/actions'

/* Hooks */
import { useNavigate } from 'react-router-dom'

/* Store & Actions */
import { useStore } from 'store'
import useSession from 'modules/session'
import { toggleFavorite } from 'modules/global-reports/actions'

/* Components */
import Filters from 'components/filters'
import Input from 'components/input'
import { Dropdown } from 'components/dropdown'

const { getCommonFields } = utils.object
const { REPORT_TYPES_NAMING } = globalReports
/**
 * Index page for Global Reports dashboard.
 * Accessed from the Reports panel and enables the users to view a specific embedded report.
 * @returns {React.ComponentElement}
 */
const DashboardIndex = () => {
  const navigate = useNavigate()
  const [, userData] = useSession()
  const {
    state: {
      tableFilterSort,
      globalReports,
      companies: { currentCompany },
    },
    dispatch,
  } = useStore()
  const { viewList } = globalReports
  // const [reports, setReports] = useState(viewList)

  const tableContainer = TABLE_CONTAINER.REPORTS_INDEX
  const tableState = tableFilterSort.filterSort[tableContainer]
  const defaultTableState = defaultFilterSortConfig.filterSort[tableContainer]

  const filters = tableState.filter || defaultTableState.filter
  const pagination = tableState.pagination || defaultTableState.pagination
  const sort = tableState.sort || defaultTableState.sort

  // const { reportType } = filters

  const sortChanged = (newSort) => {
    if (!isEqual(newSort, sort)) {
      saveSort(dispatch, tableContainer, newSort)
    }
  }

  const onChangeTableView = ({ pageIndex, pageSize, sort: newSort }) => {
    if (newSort) {
      sortChanged(newSort)
    }

    savePagination(dispatch, tableContainer, {
      page: pageIndex,
      size: pageSize,
    })
  }

  // // Set reports to filtered reports when report type changes
  // useEffect(() => {
  //   let filteredReports = viewList
  //   if (reportType.length) {
  //     filteredReports = filteredReports.filter(({ reportType: type }) =>
  //       reportType.includes(type)
  //     )
  //   }
  //   setReports(filteredReports)
  // }, [reportType, JSON.stringify(viewList)])

  const columns = [
    {
      header: '',
      id: 'open',
      cell: (cell) => {
        const { _id, isCustom } = cell.row.original
        const route = isCustom ? `/reports/${_id}` : `/tableau-dashboard/${_id}`
        return (
          <div className="table__actions">
            <div
              className="table__open"
              onClick={() => {
                navigate(route)
              }}
            />
          </div>
        )
      },
      size: 70,
      maxSize: 70,
      textAlign: 'center',
      cellTextAlign: 'center',
    },
    {
      header: '',
      id: 'favorite',
      cell: (cell) => {
        const { _id, isCustom, favorite } = cell.row.original
        return (
          <div className="table__actions">
            <div
              className={cx('table__star', {
                'table__star--empty': !favorite,
              })}
              onClick={() => {
                const reportInfo = {
                  userId: userData._id,
                  companyId: currentCompany._id,
                  reportId: _id,
                  isCustom: !!isCustom,
                }
                toggleFavorite(dispatch, reportInfo)
              }}
            />
          </div>
        )
      },
      size: 50,
      maxSize: 50,
      textAlign: 'center',
      cellTextAlign: 'center',
    },
    {
      header: 'Name',
      accessorKey: 'name',
      sortingFn: 'name',
      size: 550,
    },
    {
      header: 'Report Type',
      accessorFn: (row) => {
        return row.isCustom ? 'Custom' : 'Global'
      },
      size: 350,
    },
    {
      header: 'Last Viewed',
      accessorFn: (row) => {
        return row.lastViewed
          ? format(new Date(row.lastViewed), 'MM/dd/yyyy')
          : '-'
      },
      size: 100,
    },
  ]

  return (
    <div className="reports-index" data-cy="reports">
      <h1 className="heading" data-cy="page-heading">
        Reports
      </h1>
      <Table
        autoSort
        columns={columns}
        data={viewList || []}
        paginationValues={[5, 10, 20, 50, 100]}
        totalRows={viewList?.length || 0}
        showPagination={true}
        showSearchInput={false}
        manualFilter={filters.name}
        onChangeTableView={onChangeTableView}
        initialState={{
          sortBy: sort,
          pagination: {
            pageIndex: pagination.page,
            pageSize: pagination.size,
          },
        }}
      />
      <ReportsFilters
        dispatch={dispatch}
        tableContainer={tableContainer}
        filters={filters}
      />
    </div>
  )
}

const ReportsFilters = ({ tableContainer, dispatch, filters }) => {
  const initialState = defaultFilterSortConfig.filterSort[tableContainer].filter
  const [state, setState] = useState({ ...initialState })
  const [initial, current] = getCommonFields(initialState, state)

  useEffect(() => {
    setState(filters)
  }, [filters])

  const applyFilters = () => {
    saveFilter(dispatch, tableContainer, state)
  }

  const clearFilters = () => {
    resetFilterSort(dispatch, tableContainer)
  }

  return (
    <Filters
      loading={false}
      onApply={applyFilters}
      initialApplied={!isEqual(initial, current)}
      onClear={clearFilters}
    >
      <Input
        onChange={(name) => setState({ ...state, name })}
        value={state.name}
        placeholder="Search"
        search
      />
      {/* <div className="margin-top-10 general-label">Report Type</div>
      <Dropdown
        defaultOptionText="Select Report Type"
        options={Object.values(REPORT_TYPES_WITH_LABELS)}
        selectedItems={state.reportType}
        onChange={(reportType) => setState({ ...state, reportType })}
        selectAll={'All'}
        multiSelect
      /> */}
    </Filters>
  )
}

ReportsFilters.propTypes = {
  tableContainer: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
}

// const REPORT_TYPES_WITH_LABELS = {
//   CUSTOM: { value: REPORT_TYPES_NAMING.CUSTOM, label: 'Custom' },
//   GLOBAL: { value: REPORT_TYPES_NAMING.GLOBAL, label: 'Global' },
// }
export { DashboardIndex }
