import DataStoreManageCategory from 'modules/data-store-global/pages/category/create-edit'
import DataStoreCreateDimension from 'modules/data-store-global/pages/dimension/create'
import { useRoutes } from 'react-router-dom'
import '../style.scss'

/**
 * Routing module for CRUD operations on Categories and Dimensions/Metrics
 * @returns {React.FunctionComponent}
 */
const DataStoreGlobalModule = () => {
  const dataStoreRoutes = useRoutes([
    {
      path: 'category',
      children: [
        {
          path: 'new',
          element: <DataStoreManageCategory />,
        },
        {
          path: ':id',
          element: <DataStoreManageCategory />,
        },
      ],
    },
    {
      path: 'dimension-metric',
      children: [
        {
          path: 'new',
          element: <DataStoreCreateDimension />,
        },
      ],
    },
  ])

  return dataStoreRoutes
}

export default DataStoreGlobalModule
