import React, { useEffect, useState } from 'react'
import { Outlet, useParams, Navigate, useNavigate } from 'react-router-dom'
import { utils } from '@decision-sciences/qontrol-common'

/* Utils */
import { useStore } from 'store'
import { v4 as uuidv4 } from 'uuid'
import { getCompanySettings } from 'modules/companies/utils'

/* Hooks */
import { usePendingState } from 'components/utils/custom-hooks'
import useLeaveConfirm from 'components/leave-confirm'
import { useSimulationResults } from 'modules/companies/simulations'

/* Components */
import Loader from 'components/loader'
import BusinessUnitCopyConfirmation from 'modules/companies/subsections/business-unit-copy-confirmation/index'
import { openSingleClientAlertConfirmationModal } from 'modules/companies/subsections/alerts/single-client-confirmation/index'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block/index'

/* Actions */
import {
  getClientByClientId,
  getCompanies,
  getSingleClientAlerts,
  updateLinkSections,
} from 'modules/companies/actions'
import {
  showErrorMessage,
  showSuccessMessage,
} from 'modules/notifications/actions'

/* Constants */
import { NOT_FOUND_ROUTE } from 'routes'
import { COMPANY_DEFAULT, CREATE_BU_OPTIONS } from 'modules/companies/constants'

const { getArrayAsObjectWithUniqueKeysAndValues } = utils.array

const companyDefaultWithId = {
  new: true,
  _id: uuidv4(),
  ...COMPANY_DEFAULT,
}

const ClientOutlet = () => {
  const { clientId } = useParams()
  const navigate = useNavigate()

  const { dispatch, state } = useStore()
  const companies = state.companies.list
  const users = state.users.list
  const { userData } = state.session

  const createMode = clientId === 'new'

  const [inheritClientModal, setInheritClientModal] = useState(false)
  const [flags, setFlags] = useState({})
  const [loading, setLoading] = useState(true)
  const [editedCompany, setEditedCompany] = useState({
    ...companyDefaultWithId,
  })
  const [companiesIds, setCompaniesIds] = useState([])

  useEffect(() => {
    if (!createMode) {
      setLoading(true)
      getClientByClientId(clientId)
        .then((company) => {
          const usersInCompany = users.filter((user) =>
            user.clients.find((el) => el.clientId === company._id)
          )
          setEditedCompany({ ...company, users: usersInCompany })
          reset({ ...company, users: usersInCompany })
        })
        .catch((err) => showErrorMessage(err, dispatch))
        .finally(() => setLoading(false))
    } else {
      if (loading) {
        setLoading(false)
      }
    }
  }, [createMode, clientId])

  useEffect(() => {
    if (inheritClientModal) {
      getAllCompaniesIds()
    }
  }, [inheritClientModal])

  const onChangeFlags = (clientId, updates) => {
    setFlags((flags) => ({
      ...flags,
      [clientId]: { ...flags[clientId], ...updates },
    }))
  }

  const [
    company,
    setCompany,
    pendingChanges,
    defaultState,
    setDefaultCompany,
    reset,
  ] = usePendingState(editedCompany, companyDefaultWithId)

  const simulations = useSimulationResults({
    client: company,
    onSimulationResults: (simulationResults) =>
      setCompany({ simulationResults }),
    setLatestSimulation: (simulationResults) => {
      setDefaultCompany({ ...defaultState, simulationResults })
    },
    thresholdChanges: pendingChanges.alertThresholds,
    revertThresholds: () => {
      reset(defaultState, ['alertThresholds', 'simulationResults'])
    },
  })

  const [setDirty, LeaveConfirmModal, dirty] = useLeaveConfirm({
    safeRoutes: [
      '/company/:/business-unit',
      '/company/:/business-unit/:',
      '/company/:/account',
      '/company/:/account/:',
    ],
    customHeader: simulations.loading && 'Unsaved Changes & Simulation Running',
    customContent: simulations.loading && (
      <>
        <div>
          You have unsaved changes and a simulation is running for one or more
          entered client thresholds.
        </div>
        <div className="margin-top-22">
          Click the Continue button to move forward without saving your updates,
          or click Cancel to close this modal.
        </div>
        <InformationBlock
          type={INFORMATION_BLOCK_TYPE.SIMULATION}
          info={
            'Leaving the page before a simulation is complete will cancel the simulation. Entered values for client thresholds will not be saved.'
          }
        />
      </>
    ),
  })

  const originalBusinessUnits = getArrayAsObjectWithUniqueKeysAndValues(
    defaultState.businessUnits,
    '_id'
  )

  company.businessUnits = pendingChanges.businessUnits
    ? pendingChanges.businessUnits.map((bu) => ({
        ...bu,
        ...originalBusinessUnits[bu._id],
      }))
    : company.businessUnits

  if (!companies || loading) {
    return <Loader />
  }

  if (clientId && !createMode && !editedCompany) {
    return <Navigate to={NOT_FOUND_ROUTE} replace />
  }

  const getAllCompaniesIds = async () => {
    try {
      const clientIds = new Set()

      const { companies } = await getCompanies(dispatch)
      companies.forEach((item) => {
        if (item.businessUnits && item.businessUnits.length > 0) {
          item.businessUnits.forEach((bu) => {
            bu.clientId && clientIds.add(bu.clientId)
          })
        }
      })

      setCompaniesIds(Array.from(clientIds))
    } catch (err) {
      showErrorMessage(err, dispatch)
    }
  }

  const generateUniqueClientId = (baseClientId) => {
    let uniqueClientId = baseClientId
    let suffix = 1
    while (companiesIds.some((bu) => bu === uniqueClientId)) {
      suffix += 1
      uniqueClientId = `${baseClientId}-S${suffix}`
    }
    return uniqueClientId
  }

  const splitBUs = (selectedBU, newBUsToBeCreated, selectedAccounts) => {
    const newClient = structuredClone(company)
    const oldBUIndex = newClient.businessUnits.findIndex(
      ({ _id }) => _id === selectedBU
    )
    const oldBU = newClient.businessUnits[oldBUIndex]
    const [first, ...rest] = newBUsToBeCreated
    rest.forEach((newBUToBeCreated, idx) => {
      const _id = uuidv4()
      const baseClientId = `${oldBU.clientId}-S${idx + 1}`
      const uniqueClientId = generateUniqueClientId(baseClientId)

      const newBU = getCompanySettings({
        newBusinessUnitId: _id,
        companyToExtractDataFrom: oldBU,
        userData,
        accountsToInclude: newBUToBeCreated.accounts,
        clientId: uniqueClientId,
        newName: newBUToBeCreated.name,
      })
      newClient.businessUnits.push(newBU)
    })
    newClient.accounts = [
      ...newClient.accounts,
      ...oldBU.accounts.filter(({ key }) => !selectedAccounts.includes(key)),
    ]
    newClient.primaryKPIs = [
      ...newClient.primaryKPIs,
      ...oldBU.primaryKPIs.filter(
        ({ accountId }) => !selectedAccounts.includes(accountId)
      ),
    ]
    newClient.secondaryKPIs = [
      ...newClient.secondaryKPIs,
      ...oldBU.secondaryKPIs.filter(
        ({ accountId }) => !selectedAccounts.includes(accountId)
      ),
    ]
    newClient.reportingKPIs = [
      ...newClient.reportingKPIs,
      ...(oldBU.reportingKPIs || []).filter(
        ({ accountId }) => !selectedAccounts.includes(accountId)
      ),
    ]
    // Edit first
    const editedBU = getCompanySettings({
      newBusinessUnitId: oldBU._id,
      companyToExtractDataFrom: oldBU,
      userData,
      accountsToInclude: first.accounts,
      clientId: oldBU.clientId,
      newName: first.name,
      editExistingBU: true,
    })
    newClient.businessUnits[oldBUIndex] = editedBU
    setCompany(newClient)
  }

  /**
   * Runs a pre-check to see if copied/linked clients have single client alerts configured
   * @param {Array} companyIds List of companyIds to fetch alerts for
   * @returns {Promise<Boolean>} A promise that never rejects
   */
  const checkForSingleClientAlerts = (companyIds) => {
    return new Promise((resolve) =>
      getSingleClientAlerts(companyIds)
        .then((alerts) => {
          if (!alerts?.length) {
            resolve(true)
            return
          }
          openSingleClientAlertConfirmationModal(
            dispatch,
            alerts,
            () => resolve(true),
            () => resolve(false)
          )
        })
        .catch((error) => {
          console.log({ error })
          resolve()
        })
    )
  }

  // TODO [Titus]: Add the alert diff check between the linking and modal here words stuff etc
  const linkClientSections = (links) => {
    setLoading(true)

    updateLinkSections(company._id, links)
      .then((newClient) => {
        setDefaultCompany({
          ...newClient,
          users: company.users, // Users is not brought over through the API so we keep the old version
        })
        showSuccessMessage('Saved Section Link successfully', dispatch)
      })
      .catch((error) => {
        showErrorMessage(error, dispatch)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <BusinessUnitCopyConfirmation
        opened={inheritClientModal}
        onConfirm={(
          createBUOption,
          selectedBU,
          newBUsToBeCreated,
          selectedAccounts
        ) => {
          let inheritFrom
          switch (createBUOption) {
            case CREATE_BU_OPTIONS.COPY_CLIENT:
              inheritFrom = company._id
              break
            case CREATE_BU_OPTIONS.DUPLICATE_BU:
              inheritFrom = selectedBU
              break
            default:
              inheritFrom = 'none'
              break
          }
          if (createBUOption === CREATE_BU_OPTIONS.SPLIT_BU) {
            splitBUs(selectedBU, newBUsToBeCreated, selectedAccounts)
          } else {
            navigate({
              pathname: `/company/${
                company.clientId || 'new'
              }/business-unit/new`,
              search: `?inheritFrom=${inheritFrom}`,
            })
          }
          setInheritClientModal(false)
        }}
        onCancel={() => {
          setInheritClientModal(false)
          navigate(`/company/${company.clientId || 'new'}`)
        }}
        businessUnits={company.businessUnits}
      />
      <Outlet
        context={{
          isCreate: createMode,
          company,
          setCompany,
          pendingChanges,
          defaultCompany: editedCompany || companyDefaultWithId,
          initialCompanyState: defaultState,
          setDefaultCompany,
          isViewMode: company.deleted,
          reset,
          dirty,
          setDirty,
          LeaveConfirmModal,
          showInheritClientModal: () => setInheritClientModal(true),
          flags,
          setFlags: onChangeFlags,
          inheritClientModal,
          linkClientSections,
          simulations,
          checkForSingleClientAlerts,
        }}
      />
    </>
  )
}

export default ClientOutlet
