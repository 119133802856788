import React, { useState, useEffect, useMemo } from 'react'
import { useStore } from 'store'
import PropTypes from 'prop-types'
import { useNavigate, redirect } from 'react-router-dom'

import { getPermissionGroups } from 'modules/permission-groups/actions'
import {
  createUpdateTraqTemplate,
  getGranularitiesOfAlert,
} from 'modules/traq-templates/actions'
import SelectAlertModalContent from 'modules/traq-templates/select-alert-modal-content'
import useSession from 'modules/session'
import { fetchAlerts } from 'modules/alerts/actions'

import Input from 'components/input'
import { Dropdown } from 'components/dropdown'
import StickyFooter from 'components/sticky-footer'
import { FIELD_TYPES, validate } from 'components/validator'
import { showErrorMessage } from 'modules/notifications/actions'
import { getTooltipList } from 'components/utils/tooltip'
import LevelOfAttention from 'components/level-of-attention'
import Modal from 'components/modal'
import DropdownWithSubsections from 'components/dropdown-with-subsections'
import Table from 'components/table/beta'
import ListSelection from 'components/list-selection'
import Loader from 'components/loader'
import { getPrevLocation } from 'components/history'
import ScssConstants from 'styles/shared.module.scss'
import { entityStatus } from '@decision-sciences/qontrol-common'

const { ENTITY_STATUS, ENTITY_STATUS_LABEL, ENTITY_STATUS_OPTIONS } =
  entityStatus

/**
 * Create / Edit Traq Templates
 * @param {Object} options Options object
 * @param {Object} [options.defaultState] Pre-existing permission Group
 * @param {Boolean} [options.isNew] True if the Permission Group is new.
 * @param {Boolean} options.hasCreateAccess
 * @param {Boolean} options.hasEditAccess
 * @param {Function} options.setDirty To be called on unsaved form changes
 * @param {Array<Object>} options.allCompanies all companies with Business Units for the clients dropdown
 * @returns {React.Component}
 */
const TraqTemplatesForm = ({
  defaultState,
  allCompanies,
  isNew = true,
  hasCreateAccess,
  hasEditAccess,
  setDirty,
}) => {
  const [, user] = useSession()
  const [traqTemplate, setTraqTemplate] = useState(
    isNew
      ? {
          name: '',
          active: ENTITY_STATUS.ACTIVE,
          alerts: [],
          companies: [],
          groups: [],
          allCompaniesSelected: false,
          levelOfAttention: {},
          owner: user,
        }
      : {
          ...defaultState,
        }
  )

  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [openAlertModal, setOpenAlertModal] = useState(false)
  const [levelOfAttentionOptions, setLevelOfAttentionOptions] = useState({})
  const [availableUsers, setAvailableUsers] = useState([])
  const [permissionGroups, setPermissionGroups] = useState([])
  const [alerts, setAlerts] = useState(null)
  const [alertsUsed, setAlertsUsed] = useState([])

  const { dispatch, state } = useStore()
  const { list: permissionGroupList } = state.permissionGroups

  const viewOnly = useMemo(
    () => !isNew && !hasEditAccess,
    [isNew, hasEditAccess]
  )

  useEffect(() => {
    if (!permissionGroupList?.length) {
      getPermissionGroups(dispatch)
    }
  }, [permissionGroupList])

  useEffect(() => {
    let newUsers = []
    let newPermissionGroups = []

    // if all companies selected show all users, otherwise just show the users
    // from whatever clients are selected
    if (!traqTemplate.allCompaniesSelected) {
      newUsers = state.users.list.filter((user) => {
        if (user.clients) {
          const intersection = user.clients.filter((userClient) =>
            traqTemplate.companies?.includes(userClient.clientId)
          )
          return intersection.length > 0
        } else {
          return false
        }
      })
    } else {
      newUsers = state.users.list
    }
    if (state.permissionGroups.list) {
      const userPermissionGroups = newUsers
        ? newUsers.map((a) => a.permissionGroup)
        : state.users.list.map((a) => a.permissionGroup)
      newPermissionGroups = state.permissionGroups?.list.filter((permission) =>
        userPermissionGroups.includes(permission._id)
      )
    }
    let assignToUsers = []
    if (!traqTemplate.allPermissionGroupSelected) {
      assignToUsers = newUsers.filter((user) =>
        traqTemplate.permissionGroups?.includes(user.permissionGroup)
      )
    } else {
      assignToUsers = newUsers
    }
    setPermissionGroups(newPermissionGroups)
    setAvailableUsers(assignToUsers)
  }, [
    JSON.stringify(state.permissionGroups.list),
    JSON.stringify(traqTemplate.companies),
    traqTemplate.allPermissionGroupSelected,
    JSON.stringify(traqTemplate.permissionGroups),
    traqTemplate.allCompaniesSelected,
    JSON.stringify(state.users),
  ])

  const isAdmin = user.isSuperAdmin

  const ownerCompanies = useMemo(() => {
    if (traqTemplate.owner) {
      return traqTemplate.owner.isSuperAdmin ||
        user._id === traqTemplate.owner._id
        ? allCompanies
        : // Filter out any companies or business units that alert owner doesn't belong to
          allCompanies.reduce((companies, option) => {
            const foundClient = traqTemplate.owner.clients.find(
              ({ clientId }) => clientId === option.value
            )
            const newSubsections = option.subsections.reduce(
              (subsections, option) => {
                const disabled = !foundClient?.businessUnits.some(
                  (buId) => buId === option.value
                )
                if (disabled) {
                  return subsections
                }
                return [...subsections, option]
              },
              []
            )
            if (!foundClient) {
              return companies
            }
            return [
              ...companies,
              {
                ...option,
                subsections: newSubsections,
              },
            ]
          }, [])
    } else {
      return []
    }
  }, [JSON.stringify(allCompanies), traqTemplate.owner])

  const allOptions = useMemo(() => {
    const allOptions = []
    if (ownerCompanies) {
      ownerCompanies.forEach((option) => {
        if (!option.disabled) {
          allOptions.push(option.value)
          if (option?.subsections.length) {
            option.subsections.forEach(
              (subsection) =>
                !subsection.disabled && allOptions.push(subsection.value)
            )
          }
        }
      })
    }
    return allOptions
  }, [JSON.stringify(ownerCompanies)])

  useEffect(() => {
    if (!alerts) {
      const companyId = state.companies.currentCompany._id
      fetchAlerts(user.isSuperAdmin ? null : companyId)
        .then(setAlerts)
        .catch(console.error)
    }
  }, [traqTemplate])

  useEffect(() => {
    if (alerts?.length > 0 && traqTemplate.alerts) {
      const alertsUsed = alerts.filter((alert) =>
        traqTemplate.alerts.some(({ _id }) => _id === alert._id)
      )
      setAlertsUsed(alertsUsed)

      const granularities = alertsUsed.reduce((acc, row) => {
        return { ...acc, [row._id]: [...getGranularitiesOfAlert(row)] }
      }, {})

      const uniqueGranularities = Object.values(granularities).reduce(
        (acc, listOfGranularities) => [
          ...new Set([...acc, ...listOfGranularities]),
        ],
        []
      )

      const newOptions = {}
      uniqueGranularities.forEach((element) => {
        newOptions[element] = levelOfAttentionOptions[element]
          ? levelOfAttentionOptions[element]
          : {
              needs_immediate_attention: {
                from: 35,
                to: 100,
              },
              review_recommended: {
                from: 20,
                to: 35,
              },
              validated: {
                from: 0,
                to: 20,
              },
            }
      })
      setLevelOfAttentionOptions(newOptions)
    }
  }, [JSON.stringify(traqTemplate.alerts), JSON.stringify(alerts)])

  /* On mount check if the user has permissions to view */
  useEffect(() => {
    if (isNew && !hasCreateAccess) {
      redirect('/unauthorized')
    }
  }, [])

  const editField = (field, value) => {
    setDirty(true)
    setTraqTemplate((traqTemplate) => ({
      ...traqTemplate,
      [field]: value,
    }))
    setErrors({ ...errors, [field]: null })
  }

  const onSave = (e) => {
    e.preventDefault()
    // Validate
    setLoading(true)
    traqTemplate.owner = { _id: user._id, isAdmin: user.isAdmin }
    traqTemplate.levelOfAttention = levelOfAttentionOptions
    const [isValid, errors] = validate(ERROR_MAP, traqTemplate)

    if (!isValid) {
      setLoading(false)
      setErrors(errors)
      return
    }
    createUpdateTraqTemplate(dispatch, traqTemplate)
      .then(() => {
        setDirty(false)
        setTimeout(() => {
          navigate('/embarq-templates', {
            state: { BYPASS_LEAVE_CONFIRM: true },
          })
        })
      })
      .catch((error) => {
        if (error.name) {
          setErrors({ ...errors, name: error.name })
        } else {
          setErrors({ ...errors, general: error.toString() })

          showErrorMessage(
            `Could not save ${
              traqTemplate.name
            } due to error ${error.toString()}`,
            dispatch
          )
        }
        setLoading(false)
      })
  }

  const removeAlert = (e) =>
    setTraqTemplate({
      ...traqTemplate,
      alerts: traqTemplate.alerts.filter((alert) => alert._id !== e),
    })

  const limitWidth = window.screen.width <= 1440 ? 40 : 100

  const alertColumns = useMemo(
    () => [
      {
        header: '',
        id: 'actions',
        cell: (cell) => (
          <div
            className={!viewOnly ? 'embarq-template__remove' : ''}
            onClick={() => {
              !viewOnly && removeAlert(cell.row.original._id)
            }}
          />
        ),
        size: 20,
        minSize: 40,
        maxSize: 40,
      },
      {
        header: 'Alert Name',
        accessorFn: (row) =>
          `${row.name?.substring(0, limitWidth)}${
            row.name?.length >= limitWidth ? '...' : ''
          }`,
        tooltip: (row) => {
          return row.name?.length >= limitWidth
            ? getTooltipList('Alert Name', [row.name])
            : null
        },
        cell: (cell) => (
          <p style={!viewOnly ? { color: ScssConstants.colorButton } : {}}>
            {cell.row.original.name}
          </p>
        ),
        size: 270,
      },
      {
        header: 'Alert template',
        accessorFn: (row) => row.template?.name || '',
        size: 180,
      },
      {
        header: 'Alert type',
        accessorFn: (row) => row.alertType,
        size: 50,
      },
      {
        header: 'Primary sort',
        accessorFn: (row) => row.traqVolumeSortCalculation,
        size: 90,
      },
      {
        header: 'Secondary sort',
        accessorFn: (row) =>
          row.traqPercentageSortCalculation
            ? `${row.traqPercentageSortCalculation?.substring(
                0,
                limitWidth / 2
              )}${
                row.traqPercentageSortCalculation?.length >= limitWidth / 2
                  ? '...'
                  : ''
              }`
            : '',
        tooltip: (row) => {
          return row.traqPercentageSortCalculation?.length >= limitWidth / 2
            ? getTooltipList('Secondary sort', [
                row.traqPercentageSortCalculation,
              ])
            : null
        },
        size: 90,
      },
    ],
    [viewOnly, JSON.stringify(traqTemplate.alerts)]
  )

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {openAlertModal ? (
        <Modal
          opened={openAlertModal}
          heading="Select Alerts to Associate"
          className="embarq-template__modal duplicate-campaign-modal"
        >
          <SelectAlertModalContent
            onCloseX={() => {
              setOpenAlertModal(false)
            }}
            traqTemplate={traqTemplate}
            setTraqTemplate={setTraqTemplate}
          />
        </Modal>
      ) : null}
      <form className="form embarq-template">
        <section className="form__section">
          <div className="form__section__body alerts-create-edit-alert">
            <div className="form__row">
              {viewOnly ? (
                <div className="form__half">
                  <p className="general-label">Template Name</p>
                  <p className="general-name">{traqTemplate.name}</p>
                </div>
              ) : (
                <Input
                  className="form__half input-wrapper--uppercase"
                  placeholder="Name"
                  label="Template Name"
                  onChange={(val) => editField('name', val)}
                  value={traqTemplate.name}
                  error={errors.name}
                />
              )}

              {viewOnly ? (
                <div className="form__half">
                  <p className="general-label">Active</p>
                  <p className="general-name">
                    {ENTITY_STATUS_LABEL[traqTemplate.active]}
                  </p>
                </div>
              ) : (
                <Dropdown
                  options={ENTITY_STATUS_OPTIONS}
                  label="Status"
                  defaultState={traqTemplate.active}
                  className="form__half input-wrapper--uppercase"
                  onChange={(status) => editField('active', status)}
                />
              )}
            </div>

            <div className="form__row">
              {viewOnly ? (
                <div className="form__half">
                  <p className="general-label">Permission Group Access</p>
                  <p className="general-name">
                    {traqTemplate.allPermissionGroupSelected
                      ? 'All'
                      : permissionGroups
                          .filter((p) =>
                            traqTemplate.permissionGroups.includes(p._id)
                          )
                          .map(({ name }) => name)
                          .join(', ')}
                  </p>
                </div>
              ) : (
                <DropdownWithSubsections
                  className="form__half input-wrapper--uppercase"
                  label="Permission Group Access"
                  options={
                    permissionGroups
                      ? permissionGroups.map((permission) => ({
                          label: permission.name,
                          value: permission._id,
                        }))
                      : []
                  }
                  defaultOptionText="Select Permission Groups"
                  disabled={!isAdmin || !permissionGroups?.length}
                  selectedItems={
                    traqTemplate.allPermissionGroupSelected
                      ? state.permissionGroups.list?.map(
                          (permission) => permission._id
                        )
                      : traqTemplate.permissionGroups
                  }
                  error={errors.permissionGroup}
                  onChange={(value) => {
                    setDirty(true)
                    const newState = {
                      ...traqTemplate,
                      permissionGroups: value,
                      allPermissionGroupSelected: false,
                      isGlobal: !traqTemplate.allPermissionGroupSelected,
                    }

                    setTraqTemplate(newState)
                    setErrors({ ...errors, permissionGroups: null })
                  }}
                  selectAllOptions={
                    isAdmin
                      ? {
                          label: 'All',
                          allSelected: traqTemplate.allPermissionGroupSelected,
                          onCheck: (value) => {
                            setDirty(true)
                            setTraqTemplate({
                              ...traqTemplate,
                              permissionGroups: [],
                              allPermissionGroupSelected: value,
                            })
                            setErrors({ ...errors, permissionGroups: null })
                            setErrors({
                              ...errors,
                              allPermissionGroupSelected: null,
                            })
                          },
                        }
                      : undefined
                  }
                />
              )}

              {viewOnly ? (
                <div className="form__half">
                  <p className="general-label">Clients</p>
                  <p className="general-name">
                    {traqTemplate.allCompaniesSelected
                      ? 'All'
                      : allCompanies
                          .filter((c) =>
                            traqTemplate.companies.includes(c.value)
                          )
                          .map((c) => c.label)
                          .join(', ')}
                  </p>
                </div>
              ) : (
                <DropdownWithSubsections
                  className="form__half input-wrapper--uppercase"
                  selectedItems={
                    traqTemplate.allCompaniesSelected
                      ? allOptions
                      : traqTemplate?.companies?.map((a) => (a._id ? a._id : a))
                  }
                  options={ownerCompanies}
                  defaultOptionText="Apply To"
                  label="Clients"
                  disabled={!isAdmin && ownerCompanies.length <= 1}
                  error={errors.companies}
                  onChange={(value) => {
                    setDirty(true)
                    const newState = {
                      ...traqTemplate,
                      companies: value,
                      allCompaniesSelected: false,
                    }

                    setTraqTemplate(newState)
                    setErrors({ ...errors, companies: null })
                  }}
                  selectAllOptions={
                    traqTemplate.owner?.isSuperAdmin && isAdmin
                      ? {
                          label: 'All Clients',
                          allSelected: traqTemplate.allCompaniesSelected,
                          onCheck: (value) => {
                            setDirty(true)
                            setTraqTemplate({
                              ...traqTemplate,
                              allCompaniesSelected: value,
                              companies: [],
                            })
                            setErrors({ ...errors, companies: null })
                          },
                        }
                      : undefined
                  }
                />
              )}
            </div>
          </div>
        </section>

        {!viewOnly && (
          <section className="form__section">
            <ListSelection
              availableItems={
                availableUsers
                  ? availableUsers.map((user) => ({
                      name: user.name,
                      _id: user._id,
                    }))
                  : []
              }
              selectedItems={
                traqTemplate.userAssignedTo
                  ? traqTemplate.userAssignedTo.map((user) => ({
                      name: user.name
                        ? user.name
                        : `${user.firstName} ${user.lastName}`,
                      _id: user._id,
                    }))
                  : []
              }
              setSelectedItems={(userAssignedTo) => {
                setTraqTemplate((traqTemplate) => ({
                  ...traqTemplate,
                  userAssignedTo,
                }))
              }}
              idKey="_id"
              displayKey="name"
              title="Assign to users"
              availableListName="Users"
              selectedListName="Selected Users"
            />
          </section>
        )}

        <section className="form__section">
          <div className="form__section__body alerts-create-edit-alert">
            <label className="general-label">Associated alerts</label>
            {!viewOnly && (
              <div
                className="embarq-template__align-to-right"
                onClick={() => {
                  setOpenAlertModal(!openAlertModal)
                }}
              >
                + Select Alerts
              </div>
            )}
            {errors.general ? (
              <div className="error">{errors.general}</div>
            ) : null}
            {!viewOnly && (
              <label className="embarq-template__label">
                The selected alerts are displayed below. To adjust the sort
                priority, you must edit the alert.
              </label>
            )}
            <Table columns={alertColumns} data={alertsUsed} />
          </div>
        </section>
        <section className="form__section">
          <label className="embarq-template__info-title">
            Level of Attention (Per Alert Granularity)
          </label>
          <label className="embarq-template__label">
            For each alert selected, set percentage ranges determining the level
            of attention needed for each level of granularity within the alert.
            Use the sliders below to determine the percentage range for when an
            alert needs immediate attention, when review is recommended, and
            when it is validated.
          </label>
          {Object.keys(levelOfAttentionOptions).map((element) => {
            return (
              <LevelOfAttention
                title={element}
                key={element}
                options={levelOfAttentionOptions[element]}
                setOptions={(val) =>
                  setLevelOfAttentionOptions({
                    ...levelOfAttentionOptions,
                    [element]: val,
                  })
                }
                viewOnly={viewOnly}
              ></LevelOfAttention>
            )
          })}
        </section>
      </form>
      <StickyFooter
        buttons={[
          {
            value: isNew ? 'Save EmbarQ template' : 'Save Changes',
            onClick: onSave,
            renderCondition: !viewOnly,
          },
          {
            value: 'Cancel',
            onClick: () => navigate('/embarq-templates'),
            secondaryGray: true,
            renderCondition: !viewOnly,
          },
          {
            value: 'Back',
            onClick: () =>
              getPrevLocation() ? navigate(-1) : navigate('/embarq-templates'),
            renderCondition: viewOnly,
          },
        ]}
      />
    </>
  )
}

const ERROR_MAP = {
  name: FIELD_TYPES.REQUIRED,
  active: FIELD_TYPES.BOOLEAN,
}

TraqTemplatesForm.propTypes = {
  allCompanies: PropTypes.array.isRequired,
  defaultState: PropTypes.object,
  isNew: PropTypes.bool,
  onDelete: PropTypes.func,
  hasCreateAccess: PropTypes.bool,
  hasEditAccess: PropTypes.bool,
  setDirty: PropTypes.func.isRequired,
}

export default TraqTemplatesForm
