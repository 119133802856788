import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import '../style.scss'

const NamingConventionsPreview = ({ dimensions, delimiter }) => {
  const previewString = useMemo(
    () => dimensions.map(({ dimensionName }) => dimensionName).join(delimiter),
    [JSON.stringify(dimensions), delimiter]
  )
  return (
    <section className="naming-conventions__preview">
      <div className="general-label general-label--no-bottom-margin">
        Preview
      </div>
      <div className="naming-conventions__preview__box">{previewString}</div>
    </section>
  )
}

NamingConventionsPreview.propTypes = {
  dimensions: PropTypes.array.isRequired,
  delimiter: PropTypes.string.isRequired,
}

export default NamingConventionsPreview
