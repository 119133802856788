import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as WarningIcon } from 'assets/icon_warning.svg'
import cx from 'classnames'
import Button from 'components/button'
import { closeModal, openModal } from 'components/modal/actions'
import StickyFooter from 'components/sticky-footer/index'
import Table from 'components/table/beta'
import Toggle from 'components/toggle/index'
import { DEFAULT_DATE_FORMAT } from 'components/utils/date'
import { format } from 'date-fns'
import { useIntermediaryStates } from 'hooks/intermediary-states'
import {
  deleteCategory,
  editCategoryDisplay,
  getAllCategories,
  reorderCategories,
  saveCategoriesOrder,
} from 'modules/data-store-global/actions'
import {
  showErrorMessage,
  showSuccessMessage,
} from 'modules/notifications/actions'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'

/**
 * Table containing all Data Store categories.
 * Allows editing, reordering, hiding and deleting categories.
 * @returns {React.FunctionComponent}
 */
const DataStoreCategoriesTable = () => {
  const navigate = useNavigate()

  const { dispatch, state } = useStore()
  const {
    dataStoreGlobal: { categories },
  } = state

  const { getLoadingFor, setLoadingFor } = useIntermediaryStates()

  const [reorderDirty, setReorderDirty] = useState(false)

  useEffect(() => {
    setLoadingFor(FIELDS.TABLE, true)
    getAllCategories(dispatch)
      .catch((error) =>
        console.error('Error occurred when getting categories', error)
      )
      .finally(() => setLoadingFor(FIELDS.TABLE, false))
  }, [])

  const columns = [
    {
      header: '',
      id: 'edit-category',
      size: 30,
      maxSize: 50,
      textAlign: 'center',
      cellTextAlign: 'center',
      cell: (cell) => {
        const { _id } = cell.row.original
        return (
          <EditIcon
            data-cy="data-store-category-index-edit"
            onClick={onEdit(_id)}
            className={cx('cursor--pointer')}
          />
        )
      },
    },
    {
      header: 'Name',
      id: 'name',
      accessorKey: 'name',
    },
    {
      header: 'Display',
      id: 'display',
      cell: (cell) => {
        const { _id, display } = cell.row.original
        return (
          <Toggle
            data-cy="data-store-category-index-display-toggle"
            disabled={getLoadingFor(FIELDS.DISPLAY_TOGGLE)}
            reversed
            manualToggle
            value={display}
            defaultChecked={display}
            onChange={onDisplayToggle(_id)}
          />
        )
      },
      size: 40,
      maxSize: 80,
    },
    {
      header: 'Dimensions / Metrics',
      id: 'dimensionCount',
      accessorFn: (row) => row.dimensionCount,
    },
    {
      header: 'Last modified',
      id: 'lastModified',
      accessorFn: (row) =>
        row.updatedAt && format(new Date(row.updatedAt), DEFAULT_DATE_FORMAT),
    },
    {
      header: '',
      id: 'delete-category',
      size: 30,
      maxSize: 50,
      textAlign: 'center',
      cellTextAlign: 'center',
      cell: (cell) => {
        const { id } = cell.row.original
        return (
          <DeleteIcon
            data-cy="data-store-category-index-delete"
            onClick={onDeleteConfirmation(id)}
            className={cx('cursor--pointer fill-red')}
          />
        )
      },
    },
  ]

  const onEdit = (id) => () => {
    navigate(`./category/${id}`)
  }

  const onDeleteConfirmation = (id) => () => {
    const deleteCategoryModal = openModal(dispatch, {
      heading: 'Delete Category',
      icon: <WarningIcon />,
      contentSeparator: true,
      rightAlignButtons: true,
      children: (
        <div className="max-width-500">
          <p>
            You are about to delete a Data Store Category. This action cannot be
            undone.
          </p>
          <p>
            Click the confirm button to continue. Or click the Cancel button to
            close this modal.
          </p>
        </div>
      ),
      button: (
        <Button
          green
          data-cy="data-store-category-index-delete-confirm"
          value="Confirm"
          onClick={() =>
            onDelete(id).then(() => {
              closeModal(dispatch, deleteCategoryModal)
              getAllCategories(dispatch)
            })
          }
        />
      ),
      buttonSecondary: (
        <Button
          secondaryGray
          data-cy="data-store-category-index-delete-cancel"
          value="Cancel"
          onClick={() => {
            closeModal(dispatch, deleteCategoryModal)
          }}
        />
      ),
    })
  }

  const onDelete = (id) =>
    deleteCategory(id)
      .then(() => showSuccessMessage('Category deleted', dispatch))
      .catch((error) =>
        showErrorMessage(`Unable to delete category: ${error}`, dispatch)
      )

  const onDisplayToggle = (id) => (checked) => {
    setLoadingFor(FIELDS.DISPLAY_TOGGLE, true)
    editCategoryDisplay(id, checked)
      .then(() => {
        showSuccessMessage('Category updated', dispatch)
        getAllCategories(dispatch)
      })
      .catch((error) =>
        showErrorMessage(`Unable to update category: ${error}`, dispatch)
      )
      .finally(() => setLoadingFor(FIELDS.DISPLAY_TOGGLE, false))
  }

  const onReorder = (categoriesReordered) => {
    setReorderDirty(true)
    reorderCategories(dispatch, categoriesReordered)
  }

  const onReorderSave = () => {
    setLoadingFor(FIELDS.REODER, true)
    saveCategoriesOrder(
      categories.map(({ _id }, index) => ({ _id, itemIndex: index }))
    )
      .then(() => {
        showSuccessMessage('Categories reordered', dispatch)
        setReorderDirty(false)
      })
      .catch((error) =>
        showErrorMessage(`Unable to reorder categories: ${error}`, dispatch)
      )
      .finally(() => {
        setLoadingFor(FIELDS.REODER, false)
      })
  }

  const onReorderCancel = () => {
    setReorderDirty(false)
    getAllCategories(dispatch)
  }

  if (!categories) {
    return null
  }

  return (
    <div className="section__body">
      <Table
        withDragDrop={true}
        loading={getLoadingFor(FIELDS.TABLE)}
        onDragDrop={onReorder}
        dragDropRowIndex={1}
        columns={columns}
        data={categories}
      />
      {reorderDirty && (
        <StickyFooter
          buttons={[
            {
              value: 'Update categories order',
              onClick: onReorderSave,
              disabled: getLoadingFor(FIELDS.REODER),
            },
            {
              value: 'Cancel',
              onClick: onReorderCancel,
              secondaryGray: true,
              disabled: getLoadingFor(FIELDS.REODER),
            },
          ]}
        />
      )}
    </div>
  )
}

const FIELDS = {
  TABLE: 'TABLE',
  DISPLAY_TOGGLE: 'DISPLAY_TOGGLE',
  REODER: 'REORDER',
}

export default DataStoreCategoriesTable
