import React, { useEffect, useMemo } from 'react'
import { useParams, redirect, useOutletContext } from 'react-router-dom'
import CreateEditBusinessUnit from 'modules/business-units'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'
import Loader from 'components/loader'

const BusinessUnits = () => {
  const { businessUnitClientId } = useParams()
  const isNewBu = businessUnitClientId === 'new'

  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.BUSINESS_UNIT_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })
  const hasViewAccess = useAccess({
    feature: PERMISSIONS.BUSINESS_UNIT_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })

  const { company } = useOutletContext()

  useEffect(() => {
    // Check for access on this page
    if (
      (businessUnitClientId && !hasViewAccess) ||
      (!businessUnitClientId && !hasCreateAccess)
    ) {
      return redirect('/unauthorized')
    }
  }, [businessUnitClientId])

  const businessUnitId = useMemo(() => {
    if (businessUnitClientId && !isNewBu && company) {
      return company.businessUnits.find(
        (item) => `${item.clientId}` === `${businessUnitClientId}`
      )?._id
    }
  }, [businessUnitClientId, JSON.stringify(company.businessUnits)])

  if (!businessUnitId && !isNewBu) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <div
        className="heading"
        data-cy={
          !isNewBu
            ? 'edit-business-unit-page-heading'
            : 'add-business-unit-page-heading'
        }
      >
        {!isNewBu ? 'Edit' : 'Add'} Business Unit
      </div>
      <CreateEditBusinessUnit
        isCreate={isNewBu}
        businessUnitClientId={businessUnitClientId}
        businessUnitId={businessUnitId}
      />
    </React.Fragment>
  )
}

export default BusinessUnits
