import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { format } from 'date-fns-tz'

import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { ReactComponent as ArrowIcon } from 'assets/icon_arrow_downwards_with_tip_to_right.svg'

import Table from 'components/table/beta'

import { ACCOUNT_TYPE_ICONS } from 'constants/account'

/**
 * Renders the campaign comparison group table
 * @param {Object} props
 * @param {Boolean} props.isViewMode
 * @param {Array} props.comparisons - Campaign comparison groups
 * @param {Function} props.setGroupIdToBeDeleted - Set group id to be deleted
 * @param {Function} props.onEditClicked -  Method to be called when the edit button is clicked
 * @param {Boolean} props.disabledActions -  A flag that tells us when to disable table's actions
 */
const CampaignComparisonGroupTable = ({
  isViewMode,
  comparisons,
  setGroupIdToBeDeleted,
  onEditClicked,
  disabledActions,
}) => {
  const columns = [
    {
      header: '',
      accessorKey: 'edit-rule',
      size: 10,
      maxSize: 70,
      textAlign: 'center',
      cellTextAlign: 'center',
      // eslint-disable-next-line react/prop-types
      cell: (cell) => {
        if (isViewMode) {
          return null
        }
        return (
          <EditIcon
            disabled={disabledActions}
            className={cx('cursor--pointer', {
              'disabled-exclusion-action': disabledActions,
            })}
            onClick={() => !disabledActions && onEditClicked(cell.row.original)}
          />
        )
      },
    },
    {
      header: 'Comparison Name',
      accessorKey: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 460,
    },
    {
      header: 'Start Date',
      accessorKey: 'startDate',
      size: 25,
      cell: (cell) =>
        cell.row.original.startDate
          ? format(new Date(cell.row.original.startDate), 'MM/dd/yyyy')
          : null,
    },
    {
      header: 'End Date',
      accessorKey: 'endDate',
      size: 25,
      cell: (cell) =>
        cell.row.original.endDate
          ? format(new Date(cell.row.original.endDate), 'MM/dd/yyyy')
          : null,
    },
    {
      header: '',
      accessorKey: 'delete-rule',
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 10,
      maxSize: 60,
      // eslint-disable-next-line react/prop-types
      cell: (cell) => {
        if (isViewMode) {
          return null
        }
        return (
          <DeleteIcon
            disabled={disabledActions}
            onClick={() =>
              !disabledActions && setGroupIdToBeDeleted(cell.row.original._id)
            }
            className={cx('fill-red  cursor--pointer', {
              'disabled-exclusion-action': disabledActions,
            })}
          />
        )
      },
    },
  ]

  const _renderExpandableContent = (row) => {
    const { side_1, side_2 } = row.original

    const columns = [
      {
        header: 'Sides',
        accessorKey: 'name',
        textAlign: 'left',
        cellTextAlign: 'left',
        size: 20,
      },
    ]

    const _renderExpandableSubContent = (row) => {
      const { campaigns } = row.original

      const columns = [
        {
          header: 'Campaign',
          accessorKey: 'name',
          textAlign: 'left',
          cellTextAlign: 'left',
          size: 20,
          cell: (cell) => {
            const PublisherIcon =
              ACCOUNT_TYPE_ICONS[cell.row.original.publisher]

            return (
              <div className="display-flex">
                <PublisherIcon className="comparison-group-campaign-publisher" />
                <div>{cell.row.original.name}</div>
              </div>
            )
          },
        },
      ]

      return (
        <div className="expandable-content-wrapper">
          <div className="expandable-content-wrapper__left-margin">
            <ArrowIcon className="expandable-content__icon expandable-content__icon--arrow" />
          </div>
          <Table
            id="comparison-groups-table-campaigns"
            columns={columns}
            data={campaigns}
          />
        </div>
      )
    }

    return (
      <div className="expandable-content-wrapper">
        <div className="expandable-content-wrapper__left-margin">
          <ArrowIcon className="expandable-content__icon expandable-content__icon--arrow" />
        </div>
        <div className="display-flex-column comparison-group-table-row">
          <Table
            id="comparison-groups-side-one"
            columns={columns}
            data={[side_1]}
            hasExpandableRows={true}
            renderExpandableContent={_renderExpandableSubContent}
          />
          <Table
            id="comparison-groups-side-two"
            columns={columns}
            data={[side_2]}
            hasExpandableRows={true}
            renderExpandableContent={_renderExpandableSubContent}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <Table
        id="comparison-group-table"
        columns={columns}
        data={comparisons}
        hasExpandableRows={true}
        renderExpandableContent={_renderExpandableContent}
      />
    </>
  )
}

export default CampaignComparisonGroupTable

CampaignComparisonGroupTable.propTypes = {
  isViewMode: PropTypes.bool.isRequired,
  comparisons: PropTypes.array.isRequired,
  setGroupIdToBeDeleted: PropTypes.func.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  disabledActions: PropTypes.bool.isRequired,
}
