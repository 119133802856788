import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Tooltip from 'components/tooltip/index'

import './style.scss'

const TextWithOverflowTooltip = ({ text }) => {
  const textElementRef = useRef()

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    setIsOverflowed(compare)
  }

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)

    return () => {
      window.removeEventListener('resize', compareSize)
    }
  }, [])

  // Define state and function to update the value
  const [showTooltip, setShowTooltip] = useState(false)
  const [isOverFlowed, setIsOverflowed] = useState(false)

  return (
    <>
      <div
        ref={textElementRef}
        className={cx('text-with-overflow-tooltip', {
          'text-with-overflow-tooltip--overflowed': isOverFlowed,
        })}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {text}
      </div>
      <Tooltip content={text} show={isOverFlowed && showTooltip} />
    </>
  )
}

TextWithOverflowTooltip.propTypes = {
  text: PropTypes.string.isRequired,
}

export default TextWithOverflowTooltip
