import { ReactComponent as PlusIcon } from 'assets/icon_add_circle.svg'
import { ReactComponent as RemoveIcon } from 'assets/icon_remove_circle_outline.svg'
import cx from 'classnames'
import { dataStore } from '@decision-sciences/qontrol-common'
import Input from 'components/input/index'
import Table from 'components/table/beta'
import Toggle from 'components/toggle'
import { useDebouncedEffect } from 'components/utils/custom-hooks'
import { getTooltipList } from 'components/utils/tooltip'
import { getUniqueElements } from 'components/utils/sorting'
import {
  editCategoryField,
  searchDimensions,
} from 'modules/data-store-global/actions'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useStore } from 'store'

const { DATA_STORE_ITEM_PLACEMENT_OPTIONS, DATA_STORE_ITEM_TYPE_OPTIONS } =
  dataStore

/**
 * Form which allows linking a Data Store category with dimensions.
 * @param {Object} props Component props
 * @param {Boolean} props.isDirty Dirty flag
 * @param {Function} props.setDirty Setter for the dirty state
 * @param {Boolean} [props.disabled] Disabled flag
 * @returns {React.FunctionComponent}
 */
const CategoryDimensionLink = ({ isDirty, setDirty, disabled }) => {
  const { dispatch, state } = useStore()
  const {
    dataStoreGlobal: { editedCategory: category },
  } = state

  const [dimensions, setDimensions] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  useDebouncedEffect(
    () => searchDimensions(searchTerm).then(setDimensions),
    300,
    [searchTerm]
  )

  const columns = [
    {
      header: '',
      id: 'add-remove',
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 30,
      maxSize: 50,
      cell: (cell) => {
        const { _id: dimensionId } = cell.row.original
        const isSelected = category.dimensions?.some(
          (dimensionConfig) => dimensionConfig.dimensionId === dimensionId
        )
        return !isSelected ? (
          <PlusIcon
            data-cy="data-store-category-form-link-dimension"
            width={18}
            height={18}
            onClick={onDimensionLink(dimensionId)}
            className={cx('cursor--pointer svg--blue', {
              'svg--disabled': disabled,
            })}
          />
        ) : (
          <RemoveIcon
            data-cy="data-store-category-form-unlink-dimension"
            width={20}
            height={20}
            onClick={onDimensionUnlink(dimensionId)}
            className={cx('cursor--pointer', { 'svg--disabled': disabled })}
          />
        )
      },
    },
    {
      header: 'Name',
      id: 'name',
      accessorFn: (row) => row.dimension?.COLUMN_NAME || '',
    },
    {
      header: 'Type',
      id: 'type',
      accessorFn: (row) => DATA_STORE_ITEM_TYPE_OPTIONS[row.type],
    },
    {
      header: 'Placement',
      id: 'placement',
      accessorFn: (row) => DATA_STORE_ITEM_PLACEMENT_OPTIONS[row.placement],
    },
    {
      header: 'Publisher',
      id: 'publisher',

      accessorFn: (row) => {
        const uniquePublishersArray = getUniqueElements(
          row.dimension.PUBLISHERS
        )

        if (!uniquePublishersArray) {
          return 0
        } else if (uniquePublishersArray.length === 1) {
          return uniquePublishersArray
        } else {
          return 'Multiple'
        }
      },
      tooltip: (row) => {
        const uniquePublishersArray = getUniqueElements(
          row.dimension.PUBLISHERS
        )
        if (!uniquePublishersArray || uniquePublishersArray.length === 1) {
          return null
        }

        return getTooltipList('Publishers', uniquePublishersArray)
      },
    },
  ]

  const columnsAssociated = [
    {
      header: 'Name',
      id: 'name',
      accessorFn: (row) => {
        return row.dimensionData?.dimension?.COLUMN_NAME || ''
      },
    },
    {
      header: 'Display',
      id: 'display',
      cell: (cell) => {
        const { dimensionId, display } = cell.row.original
        return (
          <Toggle
            data-cy="data-store-category-index-display-toggle"
            reversed
            manualToggle
            value={display}
            defaultChecked={display}
            onChange={onDimensionDisplayToggle(dimensionId)}
          />
        )
      },
      size: 50,
      maxSize: 75,
    },
    {
      header: 'Type',
      id: 'type',
      accessorFn: (row) => DATA_STORE_ITEM_TYPE_OPTIONS[row.type],
    },
    {
      header: 'Placement',
      id: 'placement',
      accessorFn: (row) => DATA_STORE_ITEM_PLACEMENT_OPTIONS[row.placement],
    },
    {
      header: 'Publisher',
      id: 'publisher',

      accessorFn: (row) => {
        const uniquePublishersArray = getUniqueElements(
          row.dimension.PUBLISHERS
        )

        if (!uniquePublishersArray) {
          return 0
        } else if (uniquePublishersArray.length === 1) {
          return uniquePublishersArray
        } else {
          return 'Multiple'
        }
      },
      tooltip: (row) => {
        const uniquePublishersArray = getUniqueElements(
          row.dimension.PUBLISHERS
        )
        if (!uniquePublishersArray || uniquePublishersArray.length === 1) {
          return null
        }

        return getTooltipList('Publishers', uniquePublishersArray)
      },
    },
    {
      header: '',
      id: 'remove',
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 30,
      maxSize: 50,
      cell: (cell) => {
        const { dimensionId } = cell.row.original
        return (
          <RemoveIcon
            data-cy="data-store-category-form-remove-dimension"
            width={20}
            height={20}
            onClick={onDimensionUnlink(dimensionId)}
            className={cx('cursor--pointer', { 'svg--disabled': disabled })}
          />
        )
      },
    },
  ]

  const updateCategoryField = (fieldName) => (value) => {
    if (!isDirty) {
      setDirty(true)
    }
    editCategoryField(dispatch, { [fieldName]: value })
  }

  const onDimensionLink = (dimensionId) => () => {
    const dimension = dimensions.find(({ _id }) => _id === dimensionId)
    const dimensionLink = {
      dimensionId,
      dimensionData: dimension,
      display: true,
    }
    const updatedDimensions = category.dimensions?.length
      ? [...category.dimensions, dimensionLink]
      : [dimensionLink]
    updateCategoryField('dimensions')(updatedDimensions)
  }

  const onDimensionUnlink = (dimensionId) => () => {
    const updatedDimensions = category.dimensions?.filter(
      (dimensionConfig) => dimensionConfig.dimensionId !== dimensionId
    )
    updateCategoryField('dimensions')(updatedDimensions)
  }

  const onDimensionDisplayToggle = (dimensionId) => (display) => {
    const updatedDimensions = category.dimensions?.map((dimensionConfig) =>
      dimensionConfig.dimensionId === dimensionId
        ? { ...dimensionConfig, display }
        : dimensionConfig
    )
    updateCategoryField('dimensions')(updatedDimensions)
  }

  const onReorder = (dimensionsReordered) => {
    updateCategoryField('dimensions')(dimensionsReordered)
  }

  return (
    <>
      <div className="section">
        <div className="align-row center space-between section__header">
          <h3 className="generic-heading generic-heading--no-margin">
            Available Dimensions & Metrics
          </h3>
          <div className="w-50">
            <Input
              search
              placeholder="Search"
              onChange={setSearchTerm}
              className="width-50 data-store-global__search"
            />
          </div>
        </div>
        <div className="section__body">
          <Table
            className="table--compact"
            columns={columns}
            data={dimensions || []}
          />
        </div>
      </div>
      <div className="section">
        <div className="section__header">
          <h3 className="generic-heading generic-heading--no-margin">
            Associated Dimensions & Metrics
          </h3>
        </div>
        {category?.dimensions?.length > 0 && (
          <div className="section__body">
            <Table
              className="table--compact"
              withDragDrop
              onDragDrop={onReorder}
              columns={columnsAssociated}
              data={category.dimensions.reduce((acc, dimensionConfig) => {
                const categoryDimensionLink = dimensions?.find(
                  ({ _id }) => _id === dimensionConfig.dimensionId
                )
                if (categoryDimensionLink) {
                  return [
                    ...acc,
                    { ...categoryDimensionLink, ...dimensionConfig },
                  ]
                }
                return acc
              }, [])}
            />
          </div>
        )}
      </div>
    </>
  )
}

CategoryDimensionLink.propTypes = {
  isDirty: PropTypes.bool.isRequired,
  setDirty: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default CategoryDimensionLink
