import React, { useEffect, useState, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { useStore } from 'store'
import { getAlertTriggersArchived } from 'modules/alert-triggers/actions'
import { AlertTriggerFilters } from 'modules/alert-triggers/active-alert-triggers'
import Table from 'components/table/beta'

import 'modules/alert-triggers/archived-alert-triggers/style.scss'

const DEFAULT_PAGE_SIZE = 10
/**
 * Renders a page containing a filterable Table which holds archived Alert Triggers
 * @returns {React.Component}
 */
const ArchivedAlertTriggerPage = () => {
  const [alertTriggers, setAlertTriggers] = useState([])
  const [pageData, setPageData] = useState({ page: 1, size: DEFAULT_PAGE_SIZE })
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [filterState, setFilterState] = useState({})
  const {
    state: {
      companies: { currentCompany },
    },
  } = useStore()
  const [sortData, setSortData] = useState({})

  const navigate = useNavigate()

  const limits = useMemo(() => {
    const { page, size } = pageData
    return { min: (page - 1) * size, max: page * size }
  }, [JSON.stringify(pageData)])

  useEffect(() => {
    setPageData({ ...pageData, page: 1 })
  }, [JSON.stringify(filterState)])

  useEffect(() => {
    setLoading(true)
    getAlertTriggersArchived({
      min: limits.min,
      max: limits.max,
      filters: filterState,
      company: currentCompany._id,
      sorting: sortData,
    })
      .then((response) => {
        if (!response || !response.success || !response.result) {
          setAlertTriggers([])
        } else {
          setAlertTriggers(response.result.result)
          setTotal(response.result.total)
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }, [
    JSON.stringify(limits),
    JSON.stringify(filterState),
    JSON.stringify(sortData),
  ])

  const columns = [
    {
      id: 'alertSnapshot.name',
      accessorKey: 'alertSnapshot.name',
      header: 'Alert Name',
      cell: (cell) => {
        return (
          <span
            className="fake-link fake-link--black"
            role="button"
            onClick={() => navigate(`/alert-triggers/${cell.row.original._id}`)}
          >
            {cell.row.original.alertSnapshot.name}
          </span>
        )
      },
      className: 'margin-left-16',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 240,
    },
    {
      id: 'alertSnapshot.description',
      accessorKey: 'alertSnapshot.description',
      header: 'Details',
      accessorFn: (row) => row.alertSnapshot.description,
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 240,
    },
    {
      id: 'createdAt',
      accessorKey: 'createdAt',
      header: 'Triggered',
      accessorFn: (row) =>
        format(new Date(row.createdAt), 'MM-dd-yyyy h:mm aa'),
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 160,
    },
    {
      id: 'alertType',
      header: 'Alert Type',
      accessorKey: 'alertType',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 96,
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 96,
    },
  ]

  return (
    <div className="archived-alert-triggers page">
      <AlertTriggerFilters
        filterState={filterState}
        setFilterState={setFilterState}
        initialFilterState={{}}
      />

      <Helmet>
        <title>Archived Alerts</title>
      </Helmet>

      <div className="heading" data-cy="page-heading">
        Archived Alerts
      </div>
      <Table
        columns={columns}
        data={alertTriggers}
        paginationValues={[5, 10, 20, 50, 100]}
        showPagination={true}
        showSearchInput={true}
        loading={loading}
        onChangeTableView={({ pageIndex, pageSize, filter, sort }) => {
          setFilterState({ ...filterState, keyword: filter })

          if (sort) {
            setSortData(sort[0])
          }
          setPageData({ page: pageIndex + 1, size: pageSize })
        }}
        pageCount={Math.ceil(total / pageData.size)}
        manualPagination={true}
        manualFilter={filterState.keyword}
        manualCount={total}
        initialState={{ pageSize: DEFAULT_PAGE_SIZE }}
        autoResetPageIndex={false}
      />
    </div>
  )
}

export default ArchivedAlertTriggerPage
