import Api from 'easy-fetch-api'
import uniqBy from 'lodash.uniqby'
import { showErrorMessage } from 'modules/notifications/actions'
import { accounts, utils } from '@decision-sciences/qontrol-common'

const { ACCOUNT_TYPE_NAMES } = accounts
const { compareIgnoreCase } = utils.string

export const ACTIONS = {
  GET_ACCOUNTS: 'accounts.getAccounts',
  SET_BULK_ACCOUNTS: 'accounts.setBulkAccounts',
  SET_CUSTOM_CONVERSIONS: 'accounts.setCustomConversionsForAccounts',
}

/**
 * Initialise fetching unlinked accounts of a specific type for a specific company
 * @param dispatch {Function}
 * @param companyId {String} company ID
 * @return {*}
 */
export const initialiseClientUnlinkedAccounts = (dispatch, companyId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/accounts/company-unlinked/initialise/${companyId || ''}`,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
      .then((result) => {
        if (!result || !result.success) {
          const error = `Error initialising fetching accounts`
          showErrorMessage(error, dispatch)
          return reject(error)
        }

        resolve()
      })
      .catch((err) => reject(err))
  })
}

/**
 * Get unlinked accounts of a specific type for a specific company
 * @param dispatch {Function}
 * @param type {String} Account type
 * @param companyId {String} company ID
 * @param [forceRefetch] {Boolean} whether to force refetching, or use cached data
 * @return {*}
 */
export const getClientUnlinkedAccounts = (
  dispatch,
  type,
  companyId,
  forceRefetch = false
) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/accounts/company-unlinked/${type}/${companyId || ''}`,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
      query: {
        forceRefetch,
      },
    })
      .then((result) => {
        if (!result || !result.success) {
          const error = `Error getting accounts for ${ACCOUNT_TYPE_NAMES[type]}`
          showErrorMessage(error, dispatch)
          return reject(error)
        }
        if (result.accounts) {
          return resolve(result.accounts)
        }
      })
      .catch((err) => reject(err))
  })
}

/**
 * Get properties of a Google Analytics 4 account
 * @param {String} companyId company ID
 * @param {String} accountId account ID
 * @return {*}
 */
export const getGAPropertyIds = (companyId, accountId) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/accounts/ga-account-properties`,
      query: {
        companyId,
        accountId,
      },
    })
      .then((result) => {
        if (!result || !result.success) {
          const error = `Error getting account properties - ${accountId}`
          return reject(error)
        }
        if (result.properties) {
          return resolve(result.properties)
        }
      })
      .catch((err) => reject(err))
  })
}

export const bulkEditAccounts = (accounts, data, company) => {
  return new Promise((resolve, reject) => {
    const companyUpdates = {}

    accounts.forEach((account) => {
      // If account belongs to company, we update the company accounts
      if (account.company === company._id) {
        if (!companyUpdates.accounts) {
          companyUpdates.accounts = structuredClone(company.accounts)
        }
        companyUpdates.accounts = _updateSpecificAccount(
          companyUpdates.accounts,
          account.externalAccountId,
          data
        )
      } else {
        // If the account belongs to a business unit, we have to update the entirety of the business units object
        if (!companyUpdates.businessUnits) {
          companyUpdates.businessUnits = structuredClone(company.businessUnits)
        }
        const buIndex = companyUpdates.businessUnits.findIndex(
          ({ _id, id }) => account.company === _id || account.company === id
        )
        if (buIndex !== -1) {
          companyUpdates.accounts = _updateSpecificAccount(
            companyUpdates.businessUnits[buIndex].accounts,
            account.externalAccountId,
            data
          )
        }
      }
    })

    try {
      resolve(companyUpdates)
    } catch {
      reject('Something went wrong')
    }
  })
}

export const bulkDeleteAccounts = (accounts, company) => {
  return new Promise((resolve, reject) => {
    const companyUpdates = {}
    const changes = { company: null, changed: true }

    accounts.forEach((account) => {
      // If account belongs to company, we update the company accounts
      if (account.company === company._id) {
        if (!companyUpdates.accounts) {
          companyUpdates.accounts = structuredClone(company.accounts)
        }
        companyUpdates.accounts = _updateSpecificAccount(
          companyUpdates.accounts,
          account.externalAccountId,
          changes
        )
      } else {
        // If the account belongs to a business unit, we have to update the entirety of the business units object
        if (!companyUpdates.businessUnits) {
          companyUpdates.businessUnits = structuredClone(company.businessUnits)
        }
        const buIndex = companyUpdates.businessUnits.findIndex(
          ({ _id, id }) => account.company === _id || account.company === id
        )
        if (buIndex !== -1) {
          const accountIndex = companyUpdates.businessUnits[
            buIndex
          ].accounts.findIndex(
            ({ externalAccountId, type }) =>
              account.externalAccountId === externalAccountId &&
              type === account.type
          )

          if (accountIndex !== -1) {
            const newAccount = companyUpdates.businessUnits[
              buIndex
            ].accounts.splice(accountIndex, 1)[0]

            if (!companyUpdates.accounts) {
              companyUpdates.accounts = structuredClone(company.accounts)
            }
            companyUpdates.accounts.push({ ...newAccount, ...changes })
          }
        }
      }
    })

    try {
      resolve(companyUpdates)
    } catch {
      reject('Something went wrong')
    }
  })
}

/**
 * Get custom conversions for a list of Accounts
 * @param {Array} accounts Account list (with details)
 * @param {String} type Account Type
 * @returns {Promise<Array>}
 */
export const getCustomConversionsForAccounts = (accounts, type) => {
  accounts = accounts.filter((acc) => acc.type === type)
  if (!accounts.length) {
    return Promise.resolve([])
  }
  const uniqueAccounts = uniqBy(accounts, 'externalAccountId')
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/accounts/conversions-list`,
      query: {
        accountIds: uniqueAccounts.map((acc) => acc.externalAccountId),
        type,
      },
    })
      .then((result) => {
        if (!result || !result.success) {
          return reject()
        }
        resolve(
          result.conversions.sort((a, b) => compareIgnoreCase(a.name, b.name))
        )
      })
      .catch(reject)
  })
}

/**
 * Set custom conversions for a list of Accounts
 */
export const setCustomConversionsForAccounts = (dispatch, conversions) => {
  dispatch({ type: ACTIONS.SET_CUSTOM_CONVERSIONS, conversions: conversions })
}

/**
 * Get all linked accounts
 * @param dispatch {Function}
 * @return {*}
 */
export const getLinkedAccounts = (dispatch) => {
  return new Promise((resolve, reject) => {
    Api.get({
      url: `/api/accounts/linked`,
    }).then((result) => {
      if (!result || !result.success) {
        const error = `Error getting linked accounts`
        showErrorMessage(error, dispatch)
        return reject(error)
      }

      if (result.accounts) {
        return resolve(result.accounts)
      }

      reject()
    })
  })
}

const _updateSpecificAccount = (list, accountId, data) => {
  const foundIdx = list.findIndex(
    ({ externalAccountId }) => externalAccountId === accountId
  )
  if (foundIdx !== -1) {
    list[foundIdx] = { ...list[foundIdx], ...data }
  }
  return list
}
