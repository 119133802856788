import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'
import { useStore } from 'store'
import { count } from 'react-table/src/aggregations'

import Table from 'components/table/beta'
import Filters, { FilterSection } from 'components/filters'
import Input from 'components/input'
import { Dropdown } from 'components/dropdown/index'
import useSession from 'modules/session'
import { getUserCompanies } from 'components/utils/user'
import DropdownWithSubsections from 'components/dropdown-with-subsections'

import { setTraQTemplateAsFavourite } from 'modules/traq-templates/actions'
import { getTooltipList } from 'components/utils/tooltip'
import { getAlertNames } from 'modules/alerts/actions'
import { getCompanies } from 'modules/companies/actions'
import {
  fetchForPaginatedTable,
  applyFilters,
  clearFilters,
} from 'modules/traq-templates/pagination'
import { utils } from '@decision-sciences/qontrol-common'

import './style.scss'

const { shallowComparison } = utils.object
const { compareIgnoreCase } = utils.string

/**
 * Traq Templates List for TraQ Dashboard
 * @param {Object} params React Params
 * @param {Array} params.list list of TraQ Templates
 * @returns {React.Component}
 */
const TraqDashboardIndex = ({ list }) => {
  const navigate = useNavigate()
  const {
    dispatch,
    state: {
      companies: {
        list: companies,
        currentCompany: { _id: currentCompanyId },
      },
      traqTemplates: { userTraq },
    },
  } = useStore()

  const [, user] = useSession()
  const userCompanies = getUserCompanies(user, companies)
  const allCompanies = userCompanies.map((company) => ({
    value: company._id,
    label: company.name,
    subsections: company.businessUnits.map((bu) => ({
      value: bu._id,
      label: bu.name,
    })),
  }))
  const [alerts, setAlerts] = useState(null)

  /** If there are no alerts or companies in the store, fetch them */
  useEffect(() => {
    if (!alerts) {
      getAlertNames(user.isSuperAdmin ? null : currentCompanyId)
        .then(setAlerts)
        .catch(console.error)
    }

    if (!companies) {
      getCompanies(dispatch, { deleted: false })
    }

    // Set initial data on first load
    fetchForPaginatedTable({
      setLoadingItems,
      filterState,
      setFilterState,
      setSortData,
      setPageData,
      setTraQTemplatesToShow,
      setTotalRows,
      list,
    })
  }, [])

  const columns = [
    {
      header: '',
      id: 'open',
      cell: (cell) => (
        <div className="table__actions">
          <div
            className="table__open"
            onClick={() =>
              navigate(`/embarq-dashboard/${cell.row.original._id}`)
            }
          />
        </div>
      ),
      size: 30,
      maxSize: 60,
      textAlign: 'center',
      cellTextAlign: 'center',
    },
    {
      header: '',
      id: 'star',
      cell: (cell) => (
        <div className="table__actions">
          <div
            className={cx('table__star', {
              'table__star--empty': !userTraq?.some(
                ({ traqTemplate, favorite }) =>
                  favorite && traqTemplate._id === cell.row.original._id
              ),
            })}
            onClick={() =>
              setTraQTemplateAsFavourite(
                dispatch,
                cell.row.original._id,
                !userTraq?.some(
                  ({ traqTemplate, favorite }) =>
                    favorite && traqTemplate._id === cell.row.original._id
                )
              )
            }
          />
        </div>
      ),
      size: 30,
      maxSize: 60,
      textAlign: 'center',
      cellTextAlign: 'center',
    },
    {
      header: 'Template name',
      accessorKey: 'name',
      size: 500,
    },
    {
      header: 'Associated Alerts',
      id: 'associated-alerts',
      size: 100,
      accessorFn: (row) => (row.alerts ? count(row.alerts) : 0),
      tooltip: (row) => {
        if (!row.alerts || !row.alerts.length) {
          return null
        }
        return getTooltipList(
          'Associated Alerts',
          row.alerts.map((alert) => alert.name)
        )
      },
      sortType: 'number',
    },
    {
      header: 'Last Viewed',
      id: 'lastViewed',
      cell: (cell) => {
        const date = userTraq?.find(
          (userTraQ) => userTraQ.traqTemplate?._id === cell.row.original?._id
        )?.lastViewed

        if (date) {
          return <span>{format(new Date(date), 'MM/dd/yyyy')}</span>
        }

        return null
      },
      size: 100,
      accessorFn: (row) => {
        const date = userTraq?.find(
          (userTraQ) => userTraQ.traqTemplate?._id === row._id
        )?.lastViewed
        return date ? format(new Date(date), 'MM/dd/yyyy') : null
      },
      sortType: 'date',
    },
  ]

  /** State items for the paginated table */
  const [traQTemplatesToShow, setTraQTemplatesToShow] = useState([])

  const [loadingItems, setLoadingItems] = useState(false)
  const [totalRows, setTotalRows] = useState(traQTemplatesToShow?.length || 0)
  const [sortData, setSortData] = useState({})
  const [filterState, setFilterState] = useState({})
  const [pageData, setPageData] = useState({ page: 1, size: 2 })

  /**
   * Filter out inactive templates
   */
  const activeTemplates = useMemo(
    () => list.filter(({ active }) => active),
    [JSON.stringify(list)]
  )

  return (
    <div className="page">
      <IndexFilters
        allAlerts={
          alerts?.map(({ _id: value, name: label }) => ({ value, label })) || []
        }
        allCompanies={allCompanies || []}
        state={filterState}
        setState={setFilterState}
        applyFilters={(...args) =>
          applyFilters({
            ...args,
            pageData,
            setLoadingItems,
            setPageData,
            filterState,
            sortData,
            setTraQTemplatesToShow,
            setTotalRows,
            list: activeTemplates,
          })
        }
        clearFilters={(...args) =>
          clearFilters({
            ...args,
            pageData,
            setLoadingItems,
            setPageData,
            sortData,
            setTraQTemplatesToShow,
            setTotalRows,
            list: activeTemplates,
          })
        }
      />
      <Table
        columns={columns}
        data={traQTemplatesToShow}
        paginationValues={[5, 10, 20, 50, 100]}
        loading={loadingItems}
        onChangeTableView={({ ...args }) =>
          fetchForPaginatedTable({
            ...args,
            setLoadingItems,
            filterState,
            setFilterState,
            setSortData,
            setPageData,
            setTraQTemplatesToShow,
            setTotalRows,
            list: activeTemplates,
          })
        }
        manualPagination={true}
        manualFilter={filterState.name}
        manualCount={totalRows}
        pageCount={Math.ceil(totalRows / pageData.size)}
        showSearchInput={false}
        showPagination={true}
      />
    </div>
  )
}

/**
 * Filters Traq Templates
 * @param {Object} props props
 * @param {Array<Object>} props.allAlerts all alerts
 * @param {Array<Object>} props.allCompanies all companies
 * @param {Object} props.state filter state
 * @param {Function} props.setState function to set filter state
 * @param {Function} props.applyFilters function to apply filters
 * @param {Function} props.clearFilters function to clear filters
 * @returns {React.Component}
 */
const IndexFilters = ({
  allAlerts,
  allCompanies,
  state,
  setState,
  applyFilters,
  clearFilters,
}) => {
  const [loading, setLoading] = useState(false)

  const applyFiltersFunc = () => {
    setLoading(true)
    applyFilters()
    setLoading(false)
  }

  const clearFiltersFunc = () => {
    if (!shallowComparison(state, {})) {
      setLoading(true)
      setState({})
      clearFilters()
      setLoading(false)
    }
  }

  const onSelectAllClients = (checked) => {
    setState((previousFilters) => {
      if (checked) {
        delete previousFilters.clientSearch
        return { ...previousFilters }
      }
      return { ...previousFilters, clientSearch: [] }
    })
  }

  return (
    <Filters
      loading={loading}
      onApply={applyFiltersFunc}
      onClear={clearFiltersFunc}
    >
      {/* Search by Traq Template Name */}
      <FilterSection label="Template Name">
        <Input
          onChange={(name) => setState({ ...state, name })}
          value={state.name}
          placeholder="Search..."
          searchBlue
        />
      </FilterSection>

      <FilterSection label="Associated Clients">
        <DropdownWithSubsections
          onChange={(clientSearch) => setState({ ...state, clientSearch })}
          selectedItems={state.clientSearch}
          options={allCompanies}
          defaultOptionText="All"
          selectAllOptions={{
            label: 'All',
            allSelected:
              state.clientSearch === undefined ||
              state?.clientSearch?.length === allCompanies?.length,
            onCheck: (checked) => onSelectAllClients(checked),
          }}
        />
      </FilterSection>
      <FilterSection label="Associated Alerts">
        <Dropdown
          onChange={(alerts) => setState({ ...state, alerts })}
          selectedItems={state.alerts}
          defaultOptionText="All"
          deselectLabel="All"
          options={allAlerts.sort((a, b) =>
            compareIgnoreCase(a.label, b.label)
          )}
          multiSelect
        />
      </FilterSection>
    </Filters>
  )
}

TraqDashboardIndex.propTypes = {
  list: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  hasCreateAccess: PropTypes.bool,
  hasEditAccess: PropTypes.bool,
}

IndexFilters.propTypes = {
  allAlerts: PropTypes.array,
  allCompanies: PropTypes.array,
  state: PropTypes.object,
  setState: PropTypes.func,
  applyFilters: PropTypes.func,
  clearFilters: PropTypes.func,
}

export default TraqDashboardIndex
