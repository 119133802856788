import { alert, metrics, socket } from '@decision-sciences/qontrol-common'
import cx from 'classnames'
import { subDays } from 'date-fns'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useStore } from 'store'

// Hooks
import { useSocket } from 'components/utils/socket'

// Utils
import {
  initDailyPerformanceReferenceTableData,
  initDailyPerformanceReferenceTableConversions,
} from 'modules/alerts/alerts-thresholds-statistics/actions'
import { getClientConversionGroupings } from 'modules/companies/actions'

// Constants
import {
  DAILY_PERFORMANCE_REFERENCE_KPI_LABELS,
  DAILY_PERFORMANCE_REFERENCE_METRIC_LABELS,
  DAILY_REFERENCE_TABLE_TOOLTIP,
  getDailyPerformanceReferenceColumns,
} from 'modules/alerts/alerts-thresholds-statistics/utils'

// Components
import DropdownTree from 'components/dropdown-tree'
import LoadingOverlay from 'components/loading-overlay/index'
import Table from 'components/table/beta'
import { useTooltip } from 'components/tooltip'
import { showErrorMessage } from 'modules/notifications/actions'

// Icons
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import { ReactComponent as LoadingIcon } from 'assets/icon_ring_loader.svg'

import '../style.scss'

const { NOTIFICATIONS } = socket
const { DAILY_PERFORMANCE_REFERENCE_SUPPORTED_METRICS, METRICS } = metrics
const { DAY, WEEK } = alert

const ALERT_TIME_LABELS = {
  [DAY]: '1',
  [WEEK]: '7',
}

const PerformanceReferenceTable = ({
  onRoadmap,
  alert,
  alertThresholds,
  companyId,
  recommendedThresholdsLoading,
  updateRecommendedThresholds,
}) => {
  const { dispatch } = useStore()

  const socketDailyPerformanceReference = useSocket({
    room: `${companyId}-${alert._id}`,
  })

  const [
    showDailyReferenceTooltip,
    hideDailyReferenceTooltip,
    DailyReferenceTooltip,
  ] = useTooltip(DAILY_REFERENCE_TABLE_TOOLTIP)

  const [dailyPerformanceReferenceData, setDailyPerformanceReferenceData] =
    useState({})
  const [conversionGroups, setConversionGroups] = useState([])
  const [dailyPerformanceReferenceMetric, setDailyPerformanceReferenceMetric] =
    useState(Object.values(DAILY_PERFORMANCE_REFERENCE_SUPPORTED_METRICS)[0])
  const [
    loadingDailyPerformanceReferenceTable,
    setLoadingDailyPerformanceReferenceTable,
  ] = useState(false)
  const [
    loadingDailyPerformanceReferenceConversions,
    setLoadingDailyPerformanceReferenceConversions,
  ] = useState(false)

  const fetchGroupings = async () => {
    try {
      const results = await getClientConversionGroupings(companyId)
      return results
    } catch (e) {
      console.error(e)
      return []
    }
  }

  // Fetch daily performance reference table data
  useEffect(() => {
    // TODO [Titus]: Remove this when performance reference table is fully implemented for real-data alert details
    if (onRoadmap) {
      return
    }
    setLoadingDailyPerformanceReferenceTable(true)
    initDailyPerformanceReferenceTableData(alert._id, companyId).catch(
      (error) => {
        showErrorMessage(error, dispatch)
        setLoadingDailyPerformanceReferenceTable(false)
      }
    )

    setLoadingDailyPerformanceReferenceConversions(true)
    initDailyPerformanceReferenceTableConversions(alert._id, companyId).catch(
      (error) => {
        showErrorMessage(error, dispatch)
        setLoadingDailyPerformanceReferenceConversions(false)
      }
    )

    fetchGroupings().then((res) => {
      if (res) {
        setConversionGroups([...res])
      }
    })
  }, [onRoadmap, JSON.stringify(alertThresholds), alert?.time])

  const mapKpiStatistics = (data, oldestDate) => {
    const mapped = {}

    for (const type of [
      'individual_statistics',
      'group_conversion_statistics',
      'group_revenue_statistics',
    ]) {
      for (const [period, valuesPerPeriod] of Object.entries(data[type])) {
        for (let [metric, values] of Object.entries(valuesPerPeriod)) {
          if (type === 'group_revenue_statistics') {
            metric = `${metric}_Revenue`
          }
          if (!mapped[metric]) {
            mapped[metric] = []
          }
          mapped[metric].push({
            period,
            ...Object.entries(values).reduce((acc, [statistic, value]) => {
              let displayedValue = '--'
              if (
                new Date(oldestDate) <=
                subDays(new Date(), period.replace('_', ''))
              ) {
                const formattedValue = new Intl.NumberFormat('en-US').format(
                  Math.round(value)
                )
                displayedValue = metric.includes('_Revenue')
                  ? `$${formattedValue}`
                  : formattedValue
              }
              return {
                ...acc,
                [statistic]: displayedValue,
              }
            }, {}),
          })
        }
      }
    }

    return mapped
  }

  useEffect(() => {
    if (socketDailyPerformanceReference?.connected) {
      socketDailyPerformanceReference.on(
        NOTIFICATIONS.dailyPerformanceReferenceConversions.receive,
        ({ success, data }) => {
          if (!success) {
            setLoadingDailyPerformanceReferenceConversions(false)
            showErrorMessage(
              `Error fetching Daily Performance reference table conversions. Try opening the modal again.`,
              dispatch
            )
          } else {
            const mapped = mapKpiStatistics(data.statistics, data.oldestDate)

            setDailyPerformanceReferenceData(
              (dailyPerformanceReferenceData) => ({
                ...dailyPerformanceReferenceData,
                ...mapped,
              })
            )

            setLoadingDailyPerformanceReferenceConversions(false)

            updateRecommendedThresholds(
              alertThresholds,
              data.threshold_recommendations
            )
          }
        }
      )
      socketDailyPerformanceReference.on(
        NOTIFICATIONS.dailyPerformanceReferenceRegularMetrics.receive,
        ({ success, data }) => {
          if (!success) {
            setLoadingDailyPerformanceReferenceTable(false)
            showErrorMessage(
              `Error fetching Daily Performance reference table conversions. Try opening the modal again.`,
              dispatch
            )
          } else {
            const mapped = Object.entries(data.statistics).reduce(
              (acc, [metric, value]) => ({
                ...acc,
                [metric]: Object.entries(value).reduce(
                  (valueAcc, [period, values]) => [
                    ...valueAcc,
                    {
                      period,
                      ...Object.entries(values).reduce(
                        (acc, [statKey, statValue]) => {
                          let displayedValue = '--'
                          const oldestDate = new Date(data.oldestDate)
                          const periodStart = subDays(
                            new Date(),
                            period.replace('_', '') - 1
                          )
                          oldestDate.setHours(0, 0, 0, 0)
                          periodStart.setHours(0, 0, 0, 0)
                          if (data.hasRecentData && oldestDate <= periodStart) {
                            const formattedValue = new Intl.NumberFormat(
                              'en-US'
                            ).format(Math.round(statValue))
                            displayedValue =
                              metric === METRICS.Cost.key
                                ? `$${formattedValue}`
                                : formattedValue
                          }
                          return {
                            ...acc,
                            [statKey]: displayedValue,
                          }
                        },
                        {}
                      ),
                    },
                  ],
                  []
                ),
              }),
              {}
            )

            setDailyPerformanceReferenceData(
              (dailyPerformanceReferenceData) => ({
                ...dailyPerformanceReferenceData,
                ...mapped,
              })
            )

            updateRecommendedThresholds(
              alertThresholds,
              data.threshold_recommendations
            )

            setLoadingDailyPerformanceReferenceTable(false)
          }
        }
      )
    }

    return () => {
      socketDailyPerformanceReference?.removeAllListeners(
        NOTIFICATIONS.dailyPerformanceReferenceConversions.receive
      )
      socketDailyPerformanceReference?.removeAllListeners(
        NOTIFICATIONS.dailyPerformanceReferenceRegularMetrics.receive
      )
    }
  }, [
    socketDailyPerformanceReference?.connected,
    JSON.stringify(alertThresholds),
    recommendedThresholdsLoading,
    alert?.time,
  ])

  const dailyPerformanceDropdownOptions = useMemo(() => {
    const toRet = []
    const conversionOptions = []
    const revenueOptions = []
    let selectedByDefault

    for (let value of Object.keys(dailyPerformanceReferenceData)) {
      if (DAILY_PERFORMANCE_REFERENCE_METRIC_LABELS[value]) {
        toRet.push({
          value,
          label: DAILY_PERFORMANCE_REFERENCE_METRIC_LABELS[value] || value,
        })

        if (!selectedByDefault) {
          selectedByDefault = value
        }

        continue
      }

      if (value.includes('_Revenue')) {
        let priority
        let label

        if (DAILY_PERFORMANCE_REFERENCE_KPI_LABELS[value]) {
          label = DAILY_PERFORMANCE_REFERENCE_KPI_LABELS[value]
          priority = -1
        } else {
          value = value.replace('_Revenue', '')
          // Get the priority of the conversion group
          priority =
            conversionGroups.find(({ name }) => name === value)?.priority || 1
          label = `${value} Revenue`
          value = `${value}_Revenue`
        }

        revenueOptions.push({ value, label, priority })

        if (!selectedByDefault) {
          selectedByDefault = value
        }

        continue
      }

      if (!selectedByDefault) {
        selectedByDefault = value
      }

      let label = value
      let priority

      if (DAILY_PERFORMANCE_REFERENCE_KPI_LABELS[value]) {
        label = DAILY_PERFORMANCE_REFERENCE_KPI_LABELS[value]
        priority = -1
      } else {
        // Get the priority of the conversion group
        priority =
          conversionGroups.find(({ name }) => name === value)?.priority || 1
      }

      conversionOptions.push({ value, label, priority })
    }

    if (conversionOptions.length) {
      toRet.push({
        label: 'Conversions',
        value: 'conversions',
        options: [
          ...conversionOptions.sort((a, b) =>
            a.priority < b.priority ? -1 : 1
          ),
        ],
      })
    }

    if (revenueOptions.length) {
      toRet.push({
        label: 'Revenue',
        value: 'revenue',
        options: [
          ...revenueOptions.sort((a, b) => (a.priority < b.priority ? -1 : 1)),
        ],
      })
    }

    setDailyPerformanceReferenceMetric(selectedByDefault)

    return toRet
  }, [dailyPerformanceReferenceData, JSON.stringify(conversionGroups)])

  return (
    <div
      className={cx('daily-performance-ref-table-wrapper', {
        'daily-performance-ref-table__roadmap': onRoadmap,
      })}
    >
      <LoadingOverlay visible={onRoadmap}>
        <div className="roadmap__title">ON ROADMAP</div>
      </LoadingOverlay>
      <div className="daily-performance-ref-table-info display-flex">
        <div className="general-label display-flex">
          {`Performance Reference | ${ALERT_TIME_LABELS[alert.time]} ${
            alert.time
          } ${alert.container || 'Account'} `}
          {DAILY_PERFORMANCE_REFERENCE_METRIC_LABELS[
            dailyPerformanceReferenceMetric
          ] ||
            DAILY_PERFORMANCE_REFERENCE_KPI_LABELS[
              dailyPerformanceReferenceMetric
            ]}
          <InfoIcon
            onMouseEnter={showDailyReferenceTooltip}
            onMouseLeave={hideDailyReferenceTooltip}
          />
          <DailyReferenceTooltip />
        </div>
        <div className="display-flex">
          {loadingDailyPerformanceReferenceConversions ? (
            <LoadingIcon width="24px" height="24px" />
          ) : null}
          <DropdownTree
            disabled={onRoadmap}
            className="daily-performance-ref-table-dropdown"
            options={dailyPerformanceDropdownOptions}
            onChange={({ value }) =>
              value && setDailyPerformanceReferenceMetric(value)
            }
            selected={
              DAILY_PERFORMANCE_REFERENCE_METRIC_LABELS[
                dailyPerformanceReferenceMetric
              ] ||
              DAILY_PERFORMANCE_REFERENCE_KPI_LABELS[
                dailyPerformanceReferenceMetric
              ]
            }
            forceOpenUpwards={true}
          />
        </div>
      </div>
      <div className="daily-performance-ref-table">
        <LoadingOverlay visible={loadingDailyPerformanceReferenceTable}>
          <LoadingIcon />
        </LoadingOverlay>
        <Table
          disableSort={true}
          columns={getDailyPerformanceReferenceColumns(
            dailyPerformanceReferenceMetric
          )}
          data={
            dailyPerformanceReferenceData[dailyPerformanceReferenceMetric] || []
          }
        />
      </div>
    </div>
  )
}

PerformanceReferenceTable.propTypes = {
  onRoadmap: PropTypes.bool,
  alert: PropTypes.object.isRequired,
  alertThresholds: PropTypes.array.isRequired,
  companyId: PropTypes.string.isRequired,
  recommendedThresholdsLoading: PropTypes.bool,
  updateRecommendedThresholds: PropTypes.func.isRequired,
}

export { PerformanceReferenceTable }
