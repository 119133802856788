import { ReactComponent as FilterIcon } from 'assets/filters.svg'
import { ReactComponent as AddIcon } from 'assets/icon_dashboard_customize.svg'
import cx from 'classnames'
import Button from 'components/button'
import LineDivider from 'components/line-divider'
import RoundRectangleButton from 'components/round-rectangle-button'
import DimensionsFilters from 'modules/data-store-global/components/dimensions/filters'
import DimensionsTable from 'modules/data-store-global/components/dimensions/table'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'
import { utils } from '@decision-sciences/qontrol-common'
import '../../style.scss'

const { isEmpty } = utils.object

/**
 * Dimensions/metrics management section
 * @returns {React.FunctionComponent}
 */
const DataStoreDimensions = () => {
  const navigate = useNavigate()
  const { state } = useStore()

  const {
    dataStoreGlobal: { bulkEditActive, dimensionsFilters },
  } = state

  const [filtersOpened, setFiltersOpened] = useState(false)

  const onCreateClick = () =>
    navigate('/global-settings/data-store/dimension-metric/new')

  const filterActive = filtersOpened || !isEmpty(dimensionsFilters)

  const toggleFiltersOpen = () => {
    if (bulkEditActive) {
      return
    }
    setFiltersOpened((prevOpened) => !prevOpened)
  }

  return (
    <div
      data-cy="data-store-dimensions"
      className="margin-top-32 form__section__body dimensions"
    >
      <div className="align-row align-baseline space-between dimensions__header">
        <h3 className="generic-heading generic-heading--no-margin">
          Dimensions & Metrics
        </h3>
        <div className="align-row">
          <Button
            compact
            data-cy="data-store-dimension-add"
            onClick={onCreateClick}
            value={
              <div className="align-row center gap-8">
                <AddIcon />
                <span>ADD DIMENSION / METRIC</span>
              </div>
            }
          />
          <RoundRectangleButton
            onClick={toggleFiltersOpen}
            data-cy="data-store-dimension-filters-toggle"
            contentRender={() => (
              <FilterIcon
                className={cx('filters__icon', {
                  'fill-white': filterActive,
                  'fill-light-blue': !filterActive,
                  'svg--disabled': bulkEditActive,
                })}
              />
            )}
            className={cx('filters__button margin-left-16', {
              'filters__button--active': filterActive,
              'filters__button--disabled': bulkEditActive,
            })}
          />
        </div>
      </div>
      <LineDivider />
      {filtersOpened && <DimensionsFilters />}
      <DimensionsTable hideActions={filtersOpened} />
    </div>
  )
}

export default DataStoreDimensions

