import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { utils } from '@decision-sciences/qontrol-common'

// Components
import EntitySelectorActions from 'components/entity-selector/action-bar'
import LineDivider from 'components/line-divider/index'
import Button from 'components/button'
import EntitySelectorFilters, {
  FILTER_TYPE,
} from 'components/entity-selector/filters'

// Constants and Utils
import { getFilterOptions } from 'modules/companies/subsections/reporting-views/utils'

// Icons
import { ReactComponent as ClearIcon } from 'assets/icon_clear_red.svg'

const { isEmpty } = utils.object

/**
 * Component to filter saved reporting views
 * @returns {React.FunctionComponent}
 */
const ReportingViewsSelector = ({
  reportingViews = [],
  onApplyFilters,
  clearFilters,
  filter,
  setFilter,
}) => {
  const [filterOptions, setFilterOptions] = useState({})

  useEffect(() => {
    const options = getFilterOptions(reportingViews)
    setFilterOptions({ ...options })
  }, [JSON.stringify(reportingViews)])

  useEffect(() => {
    if ((filter.name && filter.name.length >= 3) || isEmpty(filter.name)) {
      onApplyFilters(filter)
    }
  }, [filter.name])

  const filters = [
    {
      fieldKey: 'name',
      label: 'Search',
      placeholder: 'Search',
      type: FILTER_TYPE.SEARCH,
    },
    {
      fieldKey: 'filter-type',
      fields: [
        {
          fieldKey: 'viewType',
          label: 'View Type',
          placeholder: 'Select View Type',
          showOptionsInPlaceholder: true,
          type: FILTER_TYPE.DROPDOWN_SUBSECTIONS,
          selectAllOptions: { label: 'All' },
          options: (filterOptions.viewType || []).sort((a, b) =>
            a.label.localeCompare(b.label)
          ),
        },
        {
          fieldKey: 'creators',
          label: 'Creator',
          placeholder: 'Select User',
          showOptionsInPlaceholder: true,
          type: FILTER_TYPE.DROPDOWN_SUBSECTIONS,
          selectAllOptions: { label: 'All' },
          options: filterOptions.creators || [],
        },
      ],
      type: FILTER_TYPE.CLUSTER,
    },
    {
      fieldKey: 'filters',
      label: 'Filters',
      placeholder: 'Select Filters',
      type: FILTER_TYPE.DROPDOWN_SUBSECTIONS,
      showOptionsInPlaceholder: true,
      selectAllOptions: { label: 'All' },
      options: filterOptions.filters || [],
    },
    {
      fieldKey: 'permission-display',
      fields: [
        {
          fieldKey: 'permissions',
          label: 'Permission',
          placeholder: 'Permission',
          type: FILTER_TYPE.DROPDOWN_SUBSECTIONS,
          selectAllOptions: { label: 'All' },
          options: filterOptions.permissions || [],
          disableSearch: true,
        },
        {
          fieldKey: 'display',
          label: 'Display',
          placeholder: 'Display',
          type: FILTER_TYPE.DROPDOWN_SUBSECTIONS,
          selectAllOptions: { label: 'All' },
          options: filterOptions.display || [],
          disableSearch: true,
        },
      ],
      type: FILTER_TYPE.CLUSTER,
    },
  ]

  const handleFiltersChange = (fieldKey) => (value) => {
    const changes = { ...filter, [fieldKey]: value }
    setFilter(changes)
  }

  return (
    <>
      <EntitySelectorFilters
        filters={filters}
        selectedFilters={filter}
        onFiltersChange={handleFiltersChange}
      />
      <LineDivider />
      <EntitySelectorActions
        hasFilters
        onApplyFilters={onApplyFilters}
        actions={
          <Button
            onClick={clearFilters}
            secondaryRed
            value={
              <div className="align-row">
                <ClearIcon width={16} height={16} className="fill-red" />
                <div className="margin-left-10">Clear</div>
              </div>
            }
          />
        }
      />
    </>
  )
}

export default ReportingViewsSelector

ReportingViewsSelector.propTypes = {
  reportingViews: PropTypes.array.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
}
