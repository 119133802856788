import Api from 'easy-fetch-api'
import { utils, dataStore } from '@decision-sciences/qontrol-common'
import { Dropdown } from 'components/dropdown/index'
import {
  editDimensionField,
  getAllCategories,
} from 'modules/data-store-global/actions'
import { useIntermediaryStates } from 'hooks/intermediary-states'
import { DECIMAL_PLACES_OPTIONS } from 'modules/data-store-global/constants'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useStore } from 'store'

const { NUMBER_FORMAT } = utils.number
const { isEmpty } = utils.object
const {
  DATA_STORE_FORMAT,
  DATA_STORE_FORMAT_OPTIONS,
  DATA_STORE_ITEM_PLACEMENT_OPTIONS,
  DATA_STORE_ITEM_TYPE_OPTIONS,
  DATA_STORE_NUMBER_FORMAT_OPTIONS,
} = dataStore

/**
 * Form which allows configuring the base Dimension fields.
 * @param {Object} props Component props
 * @param {Boolean} [props.isBulkEdit = false] Flag to specify if it's bulk edit. Used to update the form fields
 * @param {Boolean} props.isDirty Dirty flag
 * @param {Function} props.setDirty Setter for the dirty state
 * @param {Object} props.errors Validation errors object
 * @param {Function} props.setErrors Setter for the validation errors
 * @param {Boolean} [props.disabled] Disabled flag
 * @returns {React.FunctionComponent}
 */
const DimensionForm = ({
  isBulkEdit,
  isDirty,
  setDirty,
  disabled,
  errors,
  setErrors,
}) => {
  const { dispatch, state } = useStore()
  const {
    dataStoreGlobal: { editedDimension: dimension, categories },
  } = state

  const { getLoadingFor, setLoadingFor, onSuccess, onFailure } =
    useIntermediaryStates()

  const isNumberFormat = dimension.format === DATA_STORE_FORMAT.NUMERIC

  const [availableDimensions, setAvailableDimensions] = useState([])

  const fetchAvailableDimesions = useCallback(() => {
    Api.get({
      url: '/api/data-store/available-dimensions',
      callBefore: () => setLoadingFor(FIELDS.DIMENSIONS, true),
    })
      .then((response) => {
        if (!response.success) {
          onFailure(FIELDS.DIMENSIONS, 'Unable to fetch dimensions')
          return
        }
        onSuccess(FIELDS.DIMENSIONS)
        setAvailableDimensions(response.dimensions)
      })
      .catch((error) => {
        onFailure(FIELDS.DIMENSIONS, error)
      })
  }, [])

  useEffect(() => {
    if (isBulkEdit && !categories) {
      getAllCategories(dispatch)
    }
  }, [isBulkEdit, categories])

  useEffect(() => {
    if (!isBulkEdit) {
      fetchAvailableDimesions()
    }
  }, [isBulkEdit, fetchAvailableDimesions])

  /** In case format is changed to something other than numeric, reset numeric config */
  useEffect(() => {
    if (dimension.format !== DATA_STORE_FORMAT.NUMERIC) {
      editDimensionField(dispatch, { numberFormat: null, decimalPlaces: null })
    } else if (dimension.format === DATA_STORE_FORMAT.NUMERIC) {
      const updatedFields = {}
      if (!dimension.numberFormat) {
        updatedFields.numberFormat = NUMBER_FORMAT.NUMBER
      }
      if (!dimension.decimalPlaces) {
        updatedFields.decimalPlaces = DECIMAL_PLACES_OPTIONS[0].value
      }
      if (!isEmpty(updatedFields)) {
        editDimensionField(dispatch, updatedFields)
      }
    }
  }, [dimension.format])

  /**
   * Builds a custom value for each Snowflake dimension
   * @param {Object} dimension Dimension to build the value for
   * @returns {String}
   */
  const getDimensionValue = (dimension) => {
    const { COLUMN_NAME, TABLE_SCHEMA, TABLE_CATALOG, TABLE_NAME } = dimension
    return `${TABLE_SCHEMA}_${TABLE_CATALOG}_${TABLE_NAME}:${COLUMN_NAME}`
  }

  const updateDimensionField = (fieldName) => (value) => {
    if (!isDirty) {
      setDirty(true)
    }
    if (!isEmpty(errors)) {
      setErrors({})
    }
    editDimensionField(dispatch, { [fieldName]: value })
  }

  const onDimensionSelection = (value) => {
    const selectedDimension = availableDimensions.find(
      (dimension) => getDimensionValue(dimension) === value
    )
    updateDimensionField('dimension')(selectedDimension)
  }

  return (
    <div className="section section__body align-row space-between gap-8 dimension__form">
      <div className="align-column half gap-16">
        {isBulkEdit ? (
          <Dropdown
            data-cy="data-store-dimension-form-category"
            disabled={disabled}
            selectAll={false}
            multiSelect
            label="Category"
            defaultOptionText="Select Category"
            options={categories?.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            onChange={updateDimensionField('categories')}
            selectedItems={dimension.categories}
            className="input-wrapper--uppercase"
          />
        ) : (
          <Dropdown
            data-cy="data-store-dimension-form-dimension"
            disabled={disabled}
            loading={getLoadingFor(FIELDS.DIMENSIONS)}
            label="Dimension / Metric"
            defaultOptionText="Select Dimension / Metric"
            options={availableDimensions.map((dimension) => ({
              value: getDimensionValue(dimension),
              label: dimension.COLUMN_NAME,
            }))}
            onChange={onDimensionSelection}
            defaultState={
              dimension.dimension ? getDimensionValue(dimension.dimension) : ''
            }
            error={errors.dimension}
            className="input-wrapper--uppercase"
          />
        )}
        <Dropdown
          data-cy="data-store-dimension-form-format"
          disabled={disabled}
          label="Format"
          defaultOptionText="Select Format"
          options={Object.entries(DATA_STORE_FORMAT_OPTIONS).map(
            ([value, label]) => ({ value, label })
          )}
          onChange={updateDimensionField('format')}
          defaultState={dimension.format}
          className="input-wrapper--uppercase"
        />
      </div>
      <div className="align-column half">
        <div className="align-row space-between gap-8">
          <div className="align-column half gap-16">
            <Dropdown
              data-cy="data-store-dimension-form-type"
              disabled={disabled}
              label="Type"
              defaultOptionText="Select Type"
              options={Object.entries(DATA_STORE_ITEM_TYPE_OPTIONS).map(
                ([value, label]) => ({ value, label })
              )}
              onChange={updateDimensionField('type')}
              defaultState={dimension.type}
              className="input-wrapper--uppercase"
            />
            <Dropdown
              data-cy="data-store-dimension-form-number-format"
              label="Number Formatting"
              disabled={!isNumberFormat || disabled}
              defaultOptionText="Select Number Formatting"
              options={Object.entries(DATA_STORE_NUMBER_FORMAT_OPTIONS).map(
                ([value, label]) => ({ value, label })
              )}
              onChange={updateDimensionField('numberFormat')}
              defaultState={dimension.numberFormat}
              className="input-wrapper--uppercase"
            />
          </div>
          <div className="align-column half gap-16">
            <Dropdown
              data-cy="data-store-dimension-form-placement"
              disabled={disabled}
              label="Placement"
              defaultOptionText="Select Placement"
              options={Object.entries(DATA_STORE_ITEM_PLACEMENT_OPTIONS).map(
                ([value, label]) => ({ value, label })
              )}
              onChange={updateDimensionField('placement')}
              defaultState={dimension.placement}
              error={errors.placement}
              className="input-wrapper--uppercase"
            />
            <Dropdown
              data-cy="data-store-dimension-form-decimal"
              disabled={!isNumberFormat || disabled}
              label="Decimal Places"
              defaultOptionText="Select Decimal Places"
              options={DECIMAL_PLACES_OPTIONS}
              onChange={updateDimensionField('decimalPlaces')}
              defaultState={dimension.decimalPlaces}
              className="input-wrapper--uppercase"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const FIELDS = {
  DIMENSIONS: 'DIMENSIONS',
}

DimensionForm.propTypes = {
  isBulkEdit: PropTypes.bool,
  isDirty: PropTypes.bool.isRequired,
  setDirty: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default DimensionForm
