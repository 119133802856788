import cx from 'classnames'
import { publisherFieldMap, utils } from '@decision-sciences/qontrol-common'

/** Icons */
import { ReactComponent as DownloadIcon } from 'assets/icon_download.svg'
import { ReactComponent as StarIcon } from 'assets/icon_star.svg'

const { ALL_OPERATIONS } = publisherFieldMap.operations
const {
  DATE_RANGE_OPTIONS,
  QUARTER_TYPE,
  getDateRange,
  DELTA_TODAY,
  DELTA_YESTERDAY,
} = utils.date

export const defaultFilter = {
  operation: ALL_OPERATIONS.CT,
  dimensions: [],
}

export const DIMENSION_FILTER = 'Dimension_'

export const DATE_OPTIONS = [
  { value: 'custom', label: 'Custom' },
  {
    value: DATE_RANGE_OPTIONS.TODAY,
    label: 'Today',
  },
  {
    value: DATE_RANGE_OPTIONS.YESTERDAY,
    label: 'Yesterday',
  },
  {
    value: DATE_RANGE_OPTIONS.THIS_WEEK,
    label: 'This week',
    arrow: true,
    extraLabels: { START_WEEK: '(SUN-TODAY)', END_WEEK: '(MON-TODAY)' },
  },
  { value: DATE_RANGE_OPTIONS.LAST_7_DAYS, label: 'Last 7 Days' },
  {
    value: DATE_RANGE_OPTIONS.LAST_WEEK,
    label: 'Last week',
    arrow: true,
    extraLabels: { START_WEEK: '(SUN-SAT)', END_WEEK: '(MON-SUN)' },
  },
  {
    value: DATE_RANGE_OPTIONS.LAST_14_DAYS,
    label: 'Last 14 DAYS',
  },
  { value: DATE_RANGE_OPTIONS.THIS_MONTH, label: 'THIS MONTH' },
  { value: DATE_RANGE_OPTIONS.LAST_30_DAYS, label: 'LAST 30 DAYS' },
  { value: DATE_RANGE_OPTIONS.LAST_MONTH, label: 'LAST MONTH' },
  {
    value: DATE_RANGE_OPTIONS.THIS_QUARTER,
    label: 'THIS QUARTER',
    arrow: true,
  },
  {
    value: DATE_RANGE_OPTIONS.LAST_QUARTER,
    label: 'LAST QUARTER',
    arrow: true,
  },
  { value: DATE_RANGE_OPTIONS.ALL_TIME, label: 'ALL TIME' },
]

export const DIMENSIONS_SUPPORTING_CONTAIN_FILTERS = [
  'Account_Main',
  'AdGroup/Adset_Main',
  'Campaign_Main',
]

export const FILTER_OPERATION_TYPES = [
  {
    label: 'contains',
    value: ALL_OPERATIONS.CT,
  },
  {
    label: 'does not contain',
    value: ALL_OPERATIONS.NON_CT,
  },
  {
    label: 'is equal to',
    value: ALL_OPERATIONS.EQ,
  },
  {
    label: 'is not equal to',
    value: ALL_OPERATIONS.NON_EQ,
  },
]

export const ACCEPTED_FILTER_TYPES = {
  CATEGORICAL: 'categorical',
  QUANTITATIVE: 'quantitive',
}

export const DATE_FILTER_NAMES = {
  DATE: 'Date',
  DATE_ADDED: 'Date Added',
  DATE_RANGE: 'Date Range',
  DATE_GRANULARITY: 'Select Date Granularity',
}

export const DATE_FILTER_NAMES_ARRAY = Object.values(DATE_FILTER_NAMES)

export const UNIQUE_FIELDS = ['name']

export const INITIAL_DATE_STATE = {
  startDate: getDateRange(DATE_RANGE_OPTIONS.YESTERDAY),
  endDate: getDateRange(DATE_RANGE_OPTIONS.TODAY),
  upToToday: 30,
  upToYesterday: 30,
  startOfWeek: 0,
  hasFiscalYear: false,
  thisQuarter: QUARTER_TYPE.STANDARD,
  lastQuarter: QUARTER_TYPE.STANDARD,
}

const DATE_OPTION_DEPENDENT_FIELDS = {
  [DATE_RANGE_OPTIONS.THIS_WEEK]: ['startOfWeek'],
  [DATE_RANGE_OPTIONS.LAST_WEEK]: ['startOfWeek'],
  [DELTA_TODAY]: ['upToToday'],
  [DELTA_YESTERDAY]: ['upToYesterday'],
  [DATE_RANGE_OPTIONS.THIS_QUARTER]: ['thisQuarter'],
  [DATE_RANGE_OPTIONS.LAST_QUARTER]: ['lastQuarter'],
  [DATE_RANGE_OPTIONS.CUSTOM]: ['startDate', 'endDate', 'dateRange'],
}

const REPORT_TEMPLATES = {
  CROSS_CHANNEL: 'Cross-Channel',
}

const WORKBOOK_SHEETS = {
  DASHBOARD: 'Dashboard',
  KPI_TRENDS: 'KPI Trends',
  DIMENSION: 'Dimension',
  PERIOD_COMPARISONS: 'Period Comparisons',
  DIMENSION_TRENDS: 'Dimension Trends',
  PACING: 'Pacing',
  RUN_RATE: 'Run Rate',
  GOOGLE_ANALYTICS: 'Google Analytics',
}

const ALLOWED_FILTER_NAMES = {
  [REPORT_TEMPLATES.CROSS_CHANNEL]: {
    [WORKBOOK_SHEETS.DASHBOARD]: {
      staticFilters: [
        'Account_Main',
        'Campaign_Main',
        'AdGroup/Adset_Main',
        'Device_Main',
        'Conversion Type_Conversion',
        'Conversion Source_Conversion',
        'Placement/Creative_Main',
      ],
    },
    [WORKBOOK_SHEETS.KPI_TRENDS]: {
      staticFilters: [
        'Account_Main',
        'Campaign_Main',
        'AdGroup/Adset_Main',
        'Device_Main',
        'Conversion Type_Conversion',
        'Conversion Source_Conversion',
        'Placement/Creative_Main',
      ],
    },
    [WORKBOOK_SHEETS.DIMENSION]: {
      staticFilters: [
        'Account_Main',
        'Campaign_Main',
        'AdGroup/Adset_Main',
        'Device_Main',
        'Conversion Type_Conversion',
        'Conversion Source_Conversion',
        'Placement/Creative_Main',
      ],
    },
    [WORKBOOK_SHEETS.PERIOD_COMPARISONS]: {
      staticFilters: [
        'Account_Main',
        'Campaign_Main',
        'AdGroup/Adset_Main',
        'Device_Main',
        'Conversion Type_Conversion',
        'Conversion Source_Conversion',
        'Placement/Creative_Main',
      ],
    },
    [WORKBOOK_SHEETS.DIMENSION_TRENDS]: {
      staticFilters: [
        'Account_Main',
        'Campaign_Main',
        'AdGroup/Adset_Main',
        'Device_Main',
        'Conversion Type_Conversion',
        'Conversion Source_Conversion',
        'Dimension Inputs_Main',
        'Select Dimension_Main',
        'Placement/Creative_Main',
      ],
    },
    [WORKBOOK_SHEETS.PACING]: {
      staticFilters: ['Date Range_Date', 'budget_segment_name_Main'],
    },
    [WORKBOOK_SHEETS.RUN_RATE]: {
      staticFilters: [],
    },
    [WORKBOOK_SHEETS.GOOGLE_ANALYTICS]: {
      staticFilters: [
        'Campaign_Main',
        'Source Medium_Main',
        'Content_Main',
        'Conversion Type_Conversion',
        'Term_Main',
        'Channel_Main',
        'Profile Id_Main',
        'Google Analytics Source_Main', // Property Type
        'Select Analytics Dimension_Main',
      ],
    },
  },
}

/**
 * Builds the payload for updating the reporting user date configuration
 * @param {Object} date Date configuration received from date panel
 * @param {Object} userDateConfig Current saved date configuration for the user
 * @returns {Object | void}
 */
export const getDateToSave = (date, userDateConfig) => {
  if (!date) {
    return
  }

  const {
    selectedDateGranularity,
    option,
    customRange,
    customStart,
    customEnd,
  } = date
  const dependentFields = DATE_OPTION_DEPENDENT_FIELDS[option]
  const newDate = { option, selectedDateGranularity }

  const {
    customRange: userCustomRange,
    customStart: userCustomStart,
    customEnd: userCustomEnd,
  } = userDateConfig?.customComparison || {}
  // In case the date selection does not have custom comparison enabled, keep previous values
  const customComparison = {
    customRange: customRange || userCustomRange,
    customStart: customStart || userCustomStart,
    customEnd: customEnd || userCustomEnd,
  }
  newDate.customComparison = customComparison

  if (dependentFields && dependentFields.length) {
    dependentFields.forEach((field) => (newDate[field] = date[field]))
  }
  return newDate
}

export const getCurrentDateConfig = (date, fiscalPeriod) => {
  const {
    option,
    upToToday,
    upToYesterday,
    startOfWeek,
    thisQuarter,
    lastQuarter,
  } = date
  if (option !== DATE_RANGE_OPTIONS.CUSTOM) {
    const range = getDateRange(
      option,
      startOfWeek,
      thisQuarter,
      lastQuarter,
      fiscalPeriod,
      option === DELTA_TODAY ? upToToday : upToYesterday
    )
    const [startDateStr, endDateStr] = range?.split('-')?.map((el) => el.trim())
    return {
      ...date,
      startDate: startDateStr,
      endDate: endDateStr,
      dateRange: range,
    }
  } else {
    return { ...date }
  }
}

export const getDateGranularityConfig = (userDateConfig) => {
  if (userDateConfig.selectedDateGranularity) {
    return { selectedDateGranularity: userDateConfig.selectedDateGranularity }
  }
  return {}
}

export const getCustomDateComparisonConfig = (userDateConfig) => {
  if (userDateConfig.customComparison) {
    const { customRange, customStart, customEnd } =
      userDateConfig.customComparison
    return { customRange, customStart, customEnd }
  }
  return {}
}

export const formatFilter = ({
  filter,
  workbook,
  worksheet,
  currentCompany,
  userData,
  globalReportData,
}) => {
  const {
    workbook: filterWorkbook,
    worksheet: filterWorksheet,
    permission: { client, creator },
    templateId,
  } = filter
  if (!filterWorkbook) {
    filter.workbook = workbook
  }
  if (!filterWorksheet) {
    filter.worksheet = worksheet
  }
  if (!client) {
    filter.permission.client = currentCompany._id
  }
  if (!creator) {
    filter.permission.creator = userData._id
    filter.permission.creatorName = userData.name
  }
  if (!templateId && globalReportData) {
    filter.templateId = globalReportData.id
  }
  return filter
}

export const EDITABLE_FILTER_SECTIONS = {
  VALUE: 'value',
  DIMENSIONS: 'dimensions',
  OPERATION: 'operation',
}

export const _renderFilterRow = (
  option,
  setDefault,
  onSelect,
  defaultFilter
) => {
  const isDefault = defaultFilter?.name === option.value
  return (
    <div className="filter-row">
      <DownloadIcon onClick={() => onSelect(option.value)} />
      <div className="filter-label">{option.label}</div>
      <StarIcon
        className={cx('star-button', {
          'star-button--selected': isDefault,
        })}
        onClick={setDefault(option)}
      />
    </div>
  )
}

export const getAllowableFiltersSheet = (filter, sheet) => {
  if (filter?.name?.startsWith(DIMENSION_FILTER)) {
    return (
      sheet !== WORKBOOK_SHEETS.RUN_RATE && sheet !== WORKBOOK_SHEETS.PACING
    )
  } else {
    return ALLOWED_FILTER_NAMES[REPORT_TEMPLATES.CROSS_CHANNEL]?.[
      sheet
    ]?.staticFilters?.includes(filter.name)
  }
}

export const getFilterOperators = (selectedFilter) => {
  let filterOperators = FILTER_OPERATION_TYPES
  if (
    selectedFilter?.name &&
    !DIMENSIONS_SUPPORTING_CONTAIN_FILTERS.includes(selectedFilter.name)
  ) {
    filterOperators = filterOperators.filter(
      ({ value }) =>
        value !== ALL_OPERATIONS.CT && value !== ALL_OPERATIONS.NON_CT
    )
  }
  if (selectedFilter?.isWorkbookParameter) {
    filterOperators = filterOperators.filter(
      ({ value }) => value === ALL_OPERATIONS.EQ
    )
  }
  return filterOperators
}
