import React from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Table from 'components/table/beta'
import Icon from 'components/icon'
import { getTooltipList } from 'components/utils/tooltip'
import { ReactComponent as RemoveIcon } from 'assets/icon_minus_red.svg'
import { TEAM_REPORT_TYPE, getCompanyAndBUNames } from '../constants'

/**
 * Reporting Views Section
 */
const TeamReportsTable = ({
  reports = [],
  selectedReports,
  setSelectedReports,
  setReportsToDelete,
  disableActions,
  viewMode,
}) => {
  const columns = [
    {
      header: 'Name',
      id: 'report-name',
      textAlign: 'left',
      cell: (cell) => {
        const report = cell.row.original
        return (
          <div>
            {report.name}
            {report.company ? (
              <div className="teams__hint">{getCompanyAndBUNames(report)}</div>
            ) : null}
          </div>
        )
      },
      size: '30%',
    },
    {
      header: 'Type',
      id: 'report-type',
      textAlign: 'left',
      accessorFn: (row) =>
        row.type === TEAM_REPORT_TYPE.EMBED ? 'Embed' : 'Global',
      size: 80,
      maxSize: 80,
    },
    {
      header: 'Creator',
      id: 'report-creator',
      textAlign: 'left',
      accessorFn: ({ owner }) => `${owner?.firstName} ${owner?.lastName}`,
      size: 80,
    },
    {
      header: 'Users',
      id: 'report-users',
      accessorFn: ({ users }) => users?.length || 0,
      tooltip: ({ users }) => {
        return users.length
          ? getTooltipList('Users with Report Permissions ', [
              ...users.map(
                ({ firstName, lastName }) => `${firstName} ${lastName}`
              ),
            ])
          : null
      },
      textAlign: 'left',
      size: 40,
      maxSize: 80,
    },
    {
      header: 'Modified',
      id: 'report-modified',
      accessorKey: 'updatedAt',
      cell: (cell) => {
        const updatedAt = cell.row.original.updatedAt
        return updatedAt ? format(new Date(updatedAt), 'MM/dd/yyyy') : ''
      },
      tooltip: ({ updatedAt }) =>
        updatedAt ? format(new Date(updatedAt), 'HH:mm a') : '',
      textAlign: 'left',
      size: 90,
      maxSize: 120,
    },
    {
      header: 'Status',
      id: 'report-status',
      accessorKey: 'active',
      cell: (cell) => {
        const { active } = cell.row.original
        if (typeof active !== 'undefined') {
          return active ? 'Active' : 'Inactive'
        } else {
          return 'Active'
        }
      },
      size: 90,
      maxSize: 90,
    },
    {
      header: '',
      id: 'delete-report',
      textAlign: 'center',
      size: 40,
      maxSize: 40,
      cell: (cell) => (
        <Icon
          disabled={disableActions}
          onClick={() =>
            !disableActions && setReportsToDelete([cell.row.original])
          }
        >
          <RemoveIcon
            className={cx('fill-red', { disabled: disableActions })}
          />
        </Icon>
      ),
      hidden: viewMode,
    },
  ]

  return (
    <Table
      id="reports-table"
      columns={columns}
      data={reports.map((report) => ({
        ...report,
        disableSelect: disableActions,
      }))}
      onSelect={!viewMode ? setSelectedReports : null}
      initialState={{
        selectedRowIds: selectedReports.map((r) => r.tableRowId),
      }}
    />
  )
}

export default TeamReportsTable

TeamReportsTable.propTypes = {
  reports: PropTypes.array.isRequired,
  selectedReports: PropTypes.array.isRequired,
  setSelectedReports: PropTypes.func.isRequired,
  setReportsToDelete: PropTypes.func.isRequired,
  disableActions: PropTypes.bool,
  viewMode: PropTypes.bool.isRequired,
}
