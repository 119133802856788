import React from 'react'
import { format as formatDate } from 'date-fns'
import PropTypes from 'prop-types'
import cx from 'classnames'

/* Store */
import { useStore } from 'store'

/* Components */
import Table from 'components/table/beta'
import Toggle from 'components/toggle'
import RoundRectangleButton from 'components/round-rectangle-button'

/* Utils */
import {
  setDefaultFilter,
  removeDefaultFilter,
  updateCustomEntity,
} from 'modules/global-reports/actions'
import { matchTaxonomyToDimension } from 'modules/global-reports/utils'

/* Icons */
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { ReactComponent as StarIconEmpty } from 'assets/icon_star_empty.svg'
import { ReactComponent as StarIconFilled } from 'assets/icon_star.svg'

/* Constants */
import { globalReports } from '@decision-sciences/qontrol-common'

import './style.scss'

const { CUSTOM_FILTER_PERMISSIONS, SAVED_VIEW_TYPES_LABELS } = globalReports

/**
 * Reporting Views Section
 */
const ReportingViewsTable = ({
  reportingFilters = [],
  currentUser,
  fetchFilters,
  setLoading,
  viewToEdit,
  setViewToEdit,
  namingConventions,
  setFilterToDelete,
}) => {
  const { dispatch } = useStore()
  const { _id: userId } = currentUser

  const columns = [
    {
      header: '',
      id: 'edit-view',
      size: 40,
      minSize: 40,
      maxSize: 70,
      width: 20,
      textAlign: 'center',
      cell: ({ row: { original: row } }) => (
        <EditIcon
          className={cx({
            'disabled-icon': viewToEdit,
          })}
          onClick={() => !viewToEdit && setViewToEdit(row)}
        />
      ),
    },
    {
      header: 'Default',
      id: 'default-view',
      textAlign: 'center',
      cell: ({ row: { original: row } }) => (
        <RoundRectangleButton
          className="cursor-on-hover"
          contentClassName="filter-icon"
          onClick={() => {
            setLoading(true)
            if (row.defaultForUsers.includes(userId)) {
              removeDefaultFilter({
                entityId: row._id,
                userId: userId,
              }).then(() => fetchFilters(true))
            } else {
              setDefaultFilter({
                entityId: row._id,
                entityType: row.type,
                userId: userId,
              }).then(() => fetchFilters(true))
            }
          }}
          disabled={!!viewToEdit}
          contentRender={() =>
            row.defaultForUsers.includes(userId) ? (
              <StarIconFilled />
            ) : (
              <StarIconEmpty />
            )
          }
        />
      ),
      size: 40,
      minSize: 40,
      maxSize: 70,
    },
    {
      header: 'Display',
      id: 'display-view-toggle',
      textAlign: 'center',
      cell: ({ row: { original: row } }) => {
        const isToggled = row.displayed
        return (
          <Toggle
            onChange={() => {
              const updatedFilter = { ...row, displayed: !isToggled }
              setLoading(true)
              updateCustomEntity(dispatch, updatedFilter).then(() =>
                fetchFilters(true)
              )
            }}
            disabled={!!viewToEdit}
            defaultChecked={isToggled}
          />
        )
      },
      size: 40,
      minSize: 40,
      maxSize: 70,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      id: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
    },
    {
      header: 'View Type',
      accessorFn: (row) => SAVED_VIEW_TYPES_LABELS[row.type],
      id: 'type',
      textAlign: 'left',
      cellTextAlign: 'left',
      minSize: 120,
      maxSize: 150,
    },
    {
      header: 'Filters',
      cell: ({ row: { original: row } }) => {
        const {
          options: { filters },
        } = row

        if (filters.length) {
          return <p className="underlined">{filters.length}</p>
        }
        return '--'
      },
      tooltip: (row) => {
        const options = matchTaxonomyToDimension(
          row.options.filters,
          namingConventions
        )
        if (options.length) {
          return (
            <div className="filters-tooltip">
              <p className="bold margin-0">Filters:</p>
              {options.map(({ label }, idx) => {
                return <div key={idx}>{label}</div>
              })}
            </div>
          )
        }
      },
      textAlign: 'center',
      size: 40,
      minSize: 120,
      maxSize: 120,
    },
    {
      header: 'Permission',
      id: 'permission',
      cell: ({ row: { original: row } }) => {
        const permission = Object.values(CUSTOM_FILTER_PERMISSIONS).find(
          ({ value }) => value === row.permission.type
        )
        return permission.label
      },
      tooltip: (row) => {
        const permission = Object.values(CUSTOM_FILTER_PERMISSIONS).find(
          ({ value }) => value === row.permission.type
        )

        return permission.tooltip
      },
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 40,
      minSize: 100,
      maxSize: 100,
    },
    {
      header: 'Creator',
      id: 'creator',
      cell: ({ row: { original: row } }) => row.permission.creatorName,
      textAlign: 'left',
      cellTextAlign: 'left',
    },
    {
      header: 'Modified',
      id: 'last-modified',
      cell: ({ row: { original: row } }) =>
        formatDate(new Date(row.lastModified), 'MM/dd/yyyy'),
      textAlign: 'left',
      cellTextAlign: 'left',
      minSize: 150,
      maxSize: 150,
    },

    {
      header: '',
      id: 'delete-custom-filter',
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 40,
      minSize: 40,
      maxSize: 70,
      cell: ({ row: { original: row } }) => (
        <DeleteIcon
          className={cx('fill-red', {
            'cursor-on-hover': !!viewToEdit,
            'disabled-icon': viewToEdit,
          })}
          alt={'filters icon'}
          onClick={() => !viewToEdit && setFilterToDelete(row)}
        />
      ),
    },
  ]

  return (
    <Table
      id="reporting-views-table"
      columns={columns}
      data={reportingFilters}
      disableSort
    />
  )
}
export default ReportingViewsTable

ReportingViewsTable.propTypes = {
  reportingFilters: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  fetchFilters: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setViewToEdit: PropTypes.func.isRequired,
  viewToEdit: PropTypes.object.isRequired,
  namingConventions: PropTypes.object.isRequired,
  setFilterToDelete: PropTypes.func.isRequired,
}
