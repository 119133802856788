import React, { useState, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/* Libraries */
import { useDrag, useDrop } from 'react-dnd'
import { v4 as uuidv4 } from 'uuid'

/* Components */
import { DragAndDrop } from 'components/utils/drag-drop'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block'
import InputGroup from 'components/input-group'
import Icon from 'components/icon'
import { Dropdown } from 'components/dropdown'

/* Assets */
import { ReactComponent as IconDragDrop } from 'assets/icon_drag_drop.svg'

import './style.scss'

/**
 * A section with ordered InputGroups.
 * @param {Object} params React Params
 * @param {Object} params.data Data array, needs at least an identifier and order field Eg [{order: 1, id: '1'}]
 * @param {Function} params.setData Data change (reorder) callback. To be called with new data.
 * @param {String} params.orderField Field name for order in data. Eg [{ dimensionOrder: 1, dimensionId: 'metrics'}] -> orderField = 'dimensionOrder'
 * @param {String} params.idField Field name for id in data. Eg [{ dimensionOrder: 1, dimensionId: 'metrics'}] -> idField = 'dimensionId'
 * @param {Boolean} [params.isReadOnly] If read only, the list is non-orderable | Defaults to false
 * @param {String} [params.error] Error message to display under list
 * @param {Function} [params.getItemOptions] Getter for extra options for the items in the list. Anything that can be used in an <InputGroup /> goes.
 */
export const OrderedItemSection = ({
  data,
  setData,
  orderField,
  idField,
  isReadOnly = false,
  error,
  getItemOptions,
}) => {
  // Random item type so as to not have items moved to different sections if there are any rendered on the page
  const itemType = useMemo(() => uuidv4(), [])

  const onReorder = (from, to) => {
    // We sort the list based on the order, so we're certain that we're working with
    const orderedData = data.sort((a, b) =>
      a[orderField] < b[orderField] ? -1 : 1
    )
    const itemToMove = orderedData[from - 1]
    orderedData.splice(from - 1, 1)
    orderedData.splice(to - 1, 0, itemToMove)

    setData(
      orderedData.map((dimension, index) => ({
        ...dimension,
        [orderField]: index + 1,
      }))
    )
  }

  const sortedData = data.sort((a, b) =>
    a[orderField] < b[orderField] ? -1 : 1
  )

  return (
    <DragAndDrop>
      <section className="ordered-item-section__dimensions">
        {sortedData.map((dimension, index) => (
          <React.Fragment key={dimension[idField]}>
            <OrderedItemDropTarget
              dimensionBefore={index >= 1 ? sortedData[index - 1] : null}
              dimensionAfter={dimension}
              itemType={itemType}
              orderField={orderField}
              onReorder={onReorder}
            />
            <OrderedItem
              dimension={dimension}
              allDimensions={data}
              onReorder={onReorder}
              orderField={orderField}
              idField={idField}
              isViewMode={isReadOnly}
              itemType={itemType}
              getItemOptions={getItemOptions}
            />
            {!sortedData[index + 1] ? (
              <OrderedItemDropTarget
                dimensionBefore={dimension}
                dimensionAfter={null}
                itemType={itemType}
                orderField={orderField}
                onReorder={onReorder}
              />
            ) : null}
          </React.Fragment>
        ))}
      </section>

      {error && (
        <InformationBlock
          className="ordered-item-section__error"
          info={error}
          type={INFORMATION_BLOCK_TYPE.ERROR}
          style={{ marginTop: '15px', maxWidth: '456px' }}
        />
      )}
    </DragAndDrop>
  )
}

OrderedItemSection.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  orderField: PropTypes.string.isRequired,
  idField: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  error: PropTypes.string,
  getItemOptions: PropTypes.func,
}

const OrderedItem = ({
  dimension,
  allDimensions,
  onReorder,
  orderField,
  idField,
  isViewMode,
  itemType,
  getItemOptions,
}) => {
  const order = dimension[orderField]

  const [animationsActive, setAnimationsActive] = useState(true)
  const [previousOrder, setPreviousOrder] = useState(dimension[orderField])

  const dropRef = useRef(null)
  const dragRef = useRef(null)

  const reorderDisabled = allDimensions.length <= 1

  /**
   * Array of available orders
   * The current dimension is set to disabled so that we can't select it in the dropdown.
   * @type {{disabled: boolean, label: *, value: *}[]}
   */
  const availablePositions = useMemo(() => {
    return allDimensions.map((_dimension) => ({
      value: _dimension[orderField],
      label: _dimension[orderField].toString(),
      disabled: order === _dimension[orderField],
    }))
  }, [JSON.stringify(allDimensions)])

  const [{ isDragging }, drag, preview] = useDrag({
    type: itemType,
    item: {
      id: dimension[idField],
      index: order,
      originalSize: dropRef.current?.getBoundingClientRect(),
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    options: {
      dropEffect: 'move',
    },
    canDrag: () => !isViewMode || reorderDisabled,
  })

  /**
   * This ensures that whenever dimension order changes, the dimension moves to its original position and animations are inactive.
   */
  useEffect(() => {
    setAnimationsActive(false)
    let timeout
    if (previousOrder !== order) {
      timeout = setTimeout(() => {
        setAnimationsActive(true)
        setPreviousOrder(order)
      }, 500)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [order])

  preview(dropRef)
  drag(dragRef)

  const dragDropElement = () => (
    <div
      ref={dragRef}
      className={cx('ordered-items-section__dimensions__dimension__dnd', {
        'ordered-items-section__dimensions__dimension__dnd--disabled':
          reorderDisabled,
      })}
      style={{
        cursor: isDragging ? 'grabbing' : 'grab',
        width: '24px',
        maxWidth: '24px',
      }}
    >
      <Icon>
        <IconDragDrop />
      </Icon>
    </div>
  )

  const renderOrder = () => {
    return (
      <Dropdown
        defaultState={dimension[orderField]}
        disableSearch
        overwriteSelectedText={
          previousOrder !== order ? (
            <div className="rotation-transition">
              <span className="rotation-transition__before">
                {previousOrder}
              </span>
              <span className="rotation-transition__after">{order}</span>
            </div>
          ) : null
        }
        options={availablePositions}
        onChange={(value) => onReorder(dimension[orderField], value)}
        disabled={isViewMode || reorderDisabled}
      />
    )
  }

  return (
    <div
      ref={dropRef}
      className={cx('ordered-items-section__dimensions__dimension', {
        'ordered-items-section__dimensions__dimension--dragging': isDragging,
        'animations-disabled': !animationsActive,
      })}
    >
      <InputGroup
        options={[
          { render: dragDropElement(), width: 40, condition: !isViewMode },
          { render: renderOrder(), width: 84 },
          ...(getItemOptions?.(dimension) || []),
        ]}
      />
    </div>
  )
}

OrderedItem.propTypes = {
  dimension: PropTypes.object.isRequired,
  allDimensions: PropTypes.array.isRequired,
  onReorder: PropTypes.func.isRequired,
  orderField: PropTypes.string.isRequired,
  idField: PropTypes.string.isRequired,
  isViewMode: PropTypes.bool,
  itemType: PropTypes.string.isRequired,
  getItemOptions: PropTypes.func,
}

const OrderedItemDropTarget = ({
  dimensionBefore,
  dimensionAfter,
  itemType,
  onReorder,
  orderField,
}) => {
  const dropRef = useRef()

  const getCanDrop = (item) => {
    if (!item) {
      return false
    }
    return !(
      item.index === dimensionAfter?.[orderField] ||
      item.index === dimensionBefore?.[orderField]
    )
  }

  const [{ isHovered, itemDragging }, drop] = useDrop({
    accept: itemType,
    drop(item) {
      let itemToReplace = dimensionBefore

      if (dimensionAfter && item.index > dimensionAfter[orderField]) {
        itemToReplace = dimensionAfter
      }
      // When the drop is done, we actually make the changes
      onReorder(item.index, itemToReplace[orderField])
    },
    collect: (monitor) => {
      return {
        isHovered: monitor.isOver(),
        canDrop: monitor.canDrop(),
        itemDragging: monitor.getItem(),
      }
    },
    canDrop: getCanDrop,
  })

  const canDrop = useMemo(() => {
    return getCanDrop(itemDragging)
  }, [itemDragging])

  drop(dropRef)

  return (
    <div
      ref={dropRef}
      className={cx(
        'ordered-items-section__dimensions__dimension-target__overlay',
        {
          'ordered-items-section__dimensions__dimension-target__overlay--hovered':
            isHovered,
        }
      )}
    >
      <div
        className={cx('ordered-items-section__dimensions__dimension-target', {
          'ordered-items-section__dimensions__dimension-target--dragging':
            itemDragging && canDrop,
        })}
      ></div>
    </div>
  )
}
