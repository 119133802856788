import React, { useState, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useTooltip } from 'components/tooltip/index'

import './style.scss'

/**
 * Displays a container that changes content on hover.
 * @param {Object} params React Parameters
 * @param {Node} params.cover "Cover" to show. The initial content of the section.
 * @param {Node} params.children Content to show. Displayed on hovering the Cover.
 * @param {Node} [params.tooltip] Optional Tooltip to show when content is shown.
 * @param {Node} [params.disabled] If disabled is true, the content is not shown on hover or click.
 * @returns {Node}
 */
const HoverContainer = forwardRef(
  ({ cover, children, tooltip, disabled }, ref) => {
    const [open, setOpen] = useState(false)
    const [coverShown, setCoverShown] = useState(true)
    const [showTooltip, hideTooltip, Tooltip] = useTooltip(tooltip)
    const [coverWidth, setCoverWidth] = useState(0)
    const [contentWidth, setContentWidth] = useState(0)

    useImperativeHandle(ref, () => ({
      toggleOpen: (value) => {
        if (typeof value === 'undefined') {
          value = !open
        }
        value ? showContent() : hideContent()
      },
    }))

    const setInitialCoverWidth = (node) => {
      if (!node || coverWidth) {
        return
      }

      const { width } = node.getBoundingClientRect()
      if (width > 0) {
        setCoverWidth(width)
      }
    }

    const setInitialContentWidth = (node) => {
      if (!node || contentWidth) {
        return
      }

      const { width } = node.getBoundingClientRect()
      if (width > 0) {
        setContentWidth(width)
      }
    }

    const showContent = () => {
      if (tooltip) {
        showTooltip()
      }

      if (disabled) {
        return
      }
      setOpen(true)
      setCoverShown(false)
    }

    const hideContent = () => {
      setOpen(false)
      hideTooltip()
      setTimeout(() => {
        setCoverShown(true)
      }, 200)
    }

    return (
      <div
        className={cx('hover-container', {
          'hover-container--open': open,
        })}
        style={{ width: !open && coverShown ? coverWidth : contentWidth }}
        onMouseEnter={showContent}
        onMouseLeave={hideContent}
        ref={ref}
      >
        <Tooltip />
        {coverShown && (
          <div ref={setInitialCoverWidth} className="hover-container__cover">
            {cover}
          </div>
        )}
        <div
          style={
            contentWidth ? { width: open ? contentWidth : coverWidth } : {}
          }
          ref={setInitialContentWidth}
          className="hover-container__content"
        >
          {children}
        </div>
      </div>
    )
  }
)

HoverContainer.propTypes = {
  cover: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.node,
  disabled: PropTypes.bool,
}

export default HoverContainer
