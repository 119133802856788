import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import {
  useLocation,
  redirect,
  useSearchParams,
  useNavigate,
} from 'react-router-dom'

import useSession from 'modules/session'
import { setCurrentCompany, getCompanies } from 'modules/companies/actions'
import {
  getNotificationsLazy,
  showWarningMessage,
} from 'modules/notifications/actions'
import { getUsers } from 'modules/users/actions'
import { getLegalViewItems } from 'modules/legal/actions'
import { getGlobalAndCustomReports } from 'modules/global-reports/actions'
import {
  getTraqTemplates,
  getFavouriteTraQTemplates,
} from 'modules/traq-templates/actions'
import { pingServer } from 'modules/session/actions'
import { getEnvironment } from 'modules/global/actions'
import Header from 'components/header'
import LeftMenu from 'components/left-menu'
import Modal from 'components/modal'
import { useDebouncedEffect } from 'components/utils/custom-hooks'

import DataRefreshProvider from 'contexts/data-refresh/index'
import EditLockProvider from 'contexts/edit-lock/index'
import Loader from 'components/loader'

import { buildLoginRedirectUrl } from 'components/utils/url'

import { access, permissions, legal } from '@decision-sciences/qontrol-common'

import Notifications from '../notifications'
import { setGlobalLoading } from './actions'

const { hasAccess } = access
const { PERMISSIONS, PERMISSION_TYPES } = permissions
const { LEGAL_DISPLAY_AREA } = legal

function GlobalAppWrapper({ children, mainClass, dataCy }) {
  const [isLoggedIn, user] = useSession()
  const { state, dispatch } = useStore()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const mainRef = useRef()
  const {
    globalReports,
    companies: { currentCompany, list: companies },
    users: { list: users },
    notifications: {
      userNotifications: {
        elementsLeft,
        showCompanySpecificNotifications,
        data: userNotificationsData,
        nrNotificationsToLoad,
        company: notificationsLoadedForCompany,
        user: notificationsLoadedForUser,
        viewUnreadOnly,
        showAlertNotifications,
        showBriefNotifications,
      },
    },
    legal: { viewList },
    traqTemplates,
    modal,
    global: { loading, environment },
  } = state

  const [itemsLoading, setItemsLoading] = useState(false)

  const { viewList: globalAndCustomReportList } = globalReports || {}
  const location = useLocation()

  // Our very own custom implementation of ScrollRestore 😅
  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.scroll({ top: 0 })
    }
  }, [JSON.stringify(location.pathname)])

  const hasEmbarQAccess = hasAccess(user, [
    {
      feature: PERMISSIONS.ALERT_DATA_FORM,
      type: PERMISSION_TYPES.READ,
    },
    {
      feature: PERMISSIONS.NON_GLOBAL_TRAQ_TEMPLATES,
      type: PERMISSION_TYPES.READ,
    },
    {
      feature: PERMISSIONS.SPECIFIC_TRAQ_TEMPLATES,
      type: PERMISSION_TYPES.READ,
    },
  ])

  const hasUserListAccess = hasAccess(user, [
    { feature: PERMISSIONS.USERS_INDEX, type: PERMISSION_TYPES.READ },
    {
      feature: PERMISSIONS.ASSIGN_USERS_TEAMS_CLIENT,
      type: PERMISSION_TYPES.READ,
    },
    {
      feature: PERMISSIONS.ASSIGN_USERS_TEAMS_BUSINESS_UNIT,
      type: PERMISSION_TYPES.READ,
    },
    {
      feature: PERMISSIONS.ASSIGN_USERS_TEAMS_ACCOUNT,
      type: PERMISSION_TYPES.READ,
    },
  ])
  const hasCustomReportsAccess = hasAccess(user, [
    {
      feature: PERMISSIONS.REPORTS,
      type: PERMISSION_TYPES.READ,
    },
  ])

  /**
   * On App load, get all dependencies for logged in users
   * Things like Companies, Users that are used on almost all logged-in pages
   */
  useEffect(() => {
    if (isLoggedIn) {
      const promises = []
      if (!companies) {
        promises.push(
          getCompanies(dispatch, { deleted: false, populateReportings: true })
        )
      }
      if (!viewList) {
        promises.push(getLegalViewItems(dispatch, LEGAL_DISPLAY_AREA.SIDE_NAV))
      }

      // if (hasEmbarQAccess && !traqTemplates?.list) {
      //   promises.push(getTraqTemplates(dispatch))
      // }
      if (!traqTemplates?.userTraq) {
        promises.push(getFavouriteTraQTemplates(dispatch))
      }
      if (
        !globalAndCustomReportList &&
        currentCompany &&
        hasCustomReportsAccess
      ) {
        promises.push(
          getGlobalAndCustomReports({
            dispatch,
            companyId: currentCompany._id,
          })
        )
      }
      if (currentCompany && !users.length && hasUserListAccess) {
        promises.push(
          getUsers(dispatch, user.isSuperAdmin ? null : currentCompany._id)
        )
      }

      if (!Object.keys(environment).length) {
        promises.push(getEnvironment(dispatch))
      }

      if (promises.length) {
        setItemsLoading(true)
        Promise.allSettled(promises).then(() => {
          setItemsLoading(false)
        })
      }
    }
  }, [isLoggedIn, currentCompany, hasEmbarQAccess, hasUserListAccess])

  /**
   * If current company is not selected, set it based on user's companies
   * Also if the client ID changed in URL (new tab or link from mail), just update the
   * local storage and change current company
   * */
  useEffect(() => {
    const clientId = searchParams.get('cid')

    if (!isLoggedIn) {
      navigate(buildLoginRedirectUrl(), { replace: true })
    }

    if (isLoggedIn && companies) {
      /* Case 1: just after login */
      let companyChanged
      if (!currentCompany) {
        if (user.isSuperAdmin) {
          companyChanged = companies && companies[0]
        } else {
          companyChanged = companies.find(
            (c) => c._id === user.clients?.[0]?.clientId
          )
        }
        if (clientId) {
          companyChanged = companies.find((c) => c._id === clientId)
        }
      } else if (
        clientId &&
        currentCompany._id &&
        currentCompany._id !== clientId
      ) {
        /* Case 2: if client ID changed in URL search the user's companies by the new client ID */
        companyChanged = companies.find((c) => c._id === clientId)
        if (!companyChanged) {
          for (const comp of companies) {
            companyChanged = comp.businessUnits.find((b) => b._id === clientId)
            if (companyChanged) {
              break
            }
          }
        }
        if (companyChanged) {
          // show warning message if we changed the client through URL
          showWarningMessage(
            'The client has been switched automatically so you can view this page',
            dispatch
          )
        } else {
          // if the new client ID doesn't belong to any of users companies then
          // user tries to access a company that doesn't have access to
          navigate('/unauthorized', { replace: true })
        }
      } else {
        /* Case 3: check if the client has access to the current company (e.g. in case of emulate user) */
        if (
          !companies.find(
            (c) =>
              c._id === currentCompany._id ||
              c.businessUnits.some(({ _id }) => _id === currentCompany._id)
          )
        ) {
          companyChanged = companies[0]
        }
      }

      if (companyChanged) {
        dispatch(setCurrentCompany(companyChanged))
        searchParams.set('cid', companyChanged?._id)
        setSearchParams(searchParams, { replace: true, state: location.state })
      }
      setGlobalLoading(dispatch, false)
    }
  }, [isLoggedIn, companies])

  /**
   * On each location change check if we have client id set up in URL and if we don't add it
   *  */
  useEffect(() => {
    const clientId = searchParams.get('cid')
    if ((!location.search || !clientId) && currentCompany?._id) {
      searchParams.set('cid', currentCompany?._id)
      setSearchParams(searchParams, { replace: true, state: location.state })
    }
  }, [location.pathname, location.search])

  /**
   * Get all user notifications for logged-in User.
   * This needs to be debounced a bit because it's triggered too early on change client and sometimes causes an error on reading the response
   */
  // useDebouncedEffect(
  //   () => {
  //     /**
  //      * Only fetch notifications if:
  //      * - the user we have notifications for differs from the currently logged user
  //      * - or if the user is superadmin, we're showing company specific notifications and the selected company changed.
  //      */
  //     if (
  //       isLoggedIn &&
  //       environment?.NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE &&
  //       ((elementsLeft && !userNotificationsData?.length) ||
  //         (user.isSuperAdmin &&
  //           showCompanySpecificNotifications &&
  //           notificationsLoadedForCompany !== currentCompany?._id) ||
  //         notificationsLoadedForUser !== user._id)
  //     ) {
  //       getNotificationsLazy(
  //         dispatch,
  //         user._id,
  //         {
  //           selectedClient: showCompanySpecificNotifications
  //             ? currentCompany?._id
  //             : null,
  //           viewUnreadOnly,
  //           showAlertNotifications,
  //           showBriefNotifications,
  //         },
  //         parseInt(environment.NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE, 10),
  //         nrNotificationsToLoad
  //       )
  //     }
  //   },
  //   1000,
  //   [
  //     user?._id,
  //     currentCompany?._id,
  //     environment?.NR_ALERT_NOTIFICATIONS_TO_LOAD_AT_ONCE,
  //     nrNotificationsToLoad,
  //   ]
  // )

  /** On every route change, ping the server in case there are no calls made on the page */
  useEffect(() => {
    setTimeout(() => {
      pingServer()
    }, 500)
  }, [])

  if (loading || itemsLoading) {
    return <Loader />
  }

  return (
    <div className="app-wrapper">
      <DataRefreshProvider>
        <EditLockProvider>
          <Header />
          <Notifications />
          <div
            id="3q-app-container"
            className="app-container"
            style={{ width: '100%' }}
          >
            {isLoggedIn && <LeftMenu />}
            {/* If logged in, don't show content until a company is selected */}
            {isLoggedIn && !currentCompany ? null : (
              <main ref={mainRef} className={mainClass} data-cy={dataCy}>
                {children}
              </main>
            )}
            {Object.entries(modal)?.map(([key, value]) => (
              <Modal key={key} {...value} />
            ))}
          </div>
        </EditLockProvider>
      </DataRefreshProvider>
    </div>
  )
}

GlobalAppWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  mainClass: PropTypes.string,
  dataCy: PropTypes.string,
}

export default GlobalAppWrapper
