import React from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { entityStatus } from '@decision-sciences/qontrol-common'

import { useStore } from 'store'

/* Components */
import Table from 'components/table/beta/index'
import Icon from 'components/icon/index'
import TextWithOverflowTooltip from 'components/text-with-tooltip-on-overflow/index'

/* Icons */
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as RemoveIcon } from 'assets/icon_delete.svg'

/* Utils */
import { PAGE_MODE, PROPERTIES } from './utils'

const { NAME, STATUS, OWNER, FORMULA } = PROPERTIES
const { ENTITY_STATUS_LABEL, ENTITY_STATUS } = entityStatus

const CalculatedMetricsTable = ({
  calculatedMetrics = [],
  selectedCalculatedMetrics,
  setSelectedCalculatedMetrics,
  setCalculatedMetricsToDelete,
  disableActions,
  setMode,
  viewMode,
}) => {
  const { state } = useStore()
  const {
    users: { list: allUsers },
  } = state
  let columns = [
    {
      header: '',
      id: 'edit-calculated-metric',
      size: 40,
      maxSize: 40,
      textAlign: 'center',
      cell: (cell) => (
        <Icon
          disabled={disableActions}
          onClick={() => {
            if (!disableActions) {
              setMode(PAGE_MODE.EDIT)
              setSelectedCalculatedMetrics([cell.row.original])
            }
          }}
        >
          <EditIcon />
        </Icon>
      ),
    },
    {
      header: 'Name',
      id: 'calculated-metric-name',
      textAlign: 'left',
      accessorFn: (row) => row[NAME],
      size: 224,
    },
    {
      header: 'Formula',
      id: 'calculated-metric-formula',
      textAlign: 'left',
      cell: (cell) => (
        <TextWithOverflowTooltip text={cell.row.original[FORMULA]} />
      ),
      size: 224,
    },
    {
      header: 'Owner',
      id: 'calculated-metric-owner',
      textAlign: 'left',
      accessorFn: (row) => allUsers.find(({ _id }) => _id === row[OWNER])?.name,
      size: 144,
    },
    {
      header: 'Status',
      id: 'calculated-metric-status',
      accessorFn: (row) => ENTITY_STATUS_LABEL[row[STATUS]],
      textAlign: 'left',
      size: 56,
      maxSize: 80,
    },
    {
      header: 'Modified',
      id: 'report-modified',
      accessorKey: 'updatedAt',
      cell: (cell) => {
        const updatedAt = cell.row.original.updatedAt
        return updatedAt ? format(new Date(updatedAt), 'MM/dd/yyyy') : ''
      },
      tooltip: ({ updatedAt }) =>
        updatedAt ? format(new Date(updatedAt), 'HH:mm a') : '',
      textAlign: 'left',
      size: 80,
      maxSize: 120,
    },
    {
      header: '',
      id: 'delete-calculated-metric',
      textAlign: 'center',
      size: 40,
      maxSize: 40,
      cell: (cell) => {
        const disabled =
          disableActions || cell.row.original[STATUS] === ENTITY_STATUS.ACTIVE

        return (
          <Icon
            disabled={disabled}
            onClick={() =>
              !disabled && setCalculatedMetricsToDelete([cell.row.original])
            }
          >
            <RemoveIcon
              className={cx('fill-red', {
                disabled,
              })}
            />
          </Icon>
        )
      },
    },
  ]

  // Remove edit/delete if view mode
  if (viewMode) {
    columns = columns.filter(
      (column) => !['delete-report', 'edit-report'].includes(column.id)
    )
  }

  return (
    <>
      <Table
        id="calculated-metrics-table"
        columns={columns}
        data={
          disableActions
            ? calculatedMetrics.map((calculatedMetric) => ({
                ...calculatedMetric,
                disableSelect: true,
              }))
            : calculatedMetrics
        }
        onSelect={!viewMode ? setSelectedCalculatedMetrics : null}
        initialState={{
          autoResetSelectedRows: true,
          selectedRowIds: selectedCalculatedMetrics.map((c) => c._id || c.id),
        }}
      />
    </>
  )
}

export default CalculatedMetricsTable

CalculatedMetricsTable.propTypes = {
  calculatedMetrics: PropTypes.array.isRequired,
  selectedCalculatedMetrics: PropTypes.array.isRequired,
  setSelectedCalculatedMetrics: PropTypes.func.isRequired,
  setCalculatedMetricsToDelete: PropTypes.func.isRequired,
  disableActions: PropTypes.bool,
  setMode: PropTypes.func.isRequired,
  viewMode: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
}
