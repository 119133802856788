import {
  alert,
  alertThresholds,
  socket,
} from '@decision-sciences/qontrol-common'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useStore } from 'store'

// Hooks
import { useSocket } from 'components/utils/socket'

// Utils
import { initFetchingTriggerFrequency } from 'modules/alerts/alerts-thresholds-statistics/actions'
import { showErrorMessage } from 'modules/notifications/actions'

// Constants
import {
  MOCK_FREQUENCY_DATA,
  mapSimulationResults,
} from 'modules/alerts/alerts-thresholds-statistics/utils'

// Components
import { TriggerFrequencyLayout } from 'modules/alerts/alerts-thresholds-statistics/trigger-frequency/layout'

import '../style.scss'

const { NOTIFICATIONS } = socket

const TriggerFrequencyRealData = ({
  header,
  tooltipContent,
  alertTriggerId,
  accountId,
  selectedEntity,
  elementType,
  companyId,
}) => {
  const { dispatch } = useStore()

  const socketTriggerFrequency = useSocket({
    room: alertTriggerId,
  })

  const [triggerFrequencyLoading, setTriggerFrequencyLoading] = useState(false)
  const [estimatedTriggerFrequency, setEstimatedTriggerFrequency] = useState([
    ...MOCK_FREQUENCY_DATA,
  ])

  const getTriggerFrequency = () => {
    setTriggerFrequencyLoading(true)
    initFetchingTriggerFrequency({
      alertTriggerId,
      accountId,
      selectedEntity,
      elementType,
    })
  }

  const mapTriggerFrequencyResults = (results) =>
    MOCK_FREQUENCY_DATA.map(({ period }) => ({
      period,
      current:
        results.nrCurrentAlertsTriggeredWithOriginalThresholdValues[period],
      associated:
        results.nrAllAlertsTriggeredWithOriginalThresholdValues[period],
    }))

  useEffect(
    () => getTriggerFrequency(),
    [
      alert._id,
      companyId,
      JSON.stringify(alertThresholds),
      elementType,
      accountId,
      selectedEntity,
      alertTriggerId,
    ]
  )

  useEffect(
    () => {
      if (socketTriggerFrequency?.connected) {
        socketTriggerFrequency.on(
          NOTIFICATIONS.realTriggerFrequency.receive,
          ({ success, data }) => {
            if (!success) {
              setTriggerFrequencyLoading(false)

              showErrorMessage(
                `Error fetching Trigger frequency. Try refreshing the page or selecting a different ${elementType}`,
                dispatch
              )
            } else {
              setEstimatedTriggerFrequency([...mapSimulationResults(data)])
              setTriggerFrequencyLoading(false)
            }
          }
        )
      }

      return () => {
        socketTriggerFrequency?.removeAllListeners(
          NOTIFICATIONS.realTriggerFrequency.receive
        )
      }
    },
    [socketTriggerFrequency?.connected],
    elementType
  )

  return (
    <TriggerFrequencyLayout
      header={header}
      tooltipContent={tooltipContent}
      showLoadingOverlay={triggerFrequencyLoading}
      triggerFrequencyData={estimatedTriggerFrequency}
    />
  )
}

TriggerFrequencyRealData.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  alertTriggerId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  selectedEntity: PropTypes.string.isRequired,
  elementType: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
}

export { TriggerFrequencyRealData }
