import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import cx from 'classnames'

// Icons
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { ReactComponent as ArrowIcon } from 'assets/icon_arrow_downwards_with_tip_to_right.svg'

// Components
import ConfirmationModal from 'components/confirmation-modal'
import Table from 'components/table/beta'
import { Dropdown } from 'components/dropdown/index'
import { AccountIcon } from 'components/account-icon/index'

// Assets
import { ReactComponent as QontrolIcon } from 'assets/icon_qontrol.svg'

/**
 * Renders the conversion groupings table
 * @param {Object} props
 * @param {Boolean} props.isViewMode
 * @param {Array} props.conversionGroupings - Client conversion groupings
 * @param {Function} props.onDeleteClicked - Method to be called when the delete button is clicked
 * @param {Function} props.onEditClicked -  Method to be called when the edit button is clicked
 * @param {Boolean} props.disabledActions -  A flag that tells us when to disable table's actions
 * @param {Array} props.availablePriorities - Available priorities based on existing client conversion groupings
 * @param {Function} props.updatePriorities - Method to be called when priorities are changed from table dropdown
 */
const ConversionGroupingTable = ({
  isViewMode,
  conversionGroupings,
  availablePriorities,
  updatePriorities,
  onDeleteClicked,
  onEditClicked,
  disabledActions,
}) => {
  const [groupIdToBeDeleted, setGroupIdToBeDeleted] = useState('')

  const _renderExpandableContent = (row) => {
    const grouping = conversionGroupings.find(
      (group) => group._id === row.original._id
    )
    const columns = [
      {
        header: 'Publisher',
        textAlign: 'center',
        cellTextAlign: 'center',
        maxSize: 160,
        cell: (cell) => {
          if (cell.row.original.provider) {
            return <AccountIcon accountType={cell.row.original.provider} />
          } else {
            return <QontrolIcon />
          }
        },
      },
      {
        header: 'Conversion Event',
        textAlign: 'left',
        cellTextAlign: 'left',
        size: 20,
        cell: (cell) =>
          cell.row.original.conversion_name || cell.row.original.dimensionName,
      },
      {
        header: 'Conversion Id',
        textAlign: 'left',
        cellTextAlign: 'left',
        size: 20,
        cell: (cell) => cell.row.original.conversion_id,
      },
      {
        header: 'Account',
        textAlign: 'left',
        cellTextAlign: 'left',
        size: 20,
        cell: (cell) =>
          cell.row.original.externalAccountId ||
          `${cell.row.original.filename} | Offline`,
      },
    ]

    return (
      <div className="expandable-content-wrapper">
        <div className="expandable-content-wrapper__left-margin">
          <ArrowIcon className="expandable-content__icon expandable-content__icon--arrow" />
        </div>
        <Table columns={columns} data={grouping.kpis} />
      </div>
    )
  }

  const columns = [
    {
      header: '',
      id: 'edit-rule',
      accessorKey: 'edit-rule',
      size: 10,
      maxSize: 80,
      textAlign: 'center',
      cellTextAlign: 'center',
      cell: (cell) => {
        if (isViewMode) {
          return null
        }
        return (
          <EditIcon
            disabled={disabledActions}
            className={cx('cursor--pointer', {
              'disabled-exclusion-action': disabledActions,
            })}
            onClick={() => !disabledActions && onEditClicked(cell.row.original)}
          />
        )
      },
    },
    {
      id: 'priority',
      header: 'Priority',
      cell: ({ row }) => {
        return (
          <Dropdown
            options={availablePriorities}
            defaultState={row.original.priority}
            onChange={(priority) => updatePriorities(priority, row.original)}
            disabled={disabledActions || isViewMode}
            disableSearch
            className="priority-dropdown"
            optionsHeight={120}
          />
        )
      },
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 10,
      maxSize: 80,
    },
    {
      header: 'Name',
      id: 'name',
      accessorKey: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 80,
    },
    {
      header: 'Conversion Events',
      id: 'events',
      accessorFn: (row) => row.kpis && row.kpis.length,
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 30,
    },
    {
      id: 'modified',
      header: 'Modified',
      textAlign: 'center',
      accessorFn: (row) =>
        row.modifiedAt && format(new Date(row.modifiedAt), 'MM/dd/yyyy'),
      cellTextAlign: 'center',
      size: 30,
    },

    {
      header: '',
      accessorKey: 'delete-conversion',
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 10,
      maxSize: 70,
      cell: (cell) => {
        if (isViewMode) {
          return null
        }
        return (
          <DeleteIcon
            disabled={disabledActions}
            onClick={() => {
              !disabledActions && setGroupIdToBeDeleted(cell.row.original._id)
            }}
            className={cx('fill-red  cursor--pointer', {
              'disabled-exclusion-action': disabledActions,
            })}
          />
        )
      },
    },
  ]

  return (
    <>
      <Table
        id="conversion-groups-table"
        columns={columns}
        data={conversionGroupings}
        hasExpandableRows={true}
        renderExpandableContent={_renderExpandableContent}
        singleRowExpanded
        showPagination={false}
        showSearchInput={false}
      />

      <ConfirmationModal
        heading="Remove Conversion Group"
        message="You are about to remove a conversion group. Confirm if you want to continue
            with this action. This action cannot be undone."
        showModal={Boolean(groupIdToBeDeleted)}
        onCancelClicked={() => setGroupIdToBeDeleted('')}
        onConfirmClicked={() => {
          onDeleteClicked(groupIdToBeDeleted)
          setGroupIdToBeDeleted('')
        }}
      />
    </>
  )
}

export default ConversionGroupingTable

ConversionGroupingTable.propTypes = {
  isViewMode: PropTypes.bool.isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  disabledActions: PropTypes.bool.isRequired,
  conversionGroupings: PropTypes.array.isRequired,
  availablePriorities: PropTypes.array.isRequired,
  updatePriorities: PropTypes.func.isRequired,
}
