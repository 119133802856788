import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Input from 'components/input'
import '../style.scss'

const FilenameInput = ({
  allFilenames = [],
  error,
  onChangeFilename,
  setError,
  offlineDataFileName,
}) => {
  const [filename, setFilename] = useState(offlineDataFileName)
  const [isDuplicate, setIsDuplicate] = useState(false)

  const validate = (filename) => {
    if (!filename) {
      return
    }

    if (
      allFilenames.some(
        (name) =>
          name === filename.trim() &&
          (!offlineDataFileName ||
            filename.trim() !== offlineDataFileName.trim())
      )
    ) {
      setError('Duplicate core filename')
      setIsDuplicate(true)
    } else {
      setError(null)
      setIsDuplicate(false)
    }
  }

  const onChange = (filename) => {
    validate(filename)
    setFilename(filename)
    onChangeFilename(filename)
  }

  return (
    <section className="offline-data__filename">
      <Input
        label="Core Filename and Date Format"
        placeholder="Enter Filename{Date Format}"
        value={filename}
        onChange={onChange}
        onEnterKeyPressed={validate}
        showErrorInfoBlock
        blur={validate}
        error={isDuplicate ? 'Duplicate core filename' : error}
      />
    </section>
  )
}

FilenameInput.propTypes = {
  offlineDataFileName: PropTypes.string,
  allFilenames: PropTypes.array,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
  onChangeFilename: PropTypes.func.isRequired,
}
export default FilenameInput
