import { ReactComponent as PlusIcon } from 'assets/icon_add_circle.svg'
import { ReactComponent as RemoveIcon } from 'assets/icon_remove_circle_outline.svg'
import cx from 'classnames'
import Input from 'components/input/index'
import Table from 'components/table/beta'
import { useDebouncedEffect } from 'components/utils/custom-hooks'
import {
  editDimensionField,
  searchCategories,
} from 'modules/data-store-global/actions'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useStore } from 'store'

/**
 * Form which allows creating a Data Store dimension.
 * @param {Object} props Component props
 * @param {Boolean} props.isDirty Dirty flag
 * @param {Function} props.setDirty Setter for the dirty state
 * @param {Boolean} [props.disabled] Disabled flag
 * @returns {React.FunctionComponent}
 */
const DimensionCategoryLink = ({ isDirty, setDirty, disabled }) => {
  const { dispatch, state } = useStore()
  const {
    dataStoreGlobal: { editedDimension: dimension },
  } = state

  const [categories, setCategories] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  useDebouncedEffect(
    () => searchCategories(searchTerm).then(setCategories),
    300,
    [searchTerm]
  )

  const columns = [
    {
      header: '',
      id: 'add-remove',
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 5,
      maxSize: 50,
      cell: (cell) => {
        const { _id } = cell.row.original
        const isSelected = dimension.categories?.some(
          (categoryId) => categoryId === _id
        )
        return !isSelected ? (
          <PlusIcon
            data-cy="data-store-dimension-form-link-category"
            width={18}
            height={18}
            onClick={onCategoryLink(_id)}
            className={cx('cursor--pointer svg--blue', {
              'svg--disabled': disabled,
            })}
          />
        ) : (
          <RemoveIcon
            data-cy="data-store-dimension-form-unlink-category"
            width={20}
            height={20}
            onClick={onCategoryUnlink(_id)}
            className={cx('cursor--pointer', { 'svg--disabled': disabled })}
          />
        )
      },
    },
    {
      header: 'Name',
      id: 'name',
      accessorKey: 'name',
    },
  ]

  const columnsAssociated = [
    {
      header: 'Name',
      id: 'name',
      accessorKey: 'name',
    },
    {
      header: '',
      id: 'remove',
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 5,
      maxSize: 50,
      cell: (cell) => {
        const { id } = cell.row.original
        return (
          <RemoveIcon
            data-cy="data-store-dimension-form-remove-category"
            width={20}
            height={20}
            onClick={onCategoryUnlink(id)}
            className={cx('cursor--pointer', { 'svg--disabled': disabled })}
          />
        )
      },
    },
  ]

  const updateDimensionField = (fieldName) => (value) => {
    if (!isDirty) {
      setDirty(true)
    }
    editDimensionField(dispatch, { [fieldName]: value })
  }

  const onCategoryLink = (categoryId) => () => {
    const updatedCategories = dimension.categories?.length
      ? [...dimension.categories, categoryId]
      : [categoryId]
    updateDimensionField('categories')(updatedCategories)
  }

  const onCategoryUnlink = (categoryId) => () => {
    const updatedCategories = dimension.categories?.filter(
      (catId) => catId !== categoryId
    )
    updateDimensionField('categories')(updatedCategories)
  }

  return (
    <>
      <div className="section">
        <div className="align-row center space-between section__header">
          <h3 className="generic-heading generic-heading--no-margin">
            Available Categories
          </h3>
          <div className="w-50">
            <Input
              search
              placeholder="Search"
              onChange={setSearchTerm}
              className="width-50 data-store-global__search"
            />
          </div>
        </div>
        <div className="section__body">
          <Table
            className="table--compact"
            columns={columns}
            data={categories || []}
          />
        </div>
      </div>
      <div className="section">
        <div className="section__header">
          <h3 className="generic-heading generic-heading--no-margin">
            Associated Categories
          </h3>
        </div>
        {dimension.categories?.length > 0 && (
          <div className="section__body">
            <Table
              className="table--compact"
              columns={columnsAssociated}
              data={categories.filter((category) =>
                dimension.categories.includes(category.id)
              )}
            />
          </div>
        )}
      </div>
    </>
  )
}

DimensionCategoryLink.propTypes = {
  disabled: PropTypes.bool,
  isDirty: PropTypes.bool.isRequired,
  setDirty: PropTypes.func.isRequired,
}

export default DimensionCategoryLink
