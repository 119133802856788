import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'
import {
  utils,
  globalReports,
  platform,
} from '@decision-sciences/qontrol-common'

/* Store */
import { useStore } from 'store'

/* Components */
import Input from 'components/input'
import Button from 'components/button'
import { Dropdown } from 'components/dropdown'
import {
  InformationBlock,
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block'
import ViewEditFilters from 'components/custom-saved-filters/view-edit-filters'

/* Constants & Utils */
import {
  DISPLAY_DEFAULT_OPTIONS,
  EDIT_DEFAULT_LABELS,
  getTypeOptions,
  getDisplayedSections,
  getHighlightedSections,
} from 'modules/companies/subsections/reporting-views/utils.js'
import {
  ACCEPTED_FILTER_TYPES,
  DATE_FILTER_NAMES_ARRAY,
  getAllowableFiltersSheet,
} from 'components/custom-saved-filters/utils'
import {
  matchTaxonomyToDimension,
  removePillNamesFromDimension,
  getVisualsWithConfig,
  getMappedVisuals,
} from 'modules/global-reports/utils'
import {
  getFiltersForReport,
  updateCustomEntity,
  setDefaultFilter,
  removeDefaultFilter,
} from 'modules/global-reports/actions'
import { getPlatformSettings } from 'modules/platform-settings/actions'

/* Icons */
import { ReactComponent as SaveIcon } from 'assets/save.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'

const { equals } = utils.array
const {
  CUSTOM_FILTER_PERMISSIONS,
  PERMISSIONS_LABELS,
  SAVED_VIEW_TYPES_LABELS,
  SAVED_VIEW_TYPES,
} = globalReports
const { PLATFORM_SETTINGS_TYPES_MAP } = platform

/**
 * Component to edit reporting view
 * @returns {React.FunctionComponent}
 */
const ReportingViewsEdit = ({
  filter,
  user,
  setViewToEdit,
  setLoading,
  fetchFilters,
  namingConventions,
  setFilterToDelete,
}) => {
  const [editedFilter, setEditedFilter] = useState(cloneDeep(filter))
  const [reportDimensions, setReportDimensions] = useState([])
  const [friendlyVisualsNames, setFriendlyVisualsNames] = useState(null)
  const [widgets, setWidgets] = useState([])

  const [errors, setErrors] = useState({})
  const { dispatch } = useStore()
  const { type: initialType, templateId } = filter
  const {
    name,
    permission: { type: permissionType, client, creator },
    type,
    defaultForUsers,
    displayed,
    workbook,
    worksheet,
  } = editedFilter

  const isDefault = defaultForUsers.includes(user)
  const displayWarning =
    initialType === SAVED_VIEW_TYPES.VIEW && initialType !== type

  // On mount, fetch widget names from platform settings
  useEffect(() => {
    getPlatformSettings(PLATFORM_SETTINGS_TYPES_MAP.FRIENDLY_WIDGET_NAMES, true)
      .then(setFriendlyVisualsNames)
      .catch((error) => {
        console.error(error)
        // If something goes wrong, we just consider that there are none
        setFriendlyVisualsNames({})
      })
  }, [])

  // On mount, fetch report filters
  useEffect(() => {
    if (
      workbook &&
      worksheet &&
      client &&
      namingConventions &&
      friendlyVisualsNames
    ) {
      getFiltersForReport(workbook, worksheet, client).then((res) => {
        if (res.success) {
          const { filters } = res
          const matchedFilters = matchTaxonomyToDimension(
            filters,
            namingConventions
          )
          const formattedFilters = removePillNamesFromDimension(matchedFilters)
          const filtersToDisplay = formattedFilters?.filter(
            (fil) =>
              fil.type === ACCEPTED_FILTER_TYPES.CATEGORICAL &&
              getAllowableFiltersSheet(fil, worksheet) &&
              !DATE_FILTER_NAMES_ARRAY.includes(fil.name) &&
              !!fil.appliedValues?.length
          )
          filtersToDisplay && setReportDimensions(filtersToDisplay)

          let availableVisuals = getVisualsWithConfig(
            workbook,
            worksheet,
            filters,
            namingConventions
          )
          const friendlyNames = friendlyVisualsNames[templateId]?.[worksheet]
          if (friendlyNames) {
            availableVisuals = getMappedVisuals(availableVisuals, friendlyNames)
          }
          setWidgets(availableVisuals)
        }
      })
    }
  }, [workbook, worksheet, client, namingConventions, friendlyVisualsNames])

  const onChangeName = (newName) => {
    setEditedFilter({ ...editedFilter, name: newName })
    if (!newName) {
      setErrors({ ...errors, name: true })
    } else {
      const newErrors = { ...errors }
      delete newErrors.name
      setErrors(newErrors)
    }
  }

  const onChangeDefault = (newDefault) => {
    let newDefaultUsers = [...defaultForUsers]
    if (newDefault && !isDefault) {
      newDefaultUsers.push(user)
    }
    if (!newDefault && isDefault) {
      newDefaultUsers = newDefaultUsers.filter((us) => us !== user)
    }
    setEditedFilter({
      ...editedFilter,
      defaultForUsers: newDefaultUsers,
    })
  }

  const onSaveEntity = () => {
    if (!Object.keys(errors).length && !isEqual(editedFilter, filter)) {
      setLoading(true)
      const defaultHasChanges = !equals(
        editedFilter.defaultForUsers,
        filter.defaultForUsers
      )
      delete editedFilter.defaultForUsers
      updateCustomEntity(dispatch, editedFilter).then(async () => {
        if (defaultHasChanges) {
          if (isDefault) {
            await setDefaultFilter({
              entityId: editedFilter._id,
              entityType: editedFilter.type,
              userId: user,
            })
          } else {
            await removeDefaultFilter({
              entityId: editedFilter._id,
              userId: user,
            })
          }
        }
        setViewToEdit(null)
        fetchFilters(true)
      })
    }
  }

  const _renderEditViewDetails = () => (
    <div className="view-details">
      <div className="view-details__top display-flex">
        <Input
          label={'Reporting View Name'}
          value={name}
          onChange={onChangeName}
          error={errors.name}
        />
        <div className="display-flex">
          <Dropdown
            label={'Default filter set'}
            labelClassName={'general-label'}
            defaultState={isDefault}
            defaultOptionText={EDIT_DEFAULT_LABELS[isDefault]}
            options={Object.values(DISPLAY_DEFAULT_OPTIONS)}
            onChange={onChangeDefault}
          />
          <Dropdown
            label={'Display'}
            labelClassName={'general-label'}
            defaultState={displayed}
            defaultOptionText={EDIT_DEFAULT_LABELS[displayed]}
            options={Object.values(DISPLAY_DEFAULT_OPTIONS)}
            onChange={(display) =>
              setEditedFilter({ ...editedFilter, displayed: display })
            }
          />
        </div>
      </div>
      <div className="view-details__bottom display-flex">
        <Dropdown
          label={'Permissions'}
          labelClassName={'general-label'}
          value={permissionType}
          onChange={(permission) =>
            setEditedFilter({
              ...editedFilter,
              permission: { ...editedFilter.permission, type: permission },
            })
          }
          disabled={creator !== user}
          options={Object.values(CUSTOM_FILTER_PERMISSIONS)}
          defaultOptionText={PERMISSIONS_LABELS[permissionType]}
          defaultState={PERMISSIONS_LABELS[permissionType]}
        />
        <Dropdown
          label={'View Type'}
          labelClassName={'general-label'}
          value={type}
          onChange={(type) =>
            setEditedFilter({
              ...editedFilter,
              type: type,
            })
          }
          options={getTypeOptions(initialType)}
          defaultOptionText={SAVED_VIEW_TYPES_LABELS[type]}
          defaultState={SAVED_VIEW_TYPES_LABELS[type]}
          // TO DO Andra: remove this once functionality in case of view type changes is clearly defined
          disabled={true}
        />
      </div>
      {displayWarning ? (
        <InformationBlock
          type={INFORMATION_BLOCK_TYPE.WARNING}
          info={
            'Updating the View Type will remove the sections highlighted in red below when Save Changes is clicked'
          }
        />
      ) : null}
    </div>
  )

  const _renderSaveBar = () => (
    <div className="display-flex save-bar padding-20">
      <Button
        secondaryGreen
        compact
        disabled={isEqual(editedFilter, filter)}
        onClick={onSaveEntity}
        value={
          <div className="align-row">
            <SaveIcon width={15} height={15} />
            <div className="keyword-button-label margin-left-5">
              Save Changes
            </div>
          </div>
        }
      />
      <Button
        className="keyword-button-value margin-left-10"
        secondaryGray
        compact
        value={'Cancel'}
        onClick={() => setViewToEdit(null)}
      />
      <Button
        className="keyword-button-value margin-left-10 delete-filter-button"
        secondaryRed
        compact
        onClick={() => setFilterToDelete(filter)}
        value={
          <div className="align-row">
            <DeleteIcon width={15} height={15} />
            <div className="margin-left-5">Delete</div>
          </div>
        }
      />
    </div>
  )
  return (
    <div className="edit-reporting-view">
      {_renderEditViewDetails()}
      <ViewEditFilters
        highLightedSections={getHighlightedSections(type, initialType)}
        displayedSections={getDisplayedSections(type, initialType)}
        customEntity={editedFilter}
        setCustomEntity={setEditedFilter}
        dimensions={reportDimensions}
        widgets={widgets}
      />
      {_renderSaveBar()}
    </div>
  )
}

export default ReportingViewsEdit

ReportingViewsEdit.propTypes = {
  filter: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  setViewToEdit: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  fetchFilters: PropTypes.func.isRequired,
  namingConventions: PropTypes.object.isRequired,
  setFilterToDelete: PropTypes.func.isRequired,
}
