import React from 'react'
import PropTypes from 'prop-types'

import { CheckboxNoHooks } from 'components/checkbox'

import { ReactComponent as QontrolIcon } from 'assets/icon_qontrol.svg'

export const OfflineConversionDropdownRow = ({
  option,
  selectedItems = [],
  noCheckbox = false,
}) => (
  <div className="align-row account-dropdown">
    <CheckboxNoHooks
      icon={<QontrolIcon />}
      label={option.label}
      isChecked={selectedItems.indexOf(option.key) > -1}
      hint={
        <span data-testid="hint-element">
          ID: {option.conversion_id} | {option.filename} | Offline
        </span>
      }
      className={noCheckbox ? 'hide-checkbox' : ''}
    />
  </div>
)

OfflineConversionDropdownRow.propTypes = {
  option: PropTypes.object.isRequired,
  selectedItems: PropTypes.array,
  noCheckbox: PropTypes.bool,
}
