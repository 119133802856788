import Api from 'easy-fetch-api'

/**
 * Fetches global convention data
 * @returns {Promise<unknown>}
 */
export const getGlobalConventions = () =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/naming-conventions/global`,
    })
      .then((res) => {
        if (!res || res.error) {
          reject(res.error)
        } else {
          resolve(res.result)
        }
      })
      .catch(reject)
  })

export const setGlobalConventions = (globalConventions) =>
  new Promise((resolve, reject) => {
    Api.post({
      url: `/api/naming-conventions/global`,
      data: globalConventions,
    })
      .then((res) => {
        if (res.success) {
          return resolve(res.result)
        }
        reject('Something went wrong')
      })
      .catch(reject)
  })

/**
 * Fetches client naming convetions
 * If client does not have custom naming convetions, returns the global naming conventions
 * @returns {Promise<unknown>}
 */
export const getConventionsForClient = (clientId) =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/naming-conventions/client/${clientId}`,
    })
      .then((res) => {
        if (!res) {
          reject(
            `Unexpected error occured while fetching naming conventions for client ${clientId}.`
          )
        } else if (res.error) {
          reject(res.error)
        } else {
          resolve(res.result)
        }
      })
      .catch(reject)
  })
