import React, { useEffect, useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/* Hooks */
import { useStore } from 'store'
import useLeaveConfirm from 'components/leave-confirm'
import { useLoading } from 'components/utils/custom-hooks'

/* Components */
import { Dropdown } from 'components/dropdown'
import Icon from 'components/icon'
import InputText from 'components/input'
import StickyFooter from 'components/sticky-footer'
import Loader from 'components/loader'
import CollapsibleSection from 'components/collapsible-section'
import Section from 'components/section'
import InputWithConfirmation from 'components/input-with-confirmation'
import { OrderedItemSection } from 'components/ordered-item-section'
import Toggle from 'components/toggle'

/* Actions */
import {
  getPlatformSettings,
  savePlatformSettings,
} from 'modules/platform-settings/actions'
import { getCompanies } from 'modules/companies/actions'
import { filterGlobalReports } from 'modules/global-reports/actions'

/* Assets */
import { ReactComponent as DeleteIcon } from 'assets/icon_delete.svg'
import { ReactComponent as EditIcon } from 'assets/icon_edit.svg'

/* Constants */
import { platform, utils } from '@decision-sciences/qontrol-common'
import {
  VISUALS_MAPPING,
  getFriendlyNameKey,
} from 'modules/global-reports/utils'

/* Style ✨ */
import 'modules/platform-settings/global-settings.scss'

const { PLATFORM_SETTINGS_TYPES_MAP } = platform

const { mergeWithoutArrays } = utils.array

/**
 * Global Reports
 * Each key is a vertical
 * Each value is an array of report options
 * {
 *   [vertical]: [reportOption1, reportOption2, etc...]
 * }
 */
const GlobalReports = () => {
  const [verticalSelection, setVerticalSelection] = useState({})
  const [backupVerticalSelection, setBackupVerticalSelection] = useState({})
  const [verticalSectionErrors, setVerticalSectionErrors] = useState({})
  const verticalSectionRef = useRef()

  const [friendlyNames, setFriendlyNames] = useState({})
  const [backupFriendlyNames, setBackupFriendlyNames] = useState({})
  const friendlyNameRef = useRef()

  const [isLoading, toggleLoading, getIsLoading] = useLoading()

  const [setDirty, LeaveConfirm, isDirty] = useLeaveConfirm()

  const { dispatch } = useStore()

  /**
   * Save global reports callback
   */
  const onSave = () => {
    toggleLoading(PLATFORM_SETTINGS_TYPES_MAP.GLOBAL_REPORTS, true)
    savePlatformSettings(
      PLATFORM_SETTINGS_TYPES_MAP.GLOBAL_REPORTS,
      verticalSelection
    )
      .then((result) => {
        getCompanies(dispatch)
        setBackupVerticalSelection(result)
        setDirty(false)
        setVerticalSectionErrors({})
      })
      .catch(console.error)
      .finally(() => {
        toggleLoading(PLATFORM_SETTINGS_TYPES_MAP.GLOBAL_REPORTS, false)
      })

    toggleLoading(PLATFORM_SETTINGS_TYPES_MAP.FRIENDLY_WIDGET_NAMES, true)
    savePlatformSettings(
      PLATFORM_SETTINGS_TYPES_MAP.FRIENDLY_WIDGET_NAMES,
      friendlyNames
    )
      .then((result) => {
        setBackupFriendlyNames(result)
        setDirty(false)
      })
      .catch(console.error)
      .finally(() => {
        toggleLoading(PLATFORM_SETTINGS_TYPES_MAP.FRIENDLY_WIDGET_NAMES, false)
      })
  }

  // On cancel, revert to previously saved global reports settings and allow user to leave without confirming.
  const onCancel = () => {
    setDirty(false)
    setVerticalSelection(backupVerticalSelection)
    setFriendlyNames(backupFriendlyNames)
  }

  return (
    <section className="global-reports">
      <LeaveConfirm />

      <CollapsibleSection
        ref={verticalSectionRef}
        header="Vertical"
        defaultCollapsed={false}
        onCollapseListener={(value) => {
          if (value) {
            friendlyNameRef.current.onCollapse(value)
          }
        }}
      >
        <VerticalSelection
          data={verticalSelection}
          setData={setVerticalSelection}
          backup={backupVerticalSelection}
          setBackup={setBackupVerticalSelection}
          setDirty={setDirty}
          errors={verticalSectionErrors}
          setErrors={setVerticalSectionErrors}
          loading={getIsLoading(PLATFORM_SETTINGS_TYPES_MAP.GLOBAL_REPORTS)}
          setLoading={(value) =>
            toggleLoading(PLATFORM_SETTINGS_TYPES_MAP.GLOBAL_REPORTS, value)
          }
        />
      </CollapsibleSection>

      <CollapsibleSection
        ref={friendlyNameRef}
        header="Friendly Widget Names"
        defaultCollapsed={true}
        onCollapseListener={(value) => {
          if (value) {
            verticalSectionRef.current.onCollapse(value)
          }
        }}
      >
        <FriendlyWidgetNames
          data={friendlyNames}
          setData={setFriendlyNames}
          backup={backupFriendlyNames}
          setBackup={setBackupFriendlyNames}
          setDirty={setDirty}
          loading={getIsLoading(
            PLATFORM_SETTINGS_TYPES_MAP.FRIENDLY_WIDGET_NAMES
          )}
          setLoading={(value) =>
            toggleLoading(
              PLATFORM_SETTINGS_TYPES_MAP.FRIENDLY_WIDGET_NAMES,
              value
            )
          }
        />
      </CollapsibleSection>

      {isDirty && (
        <StickyFooter
          buttons={[
            {
              value: 'Save Changes',
              onClick: onSave,
              disabled: isLoading,
            },
            {
              value: 'Cancel',
              onClick: onCancel,
              secondaryGray: true,
            },
          ]}
        />
      )}
    </section>
  )
}

export default GlobalReports

const VerticalSelection = ({
  data,
  setData,
  setBackup,
  setDirty,
  errors,
  setErrors,
  loading,
  setLoading,
}) => {
  const [vertical, setVertical] = useState('')
  const [reportOption, setReportOption] = useState('')

  const [selectedVertical, setSelectedVertical] = useState('')

  const filteredGlobalReports = useMemo(() => {
    return Object.entries(data).reduce((prev, [key, value]) => {
      if (Array.isArray(value)) {
        return { ...prev, [key]: value }
      }
      return prev
    }, {})
  }, [JSON.stringify(data)])

  /**
   * On component mount, fetch all the global reports from global-settings
   */
  useEffect(() => {
    setLoading(true)
    getPlatformSettings(PLATFORM_SETTINGS_TYPES_MAP.GLOBAL_REPORTS, true)
      .then((result) => {
        setData(result)
        const firstEntry = Object.entries(result).find(([, value]) => {
          return Array.isArray(value)
        })
        if (firstEntry) {
          setSelectedVertical(firstEntry[0])
        }
        setBackup(result)
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <Loader />
  }

  /**
   * Callback for adding a new vertical, basically adds an empty array to the globalReports object with the *vertical from the input as a key.
   */
  const onAddVertical = () => {
    if (data[vertical]) {
      setErrors((errors) => ({
        ...errors,
        vertical: 'Vertical already exists',
      }))
      return
    }

    if (vertical.length <= 1) {
      setErrors((errors) => ({
        ...errors,
        vertical: 'Vertical name must be at least 1 character long.',
      }))
      return
    }

    if (!selectedVertical) {
      setSelectedVertical(vertical)
    }
    setData((reports) => ({ ...reports, [vertical]: [] }))
    setVertical('')
    setDirty(true)
  }

  /**
   * Callback for deleting a vertical.
   */
  const onRemoveVertical = (key) => {
    const newGlobalReports = { ...data }
    newGlobalReports[key] = null
    setData(newGlobalReports)

    if (selectedVertical === key) {
      const firstEntry = Object.entries(newGlobalReports).find(([, value]) =>
        Array.isArray(value)
      )
      if (firstEntry) {
        setSelectedVertical(firstEntry[0])
      } else {
        setSelectedVertical(null)
      }
    }

    setDirty(true)
  }

  /**
   * Adds a report option to the array (globalReports[vertical]).
   */
  const onAddReportOption = () => {
    if (data[selectedVertical].includes(reportOption)) {
      setErrors((errors) => ({
        ...errors,
        reportOption: 'Report Option already exists',
      }))
      return
    }

    if (reportOption.length < 1) {
      setErrors((errors) => ({
        ...errors,
        reportOption: 'Report Option name must be at least 1 character long.',
      }))
      return
    }

    const newGlobalReports = { ...data }
    newGlobalReports[selectedVertical].push(reportOption)
    setData(newGlobalReports)
    setReportOption('')
    setErrors({ ...errors, reportOptions: null })
    setDirty(true)
  }

  const hasReports = Object.keys(filteredGlobalReports).length > 0

  /**
   * Removes a report option from the array (globalReports[vertical]).
   */
  const onRemoveReportOption = (key) => {
    setData({
      ...data,
      [selectedVertical]: data[selectedVertical].filter((_key) => _key !== key),
    })
    setDirty(true)
  }

  return (
    <>
      <div data-cy="global-reports-vertical" className="form__section__body">
        <h3 className="generic-heading">Vertical</h3>
        <p className="global-reports__description">
          Add options to the Vertical dropdown seen on the Client and Business
          Unit forms.
        </p>

        <div className="global-settings__half-width">
          <InputText
            className="input-wrapper--uppercase"
            label="Add Vertical"
            onChange={(value) => {
              setVertical(value)
              setErrors((errors) => ({ ...errors, vertical: null }))
            }}
            value={vertical}
            placeholder="Enter Vertical Name"
            error={errors.vertical}
            onEnterKeyPressed={onAddVertical}
            suffix={
              <div
                role="button"
                tabIndex={0}
                onClick={vertical?.length ? onAddVertical : null}
                className={cx('list__button')}
              >
                <div className="list__button__svg" />
              </div>
            }
          />

          {Object.keys(filteredGlobalReports)?.length ? (
            <div className="daily-entity-cleanup__table margin-top-22">
              {Object.keys(filteredGlobalReports).map((key) => (
                <div className="daily-entity-cleanup__table__row" key={key}>
                  <span>{key}</span>
                  <Icon onClick={() => onRemoveVertical(key)}>
                    <DeleteIcon className="fill-red" />
                  </Icon>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      <div
        data-cy="global-reports-report-option"
        className="form__section__body"
      >
        <div className="global-reports__title">
          <h3 className="generic-heading">Report Option</h3>
          {!hasReports && (
            <p className="global-reports__description margin-0">
              Vertical options must be added to configure what is available for
              Report Options.
            </p>
          )}
        </div>

        {hasReports && (
          <>
            <p className="global-reports__description">
              Report Options are associated with a specific vertical. Select the
              Vertical to add/remove available options.
            </p>

            <div className="global-settings__half-width">
              <Dropdown
                className="input-wrapper--uppercase"
                defaultState={selectedVertical}
                options={Object.keys(filteredGlobalReports)}
                onChange={setSelectedVertical}
                error={errors.reportOptions}
                label="Vertical"
              />

              <InputText
                label="Add Report Option"
                onChange={(value) => {
                  setReportOption(value)
                  setErrors((errors) => ({
                    ...errors,
                    reportOption: null,
                    reportOptions: null,
                  }))
                }}
                value={reportOption}
                className="margin-top-10 input-wrapper--uppercase"
                placeholder="Enter Report Option Name"
                error={errors.reportOption}
                onEnterKeyPressed={onAddReportOption}
                suffix={
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={onAddReportOption}
                    className={cx('list__button')}
                  >
                    <div className="list__button__svg" />
                  </div>
                }
              />

              {data[selectedVertical]?.length ? (
                <div className="daily-entity-cleanup__table margin-top-22">
                  {data[selectedVertical].map((reportOption) => (
                    <div
                      className="daily-entity-cleanup__table__row"
                      key={reportOption}
                    >
                      <span>{reportOption}</span>
                      <Icon onClick={() => onRemoveReportOption(reportOption)}>
                        <DeleteIcon className="fill-red" />
                      </Icon>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </>
  )
}

const FriendlyWidgetNames = ({
  data,
  setData,
  setBackup,
  setDirty,
  loading,
  setLoading,
}) => {
  const [templatesLoading, setTemplatesLoading] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [editedMetrics, setEditedMetrics] = useState({})
  const [duplicates, setDuplicates] = useState({})

  const PLACEHOLDER_TEXT = 'Enter Friendly Widget Name'

  const {
    state: { globalReports },
    dispatch,
  } = useStore()

  /**
   * On component mount, fetch all the global reports from global-settings
   */
  useEffect(() => {
    setLoading(true)
    getPlatformSettings(PLATFORM_SETTINGS_TYPES_MAP.FRIENDLY_WIDGET_NAMES, true)
      .then((result) => {
        setData(result)
        setBackup(result)
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    setTemplatesLoading(true)
    filterGlobalReports(dispatch).finally(() => {
      setTemplatesLoading(false)
    })
  }, [])

  useEffect(() => {
    setEditedMetrics({})
    setDuplicates({})
  }, [selectedTemplate])

  const renderNameEdit = (sheet, metric) => {
    const sheetData = data[selectedTemplate]?.[sheet]
    const metricData = sheetData?.[metric.value]

    const hasNonParam = VISUALS_MAPPING[sheet].some(({ isParam }) => !isParam)

    const metricLabel =
      typeof metricData?.label !== 'undefined'
        ? metricData.label
        : metric.label || ''

    const metricKey = metric.value
    const sheetMetricKey = `${sheet}_${metricKey}`

    const onConfirm = () => {
      const newData = {
        [selectedTemplate]: {
          [sheet]: {
            [metricKey]: {
              ...metric,
              label: editedMetrics[sheetMetricKey],
            },
          },
        },
      }

      const hasDuplicates =
        editedMetrics[`${sheet}_${metricKey}`] &&
        VISUALS_MAPPING[sheet].some((metric) => {
          const { label } = metric
          const key = getFriendlyNameKey(metric, hasNonParam)
          if (key === metricKey) {
            return false
          }

          const labelValue =
            typeof sheetData?.[key] !== 'undefined'
              ? sheetData[key].label
              : label

          if (!labelValue) {
            return false
          }

          return labelValue === editedMetrics[sheetMetricKey]
        })

      if (hasDuplicates) {
        const newDuplicates = {
          [sheet]: {
            [metric.value]: true,
          },
        }
        setDuplicates(mergeWithoutArrays(duplicates, newDuplicates))
        return
      } else {
        setDuplicates({ ...duplicates, [sheet]: {} })
      }

      setDirty(true)

      setData(mergeWithoutArrays(data, newData))

      setEditedMetrics((editedMetrics) => ({
        ...editedMetrics,
        [sheetMetricKey]: null,
      }))
    }

    const onCancel = () => {
      const newData = {
        [selectedTemplate]: {
          [sheet]: {
            [metric.value]: {
              ...metric,
              label: null,
            },
          },
        },
      }

      setDirty(true)

      setData(mergeWithoutArrays(data, newData))

      setEditedMetrics((editedMetrics) => ({
        ...editedMetrics,
        [sheetMetricKey]: null,
      }))

      const newDuplicates = {
        [sheet]: {
          [metric.value]: false,
        },
      }

      setDuplicates(mergeWithoutArrays(duplicates, newDuplicates))
    }

    if (
      typeof editedMetrics[sheetMetricKey] !== 'undefined' &&
      editedMetrics[sheetMetricKey] !== null
    ) {
      return (
        <InputWithConfirmation
          defaultValue={metricLabel}
          error={duplicates[sheet]?.[metric.value]}
          placeholder={PLACEHOLDER_TEXT}
          onChange={(newValue) =>
            setEditedMetrics((editedMetrics) => ({
              ...editedMetrics,
              [sheetMetricKey]: newValue,
            }))
          }
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )
    }

    return (
      <label className="width-100 display-flex space-between">
        {metricLabel ? (
          <div>{metricLabel}</div>
        ) : (
          <div className="placeholder-text">{PLACEHOLDER_TEXT}</div>
        )}
        <Icon
          onClick={() =>
            setEditedMetrics((editedMetrics) => ({
              ...editedMetrics,
              [sheetMetricKey]: metricLabel || '',
            }))
          }
        >
          <EditIcon />
        </Icon>
      </label>
    )
  }

  const renderToggle = (sheet, metric) => {
    const metricData = data[selectedTemplate]?.[sheet]?.[metric.value]
    let toggleValue = metricData?.display

    if (typeof toggleValue === 'undefined') {
      toggleValue = true
    }

    const onChange = (value) => {
      const newData = {
        [selectedTemplate]: {
          [sheet]: {
            [metric.value]: {
              ...metric,
              ...metricData,
              display: value,
            },
          },
        },
      }

      if (!value) {
        const visuals = VISUALS_MAPPING[sheet]
        const initialOrder = metricData?.order || metric.order

        // Move items below toggled one one level above
        visuals.forEach((_metric, index) => {
          const _metricData = data[selectedTemplate]?.[sheet]?.[_metric.value]
          let newOrder = _metricData?.order || index + 1

          if (newOrder > initialOrder) {
            newOrder -= 1
          }
          newData[selectedTemplate][sheet][_metric.value] = {
            ..._metric,
            ...(_metricData || {}),
            order: newOrder,
          }
        })
        // Finally move the element to the final position
        newData[selectedTemplate][sheet][metric.value].order = visuals.length
      }

      setDirty(true)
      setData(mergeWithoutArrays(data, newData))
    }

    return (
      <Toggle
        label="Display"
        defaultChecked={toggleValue}
        onChange={onChange}
      />
    )
  }

  const onReorderMetrics = (sheet) => (metrics) => {
    const newData = {
      [selectedTemplate]: {
        [sheet]: metrics.reduce(
          (prev, current) => ({
            ...prev,
            [current.value]: {
              ...current,
              ...(data[selectedTemplate]?.[sheet]?.[current.value] || {}),
              order: current.order,
            },
          }),
          {}
        ),
      },
    }

    setDirty(true)
    setData(mergeWithoutArrays(data, newData))
  }

  const getMetricOptions = (sheet) => (metric) => {
    return [
      {
        render: <label>{metric.value}</label>,
      },
      {
        render: renderNameEdit(sheet, metric),
        error: duplicates[sheet]?.[metric.value],
      },
      {
        render: renderToggle(sheet, metric),
        width: 130,
      },
    ]
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <Section>
        <Dropdown
          className="global-settings__half-width"
          labelTop
          defaultOptionText="Select Template"
          label="Template"
          defaultState={selectedTemplate}
          onChange={setSelectedTemplate}
          options={globalReports.list?.map(({ _id, name }) => ({
            label: name,
            value: _id,
          }))}
          loading={templatesLoading}
        />
      </Section>
      {selectedTemplate ? (
        <>
          {Object.entries(VISUALS_MAPPING).map(([sheet, metrics]) => {
            const hasNonParam = metrics.some(({ isParam }) => !isParam)
            return (
              <Section key={sheet} header={sheet}>
                <OrderedItemSection
                  data={metrics.map((metric, index) => {
                    const metricData =
                      data[selectedTemplate]?.[sheet]?.[metric.value]
                    return {
                      ...metric,
                      originalValue: metric.value,
                      value: getFriendlyNameKey(metric, hasNonParam),
                      label: metricData?.label || metric.label,
                      order: metricData?.order || index + 1,
                    }
                  })}
                  setData={onReorderMetrics(sheet)}
                  orderField="order"
                  idField="value"
                  getItemOptions={getMetricOptions(sheet)}
                  error={
                    Object.values(duplicates[sheet] || {}).some((v) => v) &&
                    'Cannot have duplicate names within the same sheet'
                  }
                />
              </Section>
            )
          })}
        </>
      ) : null}
    </>
  )
}

VerticalSelection.propTypes = FriendlyWidgetNames.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  setBackup: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
}
