import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

/* Constants*/
import { mapOfflineConversionDimensionToKpiDropdownEntry } from 'modules/companies/subsections/conversion-grouping/utils'

/* Actions */
import {
  getClientConversionGroupings,
  deleteConversionGrouping,
  saveConversionGrouping,
  updateConversionGrouping,
} from 'modules/companies/actions'

/* Components */
import Button from 'components/button'
import Loader from 'components/loader'
import CollapsibleSection from 'components/collapsible-section'
import ConversionGroupingSettings from 'modules/companies/subsections/conversion-grouping/components/conversion-grouping-settings'
import ConversionGroupingTable from 'modules/companies/subsections/conversion-grouping/components/conversion-grouping-table'
import { metrics, offlineData } from '@decision-sciences/qontrol-common'
import { ReactComponent as LoaderIcon } from 'assets/icon_ring_loader.svg'
import { ReactComponent as PlusIcon } from 'assets/icon_plus_blue.svg'

import './style.scss'

const { mapKPIRowWithID } = metrics
const { OFFLINE_DATA_DIMENSION_TYPE } = offlineData

/**
 * Conversion Grouping section
 * @param {Object} props
 * @param {Boolean} props.isViewMode
 * @param {Object} props.currentCompany Current Edited company
 * @param {Array} [props.kpis = []] List of KPIs
 * @param {Number} [props.loadingKpis] Count of publishers for which the KPIs are still loading
 * @param {Array<Object>} props.offlineData Offline data entries
 */
const ConversionGroupingSection = ({
  isViewMode,
  currentCompany,
  kpis = [],
  loadingKpis,
  offlineData,
}) => {
  const [isPanelOpened, setIsPanelOpened] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const [currentGrouping, setCurrentGrouping] = useState({})
  const [conversionGroupings, setConversionGroupings] = useState([])

  const displayTable = conversionGroupings?.length

  const fetchGroupings = async () => {
    try {
      const results = await getClientConversionGroupings(currentCompany._id)
      return results
    } catch (e) {
      console.error(e)
      return []
    }
  }

  const updateGroupings = async () => {
    setLoading(true)
    await fetchGroupings()
      .then((res) => {
        if (res) {
          setConversionGroupings([...res])
        }
      })
      .finally(() => setLoading(false))
  }

  const specialKPIs = useMemo(() => {
    const offllineConversions = offlineData.reduce((acc, offlineDataEntry) => {
      const { filename, dimensions } = offlineDataEntry

      const conversionDimensions =
        dimensions?.filter(
          ({ dimensionType }) =>
            dimensionType === OFFLINE_DATA_DIMENSION_TYPE.conversion.key
        ) || []

      return [
        ...acc,
        ...conversionDimensions.map((dimension) =>
          mapOfflineConversionDimensionToKpiDropdownEntry(filename, dimension)
        ),
      ]
    }, [])

    return [...offllineConversions, ...kpis.map(mapKPIRowWithID)]
  }, [JSON.stringify(kpis), JSON.stringify(offlineData)])

  const [availablePriorities, existingPriorites, initialPriority] =
    useMemo(() => {
      let initialPriority = ''
      let availablePriorities = []
      let existingPriorites = []

      if (!conversionGroupings.length) {
        initialPriority = FIRST_PRIORITY
        availablePriorities = [initialPriority]
        existingPriorites = []
      } else {
        existingPriorites = conversionGroupings
          .map((gr) => gr.priority.toString())
          .sort((gr1, gr2) => gr1 > gr2)
        const lastPriority = Math.max(...existingPriorites)
        initialPriority = (lastPriority + 1).toString()
        availablePriorities = [
          ...conversionGroupings
            .map((gr) => gr.priority.toString())
            .sort((gr1, gr2) => gr1 > gr2),
          initialPriority,
        ]
      }
      return [availablePriorities, existingPriorites, initialPriority]
    }, [JSON.stringify(conversionGroupings)])

  const onSave = async (
    updatedGrouping = currentGrouping,
    onUpdatePriority = false
  ) => {
    setLoading(true)
    if (isEditMode || onUpdatePriority) {
      await updateConversionGrouping(updatedGrouping).then(async (res) => {
        if (res && res.success) {
          // Update groupings with newly updated grouping
          await updateGroupings()
          // Close panel
          onCancel()
        }
      })
    } else {
      await saveConversionGrouping(currentCompany._id, updatedGrouping).then(
        async (res) => {
          if (res && res.success) {
            // Update groupings with newly saved grouping
            await updateGroupings()
            // Close panel
            onCancel()
          }
        }
      )
    }

    setLoading(false)
  }

  const onDelete = async (conversionGroupId) => {
    setLoading(true)
    await deleteConversionGrouping(conversionGroupId).then(async (res) => {
      if (res.success) {
        await updateGroupings()
      }
    })
    setLoading(false)
  }

  const onCancel = () => {
    setCurrentGrouping({ ...defaultConversionGroup, priority: initialPriority })
    setIsPanelOpened(false)
    setErrors({})
    isEditMode && setIsEditMode(false)
  }

  const onEditClicked = (conversionGroup) => {
    setCurrentGrouping({
      ...conversionGroup,
      kpis: conversionGroup.kpis.map((kpi) =>
        kpi.account ? kpi._id : kpi.conversion_id
      ),
    })
    setIsPanelOpened(true)
    setIsEditMode(true)
  }

  const updatePriorites = (newPriority, grouping) =>
    onSave({ ...grouping, priority: newPriority }, true)

  // Fetch conversion groupings for client on mount
  useEffect(() => {
    updateGroupings()
  }, [])

  // Set current grouping on mount
  useEffect(() => {
    if (!Object.keys(currentGrouping).length) {
      setCurrentGrouping({
        ...defaultConversionGroup,
        companyId: currentCompany._id,
        priority: initialPriority,
      })
    }
  }, [])

  const _renderConversionGroupingSettings = () => {
    if (!isPanelOpened) {
      return <div></div>
    }

    if (loading) {
      return <Loader />
    }

    return (
      <div className="conversion-grouping-section">
        <ConversionGroupingSettings
          conversionGrouping={currentGrouping}
          availablePriorities={availablePriorities}
          setConversionGrouping={setCurrentGrouping}
          conversionGroupings={conversionGroupings}
          isEditMode={isEditMode}
          errors={errors}
          setErrors={setErrors}
          specialKPIs={specialKPIs}
          onCancel={onCancel}
          onSave={onSave}
          onDelete={onDelete}
          loading={loading}
        />
      </div>
    )
  }

  const _renderConversionGroupingTable = () => {
    if (!displayTable) {
      return <div></div>
    }

    if (loading) {
      return <Loader />
    }

    return (
      <div className="conversion-grouping-section margin-top-20">
        <ConversionGroupingTable
          isViewMode={isViewMode}
          conversionGroupings={conversionGroupings}
          availablePriorities={existingPriorites}
          updatePriorities={updatePriorites}
          onDeleteClicked={onDelete}
          onEditClicked={onEditClicked}
          disabledActions={isEditMode || isPanelOpened}
        />
      </div>
    )
  }
  const onAddGroup = () => {
    // Set current conversion group to default
    const group = { ...defaultConversionGroup, priority: initialPriority }
    group.companyId = currentCompany._id
    setCurrentGrouping(group)
    setIsPanelOpened(true)
  }
  const _renderAddButton = () => {
    if (isViewMode) {
      return null
    }

    return (
      <Button
        value={
          <div className="action-button">
            <PlusIcon />
            Add Conversion Group
          </div>
        }
        disabled={isPanelOpened || loading}
        onClick={onAddGroup}
        className="fixed-height"
        secondary
      />
    )
  }

  return (
    <CollapsibleSection
      id="conversion-groups-section"
      header="Conversion Groups"
      defaultCollapsed={isPanelOpened}
      onCollapseListener={(value) => setIsPanelOpened(!value)}
      extras={_renderAddButton()}
      wrapperClassName="form__section"
    >
      <>
        {loading ? <LoaderIcon /> : null}
        {_renderConversionGroupingSettings()}
        {_renderConversionGroupingTable()}
      </>
    </CollapsibleSection>
  )
}
export default ConversionGroupingSection

ConversionGroupingSection.propTypes = {
  isViewMode: PropTypes.bool,
  currentCompany: PropTypes.object.isRequired,
  kpis: PropTypes.array,
  loadingKpis: PropTypes.number,
  offlineData: PropTypes.array,
}

const defaultConversionGroup = {
  name: '',
  kpis: [],
}
const FIRST_PRIORITY = '1'
