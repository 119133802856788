import React, { useEffect, useState } from 'react'
import { useStore } from 'store'
import { useParams, redirect } from 'react-router-dom'

/* Hooks */
import useSession from 'modules/session'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'

/* Actions */
import {
  getAlertCategories,
  createUpdateAlert,
  fetchAlert,
} from 'modules/alerts/actions'
import { showErrorMessage } from 'modules/notifications/actions'
import { getAlertTemplates } from 'modules/alert-templates/actions'
import { getTeams } from 'modules/teams/actions'
import { getUsersWithPermissions } from 'modules/users/actions'

/* Components */
import Loader from 'components/loader/index'

/* Modules */
import AlertsForm from 'modules/alerts/create-edit'
import AlertsView from 'modules/alerts/view'

/* Utils */
import { getUserCompanies } from 'components/utils/user'

/* Constants */
import { NOT_FOUND_ROUTE } from 'routes'

const AlertPage = () => {
  const [, user] = useSession()
  const { dispatch, state } = useStore()
  const { categories: alertCategories } = state.alerts
  const { list: alertTemplates } = state.alertTemplates
  const { list: teams } = state.teams
  const { list: users } = state.users
  const { currentCompany } = state.companies
  const companyId = currentCompany ? currentCompany._id : null
  const { alertId, view } = useParams()
  const [alert, setAlert] = useState()
  const viewOnly = view === 'view'
  const editMode = alertId && alertId !== 'new'
  const [alertLoading, setAlertLoading] = useState(editMode)
  const [possibleOwners, setPossibleOwners] = useState(null)

  const userCompanies = getUserCompanies(user, state.companies.list)
  const allCompanies = userCompanies.map((company) => ({
    value: company._id,
    label: company.name,
    subsections: company.businessUnits.map((bu) => ({
      value: bu._id,
      label: bu.name,
      mimicParent: company.links?.alerts?.[bu._id],
    })),
  }))

  const hasAlertsAccess = useAccess({
    feature: PERMISSIONS.ALERTS_INDEX,
    type: PERMISSION_TYPES.READ,
  })
  const hasViewAccess = useAccess({
    feature: PERMISSIONS.ALERT_DATA_FORM,
    type: PERMISSION_TYPES.READ,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.ALERT_DATA_FORM,
    type: PERMISSION_TYPES.EDIT,
  })
  const hasCreateAccess = useAccess({
    feature: PERMISSIONS.ALERT_DATA_FORM,
    type: PERMISSION_TYPES.CREATE,
  })

  /* On component mount check if we have access */
  useEffect(() => {
    if (!hasViewAccess) {
      return redirect('/unauthorized')
    }
    if (!hasCreateAccess && !editMode) {
      return redirect('/unauthorized')
    }
  }, [])

  /** On Page mount, get tertiary data */
  useEffect(() => {
    if (!hasAlertsAccess) {
      redirect('/unauthorized')
    } else {
      if (!alertCategories) {
        getAlertCategories(dispatch)
      }
      if (!alertTemplates) {
        getAlertTemplates(dispatch)
      }
      if (!teams) {
        getTeams(dispatch, companyId)
      }
    }
  }, [])
  const isAdmin = user.isSuperAdmin

  useEffect(() => {
    if (editMode) {
      setAlertLoading(true)

      fetchAlert(alertId, user.isSuperAdmin ? null : companyId)
        .then((found) => {
          let notificationOptions = found.notificationOptions

          if (!hasViewAccess && found.required) {
            redirect('/unauthorized')
          }

          const isOwnAlert = isAdmin || found.owner._id === user._id
          // Non-admins only have access to edit alerts created by them
          if (!hasEditAccess || !isOwnAlert) {
            return redirect('/unauthorized')
          }

          // If Limited Admin is editing a global alert he can only edit notification options
          if (!isAdmin && found.isGlobal) {
            // First filter notification options only for the ones in his team
            notificationOptions = notificationOptions
              // item.entities holds teams
              .filter((item) =>
                user.teams.find((team) => item.entities.indexOf(team._id) > -1)
              )
              .map((item) => ({ ...item, readonly: true }))
          }

          // Filter alert companies based on all companies (some companies might have been deleted meanwhile)
          const alertCompanies = found.companies.filter((el) =>
            allCompanies.find(
              (item) =>
                item.value === el._id ||
                item.subsections.some((bu) => bu.value === el._id)
            )
          )

          setAlert({
            ...state,
            ...found,
            notificationOptions,
            companies: alertCompanies.map((el) => el._id),
            category: found.category._id,
            recurrence: found.recurrence,
            template: found.template ? found.template._id : '',
            apiCallsInProgress: {},
            owner: found.owner._id,
            ownerName: `${found.owner.firstName} ${found.owner.lastName}`,
          })
          setAlertLoading(false)
        })
        .catch((error) => {
          console.error(error)
          redirect(NOT_FOUND_ROUTE)
        })

      getUsersWithPermissions([
        {
          feature: PERMISSIONS.ALERT_DATA_FORM,
          type: PERMISSION_TYPES.CREATE,
        },
      ]).then((res) => res?.list && setPossibleOwners(res.list))
    }
  }, [alertId])

  // View Alert mode
  if (viewOnly) {
    return (
      <div className="page">
        <AlertsView alertId={alertId} companyId={companyId} />
      </div>
    )
  }

  if (!teams || !users || alertLoading) {
    return <Loader />
  }

  // List or Create-Edit mode
  return (
    <div className="page">
      {teams && users && (
        <AlertsForm
          user={user}
          alert={alert}
          allCompanies={allCompanies}
          alertCategories={alertCategories}
          alertTemplates={alertTemplates}
          saveAlert={(data) =>
            createUpdateAlert(dispatch, data, user.isSuperAdmin, companyId)
          }
          displayError={(msg) => showErrorMessage(msg, dispatch)}
          teams={teams}
          users={users}
          company={companyId}
          possibleOwners={possibleOwners}
        />
      )}
    </div>
  )
}

export default AlertPage
