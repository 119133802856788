import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/button'
import LineDivider from 'components/line-divider'

import { ReactComponent as IconSave } from 'assets/icon-save-white.svg'
import { ReactComponent as DeleteIcon } from 'assets/icon_delete_white.svg'

import './style.scss'

/**
 * Renders the create campaign exclusions actions bar
 * @param {Boolean} props
 * @param {Boolean} props.loading - Loading state to disable the actions
 * @param {Function} props.onSaveClicked - Method to be called when save button is clicked
 * @param {Function} props.onCancelClicked - Method to be called when cancel button is clicked
 */
const CreateActions = ({
  loading,
  saveDisabled,
  onSaveClicked = () => {},
  onCancelClicked = () => {},
  hasDelete = false,
  onDeleteClicked = () => {},
}) => {
  const _renderSaveButtonContent = () => (
    <div className="display-flex">
      <IconSave />
      <div className="campaign-exclusions-create-actions__save__label">
        SAVE
      </div>
    </div>
  )

  const _renderDeleteButtonContent = () => (
    <div className="display-flex">
      <DeleteIcon />
      <div className="campaign-exclusions-create-actions__delete__label">
        DELETE
      </div>
    </div>
  )

  return (
    <>
      <LineDivider className="margin-bottom-16" width="unset" widthUnit="" />
      <div className="campaign-exclusions-create-actions">
        <Button
          className="campaign-exclusions-create-actions__save"
          value={_renderSaveButtonContent()}
          disabled={loading || saveDisabled}
          onClick={onSaveClicked}
          green
        />
        <Button
          secondaryGray
          className="campaign-exclusions-create-actions__cancel"
          value="CANCEL"
          disabled={loading}
          onClick={onCancelClicked}
        />
        {hasDelete && (
          <Button
            className="campaign-exclusions-create-actions__delete"
            value={_renderDeleteButtonContent()}
            disabled={loading}
            onClick={onDeleteClicked}
            secondaryRed
          />
        )}
      </div>
    </>
  )
}

export default CreateActions

CreateActions.propTypes = {
  loading: PropTypes.bool,
  saveDisabled: PropTypes.bool,
  onSaveClicked: PropTypes.func,
  onCancelClicked: PropTypes.func,
  hasDelete: PropTypes.bool,
  onDeleteClicked: PropTypes.func,
}
