import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import { entityStatus, metrics, utils } from '@decision-sciences/qontrol-common'

import useSession from 'modules/session'

import InputText from 'components/input'
import { Dropdown } from 'components/dropdown/index'
import TemplateBuilder from 'components/expression-builder/index'
import LineDivider from 'components/line-divider/index'
import Tooltip from 'components/tooltip/index'
import { FORMULA_TOOLTIP } from 'components/utils/tooltip'

import CreateActions from 'modules/companies/subsections/campaign-exclusions-section/components/campaign-exclusions-create/create-actions/index'
import {
  GET_DEFAULT_CALCULATED_METRIC,
  PAGE_MODE,
  PROPERTIES,
  getCalcluatedMetricErrors,
} from 'modules/companies/subsections/calculated-metrics/utils'

import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'

const { NAME, STATUS, OWNER, FORMULA } = PROPERTIES

const { compareIgnoreCase, stringToID } = utils.string
const { ENTITY_STATUS_OPTIONS } = entityStatus
const { METRICS, SPECIAL_METRICS, isDynamicMetric, METRICS_BUDGET_PACING } =
  metrics

const allMetrics = [
  ...Object.keys(METRICS),
  ...Object.keys(SPECIAL_METRICS).filter((metric) => !isDynamicMetric(metric)),
  ...Object.keys(METRICS_BUDGET_PACING),
].sort(compareIgnoreCase)

const CalculatedMetricsAddEditSection = ({
  calculatedMetrics = [],
  allCalculatedMetrics,
  onSave,
  onCancel,
  users,
  mode,
  companyId,
}) => {
  const [, currentUser] = useSession()
  const singleCalculatedMetric = calculatedMetrics.length === 1
  const [errors, setErrors] = useState({})
  const [isFormulaValid, setIsFormulaValid] = useState(true)
  const [calculatedMetric, setCalculatedMetric] = useState(
    singleCalculatedMetric
      ? calculatedMetrics[0]
      : GET_DEFAULT_CALCULATED_METRIC(currentUser._id)
  )
  const [changeMade, setChangeMade] = useState({})
  const [showTooltip, setShowTooltip] = useState(false)

  const onChangeProperty = (property, value) => {
    setCalculatedMetric({ ...calculatedMetric, [property]: value })
    errors[property] && setErrors({ ...errors, [property]: false })
  }

  const onSaveClicked = () => {
    const shouldCheckForErrors =
      mode === PAGE_MODE.ADD ||
      (mode === PAGE_MODE.EDIT && singleCalculatedMetric)
    if (shouldCheckForErrors) {
      // Single Edit/Add mode
      const calcluatedMetricErrors = getCalcluatedMetricErrors(
        calculatedMetric,
        allCalculatedMetrics,
        isFormulaValid
      )

      const isValidCalcluatedMetric = !Object.values(
        calcluatedMetricErrors
      ).filter((r) => r).length
      if (isValidCalcluatedMetric) {
        onSave({
          ...calculatedMetric,
          key: stringToID(calculatedMetric[NAME]),
          company: companyId,
          id: calculatedMetric._id || uuidv4(),
        })
        setChangeMade({})
      } else {
        setErrors(calcluatedMetricErrors)
      }
    } else {
      // Bulk Edit mode
      delete calculatedMetric[NAME]
      delete calculatedMetric[FORMULA]
      onSave(calculatedMetric)
      setChangeMade({})
    }
  }

  return (
    <>
      <div className="calculated-metrics-edit-section">
        <div className="calculated-metrics-edit-section__column">
          {singleCalculatedMetric && (
            <>
              <div
                className={cx('general-label', {
                  'general-label--error': errors[NAME],
                })}
              >
                Calculated Metric Name
              </div>
              <InputText
                error={!!errors[NAME]}
                value={calculatedMetric[NAME]}
                placeholder={singleCalculatedMetric ? 'Enter Name' : '<varies>'}
                onChange={(val) => {
                  onChangeProperty(NAME, val)
                  setChangeMade({ ...changeMade, [NAME]: true })
                }}
                className={'width-50'}
              />
            </>
          )}
          <div className="general-label">OWNER</div>
          <Dropdown
            options={users.map(({ name, _id, email }) => ({
              label: name,
              value: _id,
              description: email,
            }))}
            defaultOptionText={
              singleCalculatedMetric ? 'Select User' : '<varies>'
            }
            defaultState={
              singleCalculatedMetric || changeMade[OWNER]
                ? calculatedMetric[OWNER]
                : undefined
            }
            onChange={(val) => {
              onChangeProperty(OWNER, val)
              setChangeMade({ ...changeMade, [OWNER]: true })
            }}
            sortOptions
            optionsHeight={500}
            disabled={mode === PAGE_MODE.ADD || !currentUser.isSuperAdmin}
          />
        </div>

        <div className="calculated-metrics-edit-section__column">
          <div className="general-label">STATUS</div>
          <Dropdown
            options={ENTITY_STATUS_OPTIONS}
            defaultOptionText={
              singleCalculatedMetric ? 'Select Status' : '<varies>'
            }
            defaultState={
              singleCalculatedMetric || changeMade[STATUS]
                ? calculatedMetric[STATUS]
                : undefined
            }
            onChange={(val) => {
              onChangeProperty(STATUS, val)
              setChangeMade({ ...changeMade, [STATUS]: true })
            }}
            sortOptions
            optionsHeight={500}
          />
        </div>
      </div>
      {singleCalculatedMetric && (
        <>
          <LineDivider />
          <div className="calculated-metrics-edit-section">
            <div className="calculated-metrics-edit-section__column">
              <div
                className={cx('general-label', {
                  'general-label--error': errors[FORMULA],
                })}
              >
                Formula
                <InfoIcon
                  className="calculated-metrics__info fill-light-blue"
                  alt={'info'}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                />
              </div>
              <Tooltip content={FORMULA_TOOLTIP} show={showTooltip} />
              <TemplateBuilder
                defaultValue={calculatedMetric[FORMULA]}
                metrics={allMetrics}
                onChange={(isValid, val) => {
                  setIsFormulaValid(isValid)
                  onChangeProperty(FORMULA, val)
                  setChangeMade({ ...changeMade, [FORMULA]: true })
                }}
                error={errors[FORMULA]}
                validationPrefix="Formula"
                validationPlaceholder="Formula Validation"
              />
            </div>
          </div>
        </>
      )}
      <CreateActions
        editMode={mode === PAGE_MODE.EDIT}
        saveDisabled={!Object.values(changeMade).length}
        onSaveClicked={onSaveClicked}
        onCancelClicked={onCancel}
        lineDivider={false}
        className={'borders-top-bottom'}
      />
    </>
  )
}
export default CalculatedMetricsAddEditSection

CalculatedMetricsAddEditSection.propTypes = {
  calculatedMetrics: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  allCalculatedMetrics: PropTypes.array.isRequired,
}
