const configTimeString = {
  minutes: 0,
  hours: 1,
  days: 2,
  weeks: 3,
  months: 4,
}
/**
 * Takes 1 value of type string (ex: 'every 40 days')
 * and returns an object {amount: number, type: string}(ex: {amount: 40, type: 'days'})
 * @param a {String}
 */
const objectTime = (timeString) => {
  // Regex looks for the number (amount) then for the string following the number (type)
  const reg =
    /((?:[a-zA-Z]?)+)(?:\ )?(\d+)\ ((?:[a-zA-Z]?)+)(?:\ )?((?:[a-zA-Z]?)+)/
  const amount =
    timeString.match(reg) !== null ? parseInt(timeString.match(reg)[2]) : 0
  const type =
    timeString.match(reg) !== null ? timeString.match(reg)[3] : 'minutes'
  return {
    amount: amount,
    type: type,
  }
}
/**
 * Compare 2 time values that will be of type {amount: number, type: string}
 * @param a {String|''}
 * @param b {String|''}
 */
export const sortTimeStrings = (a, b) => {
  const time1 = a ? objectTime(a) : ''
  const time2 = b ? objectTime(b) : ''

  if (time1 === '' && time2 !== '') {
    return -1
  }
  if (time1 !== '' && time2 === '') {
    return 1
  }
  if (time1.type !== time2.type) {
    return configTimeString[time1.type] > configTimeString[time2.type] ? 1 : -1
  } else {
    return time1.amount >= time2.amount ? 1 : -1
  }
}
/**
 * Compare 2 values that are either 1 or 0.
 * Used for sorting active/inactive, on/off
 * @param a {1|0}
 * @param b {1|0}
 */
export const sortBoolean = (a, b) => {
  const value1 = a ? a : 0
  const value2 = b ? b : 0

  return value1 >= value2 ? -1 : 1
}
/**
 * Compare 2 number values.
 * @param a {String}
 * @param b {String}
 */
export const sortNumber = (a, b) => {
  // replace(',','') to turn numbers of type 2,000 into 2000
  const value1 =
    a === null
      ? parseFloat('')
      : a && isNaN(a)
      ? parseFloat(a.replace(',', ''))
      : parseFloat(a)
  const value2 =
    b === null
      ? parseFloat('')
      : b && isNaN(b)
      ? parseFloat(b.replace(',', ''))
      : parseFloat(b)

  if (isNaN(value1) && !isNaN(value2)) {
    return 1
  }
  if (!isNaN(value1) && isNaN(value2)) {
    return -1
  }
  if (isNaN(value1) && isNaN(value2)) {
    return 0
  }
  if (value1 === value2) {
    return 0
  }
  return value1 > value2 ? 1 : -1
}
/**
 * Compare 2 number values that are inside tags.
 * @param a {String|''}
 * @param b {String|''}
 */
export const sortNumberInTag = (a, b, desc) => {
  /** Value returned by the null checking function in case neither one of the values is null. It’s just a random choice that’s neither 1, 0 or -1 */
  const KEEP_GOING = 5

  /**
   * Check if one of the two compared values is 'null' and handle each case.
   * @returns {Number} If the returned value is [KEEP_GOING], the comparison is further handled; otherwise the comparison stops and the decided value is returned
   */
  const nullChecks = () => {
    if (a === null && b !== null) {
      return desc ? -1 : 1
    }
    if (a !== null && b === null) {
      return desc ? 1 : -1
    }
    if (a === null && b === null) {
      return 0
    }

    return KEEP_GOING
  }

  let checkForNullValues = nullChecks()
  if (checkForNullValues !== KEEP_GOING) {
    return checkForNullValues
  }

  // Extract the numeric value from the field
  const getValue = (raw) => {
    if (raw?.props?.children) {
      const { children } = raw?.props
      if (!Array.isArray(children)) {
        if (isNaN(children)) {
          return parseFloat(children.replace(',', ''))
        } else {
          return parseFloat(children)
        }
      } else {
        /** When editing values in the table the original value is present before the edit field, so that the already applied sorting stays the same */
        if (children?.[0]?.props?.children) {
          if (isNaN(children[0].props.children)) {
            return parseFloat(children[0].props.children.replace(',', ''))
          } else {
            return parseFloat(children[0].props.children)
          }
        } else {
          return null
        }
      }
    }
  }

  const value1 = getValue(a)
  const value2 = getValue(b)

  checkForNullValues = nullChecks()
  if (checkForNullValues !== KEEP_GOING) {
    return checkForNullValues
  }

  if (isNaN(value1) && !isNaN(value2)) {
    return 1
  }
  if (!isNaN(value1) && isNaN(value2)) {
    return -1
  }
  if (isNaN(value1) && isNaN(value2)) {
    return 0
  }
  if (value1 === value2) {
    return 0
  }
  return value1 > value2 ? 1 : -1
}
/**
 * Compare 2 date values.
 * @param a {String}
 * @param b {String}
 */
export const sortDate = (a, b) => {
  const date1 = new Date(a)
  const date2 = new Date(b)
  if (date1 === date2) {
    return 0
  }
  return date1 > date2 ? -1 : 1
}

/**
 * Removes duplicated values from a string.
 * @param str {String}
 */
export const getUniqueElements = (str) => {
  const arr = str.split(',').map((el) => el.trim())
  return [...new Set(arr)]
}
