import { Helmet } from 'react-helmet'

/* Components */
import DataStoreCategories from 'modules/data-store-global/components/categories'
import DataStoreDimensions from 'modules/data-store-global/components/dimensions'

import '../../data-store-global/style.scss'

/**
 * Global management module for the data store feature
 * @returns {React.FunctionComponent}
 */
const DataStoreGlobal = () => {
  return (
    <div className="align-column data-store-global">
      <Helmet>
        <title>Data Store | Global Settings</title>
      </Helmet>
      <DataStoreCategories />
      <DataStoreDimensions />
    </div>
  )
}

export default DataStoreGlobal
