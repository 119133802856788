import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { v4 as uuidv4 } from 'uuid'

import Icon from 'components/icon'
import Button from 'components/button'
import Modal from 'components/modal'
import WarningIcon from 'components/warning-icon'
import Toggle from 'components/toggle'

import DimensionsList from 'modules/naming-conventions/components/dimensions-list'
import { compareDimensionsOrder } from 'modules/naming-conventions/utils'
import NamingConventionsPreview from 'modules/naming-conventions/components/preview'

import { ReactComponent as ResetIcon } from 'assets/icon_reset.svg'
import { ReactComponent as AddIcon } from 'assets/icon_add_circle.svg'

import '../style.scss'

const DESCRIPTION_DEFAULT =
  'Place the dimensions in the order you want. Use the Free-Form toggle to have the ability to enter text into a field for that dimension.'

/**
 * Naming Dimensions Render
 * Displays a naming conventions section.
 * @param {Object} params React Params
 * @param {String} [params.className] Extra classname to give to the section
 * @param {String} params.title Section title
 * @param {String} [params.description] Section description (has default)
 * @param {Array} [params.defaultDimensions] Default Dimensions array
 * @param {Array} params.dimensions Dimensions
 * @param {Function} params.setDimensions Dimensions setter
 * @param {String} params.delimiter Delimiter to be shown
 * @param {Boolean} params.isGlobal Used at global level
 * @param {Boolean} params.isViewMode Read only (no CTAs)
 * @param {Boolean} params.isCustom Whether the NamingDimensions are custom or not
 * @param {Function} params.setIsCustom Setter for isCustom, required only when isGlobal is FALSE
 */
const NamingDimensions = ({
  className,
  title,
  description = DESCRIPTION_DEFAULT,
  defaultDimensions,
  dimensions = [],
  allDimensionNames = [],
  setDimensions,
  delimiter,
  isGlobal,
  isViewMode,
  isCustom,
  setIsCustom,
}) => {
  const [resetDefaultModal, setResetDefaultModal] = useState(false)
  const [customDimensionsModal, setCustomDimensionsModal] = useState(false)
  const unifiedDimensions = useMemo(() => {
    const dimensionsArray = dimensions || defaultDimensions
    return dimensionsArray.sort(compareDimensionsOrder)
  }, [defaultDimensions, JSON.stringify(dimensions)])

  const resetToDefault = () => {
    setResetDefaultModal(true)
  }

  const onAddDimension = () => {
    // returns a copy number that's not taken. Ensures that there are no duplicate names generated by adding a dimension.
    const getCopyNumber = () => {
      const takenNumbers = []

      // We're chucking all copy numbers into an array Eg. "Copy1" and "Copy3" gets us [1, 3]
      allDimensionNames.forEach((dimensionName) => {
        if (dimensionName.match(new RegExp(`^(Custom*[0123456789]*$)+`))) {
          takenNumbers.push(parseInt(dimensionName.split('Custom')[1]))
        }
      })

      // We're going through the sorted list of numbers, if we find an "unoccupied" spot, we return it as our copy number.
      // Eg: [1,2,4,5] returns 3
      let previousNumber = 0

      takenNumbers
        .sort((a, b) => (a > b ? 1 : -1))
        .forEach((number) => {
          if (previousNumber + 1 !== number) {
            return previousNumber + 1
          }
          previousNumber = number
        })

      return previousNumber + 1
    }

    const newDimension = {
      required: false,
      freeForm: true,
      dimensionId: uuidv4(),
      dimensionOrder: dimensions.length + 1,
      dimensionName: `Custom${getCopyNumber()}`,
      isNew: true,
    }

    setDimensions([...dimensions, newDimension])
  }

  return (
    <section className={cx('naming-conventions', { [className]: className })}>
      <Modal
        className="naming-conventions__modal"
        icon={<WarningIcon />}
        rightAlignButtons
        opened={!!resetDefaultModal}
        contentSeparator
        button={
          <Button
            value="Confirm"
            green
            onClick={() => {
              setIsCustom(false)
              setResetDefaultModal(false)
            }}
          />
        }
        buttonSecondary={
          <Button
            value={'Cancel'}
            onClick={() => setResetDefaultModal(false)}
            secondaryGray
          />
        }
        heading="Reset Naming Convention to Default"
      >
        <div>
          You are about to reset the naming convention to default. Confirm if
          you want to continue with this action. This action cannot be undone.
        </div>
      </Modal>
      <Modal
        className="naming-conventions__modal"
        icon={<WarningIcon />}
        rightAlignButtons
        opened={!!customDimensionsModal}
        contentSeparator
        button={
          <Button
            value="Confirm"
            green
            onClick={() => {
              setIsCustom(true)
              setCustomDimensionsModal(false)
            }}
          />
        }
        buttonSecondary={
          <Button
            value={'Cancel'}
            onClick={() => setCustomDimensionsModal(false)}
            secondaryGray
          />
        }
        heading="Customize Naming Convention Settings"
      >
        <div>
          Turning this option on will allow you to customize the naming
          convention settings. Confirm if you want to continue with this action.
        </div>
      </Modal>
      <div className="naming-conventions__heading">
        <h3 className="naming-conventions__heading__title">{title}</h3>
        <div className="naming-conventions__heading__group">
          {!isViewMode && (
            <Icon
              onClick={resetToDefault}
              className="naming-conventions__heading__reset"
              disabled={!isCustom}
            >
              <ResetIcon width={18} height={18} className="svg--red" />
              Reset To Default
            </Icon>
          )}
          {!isGlobal && (
            <Toggle
              className="naming-conventions__heading__toggle"
              defaultChecked={isCustom}
              disabled={isCustom || isViewMode}
              onChange={(value) => {
                if (value) {
                  setCustomDimensionsModal(true)
                } else {
                  setIsCustom(value)
                }
              }}
              manualToggle
              label="Custom Naming"
            />
          )}
        </div>
      </div>
      <div className="naming-conventions__description">{description}</div>
      <DimensionsList
        dimensions={unifiedDimensions}
        setDimensions={setDimensions}
        isGlobal={isGlobal}
        isViewMode={isViewMode}
        disabled={!isCustom}
      />
      {!isViewMode && (
        <div className="naming-conventions__add">
          <Button
            disabled={!isCustom}
            onClick={onAddDimension}
            value={
              <Icon>
                <AddIcon /> Add Custom Dimension
              </Icon>
            }
          />
        </div>
      )}
      <NamingConventionsPreview
        delimiter={delimiter}
        dimensions={unifiedDimensions}
      />
    </section>
  )
}

NamingDimensions.propTypes = {
  dimensions: PropTypes.array.isRequired,
  setDimensions: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  defaultDimensions: PropTypes.array,
  delimiter: PropTypes.string,
  isGlobal: PropTypes.bool,
  isViewMode: PropTypes.bool,
  allDimensionNames: PropTypes.array,
  isCustom: PropTypes.bool,
  setIsCustom: PropTypes.func.isRequired,
}

export default NamingDimensions
