import Api from 'easy-fetch-api'

/**
 * Fetches global offline data configuration
 * @returns {Promise<unknown>}
 */
export const getGlobalOfflineData = () =>
  new Promise((resolve, reject) => {
    Api.get({
      url: `/api/offline-data/global`,
    })
      .then((res) => {
        if (!res || res.error) {
          reject(res.error)
        } else {
          resolve(res.result)
        }
      })
      .catch(reject)
  })

export const setGlobalOfflineData = (globalConventions) =>
  new Promise((resolve, reject) => {
    Api.post({
      url: `/api/offline-data/global`,
      data: globalConventions,
    })
      .then((res) => {
        if (res.success) {
          return resolve(res.result)
        }
        reject('Something went wrong')
      })
      .catch(reject)
  })

export const getAllOfflineDataByCompanyId = async (companyId) => {
  const result = await Api.get({
    url: `/api/offline-data/all/${companyId}`,
    headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error getting offline data for company id: ${companyId}`
    )
  }

  return result
}

export const saveOfflineData = async (companyId, offlineData) => {
  const result = await Api.post({
    url: `/api/offline-data/${companyId}`,
    data: offlineData,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error saving offline data for company id: ${companyId}`
    )
  }

  return result
}

export const updateOfflineData = async (companyId, offlineData) => {
  const result = await Api.put({
    url: `/api/offline-data/${companyId}`,
    data: offlineData,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error updating offline data for company id: ${companyId}`
    )
  }

  return result
}

export const deleteOfflineData = async (companyId, offlineDataId) => {
  const result = await Api.delete({
    url: `/api/offline-data/${companyId}/${offlineDataId}`,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error deleting offline data with id: ${offlineDataId} for company id: ${companyId}`
    )
  }

  return result
}

export const deleteAllOfflineData = async (companyId) => {
  const result = await Api.delete({
    url: `/api/offline-data/all/${companyId}`,
  })

  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error deleting all offline data with for company id: ${companyId}`
    )
  }

  return result
}
