import React from 'react'
import PropTypes from 'prop-types'

import { entityStatus } from '@decision-sciences/qontrol-common'

/* Components */
import InputText from 'components/input'
import Button from 'components/button'
import { Dropdown } from 'components/dropdown/index'

/* Icons */
import { ReactComponent as SearchIcon } from 'assets/icon_search.svg'

/* Constants */
import { PROPERTIES } from 'modules/companies/subsections/calculated-metrics/utils'

const { NAME, STATUS, OWNER } = PROPERTIES

const { ENTITY_STATUS_OPTIONS } = entityStatus

const CalculatedMetricsFilterSection = ({
  filter = {},
  setFilter = () => {},
  applyFilter,
  users,
}) => {
  const onChangeProperty = (property, value) =>
    setFilter({ ...filter, [property]: value })

  return (
    <>
      <div className="calculated-metrics-edit-section">
        <div className="calculated-metrics-edit-section__column">
          <div className="general-label">Search </div>
          <InputText
            value={filter[PROPERTIES.NAME] || ''}
            placeholder="Search"
            search
            onChange={(val) => onChangeProperty(NAME, val)}
            className={'width-50'}
          />
          <div className="general-label">Owner</div>
          <Dropdown
            options={users.map((user) => ({
              label: user.name,
              value: user._id,
            }))}
            onChange={(val) => onChangeProperty(OWNER, val)}
            defaultOptionText="Select Owners"
            selectedItems={filter[PROPERTIES.OWNER] || []}
            multiSelect={true}
            selectAll="All"
            overwriteSelectedText={
              filter[PROPERTIES.OWNER]?.length === users.length ? 'All' : null
            }
          />
        </div>

        <div className="reports-edit-section__column">
          <div className="general-label">Status</div>
          <Dropdown
            options={ENTITY_STATUS_OPTIONS}
            defaultState={ENTITY_STATUS_OPTIONS.map(({ value }) => value)}
            onChange={(val) => onChangeProperty(STATUS, val)}
            defaultOptionText={'Select Statuses'}
            selectedItems={filter[PROPERTIES.STATUS] || []}
            multiSelect={true}
            selectAll="All"
            overwriteSelectedText={
              filter[PROPERTIES.STATUS]?.length === ENTITY_STATUS_OPTIONS.length
                ? 'All'
                : null
            }
          />
        </div>
      </div>
      <div className="borders-top-bottom">
        <Button
          secondary
          value={
            <div className="action-button">
              <SearchIcon /> Apply Search
            </div>
          }
          onClick={applyFilter}
        />
      </div>
    </>
  )
}
export default CalculatedMetricsFilterSection

CalculatedMetricsFilterSection.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
}
