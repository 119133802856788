import { dataStore } from '@decision-sciences/qontrol-common'

import Button from 'components/button'
import EntitySelectorActions from 'components/entity-selector/action-bar'
import EntitySelectorFilters, {
  FILTER_TYPE,
} from 'components/entity-selector/filters'
import {
  filterDimensions,
  getAllCategories,
} from 'modules/data-store-global/actions'
import { useEffect, useState } from 'react'
import { useStore } from 'store'

const {
  DATA_STORE_FORMAT_OPTIONS,
  DATA_STORE_ITEM_PLACEMENT_OPTIONS,
  DATA_STORE_ITEM_TYPE_OPTIONS,
} = dataStore

/**
 * Dimensions/metrics collapsible filters section
 * @returns {React.FunctionComponent}
 */
const DimensionsFilters = () => {
  const { dispatch, state } = useStore()
  const {
    dataStoreGlobal: { categories, dimensionsFilters },
  } = state

  const [appliedFilters, setAppliedFilters] = useState(dimensionsFilters)

  useEffect(() => {
    if (!categories) {
      getAllCategories(dispatch)
    }
  }, [categories])

  const filters = [
    {
      fieldKey: 'searchTerm',
      label: 'Search',
      placeholder: 'Search',
      type: FILTER_TYPE.SEARCH,
    },
    {
      fieldKey: 'categories',
      label: 'Associated Categories',
      placeholder: 'All',
      type: FILTER_TYPE.DROPDOWN_MULTISELECT,
      selectAll: true,
      options:
        categories?.map(({ id, name }) => ({ value: id, label: name })) || [],
    },
    {
      fieldKey: 'type',
      label: 'Type',
      placeholder: 'All',
      selectAll: true,
      type: FILTER_TYPE.DROPDOWN_MULTISELECT,
      options: Object.entries(DATA_STORE_ITEM_TYPE_OPTIONS).map(
        ([value, label]) => ({ value, label })
      ),
    },
    {
      fieldKey: 'placement',
      label: 'Placement',
      placeholder: 'All',
      selectAll: true,
      type: FILTER_TYPE.DROPDOWN_MULTISELECT,
      options: Object.entries(DATA_STORE_ITEM_PLACEMENT_OPTIONS).map(
        ([value, label]) => ({ value, label })
      ),
    },
    {
      fieldKey: 'format',
      label: 'Format',
      placeholder: 'All',
      selectAll: true,
      type: FILTER_TYPE.DROPDOWN_MULTISELECT,
      options: Object.entries(DATA_STORE_FORMAT_OPTIONS).map(
        ([value, label]) => ({ value, label })
      ),
    },
  ]

  const onFilterChange = (fieldKey) => (value) => {
    setAppliedFilters((prevFilters) => {
      if (!prevFilters) {
        return { [fieldKey]: value }
      }
      return { ...prevFilters, [fieldKey]: value }
    })
  }

  const onFilterApply = () => {
    filterDimensions(dispatch, appliedFilters)
  }

  const onFilterClear = () => {
    setAppliedFilters(null)
    filterDimensions(dispatch, {})
  }

  return (
    <>
      <EntitySelectorFilters
        filters={filters}
        selectedFilters={appliedFilters || {}}
        onFiltersChange={onFilterChange}
      />
      <EntitySelectorActions
        hasFilters
        onApplyFilters={onFilterApply}
        actions={
          <Button
            compact
            data-cy="data-store-dimension-filters-clear"
            secondary
            onClick={onFilterClear}
            className="clear-filters"
            value="Clear"
          />
        }
      />
    </>
  )
}

export default DimensionsFilters
