import React, { useEffect } from 'react'
import { useStore } from 'store'
import {
  getVariableMetrics,
  getCalculatedMetrics,
  deleteVariableMetric,
} from 'modules/calculated-metrics/actions'
import { TABLE_CONTAINER } from 'modules/table-filter-sort/constants'
import VariablesModule from 'modules/calculated-metrics/variables'
import Loader from 'components/loader'

const MetricVariableIndexPage = () => {
  const tableContainer = TABLE_CONTAINER.METRIC_VARIABLES
  const { dispatch, state } = useStore()
  const { variables, list } = state.calculatedMetrics
  const {
    session: { userData },
  } = state

  useEffect(() => {
    if (!variables) {
      getVariableMetrics(dispatch)
    }
  }, [variables])

  useEffect(() => {
    if (!list) {
      getCalculatedMetrics(dispatch)
    }
  }, [list])

  if (!variables || !list) {
    return <Loader />
  }

  return (
    <VariablesModule
      variables={variables}
      userData={userData}
      onDelete={(_id) => deleteVariableMetric(dispatch, _id)}
      tableContainer={tableContainer}
    />
  )
}

export default MetricVariableIndexPage
