import { useNavigate } from 'react-router-dom'

/* Components */
import Button from 'components/button'
import DataStoreCategoriesTable from 'modules/data-store-global/components/categories/table'

/* Assets */
import { ReactComponent as AddIcon } from 'assets/icon_dashboard_customize.svg'

/**
 * Categories overview table and management section
 * @returns {React.FunctionComponent}
 */
const DataStoreCategories = () => {
  const navigate = useNavigate()

  const onCreateClick = () =>
    navigate('/global-settings/data-store/category/new')

  return (
    <div data-cy="data-store-categories" className="section">
      <div className="align-row align-baseline space-between section__header">
        <h3 className="generic-heading generic-heading--no-margin">
          Categories
        </h3>
        <Button
          compact
          data-cy="data-store-category-add"
          onClick={onCreateClick}
          value={
            <div className="align-row center gap-8">
              <AddIcon />
              <span>ADD CATEGORY</span>
            </div>
          }
        />
      </div>
      <DataStoreCategoriesTable />
    </div>
  )
}

export default DataStoreCategories
