import { entityStatus } from '@decision-sciences/qontrol-common'

const { ENTITY_STATUS } = entityStatus

export const PAGE_MODE = {
  EDIT: 'edit',
  ADD: 'add',
  DEFAULT: 'default',
}

export const PROPERTIES = {
  NAME: 'name',
  STATUS: 'active',
  OWNER: 'owner',
  FORMULA: 'expression',
}

export const GET_DEFAULT_CALCULATED_METRIC = (currentUserId) => ({
  [PROPERTIES.NAME]: '',
  [PROPERTIES.STATUS]: ENTITY_STATUS.ACTIVE,
  [PROPERTIES.OWNER]: currentUserId,
  [PROPERTIES.FORMULA]: '',
})

export const getCalcluatedMetricErrors = (
  calcluatedMetric,
  allCalculatedMetrics,
  isFormulaValid
) => {
  const errors = {}
  if (
    !calcluatedMetric[PROPERTIES.NAME] ||
    allCalculatedMetrics.some(
      (metric) =>
        metric[PROPERTIES.NAME] === calcluatedMetric[PROPERTIES.NAME] &&
        (calcluatedMetric._id ?? calcluatedMetric.id) !==
          (metric._id ?? metric.id)
    )
  ) {
    errors[PROPERTIES.NAME] = true
  }
  if (!calcluatedMetric[PROPERTIES.FORMULA] || !isFormulaValid) {
    errors[PROPERTIES.FORMULA] = true
  }
  return errors
}

export const filterCalculatedMetrics = ({
  calculatedMetrics,
  filter,
  users,
}) => {
  const { name, active, owner } = filter
  return calculatedMetrics.filter(
    ({
      owner: calcluatedMetricOwnerId,
      name: calcluatedMetricName,
      active: calcluatedMetricStatus,
    }) => {
      if (name) {
        const ownerName = users.find(
          ({ _id }) => _id === calcluatedMetricOwnerId
        )?.name
        const nameMatch = [ownerName, calcluatedMetricName].some(
          (property) =>
            property && property.toLowerCase().match(name.toLowerCase())
        )

        if (!nameMatch) {
          return false
        }
      }
      if (
        active &&
        active.length &&
        !active.some((statusEntry) => statusEntry === calcluatedMetricStatus)
      ) {
        return false
      }
      if (
        owner &&
        owner.length &&
        !owner.some((ownerEntry) => ownerEntry === calcluatedMetricOwnerId)
      ) {
        return false
      }
      return true
    }
  )
}
