import StickyFooter from 'components/sticky-footer/index'
import { createDimension } from 'modules/data-store-global/actions'
import { validateDimension } from 'modules/data-store-global/validate'
import {
  showErrorMessage,
  showSuccessMessage,
} from 'modules/notifications/actions'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'store'

/**
 * Bottom Save / Cancel action bar for the Dimension form. Hanles saving or cancelling the operation.
 * @param {Object} props Component props
 * @param {Boolean} [props.disabled] Disabled flag
 * @param {Boolean} props.isDirty Dirty flag
 * @param {Function} props.setDirty Setter for the dirty state
 * @param {Function} props.setErrors Setter for the validation errors
 * @returns {React.FunctionComponent}
 */
const DimensionFormActionBar = ({ isDirty, setDirty, setErrors, disabled }) => {
  const navigate = useNavigate()

  const { dispatch, state } = useStore()
  const {
    dataStoreGlobal: { editedDimension: dimension },
  } = state

  const [loading, setLoading] = useState(false)

  const onSave = () => {
    const [isValid, errors] = validateDimension(dimension)
    setLoading(true)
    if (!isValid) {
      setErrors(errors)
      setLoading(false)
      return
    }
    setDirty(false)
    setLoading(true)
    createDimension(dimension)
      .then(() => {
        showSuccessMessage('Dimension created', dispatch)
        navigate('/global-settings/data-store')
      })
      .catch((error) => {
        showErrorMessage(`Unable to create dimension: ${error}`)
        setDirty(true)
        setLoading(false)
      })
  }

  const onCancel = () => {
    navigate('/global-settings/data-store')
  }

  return (
    <StickyFooter
      buttons={[
        {
          'data-cy': 'data-store-category-form-save',
          value: 'Save',
          disabled: disabled || loading || !isDirty,
          onClick: onSave,
        },
        {
          'data-cy': 'data-store-category-form-cancel',
          value: 'Cancel',
          disabled: disabled || loading,
          onClick: onCancel,
          secondaryGray: true,
        },
      ]}
    />
  )
}

DimensionFormActionBar.propTypes = {
  disabled: PropTypes.bool,
  isDirty: PropTypes.bool.isRequired,
  setDirty: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
}

export default DimensionFormActionBar
