import React, { useMemo, useState } from 'react'
import { useStore } from 'store'
import PropTypes from 'prop-types'
import {
  entityStatus,
  currency,
  timezoneCountry,
} from '@decision-sciences/qontrol-common'
import { useOutletContext } from 'react-router-dom'

/* Components */
import Loader from 'components/loader'
import InputText from 'components/input'
import { Dropdown } from 'components/dropdown'
import { CheckboxNoHooks } from 'components/checkbox'

/* Actions */
import { checkForRestore } from 'modules/companies/actions'

/* Other Sections */
import KpiSection from 'modules/companies/subsections/kpi-section/index'
import { Reporting } from 'modules/companies/subsections/reporting'
import AccountLead from 'modules/companies/subsections/account-lead'
import { RestoreNotification } from 'modules/companies/restore-popover'

/* Constants */
import { COMPANY_DEFAULT } from 'modules/companies/constants'

import { TABLE_CONTAINER } from 'modules/table-filter-sort/constants'
import { useRestoreClient, useSeeArchivedClients } from './hooks'
const { COUNTRIES, COUNTRY_LABELS, TIMEZONES, US_STATES } = timezoneCountry
const { ENTITY_STATUS_OPTIONS } = entityStatus
const { CURRENCY_LABELS } = currency

/**
 * Displays an editable Client Form
 * @param {Object} params
 * @param {Object} params.originalCompany Company data
 * @param {Object} [params.pendingChanges = {}] Pending company changes
 * @param {Function} params.setCompany Function to set company data
 * @param {Function} params.setDirty Function to mark the page as dirty for leave confirmation
 * @param {Function} params.setErrors Function to set errors object
 * @param {Object} params.errors Company errors to display
 * @param {Boolean} [params.loading] Whether the form is loading or not
 * @param {Array} [params.allowedApprovers = []] Array of allowed approvers
 * @param {Array} [params.kpis = []] Accounts to draw KPIs from
 */
const ClientForm = ({
  originalCompany,
  pendingChanges = {},
  setCompany,
  setDirty,
  setErrors,
  errors,
  loading,
  allowedApprovers = [],
  kpis = [],
  loadingKpis,
}) => {
  const tableContainer = TABLE_CONTAINER.CLIENT_MANAGEMENT

  const { state } = useStore()
  const { isViewMode } = state.companies

  const { isCreate } = useOutletContext()

  const [restoreInfo, setRestoreInfo] = useState(null)

  const [restoreClient] = useRestoreClient({
    tableContainer,
    restoreInfo,
    onSuccess: () => closeRestoreNotification(),
    onError: () => setDirty(true),
  })

  const seeArchivedClients = useSeeArchivedClients({
    tableContainer,
    setDirty,
    closeRestoreNotification: () => closeRestoreNotification(),
  })

  const company = useMemo(() => {
    return {
      ...originalCompany,
      defaultApprover:
        originalCompany.defaultApprover?._id || originalCompany.defaultApprover,
      backupApprover:
        originalCompany.backupApprover?._id || originalCompany.backupApprover,
      accountLead:
        originalCompany.accountLead?._id || originalCompany.accountLead,
    }
  }, [JSON.stringify(originalCompany)])

  /** Edit Name field */
  const editName = (value) => {
    setCompany({ name: value })
    setErrors({ ...errors, name: null })
  }

  /** Edit any other text field */
  const editField = (fieldName, fieldValue) => {
    // Case for nested fields, like "contact.name"
    if (fieldName.indexOf('.') > -1) {
      const [field, subField] = fieldName.split('.')
      const fieldObj = { ...company[field], [subField]: fieldValue }
      setCompany({ [field]: fieldObj })
      const errorObj = { ...errors[field], [subField]: null }
      setErrors({ ...errors, [field]: errorObj })
    } else {
      // Regular case
      setCompany({ [fieldName]: fieldValue })
      setErrors({ ...errors, [fieldName]: null })
    }
  }

  const handleKpiChange = (updatedCompany, field) => {
    if (field.indexOf('.') > -1) {
      const [field, subField] = field.split('.')
      const errorObj = { ...errors[field], [subField]: null }
      setErrors({ ...errors, [field]: errorObj })
    } else {
      setErrors({ ...errors, [field]: null })
    }
    setCompany(updatedCompany)
  }

  const getAllowedApprovers = (excludedApprover) => {
    /*
    If an user is selected as the default approver, it should not appear in
    the backup approvers dropdown (the revers is also true)
    */
    return [...allowedApprovers]
      .filter((a) => a._id !== excludedApprover || !excludedApprover)
      .map((user) => ({
        value: user._id,
        label: user.name,
        disabled: user._id === company.backupApprover,
      }))
  }

  const clearErrorOnField = (field) => {
    if (errors[field]) {
      setErrors({ ...errors, [field]: null })
    }
  }

  const triggerRestoreValidation = () => {
    if (!company.clientId || !isCreate) {
      return
    }
    checkForRestore(company.clientId)
      .then((restoreInfo) => {
        if (restoreInfo.canRestore) {
          setErrors({ clientId: true })
          setRestoreInfo(restoreInfo)
          return
        }
        clearErrorOnField('clientId')
        if (restoreInfo) {
          setRestoreInfo(null)
        }
      })
      .catch(console.error)
  }

  const closeRestoreNotification = () => setRestoreInfo(null)

  const handleRestore = () => {
    return new Promise((resolve) => {
      setDirty(false)
      restoreClient(resolve)
    })
  }

  const cancelRestorePrompt = () => {
    setCompany({ new: true, _id: originalCompany._id, ...COMPANY_DEFAULT })
    clearErrorOnField('clientId')
    closeRestoreNotification()
  }

  if (loading) {
    return <Loader />
  }

  return (
    <form className="form" data-cy="create-edit-client-form">
      <RestoreNotification
        visible={!!restoreInfo}
        restoreInfo={restoreInfo}
        onCancel={cancelRestorePrompt}
        onClose={closeRestoreNotification}
        onRestore={handleRestore}
        onArchivedFilter={seeArchivedClients}
      />
      <div className="align-row form__section__body add-edit-companies__form">
        <div className="form__section__body__half-width-section">
          <InputText
            data-cy="create-edit-client-form-name"
            placeholder={'Client Name'}
            label="Client Name"
            value={company.name}
            onChange={editName}
            error={errors.name}
            disabled={loading || isViewMode}
            className="input-wrapper--uppercase"
          />
          <div className="align-row">
            <InputText
              data-cy="create-edit-client-form-office-phone"
              placeholder="Office Phone"
              label="Office Phone"
              type="phone"
              value={company.contact?.phone}
              onChange={(val) => editField('contact.phone', val)}
              error={errors.contact?.phone}
              disabled={loading || isViewMode}
              className="input-half-width input-wrapper--uppercase"
            />
            <InputText
              data-cy="create-edit-client-form-alternate-phone"
              placeholder="Alternate Phone"
              label="Alternate Phone"
              type="phone"
              value={company.contact?.alternatePhone}
              onChange={(val) => editField('contact.alternatePhone', val)}
              error={errors.contact?.alternatePhone}
              disabled={loading || isViewMode}
              className="input-half-width right-half-input input-wrapper--uppercase"
            />
          </div>

          <div className="align-row">
            <InputText
              data-cy="create-edit-client-form-primary-contact"
              placeholder="Primary Contact"
              label="Primary Contact"
              value={company.contact?.name}
              onChange={(val) => editField('contact.name', val)}
              error={errors.contact?.name}
              disabled={loading || isViewMode}
              className="input-half-width input-wrapper--uppercase"
            />
            <InputText
              data-cy="create-edit-client-form-primary-contact-phone"
              placeholder="Primary Contact Phone"
              label="Primary Contact Phone"
              type="phone"
              value={company.contact?.primaryContactPhone}
              onChange={(val) => editField('contact.primaryContactPhone', val)}
              error={errors.contact?.primaryContactPhone}
              disabled={loading || isViewMode}
              className="input-half-width right-half-input input-wrapper--uppercase"
            />
          </div>
          <InputText
            data-cy="create-edit-client-form-primary-contact-email"
            placeholder="Primary Contact Email"
            label="Primary Contact Email"
            type="email"
            value={company.contact?.email}
            onChange={(val) => editField('contact.email', val)}
            error={errors.contact?.email}
            disabled={loading || isViewMode}
            className="input-wrapper--uppercase"
          />
          <InputText
            data-cy="create-edit-client-form-slack-channel"
            placeholder={'Slack Channel'}
            label="Slack Channel"
            value={company.slack}
            onChange={(val) => editField('slack', val)}
            error={errors.slack}
            className="input-wrapper--uppercase"
            disabled={loading || isViewMode}
          />
          {/* Default Approver */}
          <Dropdown
            data-cy="create-edit-client-form-default-approver"
            defaultOptionText="Default Approver"
            label="Default Approver"
            options={getAllowedApprovers(company.backupApprover)}
            deselectLabel="Default Approver"
            className="input-wrapper--uppercase"
            defaultState={company.defaultApprover || ''}
            disabled={loading || isViewMode}
            error={errors.defaultApprover}
            onChange={(defaultApprover) => {
              editField('defaultApprover', defaultApprover)
            }}
          />
          {/* Backup Approver */}
          <Dropdown
            data-cy="create-edit-client-form-backup-approver"
            defaultOptionText="Backup Approver"
            label="Backup Approver"
            className="input-wrapper--uppercase"
            options={getAllowedApprovers(company.defaultApprover)}
            deselectLabel="Backup Approver"
            defaultState={company.backupApprover || ''}
            disabled={loading || isViewMode}
            onChange={(backupApprover) =>
              editField('backupApprover', backupApprover)
            }
          />

          <CheckboxNoHooks
            data-cy={'account-outage-notifications'}
            label={'Missing Access Slack Notifications'}
            isChecked={company.hasOutageNotifications}
            onChange={() => {
              editField(
                'hasOutageNotifications',
                !company.hasOutageNotifications
              )
            }}
            hint={
              <span>
                <div>
                  If checked, Client will receive Account Outage Slack
                  notifications
                </div>
              </span>
            }
            className="checkbox-first "
          />
        </div>

        <div className="form__section__body__half-width-section right-side">
          {/* Client Id */}
          <InputText
            data-cy="create-edit-client-form-client-id"
            placeholder="Client ID"
            label="Client ID"
            value={company.clientId}
            onChange={(value) => editField('clientId', value)}
            blur={triggerRestoreValidation}
            error={errors.clientId}
            disabled={loading || isViewMode}
            className="input-wrapper--uppercase"
          />
          {/* Status */}
          <Dropdown
            data-cy="create-edit-client-form-status"
            defaultOptionText="Status"
            label="Status"
            defaultState={
              typeof company.active === 'boolean' ? company.active : ''
            }
            options={ENTITY_STATUS_OPTIONS}
            className="input-wrapper--uppercase"
            onChange={(value) => editField('active', value)}
            error={errors.active}
            disabled={loading || isViewMode}
          />
          <InputText
            data-cy="create-edit-client-form-website"
            placeholder={'Website'}
            label="Website"
            value={company.webSite}
            onChange={(val) => editField('webSite', val)}
            error={errors.webSite}
            disabled={loading || isViewMode}
            className="input-wrapper--uppercase"
          />
          <div className="align-row">
            {/* Country */}
            <Dropdown
              data-cy="create-edit-client-form-country"
              label="Country"
              defaultOptionText="Country"
              defaultState={company.country ? company.country : ''}
              options={COUNTRIES.map((country) => ({
                value: country.label,
                label: country.label,
              }))}
              disabled={loading || isViewMode}
              className="input-half-width input-wrapper--uppercase"
              hasSearch={true}
              onChange={(country) => editField('country', country)}
            />
            {/* State */}
            {company.country === COUNTRY_LABELS.US ? (
              <Dropdown
                data-cy="create-edit-client-form-state"
                defaultOptionText="State"
                label="State"
                defaultState={company.state ? company.state : ''}
                options={US_STATES.map((state) => ({
                  value: state.label,
                  label: state.label,
                }))}
                disabled={loading || isViewMode}
                className="input-half-width right-half-input input-wrapper--uppercase"
                hasSearch
                onChange={(state) => editField('state', state)}
              />
            ) : null}
          </div>
          {/* Currency */}
          <Dropdown
            data-cy="create-edit-client-form-currency"
            defaultOptionText="Currency"
            label="Currency"
            defaultState={
              company.primaryCurrency ? company.primaryCurrency : ''
            }
            options={Object.entries(CURRENCY_LABELS).map(([value, label]) => ({
              value,
              label,
            }))}
            disabled={loading || isViewMode}
            className="input-wrapper--uppercase"
            onChange={(primaryCurrency) =>
              editField('primaryCurrency', primaryCurrency)
            }
          />
          {/* Timezone */}
          <Dropdown
            data-cy="create-edit-client-form-timezone"
            defaultOptionText="Time Zone"
            label="Time Zone"
            defaultState={company.timeZone ? company.timeZone : ''}
            options={TIMEZONES.map((tmz) => ({
              value: tmz.name,
              label: tmz.label,
            }))}
            disabled={loading || isViewMode}
            className="input-wrapper--uppercase"
            onChange={(timeZone) => editField('timeZone', timeZone)}
          />
          {/* Account Lead */}
          <AccountLead
            defaultState={company.accountLead}
            onChange={(accountLead) => editField('accountLead', accountLead)}
            isViewMode={isViewMode}
          />
        </div>
      </div>

      {errors.general ? (
        <div data-cy="create-edit-client-form-general-error" className="error">
          {errors.general.message || errors.general.toString()}
        </div>
      ) : null}

      <KpiSection
        data-cy="create-update-client-kpi"
        company={company}
        pendingChanges={pendingChanges}
        onChange={handleKpiChange}
        kpis={kpis}
        loadingKpis={loadingKpis}
        disabled={isViewMode}
        errors={errors}
        setErrors={setErrors}
      />
    </form>
  )
}

ClientForm.propTypes = {
  originalCompany: PropTypes.object.isRequired,
  pendingChanges: PropTypes.object,
  setCompany: PropTypes.func.isRequired,
  setDirty: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  allowedApprovers: PropTypes.array,
  kpis: PropTypes.array,
  loadingKpis: PropTypes.number,
}

export default ClientForm
