import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// Components
import InputText from 'components/input'
import { Dropdown } from 'components/dropdown/index'
import InformationBlock from 'components/information-block'
import ItemRow from 'components/item-row'
import { AccountDropdownRow, AccountIcon } from 'components/account-icon/index'
import ConfirmationModal from 'components/confirmation-modal'
import { OfflineConversionDropdownRow } from 'modules/companies/subsections/conversion-grouping/components/offline-conversion-dropdown-row'
import CreateActions from 'modules/companies/subsections/campaign-exclusions-section/components/campaign-exclusions-create/create-actions/index'

// Constants
import { OFFLINE_COVNERSION_TYPE } from 'modules/companies/subsections/conversion-grouping/utils'

// Assets
import { ReactComponent as QontrolIcon } from 'assets/icon_qontrol.svg'

const ConversionGroupingSettings = ({
  errors = {},
  setErrors,
  conversionGrouping = {},
  setConversionGrouping,
  conversionGroupings,
  availablePriorities,
  isEditMode,
  specialKPIs,
  onCancel,
  onDelete,
  onSave,
  loading = false,
}) => {
  const [deleteModalOpened, setDeleteModalOpened] = useState(false)
  const onChangeName = (name) => {
    setErrors({ ...errors, name: null })
    setConversionGrouping({ ...conversionGrouping, name: name })
  }

  const onChangePriority = (priority) =>
    setConversionGrouping({ ...conversionGrouping, priority: priority })

  const onChangeConversions = (conversions) => {
    errors.kpis && setErrors({ ...errors, kpis: null })
    setConversionGrouping({
      ...conversionGrouping,
      kpis: conversions,
    })
  }
  const onRemoveConversion = (kpi) =>
    setConversionGrouping({
      ...conversionGrouping,
      kpis: conversionGrouping.kpis.filter(
        (conv) => conv !== (kpi.conversion_id || kpi._id)
      ),
    })
  const onDeleteGrouping = () => {
    onDelete(conversionGrouping._id)
    setDeleteModalOpened(false)
    onCancel()
  }
  const validateGrouping = async () => {
    const errors = {}
    let isValid = true
    const { name, kpis } = conversionGrouping
    const clearedName = name.replaceAll(' ', '')
    if (!clearedName) {
      isValid = false
      errors.name = 'Conversion grouping name is required.'
    } else {
      const isNameDuplicate = conversionGroupings.find((group) => {
        const groupName = group.name.replaceAll(' ', '')
        return (
          group._id !== conversionGrouping._id &&
          groupName.match(new RegExp(`^${clearedName}$`, 'i'))
        )
      })

      if (isNameDuplicate) {
        isValid = false
        errors.name =
          'A conversion grouping with this name already exists. Please enter a different name.'
      }
    }
    if (!kpis.length) {
      isValid = false

      errors.kpis = 'Conversions are required.'
    }
    return [isValid, errors]
  }

  const onSaveGrouping = async () => {
    const [isValid, errors] = await validateGrouping()
    if (!isValid) {
      setErrors(errors)
    } else {
      setErrors({})
      await onSave()
    }
  }

  const availableConversions = useMemo(
    () =>
      specialKPIs.filter(
        (el) =>
          !conversionGroupings.some(
            ({ _id, kpis }) =>
              // Exclude current group's conversions from the exclusion => display them in the dropdown
              _id !== conversionGrouping._id &&
              // Exclude already used conversions
              kpis.some(
                ({
                  conversion_id,
                  _id,
                  conversion_name,
                  provider,
                  externalAccountId,
                }) => {
                  if (
                    conversion_id &&
                    conversion_id !== '0' &&
                    el.conversion_id
                  ) {
                    return conversion_id === el.conversion_id
                  }

                  if (conversion_id && conversion_id !== '0' && el.id) {
                    return (
                      conversion_id === el.id &&
                      externalAccountId === el.accountId
                    )
                  }

                  if (_id && el._id) {
                    return _id === el._id
                  }

                  return (
                    conversion_name === el.name &&
                    provider === el.type &&
                    externalAccountId === el.accountId
                  )
                }
              )
          )
      ),
    [JSON.stringify(conversionGroupings)]
  )

  return (
    <>
      <div className="conversion-grouping-settings">
        <div
          className={cx('general-label', {
            'label-error': errors.duplicatedName,
          })}
        >
          GROUP NAME
        </div>
        <InputText
          error={!!errors.name}
          value={conversionGrouping.name || ''}
          placeholder="Enter Group Name"
          onChange={onChangeName}
          className={'width-50'}
        />
        {errors.name ? (
          <InformationBlock
            className="name-information-block"
            info={errors.name}
          />
        ) : null}
        {!isEditMode && (
          <>
            <div className="general-label margin-top-15">PRIORITY</div>
            <Dropdown
              options={availablePriorities}
              defaultState={conversionGrouping.priority}
              onChange={onChangePriority}
              disableSearch
              className="priority-dropdown"
            />
          </>
        )}
        <div className="general-label margin-top-15">KPI</div>
        <Dropdown
          selectAll
          isConversionDropdown
          sortOptions
          optionsHeight={610}
          className="kpi-dropdown"
          error={!!errors.kpis}
          options={availableConversions.map((el) => ({
            ...el,
            value:
              el.type === OFFLINE_COVNERSION_TYPE ? el.conversion_id : el._id,
          }))}
          defaultOptionText="Select KPI"
          selectedItems={conversionGrouping.kpis || []}
          loading={loading}
          optionRenderer={(option, selectedItems) => {
            if (option.type === OFFLINE_COVNERSION_TYPE) {
              return (
                <OfflineConversionDropdownRow
                  option={{ ...option, key: option.conversion_id }}
                  selectedItems={selectedItems}
                />
              )
            }

            return (
              <AccountDropdownRow
                option={{ ...option, key: option._id }}
                selectedItems={selectedItems}
              />
            )
          }}
          multiSelect
          onChange={onChangeConversions}
          showOptionsInPlaceholder={false}
        />
        {errors.kpis ? (
          <InformationBlock
            className="name-information-block"
            info={errors.kpis}
          />
        ) : null}
        {!loading &&
          conversionGrouping.kpis?.map((kpiId, idx) => {
            const kpiItem = specialKPIs.find(
              (kpi) =>
                (kpi.type === OFFLINE_COVNERSION_TYPE
                  ? kpi.conversion_id
                  : kpi._id) === kpiId
            )

            if (!kpiItem) {
              return null
            }
            return (
              <ItemRow
                key={idx}
                item={
                  kpiItem.type === OFFLINE_COVNERSION_TYPE
                    ? {
                        ...kpiItem,
                        value: kpiItem.name,
                        description: `ID: ${kpiItem.conversion_id} | ${kpiItem.filename} | Offline`,
                      }
                    : {
                        ...kpiItem,
                        value: kpiItem.name,
                        description: `ID: ${kpiItem.id} | Account: ${kpiItem.accountId}`,
                      }
                }
                hasPreffixIcon
                preffixIcon={
                  kpiItem.type === OFFLINE_COVNERSION_TYPE ? (
                    <QontrolIcon />
                  ) : (
                    <AccountIcon accountType={kpiItem.type} />
                  )
                }
                onRemove={onRemoveConversion}
              />
            )
          })}
      </div>
      <CreateActions
        loading={false}
        saveDisabled={false}
        onSaveClicked={onSaveGrouping}
        onCancelClicked={onCancel}
        hasDelete={isEditMode}
        onDeleteClicked={() => setDeleteModalOpened(true)}
      />

      <ConfirmationModal
        heading="Remove Conversion Group"
        message="You are about to remove a conversion group. Confirm if you want to continue
            with this action. This action cannot be undone."
        showModal={deleteModalOpened}
        onCancelClicked={() => setDeleteModalOpened(false)}
        onConfirmClicked={onDeleteGrouping}
      />
    </>
  )
}

export default ConversionGroupingSettings

ConversionGroupingSettings.propTypes = {
  conversionGrouping: PropTypes.object,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  isEditMode: PropTypes.bool,
  setConversionGrouping: PropTypes.func,
  specialKPIs: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  conversionGroupings: PropTypes.array.isRequired,
  availablePriorities: PropTypes.array.isRequired,
  loading: PropTypes.bool,
}
