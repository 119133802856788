import Api from 'easy-fetch-api'
import {
  showSuccessMessage,
  showErrorMessage,
} from 'modules/notifications/actions'
import { getVariableMetrics } from 'modules/calculated-metrics/actions'
import { getAlertThresholds } from 'modules/alert-thresholds/actions'

export const ACTIONS = {
  GET_ALERT_TEMPLATES: 'alertTemplates.getAll',
}

export const getAlertTemplates = (dispatch) =>
  Api.get({ url: `/api/alert-templates` }).then((result) => {
    if (!result || !result.success) {
      return console.error(
        (result && result.error) || 'Error getting alert templates'
      )
    }
    dispatch({ type: ACTIONS.GET_ALERT_TEMPLATES, list: result.list })
    return result
  })

export const getAlertTemplateById = async (id, location) => {
  const result = await Api.get({
    url: `/api/alert-templates/thresholds-from-template/${id}`,
    query: {
      location,
    },
  })
  if (!result || !result.success) {
    return console.error(
      (result && result.error) ||
        `Error getting thresholds from template with id ${id}`
    )
  }
  return result
}

export const createUpdateAlertTemplate = (dispatch, alertTemplate) => {
  return new Promise((resolve, reject) => {
    let promise
    const payload = {
      url: `/api/alert-templates`,
      data: alertTemplate,
    }
    if (alertTemplate._id) {
      promise = Api.put(payload)
    } else {
      promise = Api.post(payload)
    }
    promise
      .then(async (res) => {
        if (res.error) {
          console.error(res.error)
          return reject(res.error)
        }
        await getAlertTemplates(dispatch)
        getAlertThresholds(dispatch)
        getVariableMetrics(dispatch)
        resolve(res.data)
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}

export const deleteAlertTemplate = (dispatch, _id) => {
  return new Promise((resolve, reject) => {
    Api.delete({ url: `/api/alert-templates/${_id}` })
      .then((res) => {
        if (res.success) {
          showSuccessMessage('Alert Template successfully deleted', dispatch)
          getAlertThresholds(dispatch)
          getAlertTemplates(dispatch).then(resolve)
          getVariableMetrics(dispatch).then(() => resolve(res))
        } else {
          showErrorMessage('Error deleting Alert Template', dispatch)
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })
}
