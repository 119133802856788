import cx from 'classnames'
import PropTypes from 'prop-types'
import { globalReports } from '@decision-sciences/qontrol-common'

/* Icons */
import { ReactComponent as FiltersIcon } from 'assets/filters.svg'
import { ReactComponent as VisualsIconBlue } from 'assets/reports-blue.svg'
import { ReactComponent as ConversionsIcon } from 'assets/icon_currency.svg'

/* Utils*/
import { getFilterOperators } from 'components/custom-saved-filters/utils'

/* Constants */
const { SAVED_VIEW_TYPES_LABELS } = globalReports

export const SECTIONS = {
  FILTERS: 'filters',
  VISUALS: 'visuals',
  CONVERSIONS: 'conversions',
}

export const getStyling = (selected, darkMode, className, highlighted) =>
  cx(`${className ? className : ''} blue-box cursor-on-hover`, {
    'blue-box--selected': selected && !darkMode,
    'blue-box--selected--dark': selected && darkMode,
    'blue-box--selected--red': selected && highlighted,
    'blue-box--dark': darkMode,
  })

export const getOperation = (filter, operation) => {
  const availableOperations = getFilterOperators(filter)
  let defaultOperation = operation
  if (
    !operation ||
    !Object.values(availableOperations)
      .map((el) => el.value)
      .includes(operation)
  ) {
    defaultOperation = availableOperations[0]
  }
  return [availableOperations, defaultOperation]
}

const ICONS_MAP = {
  [SECTIONS.FILTERS]: (
    <FiltersIcon width={20} height={20} className="fill-light-blue" />
  ),
  [SECTIONS.VISUALS]: <VisualsIconBlue width={20} height={20} />,
  [SECTIONS.CONVERSIONS]: <ConversionsIcon width={20} height={20} />,
}

export const SectionIcon = ({ section, hasCount, sectionOptions = [] }) => {
  const IconComponent = ICONS_MAP[section]
  return (
    <div className="blue-box__content">
      {IconComponent}
      {SAVED_VIEW_TYPES_LABELS[section]}
      {hasCount && sectionOptions?.length ? (
        <div className={'dimensions-count'}>{sectionOptions.length}</div>
      ) : null}
    </div>
  )
}

SectionIcon.propTypes = {
  section: PropTypes.string.isRequired,
  hasCount: PropTypes.bool,
  sectionOptions: PropTypes.array,
}
