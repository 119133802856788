import { getConfig } from 'modules/global/actions'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useStore } from 'store'

const AppConfig = () => {
  const { dispatch, state } = useStore()

  const {
    global: { config },
  } = state

  useEffect(() => {
    if (!Object.keys(config)?.length) {
      getConfig(dispatch)
    } else {
      const { GOOGLE_TAG_MANAGER_STATUS, GOOGLE_TAG_MANAGER_ID } = config
      if (GOOGLE_TAG_MANAGER_STATUS === 'on' && GOOGLE_TAG_MANAGER_ID) {
        initializeGoogleTag()
      }
    }
  }, [config])

  const initializeGoogleTag = () => {
    const tagManagerArgs = {
      gtmId: config.GOOGLE_TAG_MANAGER_ID,
    }
    TagManager.initialize(tagManagerArgs)
  }

  return null
}

export default AppConfig
