import { dataStore } from '@decision-sciences/qontrol-common'
import LineDivider from 'components/line-divider/index'
import Table from 'components/table/beta'
import { DEFAULT_DATE_FORMAT } from 'components/utils/date'
import { getTooltipList } from 'components/utils/tooltip'
import { getUniqueElements } from 'components/utils/sorting'
import { format } from 'date-fns'
import {
  getAllDimensions,
  selectDimensions,
  toggleBulkEdit,
} from 'modules/data-store-global/actions'
import DimensionBulkEditForm from 'modules/data-store-global/components/dimensions/form/bulk-edit'
import DimensionsTableActions from 'modules/data-store-global/components/dimensions/table/action-bar'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useStore } from 'store'

const { DATA_STORE_ITEM_PLACEMENT_OPTIONS, DATA_STORE_ITEM_TYPE_OPTIONS } =
  dataStore

/**
 * Dimensions/metrics data table with bulk edit capabilities
 * @param {Object} props Component props
 * @param {Boolean} props.hideActions Flag to hide actions bar when filters are expanded
 * @returns {React.FunctionComponent}
 */
const DimensionsTable = ({ hideActions }) => {
  const { dispatch, state } = useStore()
  const {
    dataStoreGlobal: {
      dimensions,
      selectedDimensions,
      bulkEditActive,
      dimensionsFilters,
    },
  } = state

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      toggleBulkEdit(dispatch, false)
      selectDimensions(dispatch, [])
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    getAllDimensions(dispatch, dimensionsFilters)
      .catch((error) =>
        console.error('Error occurred when getting dimensions', error)
      )
      .finally(() => setLoading(false))
  }, [dimensionsFilters])

  const columns = [
    {
      header: 'Name',
      id: 'name',
      accessorFn: (row) => row.dimension?.COLUMN_NAME || '',
    },
    {
      header: 'Category',
      id: 'category',
      accessorFn: (row) =>
        row.categories?.length
          ? row.categories
              .map(({ categoryData }) => categoryData.name)
              .join(', ') || ''
          : '',
    },
    {
      header: 'Type',
      id: 'type',
      accessorFn: (row) => DATA_STORE_ITEM_TYPE_OPTIONS[row.type],
    },
    {
      ieader: 'Placement',
      id: 'placement',
      accessorFn: (row) => DATA_STORE_ITEM_PLACEMENT_OPTIONS[row.placement],
    },
    {
      header: 'Publisher',
      id: 'publisher',
      accessorFn: (row) => {
        const uniquePublishersArray = getUniqueElements(
          row.dimension.PUBLISHERS
        )
        if (!uniquePublishersArray) {
          return 0
        } else if (uniquePublishersArray.length === 1) {
          return uniquePublishersArray
        } else {
          return 'Multiple'
        }
      },
      tooltip: (row) => {
        const uniquePublishersArray = getUniqueElements(
          row.dimension.PUBLISHERS
        )
        if (!uniquePublishersArray || uniquePublishersArray.length === 1) {
          return null
        }
        return getTooltipList('Publishers', uniquePublishersArray)
      },
    },
    {
      header: 'Last modified',
      id: 'lastModified',
      accessorFn: (row) =>
        row.updatedAt && format(new Date(row.updatedAt), DEFAULT_DATE_FORMAT),
    },
  ]

  const onSelect = (selectedDimensions) => {
    if (bulkEditActive) {
      return
    }
    selectDimensions(dispatch, selectedDimensions)
  }

  return (
    <div className="dimensions__section">
      {!hideActions && (
        <>
          {bulkEditActive && (
            <>
              <DimensionBulkEditForm />
              <LineDivider />
            </>
          )}
          <DimensionsTableActions />
        </>
      )}
      <div className="dimensions__table">
        <Table
          className="table--compact"
          hasSelection
          loading={loading}
          disableRowSelection={bulkEditActive}
          columns={columns}
          data={dimensions || []}
          initialState={{
            selectedRowIds: selectedDimensions.map(
              (dimension) => dimension.tableRowId
            ),
          }}
          onSelect={onSelect}
        />
      </div>
    </div>
  )
}

DimensionsTable.propTypes = {
  hideActions: PropTypes.bool,
}

export default DimensionsTable
