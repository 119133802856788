export const OFFLINE_COVNERSION_TYPE = 'OFFLINE'

export const mapOfflineConversionDimensionToKpiDropdownEntry = (
  filename,
  { dimensionName, _id, conversion_id }
) => ({
  _id,
  conversion_id,
  id: conversion_id,
  label: dimensionName,
  name: dimensionName,
  type: OFFLINE_COVNERSION_TYPE,
  filename,
})
