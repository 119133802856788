import React, { useEffect, useMemo, useState } from 'react'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'
import cx from 'classnames'

import LineDivider from 'components/line-divider'
import InputText from 'components/input'
import PublisherSelection from 'components/publisher-selection'
import InformationBlock from 'components/information-block'
import Calendar from 'components/calendar'
import { Dropdown } from 'components/dropdown/index'
import ItemRow from 'components/item-row'
import { CheckboxNoHooks } from 'components/checkbox'

import { ACCOUNT_TYPE_ICONS } from 'constants/account'

import { ReactComponent as PauseIcon } from 'assets/icon_pause.svg'
import { ReactComponent as DotIcon } from 'assets/icon_circle-complete.svg'

import { PUBLISHERS } from '../../constants'
import CreateActions from './create-actions'

import '../../style.scss'

/**
 * Create campaign comparison group panel & its functionalities
 * @param {Object} props
 * @param {Function} props.onSaveClicked - Method to be called when the save button is clicked
 * @param {Function} props.onCancelClicked - Method to be called when the cancel button is clicked
 * @param {Object} props.editedGroup - Group object to be edited
 * @param {String} props.comparisons - Current client comparison groups
 * @param {Array<Object>} props.availableCampaigns - Campaigns taht can be grouped
 * @param {Function} props.setGroupIdToBeDeleted - Set group id to be deleted
 */
const CampaignComparisonSettings = ({
  onSaveClicked,
  onCancelClicked,
  editedGroup,
  comparisons,
  availableCampaigns,
  setGroupIdToBeDeleted = () => {},
}) => {
  const [side1Publishers, setSide1Publishers] = useState([...PUBLISHERS])
  const [side2Publishers, setSide2Publishers] = useState([...PUBLISHERS])
  const [comparisonGroup, setComparisonGroup] = useState(editedGroup || {})

  useEffect(() => {
    // When an edited group is provided (on edit group action triggered) update the providers
    if (editedGroup?._id) {
      const { side_1, side_2 } = editedGroup

      const setGroupSide = (side, setPublishers) => {
        setPublishers((currentPublishers) =>
          currentPublishers.map((publisher) => {
            const updatedPublisher = { ...publisher }

            // If the updated group has the current publisher, then mark it as selected
            updatedPublisher.selected = side.publishers.includes(
              updatedPublisher.id
            )

            return updatedPublisher
          })
        )
      }

      setGroupSide(side_1, setSide1Publishers)
      setGroupSide(side_2, setSide2Publishers)
    }
  }, [JSON.stringify(editedGroup)])

  useEffect(() => {
    if (availableCampaigns) {
      const publishersUsed =
        availableCampaigns.reduce((collector, campaign) => {
          const publisherKey = campaign.publisher

          if (collector.includes(publisherKey)) {
            return collector
          } else {
            return [...collector, publisherKey]
          }
        }, []) || []

      const disablePublishers = (setPublishers) => {
        // Whenever the accounts change, we also want to mark as disabled the publishers
        // that do not have accounts
        setPublishers((currentPublishers) => {
          return currentPublishers.map((publisher) => {
            if (
              !publishersUsed.includes(publisher.id) &&
              publisher.id !== 'GLOBAL'
            ) {
              publisher.disabled = true
            } else {
              publisher.disabled = false
            }

            return publisher
          })
        })
      }

      disablePublishers(setSide1Publishers)
      disablePublishers(setSide2Publishers)
    }
  }, [
    JSON.stringify(availableCampaigns),
    JSON.stringify(setSide1Publishers),
    JSON.stringify(setSide2Publishers),
  ])

  const validate = () => {
    const updatedComparison = { ...comparisonGroup }
    let isValid = true

    // Checks for the existence of any other comparison groups with the same name on 'Save' click
    updatedComparison.duplicatedName = comparisons.some((comp) => {
      if (
        comp.name === updatedComparison.name &&
        comp._id !== updatedComparison._id
      ) {
        updatedComparison.duplicatedName = true
        isValid = false
        return true
      }
    })

    if (!updatedComparison.side_1) {
      updatedComparison.side_1 = {}
    }
    if (!updatedComparison.side_2) {
      updatedComparison.side_2 = {}
    }

    if (
      !updatedComparison.side_1.name?.length ||
      !updatedComparison.side_2.name?.length
    ) {
      if (!updatedComparison.side_1.name?.length) {
        updatedComparison.side_1.required = true
        isValid = false
      } else {
        delete updatedComparison.side_1.required
      }

      if (!updatedComparison.side_2.name?.length) {
        updatedComparison.side_2.required = true
        isValid = false
      } else {
        delete updatedComparison.side_2.required
      }
    } else if (
      updatedComparison.side_1.name === updatedComparison.side_2.name
    ) {
      isValid = false
      updatedComparison.side_1.duplicatedName = true
      updatedComparison.side_2.duplicatedName = true
    } else {
      delete updatedComparison.side_1.duplicatedName
      delete updatedComparison.side_2.duplicatedName
      delete updatedComparison.side_1.required
      delete updatedComparison.side_2.required
    }

    if (!updatedComparison.side_1.campaigns?.length) {
      updatedComparison.side_1.noCampaigns = true
      isValid = false
    } else {
      delete updatedComparison.side_1.noCampaigns
    }

    if (!updatedComparison.side_2.campaigns?.length) {
      updatedComparison.side_2.noCampaigns = true
      isValid = false
    } else {
      delete updatedComparison.side_2.noCampaigns
    }

    if (
      (updatedComparison.startDate && !updatedComparison.endDate) ||
      (!updatedComparison.startDate && updatedComparison.endDate) ||
      new Date(updatedComparison.startDate) >
        new Date(updatedComparison.endDate)
    ) {
      updatedComparison.dateError = true
      isValid = false
    } else {
      delete updatedComparison.dateError
    }

    setComparisonGroup(updatedComparison)
    return isValid
  }

  // Validate group's data whenever it's changed
  const [isGroupValid, errors] = useMemo(() => {
    const newErrors = {}
    let isValid = true

    if (comparisonGroup.name === '') {
      newErrors.name = true
      isValid = false
    } else if (!comparisonGroup.name) {
      isValid = false
    }

    return [isValid, newErrors]
  }, [JSON.stringify(comparisonGroup)])

  const onRulePropertyChanged = (value, property, side = null) => {
    if (side) {
      setComparisonGroup({
        ...comparisonGroup,
        [side]: {
          ...comparisonGroup[side],
          [property]: value,
          duplicatedName: false,
          noCampaigns: false,
        },
      })
    } else {
      setComparisonGroup({
        ...comparisonGroup,
        [property]: value,
        duplicatedName: false,
        noCampaigns: false,
      })
    }
  }

  /**
   * Render a campaign row for dropdown and selection list
   * @param {Object} campaign campaign
   * @param {Array} selectedItems selection
   * @param {Boolean} [isSelectionList] render for selection list - no checkbox needed
   * @returns {Node}
   */
  const _renderCampaignRow = (
    campaign,
    selectedItems,
    isSelectionList = false
  ) => {
    const { id, name, publisher, accountID, accountName, status } = campaign

    const PublisherIcon = ACCOUNT_TYPE_ICONS[publisher]
    const isActive = status === 'ACTIVE'
    const CampaignStatus = isActive ? DotIcon : PauseIcon

    const label = (
      <div className="comparison-group-campaign display-flex">
        <PublisherIcon className="comparison-group-campaign-publisher" />
        <CampaignStatus
          className={
            isActive
              ? 'comparison-group-campaign-status-active'
              : 'comparison-group-campaign-status-paused'
          }
        />

        <div className="comparison-group-campaign-label">
          <div className="comparison-group-campaign-name">{name}</div>
          <div className="display-flex">
            <div className="comparison-group-campaign-details">
              Account: {accountName} ({accountID})
            </div>
            <div className="comparison-group-campaign-details comparison-group-campaign-2nd-row">
              Campaign ID: {id}
            </div>
          </div>
        </div>
      </div>
    )

    if (isSelectionList) {
      return label
    }

    return (
      <CheckboxNoHooks
        isChecked={selectedItems?.some(
          ({ id: selectedId }) => selectedId === id
        )}
        label={label}
      />
    )
  }

  const [
    side1AvailableCampaigns,
    side2AvailableCampaigns,
    side1SelectedPublishers,
    side2SelectedPublishers,
  ] = useMemo(() => {
    const selectedSide1Publishers = side1Publishers.reduce(
      (acc, { id, selected }) => (selected ? [...acc, id] : acc),
      []
    )

    const selectedSide2Publishers = side2Publishers.reduce(
      (acc, { id, selected }) => (selected ? [...acc, id] : acc),
      []
    )

    const availableSide1Campaigns = []

    const availableSide2Campaigns = []

    const _mapCampaign = ({ id, name, ...others }) => ({
      id,
      name,
      label: name,
      value: id,
      ...others,
    })

    for (const campaign of availableCampaigns) {
      const campaignSelectedOnSide1 = comparisonGroup.side_1?.campaigns?.some(
        ({ id: otherSideId }) => otherSideId === campaign.id
      )

      const campaignSelectedOnSide2 = comparisonGroup.side_2?.campaigns?.some(
        ({ id: otherSideId }) => otherSideId === campaign.id
      )

      // Campaign NOT selected on side 2 and publisher is selected -> make it available on side 1
      if (
        !campaignSelectedOnSide2 &&
        (selectedSide1Publishers.includes('GLOBAL') ||
          selectedSide1Publishers.includes(campaign.publisher))
      ) {
        availableSide1Campaigns.push(_mapCampaign(campaign))
      }

      // Campaign NOT selected on side 1 and publisher is selected -> make it available on side 2
      if (
        !campaignSelectedOnSide1 &&
        (selectedSide2Publishers.includes('GLOBAL') ||
          selectedSide2Publishers.includes(campaign.publisher))
      ) {
        availableSide2Campaigns.push(_mapCampaign(campaign))
      }
    }

    return [
      availableSide1Campaigns,
      availableSide2Campaigns,
      selectedSide1Publishers,
      selectedSide2Publishers,
    ]
  }, [
    JSON.stringify(availableCampaigns),
    JSON.stringify(side1Publishers),
    JSON.stringify(side2Publishers),
    JSON.stringify(comparisonGroup.side_2?.campaigns),
    JSON.stringify(comparisonGroup.side_1?.campaigns),
  ])

  const onDropdownElementClicked = (
    campaigns,
    selected,
    sideAvailalbeCampaigns,
    side
  ) => {
    const len = campaigns.length
    const removal = campaigns
      .slice(0, len - 1)
      .some(({ id }) => id === campaigns[len - 1])

    let newCampaigns

    if (removal) {
      newCampaigns = selected.filter(({ id }) => id !== campaigns[len - 1])
    } else {
      newCampaigns = sideAvailalbeCampaigns.filter(
        ({ id }) =>
          campaigns.includes(id) ||
          campaigns.some(({ id: innerId }) => innerId === id)
      )
    }

    onRulePropertyChanged(newCampaigns, 'campaigns', side)
  }

  const _renderSide = (side) => {
    const isSide1 = side === 'side_1'
    const publishers = isSide1 ? side1Publishers : side2Publishers
    const campaignsForDropdown = isSide1
      ? side1AvailableCampaigns
      : side2AvailableCampaigns
    const sideAvailableCampaigns = isSide1
      ? side1AvailableCampaigns
      : side2AvailableCampaigns
    const publisherSetter = isSide1 ? setSide1Publishers : setSide2Publishers

    const number = isSide1 ? '1' : '2'

    return (
      <div className="comparison-group-side">
        <div className="width-50 padding-right-0">
          <div>
            <div
              className={cx('general-label', {
                'campaign-exclusions-section__labels label-error':
                  comparisonGroup[side]?.duplicatedName ||
                  comparisonGroup[side]?.required,
              })}
            >
              SIDE {number} NAME
            </div>
            <InputText
              error={
                errors.name ||
                comparisonGroup[side]?.duplicatedName ||
                comparisonGroup[side]?.required
              }
              value={comparisonGroup[side]?.name}
              placeholder={`Enter Name for Side ${number}`}
              onChange={(value) => onRulePropertyChanged(value, 'name', side)}
            />
            {comparisonGroup[side]?.duplicatedName ? (
              <InformationBlock
                className="name-information-block"
                info="Cannot have duplicate side names in the same comparison group"
              />
            ) : null}
          </div>
        </div>

        <PublisherSelection
          label="PUBLISHER FILTER"
          publishers={publishers}
          onSelectionChanged={publisherSetter}
          selectAllButton={false}
        />

        <Dropdown
          hasSearch
          multiSelect
          selectAll
          sortOptions
          disabled={!availableCampaigns?.length}
          defaultOptionText="Select Campaigns"
          options={campaignsForDropdown}
          optionRenderer={(campaign) =>
            _renderCampaignRow(campaign, comparisonGroup[side]?.campaigns)
          }
          overwriteSelectedText="Select Campaigns"
          onChange={(campaigns) =>
            onDropdownElementClicked(
              campaigns,
              comparisonGroup[side]?.campaigns,
              sideAvailableCampaigns,
              side
            )
          }
          selectedItems={comparisonGroup[side]?.campaigns}
          white
          className="comparison-group-side-campaign-dropdown"
          optionsHeight={600}
        />

        {comparisonGroup[side]?.noCampaigns ? (
          <InformationBlock
            className="name-information-block"
            info="At least one campaign needs to be selected"
          />
        ) : null}

        {/* Selected Campaigns */}
        {comparisonGroup[side]?.campaigns?.map(({ id, ...others }) => {
          return (
            <ItemRow
              key={id}
              item={{
                value: _renderCampaignRow({ id, ...others }, null, true),
              }}
              onRemove={() =>
                onRulePropertyChanged(
                  comparisonGroup[side].campaigns.filter(
                    ({ id: selectedId }) => id !== selectedId
                  ),
                  'campaigns',
                  side
                )
              }
            />
          )
        })}
      </div>
    )
  }

  return (
    <>
      <div className="campaign-exclusions-section__title">
        {editedGroup ? 'Edit Comparison Group' : 'Add Comparison Group'}
      </div>
      <LineDivider className="margin-bottom-16" width="unset" widthUnit="" />
      <div className="display-flex">
        <div className="width-50 comparison-group-name">
          <div
            className={cx('general-label', {
              'campaign-exclusions-section__labels label-error':
                comparisonGroup.duplicatedName || comparisonGroup.required,
            })}
          >
            COMPARISON NAME
          </div>
          <InputText
            error={
              errors.name ||
              comparisonGroup.duplicatedName ||
              comparisonGroup.required
            }
            value={comparisonGroup.name}
            placeholder="Enter Comparison Name"
            onChange={(value) => onRulePropertyChanged(value, 'name')}
          />
          {comparisonGroup.duplicatedName ? (
            <InformationBlock
              className="name-information-block"
              info="Comparison group name already exists"
            />
          ) : null}
          {comparisonGroup.dateError ? (
            <InformationBlock
              className="name-information-block"
              info="End date must be larger than start date"
            />
          ) : null}
        </div>
        <div className="filters-date__col comparison-group-date">
          <div className="general-label">
            Start date
            <span className="filters-date__col comparison-group-date-label">
              (Optional)
            </span>
          </div>
          <Calendar
            maxDate={comparisonGroup.endDate}
            error={comparisonGroup.dateError}
            className="input"
            format={'MM/dd/yyyy'}
            placeholder="Select Date"
            date={comparisonGroup.startDate}
            returnDate={(startDate) =>
              onRulePropertyChanged(startDate, 'startDate')
            }
            icon
          />
        </div>
        <div className="filters-date__col comparison-group-date">
          <div className="general-label">
            End date
            <span className="filters-date__col comparison-group-date-label">
              (Optional)
            </span>
          </div>
          <Calendar
            minDate={comparisonGroup.startDate}
            error={comparisonGroup.dateError}
            className="input"
            format={'MM/dd/yyyy'}
            placeholder="Select Date"
            date={comparisonGroup.endDate}
            returnDate={(endDate) => onRulePropertyChanged(endDate, 'endDate')}
            icon
          />
        </div>
      </div>

      {/* Side 1 */}
      {_renderSide('side_1')}

      {/* Side 2 */}
      {_renderSide('side_2')}

      {/* Save / Cancel buttons */}
      {!errors.publishers ? (
        <CreateActions
          saveDisabled={!isGroupValid || isEqual(editedGroup, comparisonGroup)}
          onCancelClicked={() => {
            onCancelClicked()
          }}
          onSaveClicked={() => {
            if (validate()) {
              onSaveClicked({
                ...comparisonGroup,
                side_1: {
                  ...comparisonGroup.side_1,
                  publishers: side1SelectedPublishers,
                },
                side_2: {
                  ...comparisonGroup.side_2,
                  publishers: side2SelectedPublishers,
                },
              })
              onCancelClicked()
            }
          }}
          onDeleteClicked={
            editedGroup
              ? () => {
                  setGroupIdToBeDeleted(editedGroup._id)
                }
              : null
          }
          hasDelete={!!editedGroup}
        />
      ) : null}
    </>
  )
}

export default CampaignComparisonSettings

CampaignComparisonSettings.propTypes = {
  onCancelClicked: PropTypes.func.isRequired,
  onSaveClicked: PropTypes.func.isRequired,
  editedGroup: PropTypes.object,
  comparisons: PropTypes.array,
  availableCampaigns: PropTypes.array,
  setGroupIdToBeDeleted: PropTypes.func,
}
