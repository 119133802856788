import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// Utils
import { useStore } from 'store'
import { socket } from '@decision-sciences/qontrol-common'
import { format } from 'date-fns'
import { showErrorMessage } from 'modules/notifications/actions'
import { initGetRecommendedThresholds } from 'modules/alert-thresholds/actions'

// Components
import Input from 'components/input'
import Table from 'components/table/beta'
import { StaticTooltip } from 'components/tooltip'
import {
  ThresholdRecommendationCell,
  ThresholdRecommendationHeader,
} from 'modules/alerts/alerts-thresholds-statistics/threshold-recommendations/cell'
import { BulkAssignRecommendationsModal } from 'modules/alerts/alerts-thresholds-statistics/threshold-recommendations/modal'

// Hooks
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'
import { useSocket } from 'components/utils/socket'

// Icons
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'

import AlertValueDotsGroup, {
  cellDots,
  headerDots,
} from './subsections/alert-thresholds/alert-values-dot-group'

const { NOTIFICATIONS } = socket

/**
 * Displays a list of alert Thresholds
 * @param {Object} props Props
 * @param {Boolean} props.isViewMode Flag to enable view mode
 * @param {Array} [props.alertThresholds] List of Alert Thresholds to show
 * @param {Object} props.company Currently edited company
 * @param {Object} props.defaultCompany Currently saved edited company
 * @param {Function} props.onChange Called on changing a value in the alert thresholds panel
 * @param {Boolean} props.loading Loading state flag
 * @param {Array<Object>} props.copyFromCompanyThresholds The thresholds which are copied from Client/other BU
 * @returns {React.Component}
 */
const AlertThresholdsPanel = ({
  isViewMode,
  alertThresholds = [],
  defaultCompany,
  company,
  onChange,
  loading,
  copyFromCompanyThresholds,
}) => {
  const { dispatch } = useStore()
  const [recommendedThresholds, setRecommendedThresholds] = useState({})
  const [recommendedThresholdsLoading, setRecommendedThresholdsLoading] =
    useState({})
  const [recommendedThresholdsPerMetric, setRecommendedThresholdsPerMetric] =
    useState({})
  const [bulkAssignModalVisible, setBulkAssignModalVisible] = useState(false)
  const [modifiedThresholds, setModifiedThresholds] = useState({})
  const [companyThresholds, setCompanyThresholds] = useState(
    company?.alertThresholds || []
  )
  useEffect(() => {
    setCompanyThresholds(company.alertThresholds || [])
  }, [company.alertThresholds])
  const originalThresholds = defaultCompany?.alertThresholds || []

  const unsetCount =
    alertThresholds.length - Object.entries(modifiedThresholds).length

  const editAccess = useAccess({
    feature: PERMISSIONS.ALERT_THRESHOLDS,
    type: PERMISSION_TYPES.EDIT,
  })

  const viewAccess = useAccess({
    feature: PERMISSIONS.ALERT_THRESHOLDS,
    type: PERMISSION_TYPES.READ,
  })

  const viewMode = viewAccess && !editAccess

  const tooltipAlerts = Object.values(
    alertThresholds.reduce((prev, current) => {
      current.alerts.forEach((alert) => {
        if (prev[alert._id]) {
          prev[alert._id].thresholdsUsed += alert.thresholdsUsed
        } else {
          prev[alert._id] = structuredClone(alert)
        }
      })
      return prev
    }, {})
  )

  // Fetch recommended thresholds
  useEffect(() => {
    // Array to store alert IDs and times
    const alertIdsAndTimes = []

    // Extract alert IDs and times from each alert object
    alertThresholds.forEach((alert) => {
      alert.alerts.forEach((alertDetail) => {
        alertIdsAndTimes.push({
          _id: alertDetail._id,
          time: alertDetail.time,
        })
      })
    })

    // Filter to get unique alert IDs and times
    const uniqueAlerts = alertIdsAndTimes.filter(
      (alert, index, self) =>
        index ===
        self.findIndex((t) => t._id === alert._id && t.time === alert.time)
    )

    // Make request only if uniqueAlerts is not empty and company is not new
    if (uniqueAlerts.length > 0 && !company.new) {
      // Initialize loading states for recommended thresholds
      setRecommendedThresholdsLoading(
        alertThresholds.reduce((acc, { _id }) => ({ ...acc, [_id]: true }), {})
      )

      initGetRecommendedThresholds(company._id, uniqueAlerts).catch((error) => {
        showErrorMessage(error, dispatch)
      })
    }
  }, [JSON.stringify(alertThresholds)])

  // Initialise modified thresholds
  useEffect(() => {
    const alertThresholdsIds = alertThresholds.map(({ _id }) => _id)
    const clientThr = companyThresholds?.some(({ globalThreshold }) =>
      alertThresholdsIds.includes(globalThreshold)
    )
    if (!Object.keys(modifiedThresholds).length && clientThr) {
      const modified = {}
      alertThresholds.forEach((thr) => {
        const clientThr = companyThresholds.find(
          ({ globalThreshold }) => globalThreshold === thr._id
        )
        if (clientThr) {
          modified[thr._id] = clientThr.value
        }
      })
      setModifiedThresholds(modified)
    }
  }, [
    JSON.stringify(companyThresholds),
    JSON.stringify(alertThresholds),
    modifiedThresholds,
  ])

  useEffect(() => {
    if (copyFromCompanyThresholds && Array.isArray(copyFromCompanyThresholds)) {
      setCompanyThresholds(copyFromCompanyThresholds)
      onChange(copyFromCompanyThresholds)
    }
  }, [copyFromCompanyThresholds])

  const socket = useSocket({
    room: `${company._id}_recommended_thresholds`,
  })
  useEffect(() => {
    if (socket?.connected) {
      socket.on(
        NOTIFICATIONS.dailyPerformanceThresholdRecommendations.receive,
        ({ success, data }) => {
          if (!success) {
            showErrorMessage(
              `Error fetching thresholds recomendations for Alert Thresholds Table`,
              dispatch
            )
          } else {
            updateRecommendedThresholds(alertThresholds, data)
          }
        }
      )
    }

    return () => {
      socket?.removeAllListeners(
        NOTIFICATIONS.dailyPerformanceThresholdRecommendations.receive
      )
    }
  }, [socket?.connected, JSON.stringify(alertThresholds)])

  /**
   * Map recommended threshold: metric type -> threshold
   * Set loaders for each threshold
   * Don't recommend if:
   *     - there is no metric type set on the threshold
   *     - the threshold represents a percentage
   * @param {Array<Object>} alertThresholds list of thresholds
   * @param {Object} recommendations threshold recommendations per metric type
   */
  const updateRecommendedThresholds = (
    alertThresholds,
    recommendations = {}
  ) => {
    if (!alertThresholds.length) {
      setRecommendedThresholdsPerMetric((existingRecommendationsPerMetric) => ({
        ...existingRecommendationsPerMetric,
        ...recommendations,
      }))

      return
    }

    recommendations = { ...recommendations, ...recommendedThresholdsPerMetric }

    if (!Object.keys(recommendations).length) {
      return
    }

    const newRecommendedThresholdsLoading = {}
    const newThresholdRecommendations = alertThresholds.reduce(
      (acc, { _id, metric, percentage }) => {
        const value = recommendations?.[metric]

        if (!metric || percentage) {
          newRecommendedThresholdsLoading[_id] = false

          return {
            ...acc,
            [_id]: null,
          }
        }

        newRecommendedThresholdsLoading[_id] =
          recommendedThresholds[_id] || recommendedThresholds[_id] === 0
            ? false
            : !value && value !== 0

        return {
          ...acc,
          [_id]:
            recommendedThresholds[_id] || recommendedThresholds[_id] === 0
              ? recommendedThresholds[_id]
              : value,
        }
      },
      {}
    )

    setRecommendedThresholds((existingRecommendations) => ({
      ...existingRecommendations,
      ...newThresholdRecommendations,
    }))
    setRecommendedThresholdsLoading(
      alertThresholds.reduce((acc, { _id }) => ({ ...acc, [_id]: false }), {})
    )
  }

  const changeBulkAssignModalVisibility = (visible) => () => {
    setBulkAssignModalVisible(visible)
  }

  const onThresholdChange = (value, id) => {
    const updatedThresholds = [...companyThresholds]
    const index = updatedThresholds.findIndex(
      (threshold) => threshold.globalThreshold === id
    )

    if (index !== -1) {
      if (!value) {
        updatedThresholds.splice(index, 1)
      } else {
        updatedThresholds[index] = {
          ...updatedThresholds[index],
          value: value,
          changed: true,
          updatedAt: Date.now(),
        }
      }
    } else if (value) {
      updatedThresholds.push({
        globalThreshold: id,
        value: value,
        changed: true,
        updatedAt: Date.now(),
      })
    }

    setModifiedThresholds((currentModifiedThresholds) =>
      value === ''
        ? (() => {
            const newModifiedThresholds = { ...currentModifiedThresholds }
            delete newModifiedThresholds[id]
            return newModifiedThresholds
          })()
        : {
            ...currentModifiedThresholds,
            [id]: value,
          }
    )

    onChange(updatedThresholds)
  }

  const onBulkThresholdChange = (overwrite) => {
    const newThresholds = [...companyThresholds]
    const modified = { ...modifiedThresholds }

    Object.entries(recommendedThresholds).forEach(([key, value]) => {
      const clientThreshold = modified[key]

      if (overwrite) {
        if (value) {
          const index = newThresholds.findIndex(
            (threshold) => threshold.globalThreshold === key
          )

          if (index !== -1) {
            newThresholds[index] = {
              ...newThresholds[index],
              value: parseFloat(value),
              updatedAt: Date.now(),
            }
          } else {
            const newThreshold = {
              globalThreshold: key,
              value: parseFloat(value),
              updatedAt: Date.now(),
            }
            newThresholds.push(newThreshold)
          }

          modified[key] = value
        }
      } else {
        if (!clientThreshold && value) {
          const index = newThresholds.findIndex(
            (threshold) => threshold.globalThreshold === key
          )

          if (index !== -1) {
            newThresholds[index] = {
              ...newThresholds[index],
              value: parseFloat(value),
              updatedAt: Date.now(),
            }
          } else {
            const newThreshold = {
              globalThreshold: key,
              value: parseFloat(value),
              updatedAt: Date.now(),
            }
            newThresholds.push(newThreshold)
          }

          modified[key] = value
        }
      }
    })

    setModifiedThresholds(modified)
    onChange(newThresholds)
    changeBulkAssignModalVisibility(false)()
  }

  const columns = [
    {
      header: 'Name',
      accessorKey: 'name',
      textAlign: 'left',
      cellTextAlign: 'left',
      sortingFn: 'name',
      size: 200,
    },
    {
      header: () => <AlertValueDotsGroup dots={headerDots} />,
      id: 'alertValue',
      cell: ({ row }) => (
        <AlertValueDotsGroup dots={cellDots(company._id)} row={row} />
      ),
    },
    {
      header: 'Global',
      id: 'globalThreshold',
      cell: (cell) => (
        <span
          className={
            companyThresholds?.find(
              (t) =>
                t.globalThreshold.toString() ===
                cell.row.original._id.toString()
            )
              ? 'alert-thresholds-panel__default'
              : 'font-bold'
          }
        >
          {new Intl.NumberFormat('en-US').format(cell.row.original.value)}
        </span>
      ),
      textAlign: 'right',
      cellTextAlign: 'right',
      size: 160,
      sortType: 'numberInTag',
      accessorFn: (row) => {
        return row.value
      },
    },
    {
      header: 'Client',
      id: 'clientThreshold',
      cell: (cell) => {
        const { _id } = cell.row.original
        const foundIdx = companyThresholds?.findIndex(
          (t) => t.globalThreshold.toString() === _id.toString()
        )
        const foundDefaultIdx = originalThresholds.findIndex(
          ({ globalThreshold }) => _id === globalThreshold
        )

        const value = companyThresholds?.[foundIdx]?.value
        const defaultValue = originalThresholds[foundDefaultIdx]?.value

        return (
          <Input
            className={cx('client-threshold')}
            placeholder=" -- "
            onBlur={(ev) => {
              // Update only if an actual change has been made
              if ((value?.toString() || '') !== ev.target.value) {
                onThresholdChange(ev.target.value, _id)
              }
            }}
            value={value?.toString()}
            type="budget"
            disabled={viewMode}
            min={0}
            hasChanges={foundIdx !== -1 && value !== defaultValue}
            isBordered
          />
        )
      },
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 200,
      sortType: 'numberInTag',
      disableSortBy: !companyThresholds?.length,
      accessorFn: (row) => {
        const { _id } = row
        const foundIdx = companyThresholds?.findIndex(
          (t) => t.globalThreshold.toString() === _id.toString()
        )
        return companyThresholds?.[foundIdx]?.value || 0
      },
    },
    {
      header: () => {
        return (
          <ThresholdRecommendationHeader
            alertThresholds={alertThresholds}
            clientThresholds={companyThresholds}
            recommendedThresholds={recommendedThresholds}
            openBulkAssignModal={changeBulkAssignModalVisibility(true)}
          />
        )
      },
      textAlign: 'right',
      id: 'recommended-threshold',
      size: 250,
      maxSize: 250,
      cell: (cell) => {
        return (
          <ThresholdRecommendationCell
            threshold={cell.row.original}
            clientThresholds={modifiedThresholds}
            recommendedThresholds={recommendedThresholds}
            recommendedThresholdsLoading={recommendedThresholdsLoading}
            onThresholdChange={onThresholdChange}
          />
        )
      },
    },
    {
      header: 'Last Modified',
      id: 'lastModified',
      accessorFn: (row) => {
        if (!row) {
          return null
        }
        const current = companyThresholds.find(
          (t) => t.globalThreshold.toString() === row._id.toString()
        )
        const date = current?.updatedAt || row.updatedAt
        return date && new Date(date)
      },
      cell: (cell) =>
        cell.getValue('lastModified') &&
        format(cell.getValue('lastModified'), 'MM/dd/yyyy hh:mm aa'),
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 200,
    },
    {
      header: 'Modified By',
      accessorFn: (row) => {
        if (!row) {
          return null
        }
        const current = companyThresholds.find(
          (t) => t.globalThreshold.toString() === row._id.toString()
        )
        const user = current?.updatedBy || row.updatedBy

        if (!user) {
          return ''
        }

        return `${user?.firstName} ${user?.lastName}`
      },
      textAlign: 'center',
      cellTextAlign: 'center',
      size: 200,
    },
    {
      header: () => {
        return (
          <div className="display-flex gap-8 align-items-center associated-alerts__info-icon">
            Other Alerts
            <StaticTooltip
              tooltipClassName="associated-alerts__tooltip"
              content={_renderAssociatedAlertsTable(tooltipAlerts)}
            >
              <InfoIcon />
            </StaticTooltip>
          </div>
        )
      },
      id: 'alerts',
      cell: ({
        row: {
          original: { alerts },
        },
      }) => <AssociatedAlerts alerts={alerts} />,
      textAlign: 'left',
      cellTextAlign: 'left',
      size: 100,
      maxSize: 100,
    },
  ]

  return (
    <div className="alert-thresholds-panel">
      <Table
        id="alert-thresholds-table"
        columns={columns}
        loading={loading}
        data={alertThresholds}
        paginationValues={[5, 10, 20, 50, 100]}
        showPagination={true}
        showSearchInput={true}
      />
      {bulkAssignModalVisible && (
        <BulkAssignRecommendationsModal
          visible={bulkAssignModalVisible}
          onAssign={onBulkThresholdChange}
          onClose={changeBulkAssignModalVisibility(false)}
          context="alertThresholds"
          unsetCount={unsetCount}
          totalCount={alertThresholds.length}
        />
      )}
    </div>
  )
}

const _renderAssociatedAlertsTable = (alerts) => {
  const columns = [
    {
      header: 'Alert',
      accessorKey: 'name',
    },
    {
      header: 'Used',
      accessorFn: (row) => {
        return row.thresholdsUsed
      },
      textAlign: 'center',
      size: 80,
      maxSize: 80,
    },
  ]
  return <Table height={430} disableSort columns={columns} data={alerts} />
}

const AssociatedAlerts = ({ alerts }) => {
  return (
    <div className="associated-alerts">
      {alerts?.length ? (
        <StaticTooltip
          tooltipClassName="associated-alerts__tooltip"
          content={_renderAssociatedAlertsTable(alerts)}
        >
          <div className="hoverable">{alerts.length}</div>
        </StaticTooltip>
      ) : (
        0
      )}
    </div>
  )
}

AssociatedAlerts.propTypes = {
  alerts: PropTypes.array.isRequired,
}

AlertThresholdsPanel.propTypes = {
  isViewMode: PropTypes.bool,
  alertThresholds: PropTypes.array,
  company: PropTypes.object.isRequired,
  defaultCompany: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  copyFromCompanyThresholds: PropTypes.arrayOf(Object),
}
export default AlertThresholdsPanel
