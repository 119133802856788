import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import { NOT_FOUND_ROUTE } from 'routes'
import {
  alert,
  granularities,
  notifications,
  accounts,
} from '@decision-sciences/qontrol-common'
import { getAlertTemplates } from 'modules/alert-templates/actions'
import useLeaveConfirm from 'components/leave-confirm'
import {
  showErrorMessage,
  showSuccessMessage,
} from 'modules/notifications/actions'
import {
  createUpdateCompany,
  setCurrentCompany,
} from 'modules/companies/actions'
import { PrimaryComparisonPeriodSection } from 'modules/alerts/primary-comparison-period'
import StickyFooter from 'components/sticky-footer'
import Loader from 'components/loader'
import { fetchAlert } from 'modules/alerts/actions'
import { AlertMessage } from 'modules/alerts/alert-message'
import { getAlertTrigger } from 'modules/alert-triggers/actions'
import EntityDetails from 'modules/alert-triggers/alert-trigger-details/sections/entity-details'
import { getVariableMetrics } from 'modules/calculated-metrics/actions'
import { AlertDetailsSection } from 'modules/alert-triggers/alert-trigger-details/sections/alert-details'
import { AlertTriggerActivityLog } from 'modules/alert-triggers/alert-trigger-details/sections/activity-log'
import { AlertTriggerDetails } from 'modules/alert-triggers/alert-trigger-details/sections/trigger-details'
import { getTooltipList } from 'components/utils/tooltip'

import { ReactComponent as LeftArrow } from 'assets/icon_arrow_white_leg.svg'
import { ReactComponent as Checkmark } from 'assets/icon_checkmark.svg'

import './styles.scss'

const { replaceNAN } = notifications
const {
  GRANULARITIES: { ACCOUNT, CAMPAIGN, AD_GROUP },
  GRANULARITY_DETAILS,
} = granularities
const { ACCOUNT_TYPE_CLIENT } = accounts
const { ALERT_TYPES_MAP } = alert

const DEFAULT_ALERT_SNAPSHOT = { name: '', description: '', category: '' }

const AlertTriggerDetailsPage = ({ alertTriggerId }) => {
  const navigate = useNavigate()
  const { dispatch, state } = useStore()
  const [setDirty, LeaveConfirm, dirty] = useLeaveConfirm({})

  const [loading, setLoading] = useState(true)
  const [variables, setVariables] = useState([])
  const [triggerDetails, setTriggerDetails] = useState({
    client: '',
    alertSnapshot: { ...DEFAULT_ALERT_SNAPSHOT },
    createdAt: '',
    accountId: {},
    alertType: '',
    elementType: null,
    entitiesWithDetails: {},
    activityLog: [],
    status: null,
  })
  const [clientAlert, setClientAlert] = useState(null)
  const [selectedEntity, setSelectedEntity] = useState(null)

  const [modifiedThresholds, setModifiedThresholds] = useState({})

  const {
    client: accountType,
    alertSnapshot,
    createdAt,
    accountId,
    alertType,
    elementType,
    entitiesWithDetails,
    comparisonMetricDates,
    activityLog,
    status,
    alert,
    companyId,
  } = triggerDetails

  // Types of entities that triggered the alert
  const triggerType = elementType || Object.keys(entitiesWithDetails)?.[0]

  const { _id: alertId, template: alertTemplate } = alert || {}

  const {
    alertTemplates: { list: alertTemplates },
    companies: { list: availableCompanies, currentCompany: company },
  } = state

  const {
    name: alertName,
    description,
    category,
  } = alertSnapshot || { ...DEFAULT_ALERT_SNAPSHOT }

  const isAlertThresholdsLinked = useMemo(() => {
    if (!company.parentCompany) {
      return false
    }
    return company.parentCompany.links?.alertThresholds?.[company._id]
  }, [company.links?.alertThresholds])

  const companyThresholds = isAlertThresholdsLinked
    ? company.parentCompany.alertThresholds
    : company.alertThresholds

  const thresholdsChanged = () =>
    Object.keys(modifiedThresholds).some((thr) => {
      const existingThr = companyThresholds.find(
        ({ globalThreshold }) => globalThreshold === thr
      )
      if (existingThr) {
        return existingThr.value !== modifiedThresholds[thr]
      }
      return !!modifiedThresholds[thr]
    })

  useEffect(() => {
    if (thresholdsChanged() && !dirty) {
      setDirty(true)
    }
  }, [modifiedThresholds])

  useEffect(() => {
    if (!alertTemplates) {
      getAlertTemplates(dispatch)
    }
    getVariableMetrics().then(setVariables)
  }, [])

  // Check if viewed threshold is for a different company than from the one selected
  useEffect(() => {
    if (companyId && company._id && companyId !== company._id) {
      const triggerCompany = availableCompanies.find(
        (c) =>
          c._id === companyId ||
          c.businessUnits.find((bu) => bu._id === companyId)
      )
      if (triggerCompany) {
        dispatch(setCurrentCompany(triggerCompany))
        return
      }
      navigate('/unauthorized')
    }
  }, [companyId, company._id])

  useEffect(() => {
    if (alertId) {
      fetchAlert(alertId, company._id).then(setClientAlert).catch(console.error)
    }
  }, [alertId, company._id])

  // Get alert trigger data by id
  useEffect(() => {
    const fetchAlertTrigger = async () => {
      const alertTrigger = await getAlertTrigger(alertTriggerId)

      if (!alertTrigger?.success || alertTrigger?.error) {
        console.error('Error getting alert trigger', alertTrigger?.error)
        navigate(NOT_FOUND_ROUTE, true)
        return
      }

      setLoading(false)
      if (alertTrigger?.result) {
        setTriggerDetails({ ...alertTrigger.result })
      }
    }

    fetchAlertTrigger()
  }, [alertTriggerId])

  const limitWidthName = window.screen.width <= 1440 ? 40 : 95

  // Build table data and columns
  const [triggerTableData, triggerTableColumns] = useMemo(() => {
    let data = []

    const columns = [
      {
        header: 'Triggered by',
        accessorKey: 'triggeredBy',
        textAlign: 'left',
        cellTextAlign: 'left',
        size: 100,
        cell: ({ cell }) =>
          `${cell.row.original.triggeredBy?.substring(0, limitWidthName)}${
            cell.row.original.triggeredBy?.length >= limitWidthName ? '...' : ''
          }`,
        tooltip: (row) => {
          return row.triggeredBy?.length >= limitWidthName
            ? getTooltipList('Triggered by', [row.triggeredBy])
            : null
        },
      },
      {
        header: 'Details',
        accessorKey: 'details',
        textAlign: 'left',
        // eslint-disable-next-line react/prop-types
        cell: ({ cell }) => (
          <span
            className="alert-trigger-details__entity-table__details"
            // eslint-disable-next-line react/prop-types
            dangerouslySetInnerHTML={{
              __html: `${cell.row.original.details?.substring(
                0,
                limitWidthName
              )}${
                cell.row.original.details?.length >= limitWidthName ? '...' : ''
              }`,
            }}
          />
        ),
        tooltip: (row) => {
          return row.details?.length >= limitWidthName
            ? getTooltipList('Details', [row.details])
            : null
        },
        cellTextAlign: 'left',
        size: 100,
      },
    ]

    if (triggerType !== ACCOUNT) {
      data = entitiesWithDetails?.[triggerType]
        ?.filter(({ triggered }) => triggered === undefined || triggered)
        ?.map((entity) => {
          const { name, campaign, adSet, id, adgroup_id } = entity

          // If we find parent data, add columns
          if (
            triggerType !== CAMPAIGN &&
            campaign &&
            !columns.some((col) => col.accessorKey === 'campaign')
          ) {
            columns.push({
              header: GRANULARITY_DETAILS.CAMPAIGN.labels[accountType],
              accessorKey: 'campaign',
              textAlign: 'left',
              cellTextAlign: 'left',
              size: 100,
            })
          }

          const adGroup = adSet || adgroup_id

          if (
            triggerType !== AD_GROUP &&
            adGroup &&
            !columns.some((col) => col.accessorKey === 'adSet')
          ) {
            columns.push({
              header: GRANULARITY_DETAILS.AD_GROUP.labels[accountType],
              accessorKey: 'adSet',
              textAlign: 'left',
              cellTextAlign: 'left',
              size: 100,
            })
          }

          return {
            _id: id,
            triggeredBy: name || id,
            details: replaceNAN(entity.message),
            campaign: campaign?.name || campaign || null,
            adSet: adGroup?.name || adGroup || null,
          }
        })
    }

    return [data, columns]
  }, [triggerType, entitiesWithDetails])

  const comparisonState = useMemo(() => {
    if (!triggerDetails || !alertTemplate?.comparison) {
      return {}
    }
    return {
      comparison: { ...alertTemplate.comparison },
      isActualResult: true,
      comparisonMetricDates,
    }
  }, [triggerDetails, alertTemplate])

  const updateAlertState = (values) => {
    const updated = Object.keys(values).reduce(
      (acc, curr) => ({ ...acc, [curr]: values[curr] }),
      {}
    )

    setTriggerDetails({
      client: accountType,
      alertSnapshot: { name: alertName, description, category },
      createdAt,
      accountId,
      alertType,
      elementType,
      entitiesWithDetails,
      activityLog,
      status,
      ...updated,
    })
  }

  const onSaveThresholds = () => {
    setDirty(false)
    const modifiedThrs = Object.keys(modifiedThresholds)
    // If no thresholds changed, close the panel
    if (!modifiedThrs.length) {
      return
    }
    const newCompanyThresholds = [...companyThresholds]
    modifiedThrs.forEach((id) => {
      const cliThrIdx = newCompanyThresholds.findIndex(
        ({ globalThreshold }) => globalThreshold === id
      )
      if (modifiedThresholds[id]) {
        const newThreshold = {
          globalThreshold: id,
          value: parseFloat(modifiedThresholds[id]),
          updatedAt: Date.now(),
        }
        if (cliThrIdx !== -1) {
          newCompanyThresholds[cliThrIdx] = newThreshold
        } else {
          newCompanyThresholds.push(newThreshold)
        }
      } else {
        if (cliThrIdx !== -1) {
          newCompanyThresholds.splice(cliThrIdx, 1)
        }
      }
    })
    createUpdateCompany(
      dispatch,
      { _id: company._id, alertThresholds: newCompanyThresholds },
      company._id
    )
      .then((res) => {
        if (res.error) {
          showErrorMessage(
            `Error when updating thresholds: ${res.error}`,
            dispatch
          )
          return
        }
        showSuccessMessage('Client thresholds updated successfully', dispatch)
      })
      .catch((err) => {
        showErrorMessage(`Error when updating thresholds: ${err}`, dispatch)
        setDirty(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div className="alert-trigger-details">
      <Helmet>
        <title>Alert Triggered</title>
      </Helmet>

      <LeaveConfirm />

      <AlertTriggerDetails
        alertTriggerId={alertTriggerId}
        triggerDetails={triggerDetails}
        updateAlertState={updateAlertState}
        setLoading={setLoading}
      />

      {alertType === ALERT_TYPES_MAP.PERFORMANCE && (
        <PrimaryComparisonPeriodSection
          state={comparisonState}
          hasEditAccess={false}
          variables={variables}
        />
      )}

      <EntityDetails
        elementType={triggerType}
        accountType={accountType}
        setSelectedEntity={setSelectedEntity}
        accountId={accountId}
        triggerTableData={triggerTableData}
        triggerTableColumns={triggerTableColumns}
      />

      {accountType !== ACCOUNT_TYPE_CLIENT && (
        <AlertDetailsSection
          selectedEntity={selectedEntity}
          alert={clientAlert}
          alertTrigger={triggerDetails}
          emitModifiedThresholds={setModifiedThresholds}
          triggerTableData={triggerTableData}
          granularity={elementType}
          accountType={accountType}
          setSelectedEntity={setSelectedEntity}
        />
      )}

      <div className="form__section">
        <AlertMessage
          viewOnly
          collapsible
          alert={clientAlert}
          currentTemplate={clientAlert?.template}
        />
      </div>

      {accountType !== ACCOUNT_TYPE_CLIENT && (
        <AlertTriggerActivityLog activityLog={activityLog} />
      )}

      <StickyFooter
        buttons={[
          {
            compact: true,
            secondary: true,
            value: (
              <div className="align-row gap-8">
                <LeftArrow
                  width={18}
                  height={18}
                  style={{ transform: 'rotate(180deg)' }}
                />
                <span>Back to Active Alerts</span>
              </div>
            ),
            onClick: () => navigate('/alert-triggers'),
            secondaryGray: true,
          },
          {
            compact: true,
            secondaryGreen: true,
            value: (
              <div className="align-row gap-8">
                <Checkmark width={18} height={18} />
                <span>Save</span>
              </div>
            ),
            onClick: onSaveThresholds,
            secondaryGray: true,
          },
        ]}
      />
    </div>
  )
}

AlertTriggerDetailsPage.propTypes = {
  alertTriggerId: PropTypes.string.isRequired,
}

export default AlertTriggerDetailsPage
