import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  isAfter,
  isEqual,
  addDays,
  parse as parseDate,
  differenceInDays,
  format,
} from 'date-fns'
import cx from 'classnames'

/* Components */
import InputGroup from 'components/input-group'
import { Dropdown } from 'components/dropdown/index'
import InputText from 'components/input'
import CalendarComp from 'components/calendar/index'
import { AccountDropdownRow } from 'components/account-icon/index'
import DropdownPreviewSelected from 'components/dropdown-preview-selected/index'
import { DEFAULT_DATE_FORMAT } from 'components/utils/date'

/* Utils */
import { ReactComponent as IconRemove } from 'assets/icon_remove_circle_outline.svg'
import { budgetPacing } from '@decision-sciences/qontrol-common'
import DimensionInputGroups from '../dimension-input-groups/index'

const { BUDGET_PACING_KPI_TYPE, BUDGET_PACING_DATE_GRANULARITY } = budgetPacing

/**
 * Renders the budget input group row
 * @param {Object} props
 * @param {Object} props.budget - Current displayed budget
 * @param {Object} props.error - Error for budget
 * @param {Boolean} props.isEditingBudget - Is budget in edit mode
 * @param {Object} props.dimensions - Current client dimension object
 * @param {Object} props.budgetSegmentsList - Budget Segments List
 * @param {Array} props.conversionList - Conversion list used for Assoc. Conversions
 * @param {Function} props.onBudgetChanged - Method to be called when any property is changed on the budget
 * @param {Function} props.onRemoveClicked - Method to be called when the remove button is clicked
 */
const BudgetInputGroup = ({
  budget,
  error = {},
  isEditingBudget,
  dimensions,
  budgetSegmentsList,
  conversionList,
  onBudgetChanged,
  onRemoveClicked,
}) => {
  /* We have to add a "key" prop (accountID + ID) to KPIs because IDs are not unique */
  const parsedConversions = useMemo(
    () =>
      budget?.associatedConversions?.map((el) => ({
        ...el,
        key: `${el.accountId}_${el.conversionId}`,
      })),
    [JSON.stringify(budget?.associatedConversions)]
  )

  const onPropertyChanged = (value, property) => {
    const updatedBudget = {
      ...budget,
      [property]: value,
    }

    // When editing an existing budget pacing, if the KPI type is changed, the KPI target should be cleared for updating
    if (isEditingBudget && property === 'kpiType') {
      updatedBudget.kpiTarget = null
    }
    if (property === 'dateGranularity') {
      updatedBudget.dateGranularityAutoGenerated = false
    }

    onBudgetChanged({
      budget: updatedBudget,
      error: { ...error, [property]: null },
    })
  }

  const onStartDateChanged = (date) => {
    const changes = {
      ...budget,
      startDate: date,
      dateGranularityAutoGenerated: false,
    }

    // In case the user selects a startDate that is after the current endDate
    // We set the endDate to be 1 day after the startDate
    const startDate = parseDate(date, DEFAULT_DATE_FORMAT, new Date())
    const endDate = budget?.endDate
      ? parseDate(budget.endDate, DEFAULT_DATE_FORMAT, new Date())
      : null
    if (
      endDate &&
      (isAfter(startDate, endDate) || isEqual(startDate, endDate))
    ) {
      changes.endDate = format(addDays(startDate, 1), DEFAULT_DATE_FORMAT)
    }
    handleStartEndDateChanges(changes)

    onBudgetChanged({ budget: changes, error: { ...error, startDate: null } })
  }

  const onEndDateChanged = (date) => {
    const changes = {
      ...budget,
      endDate: date,
      dateGranularityAutoGenerated: false,
    }
    handleStartEndDateChanges(changes)

    onBudgetChanged({ budget: changes, error: { ...error, endDate: null } })
  }

  /** When start/end dates change, adjust the Date Granularity automatically */
  const handleStartEndDateChanges = (changedBudget) => {
    const { startDate, endDate } = changedBudget
    if (startDate && endDate) {
      const start = parseDate(startDate, DEFAULT_DATE_FORMAT, new Date())
      const end = parseDate(endDate, DEFAULT_DATE_FORMAT, new Date())
      const dayDiff = differenceInDays(end, start)
      let dateGranularity = BUDGET_PACING_DATE_GRANULARITY.CUSTOM
      if (dayDiff === 1) {
        dateGranularity = BUDGET_PACING_DATE_GRANULARITY.DAILY
      } else if (dayDiff >= 5 && dayDiff <= 7) {
        dateGranularity = BUDGET_PACING_DATE_GRANULARITY.WEEKLY
      } else if (dayDiff >= 28 && dayDiff <= 31) {
        dateGranularity = BUDGET_PACING_DATE_GRANULARITY.MONTHLY
      } else if (dayDiff >= 77 && dayDiff <= 98) {
        dateGranularity = BUDGET_PACING_DATE_GRANULARITY.QUARTERLY
      } else if (dayDiff >= 358 && dayDiff <= 373) {
        dateGranularity = BUDGET_PACING_DATE_GRANULARITY.YEARLY
      }
      changedBudget.dateGranularity = dateGranularity
      changedBudget.dateGranularityAutoGenerated =
        dateGranularity !== budget.dateGranularity
    }
    return changedBudget
  }

  const onAssocConversionsChange = (conversions) => {
    const selectedConversions = conversionList
      .filter((el) => conversions.indexOf(el.key) > -1)
      .map((c) => ({
        conversionId: c.id,
        conversionName: c.name,
        publisher: c.type,
        accountId: c.accountId,
      }))
    onPropertyChanged(selectedConversions, 'associatedConversions')
  }

  const topInputGroupOptions = () => {
    const options = [
      {
        render: (
          <InputText
            className="budget-prefix"
            prefix={'$'}
            type="budget"
            error={error.budgetAmount}
            onChange={(v) => onPropertyChanged(v, 'budgetAmount')}
            value={budget.budgetAmount || ''}
          />
        ),
        error: error.budgetAmount,
        label: (
          <div
            className={cx('general-label', {
              'general-label--error': error.budgetAmount,
            })}
          >
            Budget Amount
          </div>
        ),
        width: '25%',
      },
      {
        render: (
          <div className="width-100">
            <CalendarComp
              icon
              placeholder="Enter Date"
              date={budget.startDate}
              error={error.startDate}
              returnDate={onStartDateChanged}
            />
          </div>
        ),
        error: error.startDate,
        label: (
          <div
            className={cx('general-label', {
              'general-label--error': error.startDate,
            })}
          >
            Start Date
          </div>
        ),
      },
      {
        render: (
          <div className="width-100">
            <CalendarComp
              icon
              placeholder="Enter Date"
              date={budget.endDate}
              minDate={addDays(new Date(budget.startDate), 1)}
              error={error.endDate}
              returnDate={onEndDateChanged}
            />
          </div>
        ),
        error: error.endDate,
        label: (
          <div
            className={cx('general-label', {
              'general-label--error': error.endDate,
            })}
          >
            End Date
          </div>
        ),
      },
      {
        label: 'Date Granularity',
        error: error.dateGranularity,
        render: (
          <Dropdown
            options={Object.values(BUDGET_PACING_DATE_GRANULARITY).map(
              (value) => ({ label: value, value })
            )}
            onChange={(v) => onPropertyChanged(v, 'dateGranularity')}
            defaultState={budget.dateGranularity}
            autoSelectFirst={true}
            defaultOptionText="Date Granularity"
            error={error.dateGranularity}
          />
        ),
      },
    ]
    if (!budget.isCustomBudget) {
      const el = {
        render: (
          <Dropdown
            options={budgetSegmentsList}
            onChange={(v) => onPropertyChanged(v, 'budgetSegment')}
            error={error.budgetSegment}
            defaultState={budget.budgetSegment}
            defaultOptionText="Select Segment"
          />
        ),
        error: error.budgetSegment,
        label: (
          <div
            className={cx('general-label', {
              'general-label--error': error.budgetSegment,
            })}
          >
            Budget Segment
          </div>
        ),
        width: '25%',
      }
      options.unshift(el)
    } else {
      const el = {
        render: (
          <InputText
            value={budget.budgetSegment || ''}
            placeholder="Enter Budget Name"
            onChange={(v) => onPropertyChanged(v, 'budgetSegment')}
            error={error.budgetSegment}
          />
        ),
        error: error.budgetSegment,
        label: (
          <div
            className={cx('general-label', {
              'general-label--error': error.budgetSegment,
            })}
          >
            Budget Name
          </div>
        ),
        width: '25%',
      }
      options.unshift(el)
    }

    return options
  }

  const bottomInputGroupOptions = () => {
    return [
      {
        render: (
          <Dropdown
            options={Object.values(BUDGET_PACING_KPI_TYPE).map(
              ({ key, label }) => ({ label, value: key })
            )}
            onChange={(v) => onPropertyChanged(v, 'kpiType')}
            defaultState={budget.kpiType}
            error={error.kpiType}
            autoSelectFirst={true}
          />
        ),
        error: error.kpiType,
        label: (
          <div
            className={cx('general-label', {
              'general-label--error': error.kpiType,
            })}
          >
            KPI Type
          </div>
        ),
        width: '25%',
      },
      {
        render: (
          <InputText
            type="budget"
            onChange={(v) => onPropertyChanged(v, 'kpiTarget')}
            value={budget.kpiTarget || ''}
            error={error.kpiTarget}
          />
        ),
        width: '25%',
        error: error.kpiTarget,
        label: (
          <div
            className={cx('general-label', {
              'general-label--error': error.kpiTarget,
            })}
          >
            KPI Target
          </div>
        ),
      },
      {
        render: (
          <DropdownPreviewSelected
            selectAll
            className="dropdown--conversion dropdown--no-padding dropdown--to-right"
            options={conversionList.map((el) => ({ ...el, value: el.key }))}
            selectedItems={parsedConversions?.map((el) => el.key) || []}
            defaultOptionToShow={budget?.associatedConversions?.length}
            optionRenderer={(option, selectedItems) => (
              <AccountDropdownRow
                option={option}
                selectedItems={selectedItems}
              />
            )}
            error={error.associatedConversions}
            onChange={(v) => onAssocConversionsChange(v)}
          />
        ),
        error: error.associatedConversions,
        label: (
          <div
            className={cx('general-label', {
              'general-label--error': error.associatedConversions,
            })}
          >
            Associated Conversions
          </div>
        ),
      },
    ]
  }

  return (
    <div data-cy="budget-input-groups" className="padding-x-30">
      <div className={cx({ 'budget-pacing__bordered': onRemoveClicked })}>
        <div className="budget-pacing__inputs">
          <InputGroup options={topInputGroupOptions()} />
          <InputGroup options={bottomInputGroupOptions()} />
        </div>
        {onRemoveClicked && (
          <div className="budget-pacing__remove">
            <IconRemove title="Remove" onClick={onRemoveClicked} />
          </div>
        )}
      </div>
      {budget?.isCustomBudget ? (
        <DimensionInputGroups
          budget={budget}
          error={error}
          clientDimensions={dimensions}
          onBudgetChanged={onBudgetChanged}
        />
      ) : null}
    </div>
  )
}
export default BudgetInputGroup

BudgetInputGroup.propTypes = {
  budget: PropTypes.object.isRequired,
  budgetSegmentsList: PropTypes.array.isRequired,
  onBudgetChanged: PropTypes.func.isRequired,
  conversionList: PropTypes.array.isRequired,
  onRemoveClicked: PropTypes.func,
  dimensions: PropTypes.object,
  error: PropTypes.object,
  isEditingBudget: PropTypes.bool,
}
