import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { Helmet } from 'react-helmet'
import useSession from 'modules/session'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { NOT_FOUND_ROUTE } from 'routes'
import Input from 'components/input'
import { OwnerDropdown } from 'components/utils/owner'
import { permissions } from '@decision-sciences/qontrol-common'
import TemplateBuilder from 'components/expression-builder'
import { FORMULA_TOOLTIP } from 'components/utils/tooltip'
import { CheckboxNoHooks } from 'components/checkbox'
import Tooltip from 'components/tooltip'
import StickyFooter from 'components/sticky-footer'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import { getUsersWithPermissions } from 'modules/users/actions'
import { PrimaryComparisonPeriodSection } from 'modules/alerts/primary-comparison-period'
import './style.scss'

const AlertTemplatesView = ({ alertTemplates, alertKey, hasViewAccess }) => {
  const [, user] = useSession()
  const [state, setState] = useState({
    key: '',
    name: '',
    expression: '',
    expressionValid: false,
    isGlobal: false,
    owner: null,
    comparison: { currentMetric: null, compareMetric: null },
  })
  const navigate = useNavigate()
  const existing = alertKey && alertKey !== 'new'
  const [showTooltip, setShowTooltip] = useState(false)
  const [possibleOwners, setPossibleOwners] = useState(null)

  useEffect(() => {
    if (existing) {
      const alertTemplate = alertTemplates.find((a) => a.key === alertKey)
      if (!alertTemplate) {
        return navigate(NOT_FOUND_ROUTE, { replace: true })
      }
      alertTemplate &&
        setState({
          ...alertTemplate,
          comparison: { ...alertTemplate.comparison },
          expressionValid: true,
          owner: alertTemplate.owner._id,
          ownerName: `${alertTemplate.owner.firstName} ${alertTemplate.owner.lastName}`,
        })
    }
  }, [alertKey])

  /** On mount, check for access */
  useEffect(() => {
    if (!hasViewAccess) {
      navigate('/unauthorized', { replace: true })
    }
    if (existing) {
      getUsersWithPermissions([
        {
          feature: permissions.PERMISSIONS.ALERT_TEMPLATE_DATA_FORM,
          type: permissions.PERMISSION_TYPES.CREATE,
        },
      ]).then((res) => res.list && setPossibleOwners(res.list))
    }
  }, [hasViewAccess])

  const pageHeader = 'View Alert Template'

  return (
    <form className={cx(`alert-templates form`)}>
      <Helmet>
        <title>{pageHeader}</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        {pageHeader}
      </div>

      <section className="form__section">
        <div className="form__section__body">
          <div className="form__row">
            <Input
              disabled={true}
              label="Name"
              placeholder="Name"
              value={state.name}
              className="form__half"
            />
            <Input
              disabled={true}
              label="Identifier"
              placeholder="Identifier"
              value={state.key}
              className="form__half"
            />
          </div>
          <div className="form__row" style={{ alignItems: 'center' }}>
            <OwnerDropdown
              disabled={true}
              currentUser={user}
              isNew={!existing}
              allUsers={possibleOwners || []}
              selectedId={state.owner}
              loading={!existing ? false : !possibleOwners}
              loadingText={state.ownerName}
            />
            <CheckboxNoHooks
              defaultValue={state.isGlobal}
              isChecked={state.isGlobal}
              label="Is Global?"
              className="checkbox-first form__half"
              disabled={true}
            />
          </div>
        </div>
      </section>

      <PrimaryComparisonPeriodSection
        state={state}
        hasEditAccess={false}
        displayIcon={true}
      />
      <section className="form__section">
        <div className="form__section__header">
          Formula{' '}
          <InfoIcon
            className="calculated-metrics__info fill-light-blue"
            alt={'info'}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
          <Tooltip content={FORMULA_TOOLTIP} show={showTooltip} />
        </div>

        <div className="form__section__body">
          <TemplateBuilder
            disabled={true}
            skipValidation={true}
            defaultValue={state.expression}
            metrics={[]}
          />
        </div>
      </section>

      <StickyFooter
        buttons={[
          {
            value: 'Back',
            onClick: () => navigate('/alert-templates'),
            secondaryGray: true,
          },
        ]}
      />
    </form>
  )
}

AlertTemplatesView.propTypes = {
  alertTemplates: PropTypes.array.isRequired,
  alertKey: PropTypes.string,
  hasViewAccess: PropTypes.bool,
}

export default AlertTemplatesView
