import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

/* Components */
import Section from 'components/section'
import DropdownWithSubsections from 'components/dropdown-with-subsections/index'
import { SinglePerformanceAccountRow } from 'modules/alerts/single-performance-apply-to/single-performance-account-row'

/* Hooks */
import { useSingleClientApplyTo } from 'modules/alerts/utils'

/* Actions */
import { getCampaigns, getAds } from 'modules/linked-in-config/actions'

import { ACCOUNTS_TO_SELECT_ELEMENTS } from 'modules/alerts/constants'

import {
  accounts,
  granularities,
  utils,
} from '@decision-sciences/qontrol-common'

import 'modules/alerts/single-performance-apply-to/style.scss'

const { LINKED_IN } = accounts.ACCOUNT_TYPES_MAP

const { ACCOUNT, CAMPAIGN, AD } = granularities.GRANULARITIES

/**
 * Single Performance Section for LinkedIn
 * @param {Object} params React Params
 * @param {String} params.clientId Alert Client ID
 * @param {Function} params.onChange On Change callback. Call with new state for selectedElements[platform]
 * @param {Object} params.state selectedElements[platform]
 * @param {Array} params.accounts Available Accounts for platform
 * @param {Object} params.elementConfig Array name per platform key. Eg: CAMPAIGN: campaigns
 * @param {Object} params.errors Object of errors
 * @param {Function} params.onChangeErrors Callback for changing errors
 * @returns {Node}
 */
export const SinglePerformanceLinkedIn = ({
  clientId,
  onChange,
  state,
  accounts,
  elementConfig,
  errors,
  onChangeErrors,
}) => {
  const {
    localState,
    toggleLoading,
    getIsLoading,
    onCheck,
    onCheckAll,
    onChangeAccounts,
  } = useSingleClientApplyTo(
    LINKED_IN,
    state,
    onChange,
    onChangeErrors,
    validDataPersistenceConfig
  )

  // Data States
  const [campaigns, setCampaigns] = useState(null)
  const [ads, setAds] = useState({})

  const availableAccounts = accounts.map(({ externalAccountId, name }) => ({
    value: externalAccountId,
    label: name || externalAccountId,
    description: externalAccountId,
  }))

  /* ACCOUNT section */
  const accountMap = useMemo(() => {
    return availableAccounts.reduce(
      (prev, current) => ({
        ...prev,
        [current.value]: current,
      }),
      {}
    )
  }, [availableAccounts])

  const hasAccounts = state.accounts?.length || state.allAccountsSelected

  const renderAccountSection = () => {
    if (!state.elements?.includes(ACCOUNT)) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Accounts"
          selectedItems={state[ACCOUNT]?.accounts.map((account) => account.id)}
          disabled={!hasAccounts}
          label={'Account'}
          options={
            state.allAccountsSelected
              ? availableAccounts
              : availableAccounts.filter((acc) =>
                  state.accounts?.includes(acc.value)
                )
          }
          onChange={(accounts) =>
            onCheck(ACCOUNT, {
              accounts: accounts.map((value) => ({
                name: accountMap[value].label,
                id: accountMap[value].value,
              })),
              allSelected: false,
            })
          }
          selectAllOptions={{
            label: 'All Accounts',
            allSelected: state[ACCOUNT]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(ACCOUNT),
          }}
          error={errors[elementConfig[ACCOUNT]]}
        />
      </div>
    )
  }
  /* End of ACCOUNT section */

  /* CAMPAIGN Section */

  useEffect(() => {
    if (!campaigns && !getIsLoading(CAMPAIGN)) {
      toggleLoading(CAMPAIGN, true)
      getCampaigns(clientId)
        .then(setCampaigns)
        .catch(console.error)
        .finally(() => {
          toggleLoading(CAMPAIGN, false)
        })
    }
  }, [JSON.stringify(localState.elements)])

  const campaignsInAccounts = useMemo(() => {
    const campaignsInAccountsMap = {}

    const accountsToParse = state.allAccountsSelected
      ? availableAccounts.map(({ value }) => value)
      : state.accounts

    if (!accountsToParse || !campaigns?.length) {
      return campaignsInAccountsMap
    }

    // Turn array to map for easy access
    const accountsSet = new Set(accountsToParse)

    // Filter campaigns based on selected accounts
    campaigns.forEach((campaign) => {
      if (accountsSet.has(campaign.account_id)) {
        campaignsInAccountsMap[campaign.id] = campaign
      }
    })

    return campaignsInAccountsMap
  }, [JSON.stringify(state.accounts), state.allAccountsSelected, campaigns])

  const campaignsByAccount = utils.array.arrayKeyBy(
    Object.values(campaignsInAccounts) || [],
    'account_id'
  )

  const getCampaignOptions = (getIsDisabled) => {
    if (!campaignsByAccount) {
      return []
    }

    const options = Object.keys(campaignsByAccount).map((accountId) => ({
      subsections: campaignsByAccount[accountId].map((campaign) => ({
        value: campaign.id,
        label: campaign.name,
        disabled: getIsDisabled && getIsDisabled(campaign),
      })),
      disabled: true,
      noCheckbox: true,
      label: accountMap[accountId].label,
      value: accountId,
    }))

    return options
  }

  const renderCampaignSection = () => {
    if (!state.elements?.includes(CAMPAIGN)) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[CAMPAIGN]?.campaigns || []).map(({ id }) => id)}
          disabled={!hasAccounts}
          label={'Campaign'}
          options={getCampaignOptions()}
          onChange={(campaigns) => {
            onCheck(CAMPAIGN, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Campaigns',
            allSelected: state[CAMPAIGN]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(CAMPAIGN),
          }}
          error={errors[elementConfig[CAMPAIGN]]}
        />
      </div>
    )
  }
  /* End of CAMPAIGN Section */

  /* AD Section */

  /**
   * Fetch ads for an ad group
   * @param {String} resource id field from LINKED_IN
   */
  const fetchAds = (resource, accountId) => {
    const loaderKey = `${AD}_${resource}`

    if (!getIsLoading(loaderKey) && !ads[resource]) {
      toggleLoading(loaderKey, true)
      getAds(clientId, null, resource, accountId)
        .then((ads) => {
          setAds((previous) => ({
            ...(previous || {}),
            [resource]: ads,
          }))
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(loaderKey, false)
        })
    }
  }

  useEffect(() => {
    if (!localState[AD]?.campaigns) {
      return
    }

    localState[AD].campaigns.forEach((campaign) => {
      fetchAds(campaign.id, campaign.account_id)
    })
  }, [localState[AD]?.campaigns])

  const getAvailableAdsMap = (element) => {
    if (!element || !element.campaigns) {
      return {}
    }

    return element.campaigns.reduce((prev, current) => {
      return {
        ...prev,
        ...(ads[current.id] || []).reduce(
          (prev, current) => ({ ...prev, [current.id]: current }),
          {}
        ),
      }
    }, {})
  }

  const adAvailableAdMap = useMemo(() => {
    return getAvailableAdsMap(localState[AD])
  }, [localState[AD]?.campaigns, ads])

  const renderAdSection = () => {
    if (!state.elements?.includes(AD)) {
      return null
    }

    const hasCampaigns = state[AD]?.campaigns?.length

    const adOptions =
      localState[AD]?.campaigns?.reduce(
        (prev, current) => [
          ...prev,
          {
            label: current.name,
            disabled: true,
            noCheckbox: true,
            subsections: (ads[current.id] || []).map(
              (ad) => ({
                label: ad.name,
                value: ad.id,
              }),
              []
            ),
          },
        ],
        []
      ) || []

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[AD]?.campaigns || []).map(({ id }) => id)}
          label={'Campaign'}
          options={getCampaignOptions(({ id }) =>
            getIsLoading(`${CAMPAIGN}_${id}`)
          )}
          onChange={(campaigns) =>
            onCheck(AD, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
            })
          }
        />

        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ads"
          selectedItems={(state[AD]?.ads || []).map(({ id }) => id)}
          label={'Ads'}
          options={adOptions}
          disabled={!hasCampaigns}
          isLoading={getIsLoading(new RegExp(AD))}
          onChange={(ads) => {
            onCheck(AD, {
              ads: ads.map((ad) => adAvailableAdMap[ad]),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Ads',
            allSelected: state[AD]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(AD),
          }}
          error={errors[elementConfig[AD]]}
        />
      </div>
    )
  }
  /* End of AD Section */

  return (
    <Section>
      <SinglePerformanceAccountRow
        state={state}
        onChange={onChange}
        onChangeAccounts={onChangeAccounts}
        platform={LINKED_IN}
        availableAccounts={availableAccounts}
        availableElements={ACCOUNTS_TO_SELECT_ELEMENTS[LINKED_IN]}
        elementConfig={elementConfig}
        errors={errors}
        setErrors={onChangeErrors}
      />
      {renderAccountSection()}
      {renderCampaignSection()}
      {renderAdSection()}
    </Section>
  )
}

SinglePerformanceLinkedIn.propTypes = {
  clientId: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  elementConfig: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChangeErrors: PropTypes.func.isRequired,
}

/** Data Validation functions, ensured data is dynamically removed based on parent element removals */
const validateCampaignChanges = (
  changes,
  { allAccountsSelected, accounts }
) => {
  if (changes.campaigns && !allAccountsSelected) {
    const availableAccountSet = new Set(accounts)
    changes.campaigns = changes.campaigns.filter(({ account_id }) =>
      availableAccountSet.has(account_id)
    )
  }
  return changes
}

const validateAdChanges = (changes, state, TYPE) => {
  changes = validateCampaignChanges(changes, state, TYPE)
  if (changes.campaigns) {
    const { ads } = state[TYPE]
    if (ads?.length) {
      const availableCampaigns = changes.campaigns.map(({ id }) => id)

      changes.ads = ads.filter(({ campaign }) =>
        availableCampaigns.some((id) => campaign.match(new RegExp(`${id}`)))
      )
    }
  }

  return changes
}

const validDataPersistenceConfig = {
  [ACCOUNT]: (changes, { allAccountsSelected, accounts }) => {
    if (changes.accounts && !allAccountsSelected) {
      const availableAccountSet = new Set(accounts)
      changes.accounts = changes.accounts.filter(({ id }) =>
        availableAccountSet.has(id)
      )
    }
    return changes
  },
  [CAMPAIGN]: validateCampaignChanges,
  [AD]: validateAdChanges,
}
