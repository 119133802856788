import React, { useMemo, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import cx from 'classnames'

import { DragAndDrop } from 'components/utils/drag-drop'
import InputGroup from 'components/input-group'
import { Dropdown } from 'components/dropdown'
import { compareDimensionsOrder } from 'modules/naming-conventions/utils'
import Input from 'components/input'
import Icon from 'components/icon'
import Toggle from 'components/toggle'
import WarningIcon from 'components/warning-icon'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import Button from 'components/button'
import Modal from 'components/modal'
import InformationBlock from 'components/information-block'
import { ReactComponent as IconDragDrop } from 'assets/icon_drag_drop.svg'
import { ReactComponent as IconEdit } from 'assets/icon_edit.svg'
import { ReactComponent as IconClose } from 'assets/icon_clear_red.svg'
import { ReactComponent as IconConfirm } from 'assets/icon_check_green.svg'
import { ReactComponent as IconRemove } from 'assets/icon_table_remove.svg'
import { namingConventions } from '@decision-sciences/qontrol-common'

import '../style.scss'

const { getEndPointPosition, getLastRequiredDimensionPosition } =
  namingConventions
const DND_ITEM_TYPE = 'dimension'

/**
 * Renders an editable / reorderable dimension list.
 * @param {Object} params React Parameters
 * @param {Array} params.dimensions Dimensions list
 * @param {Function} params.setDimensions Dimensions setter
 * @param {Boolean} params.isGlobal DimensionList used at global level
 * @param {Boolean} params.isViewMode DimensionList is read-only (like disabled but CTAs are not shown)
 * @param {Boolean} params.disabled DimensionList has a disabled state (like read-only but CTAs are disabled)
 */
const DimensionsList = ({
  dimensions,
  setDimensions,
  isGlobal,
  isViewMode,
  disabled,
}) => {
  const onReorder = (from, to) => {
    // We sort the list based on the dimensionOrder, so we're certain that we're working with
    const orderedDimensions = dimensions.sort(compareDimensionsOrder)
    const itemToMove = orderedDimensions[from - 1]
    orderedDimensions.splice(from - 1, 1)
    orderedDimensions.splice(to - 1, 0, itemToMove)

    const lastRequiredPosition =
      getLastRequiredDimensionPosition(orderedDimensions)

    setDimensions(
      orderedDimensions.map((dimension, index) => ({
        ...dimension,
        endPoint: index + 1 < lastRequiredPosition ? false : dimension.endPoint,
        dimensionOrder: index + 1,
      }))
    )
  }

  /**
   * On change dimension callback
   * @param {Object} dimension Dimension object
   */
  const onChange = (dimension) => {
    const shouldUnSetEndPoint =
      dimension.required &&
      getEndPointPosition(dimensions) < dimension.dimensionOrder
    setDimensions(
      dimensions.sort(compareDimensionsOrder).map((_dimension) => {
        let newDimension = _dimension
        if (dimension.dimensionId === _dimension.dimensionId) {
          newDimension = dimension
        } else {
          if (
            dimension.endPoint ||
            (shouldUnSetEndPoint && newDimension.endPoint)
          ) {
            newDimension.endPoint = false
          }
        }
        return newDimension
      })
    )
  }

  const onRemove = (dimension) => {
    setDimensions(
      dimensions
        .filter(({ dimensionId }) => dimension.dimensionId !== dimensionId)
        .sort(compareDimensionsOrder)
        .map((dimension, index) => ({
          ...dimension,
          dimensionOrder: index + 1,
        }))
    )
  }

  const canSetEndPoint = (dimension) => {
    const lowestRequiredDimension = dimensions.findLast(
      ({ required }) => required
    )
    if (!lowestRequiredDimension) {
      return true
    }
    return dimension.dimensionOrder >= lowestRequiredDimension.dimensionOrder
  }

  return (
    <DragAndDrop>
      <div className="naming-conventions__dimensions">
        {dimensions.map((dimension) => {
          return (
            <Dimension
              key={dimension.dimensionId}
              dimension={dimension}
              onReorder={onReorder}
              onChange={onChange}
              onRemove={onRemove}
              allDimensions={dimensions}
              isGlobal={isGlobal}
              isViewMode={isViewMode}
              disabled={disabled}
              canSetEndPoint={canSetEndPoint}
            />
          )
        })}
      </div>
    </DragAndDrop>
  )
}

/**
 * Dimension Render
 * The Drag and Drop functionality has been custom-tailored to show a little animation when dragging dimensions.
 * @param {Object} params React Params
 * @param {Object} params.dimension Dimension object to render
 * @param {Array} params.allDimensions Dimensions List
 * @param {Function} params.onReorder Reorder callback (when using drag and drop or order dropdown). onReorder({Number} from, {Number} to), where from is the initial order of the dimension, and to is the destination order
 * @param {Function} params.onChange OnChange callback. onChange({Object} newDimension) where newDimension is the edited version of what you're changing
 * @param {Function} params.onRemove Remove callback. onRemove({Object} dimension). Removes a dimension
 * @param {Boolean} params.isGlobal
 * @param {Boolean} params.isViewMode
 * @param {Boolean} params.disabled
 * @param {Function} params.canSetEndPoint
 */
const Dimension = ({
  dimension,
  allDimensions,
  onReorder,
  onChange,
  onRemove,
  isGlobal,
  isViewMode,
  disabled,
  canSetEndPoint,
}) => {
  const [editedValue, setEditedValue] = useState(null)
  const [shifted, setShifted] = useState(null)
  const [animationsActive, setAnimationsActive] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)

  const editInputRef = useRef(null)
  const dropRef = useRef(null)
  const dragRef = useRef(null)

  /**
   * Function to shift a dimension up
   * @param {number} size Number of pixels (Height of moved element)
   */
  const shiftUp = (size) => {
    const isFirstElement =
      dimension.dimensionId === allDimensions[0].dimensionId

    if (!shifted && !isFirstElement) {
      setShifted(-size)
    }
    if (shifted > 0) {
      setShifted(0)
    }
  }

  /**
   * Function to shift a dimension down
   * @param {number} size Number of pixels (Height of moved element)
   */
  const shiftDown = (size) => {
    const isLastElement =
      dimension.dimensionId ===
      allDimensions[allDimensions.length - 1].dimensionId

    if (!shifted && !isLastElement) {
      setShifted(size)
    }
    if (shifted < 0) {
      setShifted(0)
    }
  }

  /**
   * Array of available dimensionOrders
   * The current dimension is set to disabled so that we can't select it in the dropdown.
   * @type {{disabled: boolean, label: *, value: *}[]}
   */
  const availablePositions = useMemo(() => {
    return allDimensions.map(({ dimensionOrder }) => ({
      value: dimensionOrder,
      label: dimensionOrder.toString(),
      disabled: dimension.dimensionOrder === dimensionOrder,
    }))
  }, [JSON.stringify(allDimensions)])

  const [{ isHovered }, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = dimension.dimensionOrder

      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      const height = hoverBoundingRect.height + 16 // 16 is the gap between elements

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (item.lastIndex === hoverIndex) {
        // If the moved element was initially below the current one, it means that the element was shifted downwards.
        const firstShiftIsDown = item.index > hoverIndex

        // If that's the case, we need to ensure that the element can't be shifted upwards, an element will toggle between shifted downwards and not shifted
        // The reverse is also applicable. If the element was shifted downwards, it can't be shifted upwards during this "drag" session.
        if (hoverClientY < hoverMiddleY) {
          firstShiftIsDown ? shiftDown(height) : setShifted(0)
        } else {
          firstShiftIsDown ? setShifted(0) : shiftUp(height)
        }
        return
      }

      // Dragging downwards
      if (dragIndex < hoverIndex) {
        if (hoverClientY < hoverMiddleY) {
          return
        }

        // When past the middle point, we consider the hovered element as the last element touched.
        item.lastIndex = hoverIndex

        // We also shift the element upwards, to mimic what will happen after the drag is done.
        shiftUp(height)
      }
      // Dragging upwards
      if (dragIndex > hoverIndex) {
        if (hoverClientY > hoverMiddleY) {
          return
        }

        // When past the middle point, we consider the hovered element as the last element touched.
        item.lastIndex = hoverIndex
        // We also shift the element downwards, to mimic what will happen after the drag is done.
        shiftDown(height)
      }
    },
    drop(item, monitor) {
      // When the drop is done, we actually make the changes
      onReorder(item.index, dimension.dimensionOrder)
    },
    collect(monitor) {
      return {
        isHovered: monitor.isOver(),
      }
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: {
      index: dimension.dimensionOrder,
      lastIndex: dimension.dimensionOrder,
      editedValue: editedValue,
    },
    end: (item, monitor) => {
      // We're moving the element back to its original position, as the dimensions array will reset.
      setShifted(0)

      // This checks whether the drop was made correctly
      if (!monitor.didDrop()) {
        // If the element was dropped elsewhere, we move the hovered item to the last shifted position
        onReorder(item.index, item.lastIndex)
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !isViewMode && !disabled,
  })

  /**
   * This ensures that whenever dimensions change, the dimension moves to its original position and animations are inactive.
   */
  useEffect(() => {
    setShifted(0)
    setAnimationsActive(false)
  }, [JSON.stringify(allDimensions)])

  /**
   * Animations are disabled temporarily on component mount, so that post-drag-and-drop the moved cards don't move around to "settle"
   */
  useEffect(() => {
    if (!animationsActive) {
      setTimeout(() => {
        setAnimationsActive(true)
      }, 500)
    }
  }, [animationsActive])
  preview(drop(dropRef))
  drag(dragRef)

  const duplicateName = useMemo(
    () =>
      allDimensions.some(
        ({ dimensionName, dimensionOrder, dimensionId }) =>
          dimension.dimensionId !== dimensionId &&
          dimensionOrder !== dimension.dimensionOrder &&
          dimensionName === (editedValue || dimension.dimensionName)
      ),
    [editedValue, JSON.stringify(allDimensions)]
  )

  const dragDropElement = () => (
    <div
      ref={dragRef}
      className="naming-conventions__dimensions__dimension__dnd"
      style={{
        cursor: isDragging ? 'grabbing' : 'grab',
        width: '24px',
        maxWidth: '24px',
      }}
    >
      <Icon disabled={disabled}>
        <IconDragDrop />
      </Icon>
    </div>
  )

  const renderOrder = () => {
    return (
      <Dropdown
        defaultState={dimension.dimensionOrder}
        disableSearch
        options={availablePositions}
        onChange={(value) => onReorder(dimension.dimensionOrder, value)}
        disabled={isViewMode || disabled}
        className="dimension-order"
      />
    )
  }

  const renderName = () => {
    const onConfirmName = () => {
      if (
        editedValue &&
        !duplicateName &&
        editedValue !== dimension.dimensionName
      ) {
        const _dimension = {
          ...dimension,
          dimensionName: editedValue,
          oldName: dimension.dimensionName,
        }

        onChange(_dimension)
        setEditedValue(null)
      }
    }

    return (
      <div className="naming-conventions__dimensions__dimension__name">
        <Input
          ref={editInputRef}
          value={editedValue !== null ? editedValue : dimension.dimensionName}
          onChange={setEditedValue}
          disabled={editedValue === null}
          onEnterKeyPressed={onConfirmName}
        />

        {editedValue !== null ? (
          <>
            <Icon onClick={() => setEditedValue(null)}>
              <IconClose width={20} height={20} />
            </Icon>
            <Icon
              tooltip={duplicateName && `${editedValue} already exists`}
              disabled={duplicateName || !editedValue}
              onClick={onConfirmName}
            >
              <IconConfirm width={20} height={20} />
            </Icon>
          </>
        ) : (
          <>
            {!isViewMode && (
              <Icon
                onClick={() => {
                  setEditedValue(dimension.dimensionName)
                  if (editInputRef.current) {
                    setTimeout(() => {
                      editInputRef.current.focus()
                    }, 100)
                  }
                }}
                disabled={isViewMode || disabled}
              >
                <IconEdit width={20} height={20} />
              </Icon>
            )}
          </>
        )}
      </div>
    )
  }

  const renderRemove = () => (
    <Icon
      className="naming-conventions__dimensions__dimension__remove"
      disabled={dimension.required || isViewMode || disabled}
      onClick={() => {
        setRemoveModal(true)
      }}
    >
      <IconRemove />
    </Icon>
  )

  const renderEndPoint = () => {
    return (
      <Toggle
        label={
          <div className="align-row" style={{ paddingRight: 8 }}>
            <label className="switch-container__label">End Point</label>
            <Icon
              width={18}
              height={18}
              tooltip={
                <div>
                  Selecting this option will set the last element that will be
                  used for Qontrol Services that parse the Naming Conventions.
                  Only one end point can be selected at a time.
                </div>
              }
              tooltipClass="end-point-tooltip"
            >
              <InfoIcon width={18} height={18} />
            </Icon>
          </div>
        }
        defaultChecked={dimension.endPoint}
        onChange={(value) => onChange({ ...dimension, endPoint: value })}
        disabled={isViewMode || disabled || !canSetEndPoint(dimension)}
      />
    )
  }

  const style = shifted ? { transform: `translateY(${shifted}px)` } : null
  return (
    <div
      ref={dropRef}
      style={{
        opacity: isDragging ? 0 : 1,
      }}
    >
      {removeModal && (
        <Modal
          className="naming-conventions__modal"
          icon={<WarningIcon />}
          rightAlignButtons
          opened={!!removeModal}
          contentSeparator
          button={
            <Button
              value="Confirm"
              green
              onClick={() => {
                onRemove(dimension)
                setRemoveModal(false)
              }}
            />
          }
          buttonSecondary={
            <Button
              value={'Cancel'}
              onClick={() => setRemoveModal(false)}
              secondaryGray
            />
          }
          heading="Remove Dimension"
        >
          <div>
            You are about to remove a dimension. Confirm if you want to continue
            with this action. This action cannot be undone.
          </div>
        </Modal>
      )}
      <InputGroup
        style={style}
        className={cx('naming-conventions__dimensions__dimension', {
          'animations-disabled': !animationsActive,
          'naming-conventions__dimensions__dimension--disabled': disabled,
        })}
        options={[
          { render: dragDropElement(), width: 48, condition: !isViewMode },
          { render: renderOrder(), width: 74 },
          {
            render: renderName(),
            error: duplicateName,
            width: 320,
          },
          {
            render: (
              <Toggle
                label="Free-Form"
                defaultChecked={dimension.freeForm}
                onChange={(value) =>
                  onChange({ ...dimension, freeForm: value })
                }
                disabled={isViewMode || disabled}
              />
            ),
            width: 156,
          },
          {
            render: renderEndPoint(),
            width: 175,
          },
          {
            render: (
              <Toggle
                label="Required"
                defaultChecked={dimension.required}
                disabled={!isGlobal}
                onChange={(value) =>
                  onChange({ ...dimension, required: value })
                }
              />
            ),
            condition: isGlobal,
            width: 169,
          },
          { render: renderRemove(), width: 48, condition: !isViewMode },
        ]}
      />
      {duplicateName && (
        <InformationBlock
          info="Dimension name already exists."
          style={{ marginTop: '15px', maxWidth: '456px' }}
        />
      )}
    </div>
  )
}

Dimension.propTypes = {
  dimension: PropTypes.object.isRequired,
  allDimensions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isGlobal: PropTypes.bool,
  isViewMode: PropTypes.bool,
  disabled: PropTypes.bool,
  canSetEndPoint: PropTypes.func.isRequired,
}

DimensionsList.propTypes = {
  dimensions: PropTypes.array,
  setDimensions: PropTypes.func,
  isGlobal: PropTypes.bool,
  isViewMode: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default DimensionsList
