import useLeaveConfirm from 'components/leave-confirm'
import PropTypes from 'prop-types'
import { useState } from 'react'
import DimensionFormActionBar from './action-bar'
import DimensionCategoryLink from './category-link'
import DimensionForm from './form'

/**
 * Form which allows creating a Data Store dimension.
 * More of a wrapper component which encapsulates main components for editing a dimension.
 * @param {Object} props Component props
 * @param {Boolean} [props.disabled] Disabled flag
 * @returns {React.FunctionComponent}
 */
const CreateDimensionForm = ({ disabled }) => {
  const [setDirty, LeaveConfirm, isDirty] = useLeaveConfirm()

  const [errors, setErrors] = useState({})

  return (
    <>
      <LeaveConfirm />
      <DimensionForm
        disabled={disabled}
        isDirty={isDirty}
        setDirty={setDirty}
        errors={errors}
        setErrors={setErrors}
      />
      <DimensionCategoryLink
        disabled={disabled}
        isDirty={isDirty}
        setDirty={setDirty}
      />
      <DimensionFormActionBar
        disabled={disabled}
        isDirty={isDirty}
        setDirty={setDirty}
        setErrors={setErrors}
      />
    </>
  )
}

CreateDimensionForm.propTypes = {
  disabled: PropTypes.bool,
}

export default CreateDimensionForm
