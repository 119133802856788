import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import cx from 'classnames'

/* Stores */
import { useStore } from 'store'
import useSession from 'modules/session'

/* Utils */
import { format } from 'date-fns'
import uniqBy from 'lodash.uniqby'
import isEqual from 'lodash.isequal'
import { useDrop, useDrag } from 'react-dnd'
import { useSocket } from 'components/utils/socket'

/* Components */
import DropdownMultiColor from 'components/dropdown-multi-color'
import DropdownWithSubsections from 'components/dropdown-with-subsections'
import Loader from 'components/loader'
import StickyFooter from 'components/sticky-footer'
import Filters from 'components/filters'
import { Dropdown } from 'components/dropdown'
import Input from 'components/input'
import Calendar from 'components/calendar'

/* Actions */
import { fetchAlerts, getAlertCategories } from 'modules/alerts/actions'
import {
  getAlertTriggersForUserPaginated,
  updateAlertTrigger,
} from 'modules/alert-triggers/actions'

/* Constants */
import { dataRefreshContext } from 'contexts/data-refresh'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'
import { ALERT_TRIGGER_COLORS } from 'constants/alert-trigger'

/* Assets */
import { AccountIcon } from 'components/account-icon'
import {
  alertTriggers,
  accounts,
  alert,
  socket,
} from '@decision-sciences/qontrol-common'

import './styles.scss'

const { ALERT_TRIGGER_STATUS, getTriggeredBy } = alertTriggers
const { ACCOUNT_TYPE_NAMES, ACCOUNT_TYPE_CLIENT } = accounts
const { ALERT_TYPES } = alert
const { NOTIFICATIONS } = socket

const ITEM_LIMIT = 10

/**
 * Active Alert Triggers component
 * @returns {React.Component}
 */
const ActiveAlertTriggers = () => {
  const { state } = useStore()
  const {
    companies: { currentCompany },
  } = state
  const { setDataRefresh, setOptions: setDataRefreshOptions } =
    useContext(dataRefreshContext)
  const [, user] = useSession()
  const socket = useSocket({ room: currentCompany?._id })
  const [loading, setLoading] = useState(true)
  // Tuple of lower and upper bounds of elements to load next + a special key or loading only one type
  const initialItemsToLoad = {
    active: [0, ITEM_LIMIT],
    acknowledged: [0, ITEM_LIMIT],
    completed: [0, ITEM_LIMIT],
    // When bringing data, only bring for one column, and leave the rest as they are
    loadOnlyOneType: [],
  }
  const [itemsToLoad, setItemsToLoad] = useState({ ...initialItemsToLoad })
  const [loadingMore, setLoadingMore] = useState([false])
  const [error, setError] = useState(false)
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [elementsLeftToLoad, setElementsLeftToLoad] = useState({
    active: true,
    acknowledged: true,
    completed: true,
  })
  const hasEditAccess = useAccess({
    feature: PERMISSIONS.ACTIVE_ALERTS,
    type: PERMISSION_TYPES.EDIT,
  })
  const anyElementsLeftToLoad =
    elementsLeftToLoad.active ||
    elementsLeftToLoad.acknowledged ||
    elementsLeftToLoad.completed

  const initialCardsState = {
    active: [],
    acknowledged: [],
    completed: [],
  }
  const [cards, setAllCards] = useState({
    ...initialCardsState,
  })
  // Sets columns in obj, keeping the rest
  const setCards = (obj) =>
    setAllCards({
      ...cards,
      ...Object.entries(obj).reduce(
        (acc, [type, value]) => ({ ...acc, [type]: value }),
        {}
      ),
    })

  const resetCards = () => setAllCards({ ...initialCardsState })

  const initialFilterState = {
    keyword: '',
    alertTriggered: [],
    alertCategory: [],
    alertType: [],
    channel: [],
    status: [],
    startDate: null,
    endDate: null,
    clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
  const [filterState, setFilterState] = useState({ ...initialFilterState })

  const mapToCard = ({
    alertSnapshot,
    client,
    alertType,
    status,
    createdAt,
    entitiesWithDetails,
    elementType,
    activityLog,
    _id: alertTriggerId,
  }) => {
    const { description, name } = alertSnapshot || {
      description: 'N/A',
      name: 'N/A',
    }
    // Get trigger elements
    const { stringList: triggeredByString } =
      client !== ACCOUNT_TYPE_CLIENT
        ? getTriggeredBy(elementType, entitiesWithDetails, client)
        : {
            elementType,
            stringList: [`CLIENT ${elementType} level`],
          }

    return {
      title: name,
      accountType: client,
      description,
      type: alertType,
      triggeredBy: triggeredByString,
      status,
      time: createdAt,
      activityLog,
      alertTriggerId,
    }
  }

  /**
   * Get alertTriggers
   */
  const getAlertTriggers = () =>
    new Promise(async (resolve) => {
      // Filter if there are filters, or if we only need one type of element
      const filters = filterState

      if (itemsToLoad.loadOnlyOneType.length) {
        filters.status = itemsToLoad.loadOnlyOneType
      }

      const alertTriggers = await getAlertTriggersForUserPaginated({
        numberOfItemsToLoad: itemsToLoad,
        selectedClient: currentCompany?._id,
        filters,
      })

      if (alertTriggers?.success && alertTriggers?.result) {
        const {
          active,
          acknowledged,
          completed,
          activeElementsLeft,
          acknowledgedElementsLeft,
          completedElementsLeft,
        } = alertTriggers.result

        setCards({
          active: uniqBy(
            [...cards.active, ...(active?.map(mapToCard) || [])].sort((a, b) =>
              a.time < b.time ? 1 : -1
            ),
            'alertTriggerId'
          ),
          acknowledged: uniqBy(
            [
              ...cards.acknowledged,
              ...(acknowledged?.map(mapToCard) || []),
            ].sort((a, b) => (a.time < b.time ? 1 : -1)),
            'alertTriggerId'
          ),
          completed: uniqBy(
            [...cards.completed, ...(completed?.map(mapToCard) || [])].sort(
              (a, b) => (a.time < b.time ? 1 : -1)
            ),
            'alertTriggerId'
          ),
        })

        setLoading(false)
        setLoadingMore(false)
        setError(false)

        // Columns that we did not bring data for, will have undefined
        const newElementsLeft = {
          active:
            activeElementsLeft !== undefined
              ? activeElementsLeft
              : elementsLeftToLoad.active,
          acknowledged:
            acknowledgedElementsLeft !== undefined
              ? acknowledgedElementsLeft
              : elementsLeftToLoad.acknowledged,
          completed:
            completedElementsLeft !== undefined
              ? completedElementsLeft
              : elementsLeftToLoad.completed,
        }

        setElementsLeftToLoad(newElementsLeft)

        // If there are no more elements to load, hide 'Load More' button
        if (
          !newElementsLeft.active &&
          !newElementsLeft.acknowledged &&
          !newElementsLeft.completed
        ) {
          setShowLoadMore(false)
        }

        setItemsToLoad({ ...itemsToLoad, loadOnlyOneType: [] })
        resolve()
      } else {
        setLoading(false)
        setLoadingMore(false)
        setError(true)
        setItemsToLoad({ ...itemsToLoad, loadOnlyOneType: [] })
        resolve()
      }
    })

  // Fetch alert triggers
  useEffect(() => {
    getAlertTriggers()
  }, [JSON.stringify(itemsToLoad), JSON.stringify(filterState)])

  /**
   * Get a card from the local state by id
   * @param {String} alertTriggerId id of card
   * @returns {Object}
   */
  const getCardFromStateById = (alertTriggerId) => {
    // Search trough each type until found
    let cardToFind = cards.active.find(
      (card) => card.alertTriggerId === alertTriggerId
    )
    if (!cardToFind) {
      cardToFind = cards.acknowledged.find(
        (card) => card.alertTriggerId === alertTriggerId
      )
    }
    if (!cardToFind) {
      cardToFind = cards.completed.find(
        (card) => card.alertTriggerId === alertTriggerId
      )
    }

    return cardToFind
  }

  const resetCardsAndRefetch = () => {
    setLoading(true)
    setCards({ ...initialCardsState })
    getAlertTriggers()
  }

  /**
   * Update alert Trigger state
   * @param {String} alertTriggerId alertTriggerId
   * @param {String} value new status
   * @returns {Function}
   */
  const updateCardState = async (alertTriggerId, value) => {
    // Find card to edit
    const cardToEdit = getCardFromStateById(alertTriggerId)

    // Add update to activity log
    const result = await updateAlertTrigger(cardToEdit.alertTriggerId, {
      activityLog: [
        ...cardToEdit.activityLog,
        {
          user: user.email,
          change: {
            attribute: 'status',
            from: alert.status,
            to: value,
          },
        },
      ],
      status: value,
    })

    if (!result.success || result.error) {
      console.error('Error updating status', result.error)
      return
    }

    let originalStatus = cardToEdit.status
    if (originalStatus === ALERT_TRIGGER_STATUS.IGNORED) {
      originalStatus = ALERT_TRIGGER_STATUS.COMPLETED
    }

    const editedCard = {
      ...cardToEdit,
      activityLog: result.data.activityLog,
      status: result.data.status,
    }

    const oldStatusColumn = originalStatus.toLowerCase()

    const newStatusColumn =
      value === ALERT_TRIGGER_STATUS.IGNORED
        ? ALERT_TRIGGER_STATUS.COMPLETED.toLowerCase()
        : value.toLowerCase()

    // Build updated cards object step by step to avoid duplicating cards when changing the status of a card from the last column (completed/ignored)
    const updatedCards = { ...cards }
    updatedCards[oldStatusColumn] = updatedCards[oldStatusColumn].filter(
      (card) => card.alertTriggerId !== alertTriggerId
    )
    updatedCards[newStatusColumn] = [
      ...updatedCards[newStatusColumn],
      editedCard,
    ].sort((a, b) => (a.time < b.time ? 1 : -1))

    setCards(updatedCards)

    // Bring new card into the column that we moved the element from
    if (elementsLeftToLoad[oldStatusColumn]) {
      setItemsToLoad({
        ...itemsToLoad,
        [oldStatusColumn]: [
          updatedCards[oldStatusColumn].length,
          updatedCards[oldStatusColumn].length + 1,
        ],
        loadOnlyOneType:
          originalStatus === ALERT_TRIGGER_STATUS.COMPLETED
            ? [ALERT_TRIGGER_STATUS.COMPLETED, ALERT_TRIGGER_STATUS.IGNORED]
            : [originalStatus],
      })
    }

    // Notify the server that changes happened
    socket.emit(NOTIFICATIONS.alertTriggers.initialise, {
      data: {
        id: cardToEdit.alertTriggerId,
        from: originalStatus,
        to: value,
      },
    })
  }

  // Track scrolling to show 'Load More' button
  useEffect(() => {
    const scrollingElement = document.getElementsByTagName('main')?.[0]

    scrollingElement.addEventListener('scroll', trackScrolling)

    return () => {
      scrollingElement.removeEventListener('scroll', trackScrolling)
    }
  }, [elementsLeftToLoad])

  const trackScrolling = (ev) => {
    const isBottomFn = (el) => {
      return el.scrollHeight - el.scrollTop <= el.clientHeight + 50
    }
    const isBottom = isBottomFn(ev.target)

    if (isBottom) {
      anyElementsLeftToLoad && setShowLoadMore(true)
    } else {
      setShowLoadMore(false)
    }
  }

  // Drop object
  const drop = (columnStatus) => ({
    accept: 'Card',
    drop: ({ alertTriggerId, status }) =>
      status !== columnStatus && updateCardState(alertTriggerId, columnStatus),
    canDrop: (item) => item.status !== columnStatus,
    collect: (monitor) => ({
      [`cardIsOver${columnStatus}Column`]:
        !!monitor.canDrop() && !!monitor.isOver(),
    }),
  })

  // Drop to Acknowdledged column
  const [{ cardIsOverAcknowledgedColumn }, dropRefAcknowledged] = useDrop(
    drop(ALERT_TRIGGER_STATUS.ACKNOWLEDGED)
  )
  // Drop to Completed column
  const [{ cardIsOverCompletedColumn }, dropRefCompleted] = useDrop(
    drop(ALERT_TRIGGER_STATUS.COMPLETED)
  )

  /** Update alert Trigger cards states locally (without calling the server) */
  const [cardsToMoveOnRefresh, setCardsToMoveOnRefresh] = useState([])
  const updateCardsLocally = (cardsToUpdate) => {
    const updatedCards = cards
    cardsToUpdate.forEach(({ alertTriggerId, value }) => {
      // Find card to edit
      const cardToEdit = getCardFromStateById(alertTriggerId)

      if (cardToEdit) {
        let originalStatus = cardToEdit.status
        if (originalStatus === ALERT_TRIGGER_STATUS.IGNORED) {
          originalStatus = ALERT_TRIGGER_STATUS.COMPLETED
        }

        const oldStatusColumn = originalStatus.toLowerCase()

        const newStatusColumn =
          value === ALERT_TRIGGER_STATUS.IGNORED
            ? ALERT_TRIGGER_STATUS.COMPLETED.toLowerCase()
            : value.toLowerCase()

        updatedCards[oldStatusColumn] = updatedCards[oldStatusColumn].filter(
          (card) => card.alertTriggerId !== alertTriggerId
        )
        updatedCards[newStatusColumn] = [
          ...updatedCards[newStatusColumn],
          { ...cardToEdit, status: value, disabled: false },
        ].sort((a, b) => (a.time < b.time ? 1 : -1))
      }
    })

    setCards(updatedCards)
    setCardsToMoveOnRefresh([])
  }

  let newCardsToAdd = []
  const addNewCards = (newCards) => {
    if (newCards.length) {
      setCards({
        active: uniqBy([...newCards, ...cards.active], 'alertTriggerId'),
      })
      newCardsToAdd = []
    }
  }

  // Listen to data change notifications from the server
  useEffect(() => {
    if (socket) {
      socket.on(
        NOTIFICATIONS.alertTriggers.receive,
        ({
          id,
          from,
          to,
          newCard,
          data,
          archived,
          idsToArchive,
          ignored,
          idsToIgnore,
        }) => {
          // newly added card
          if (newCard) {
            newCardsToAdd.push({ ...mapToCard(data) })

            setDataRefreshOptions({
              handlers: () => addNewCards(newCardsToAdd),
              overlay: false,
            })
            setDataRefresh(true)
          } else if (archived || ignored) {
            // Some cards just got archived / ignored

            const idsToUpdate = archived ? idsToArchive : idsToIgnore

            // Disable cards
            const updateDisabledCards = {
              ...cards,
              completed: cards.completed.map((card) =>
                idsToUpdate.some(
                  (updatedId) => card.alertTriggerId === updatedId
                )
                  ? { ...card, disabled: true }
                  : card
              ),
            }

            setCards(updateDisabledCards)

            const updateRemovedCards = archived
              ? {
                  ...cards,
                  completed: cards.completed.filter(
                    (card) =>
                      !idsToUpdate.some(
                        (updatedId) => card.alertTriggerId === updatedId
                      )
                  ),
                }
              : {
                  ...cards,
                  active: cards.active.filter(
                    (card) =>
                      !idsToUpdate.some(
                        (updatedId) => card.alertTriggerId === updatedId
                      )
                  ),
                  acknowledged: cards.acknowledged.filter(
                    (card) =>
                      !idsToUpdate.some(
                        (updatedId) => card.alertTriggerId === updatedId
                      )
                  ),
                  completed: [
                    ...cards.completed,
                    ...cards.active.filter((card) =>
                      idsToUpdate.some(
                        (updatedId) => card.alertTriggerId === updatedId
                      )
                    ),
                    ...cards.acknowledged.filter((card) =>
                      idsToUpdate.some(
                        (updatedId) => card.alertTriggerId === updatedId
                      )
                    ),
                  ],
                }

            setDataRefreshOptions({
              handlers: () => {
                // Remove archived cards
                setCards(updateRemovedCards)
              },
              overlay: false,
            })
            setDataRefresh(true)
          } else {
            // Make modified card unusable
            const editedCard = getCardFromStateById(id)

            let cardsToUpdate = cardsToMoveOnRefresh

            // The edited card is loaded in state
            if (editedCard) {
              // Disable card
              const updatedCards = {
                ...cards,
                [from.toLowerCase()]: cards[from.toLowerCase()].map((card) =>
                  card.alertTriggerId === id
                    ? { ...card, disabled: true }
                    : card
                ),
              }

              cardsToUpdate = [
                ...cardsToUpdate,
                { alertTriggerId: id, value: to },
              ]

              setCardsToMoveOnRefresh(cardsToUpdate)
              setCards(updatedCards)
            }

            setDataRefreshOptions({
              handlers: () =>
                editedCard
                  ? updateCardsLocally(cardsToUpdate)
                  : resetCardsAndRefetch(),
              overlay: false,
            })
            setDataRefresh(true)
          }
        }
      )
    }

    return () => socket?.removeAllListeners(NOTIFICATIONS.alertTriggers.receive)
  }, [socket, JSON.stringify(cards), JSON.stringify(cardsToMoveOnRefresh)])

  if (error) {
    return (
      <div className="heading" data-cy="page-heading">
        An error occured. Try reloading the page.
      </div>
    )
  }

  return (
    <div className="active-alert-triggers">
      <AlertTriggerFilters
        resetItemsToLoad={() => setItemsToLoad({ ...initialItemsToLoad })}
        filterState={filterState}
        setFilterState={setFilterState}
        initialFilterState={initialFilterState}
        resetCards={resetCards}
        setCardsLoading={setLoading}
      />

      <Helmet>
        <title>Active Alerts</title>
      </Helmet>

      <div className="heading" data-cy="page-heading">
        Active Alerts
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="columns">
            <div className="column column--active">
              <div className="column__header">
                {ALERT_TRIGGER_STATUS.ACTIVE}
              </div>
              {loopCards(
                cards.active,
                updateCardState,
                ALERT_TRIGGER_STATUS.ACTIVE,
                hasEditAccess
              )}
              {itemsToLoad.loadOnlyOneType.includes(
                ALERT_TRIGGER_STATUS.ACTIVE
              ) && <Loader />}
            </div>
            <div
              className={cx('column column--acknowledged', {
                'column--drop': cardIsOverAcknowledgedColumn,
              })}
              ref={dropRefAcknowledged}
            >
              <div className="column__header">
                {ALERT_TRIGGER_STATUS.ACKNOWLEDGED}
              </div>
              {loopCards(
                cards.acknowledged,
                updateCardState,
                ALERT_TRIGGER_STATUS.ACKNOWLEDGED,
                hasEditAccess
              )}
              {itemsToLoad.loadOnlyOneType.includes(
                ALERT_TRIGGER_STATUS.ACKNOWLEDGED
              ) && <Loader />}
            </div>
            <div
              className={cx('column column--completed', {
                'column--drop': cardIsOverCompletedColumn,
              })}
              ref={dropRefCompleted}
            >
              <div className="column__header">
                {ALERT_TRIGGER_STATUS.COMPLETED} /{' '}
                {ALERT_TRIGGER_STATUS.IGNORED}
              </div>
              {loopCards(
                cards.completed,
                updateCardState,
                `${ALERT_TRIGGER_STATUS.COMPLETED}/${ALERT_TRIGGER_STATUS.IGNORED}`,
                hasEditAccess
              )}
              {(itemsToLoad.loadOnlyOneType.includes(
                ALERT_TRIGGER_STATUS.COMPLETED
              ) ||
                itemsToLoad.loadOnlyOneType.includes(
                  ALERT_TRIGGER_STATUS.IGNORED
                )) && <Loader />}
            </div>
          </div>
          {loadingMore ? (
            <div className="loader-footer-bottom">
              <Loader />
            </div>
          ) : (
            <StickyFooter
              className={cx('load-more__wrapper', {
                'load-more__wrapper--hidden': !showLoadMore,
              })}
              buttons={[
                {
                  value: 'Load More Alerts',
                  onClick: () => {
                    setLoadingMore(true)

                    // Add ITEM_LIMIT more to the already existing numbr of cards in a column
                    setItemsToLoad({
                      ...itemsToLoad,
                      active: [
                        cards.active.length,
                        cards.active.length + ITEM_LIMIT,
                      ],
                      acknowledged: [
                        cards.acknowledged.length,
                        cards.acknowledged.length + ITEM_LIMIT,
                      ],
                      completed: [
                        cards.completed.length,
                        cards.completed.length + ITEM_LIMIT,
                      ],
                    })
                  },
                  type: 'secondary',
                  className: 'load-more',
                },
              ]}
            />
          )}
        </>
      )}
    </div>
  )
}

/**
 * Function that loops over a set of alert triggers
 * @param {Array} cards list of alert triggers
 * @param {Function} updateCardState function to update status of an alert trigger
 * @param {String} status
 * @param {Boolean} hasEditAccess
 * @returns {Function}
 */
const loopCards = (cards, updateCardState, status, hasEditAccess) =>
  cards.length ? (
    cards?.map(
      (
        {
          title,
          accountType,
          time,
          description,
          type,
          triggeredBy,
          status,
          alertTriggerId,
          disabled,
        },
        index
      ) => (
        <AlertCard
          title={title}
          accountType={accountType}
          time={time}
          description={description}
          type={type}
          triggeredBy={triggeredBy}
          status={status}
          key={index}
          updateState={updateCardState}
          alertTriggerId={alertTriggerId}
          viewOnly={!hasEditAccess}
          disabled={disabled}
        />
      )
    )
  ) : (
    <p className="no-data">No {status} Alerts at this time.</p>
  )

/**
 * Function that loops over a set of alert triggers
 * @param {Object} props props
 * @param {String} props.title name of alert
 * @param {String} props.accountType type of account (facebook, google etc)
 * @param {String} props.time time the alert was triggered at
 * @param {String} props.description alert description
 * @param {String} props.type type of alert (performance, settings)
 * @param {String} props.triggeredBy elements that triggered the alert
 * @param {String} props.status alert trigger status (active, acknowledged, completed, ignored)
 * @param {Function} props.updateState function to update status of an alert trigger
 * @param {String} props.alertTriggerId id of alert trigger
 * @param {Boolean} props.viewOnly user has view only access to this trigger
 * @param {Boolean} props.disabled the card is disabled and greyed out
 * @returns {React.Component}
 */
const AlertCard = ({
  title,
  accountType,
  time,
  description,
  type,
  triggeredBy,
  status,
  updateState,
  alertTriggerId,
  viewOnly,
  disabled = false,
}) => {
  // Get status key and color from status of string format (eg. 'Active')
  const statusKey = Object.keys(ALERT_TRIGGER_STATUS).find(
    (key) => ALERT_TRIGGER_STATUS[key] === status
  )

  // Drag n drop
  const [{ opacity }, dragRef] = useDrag({
    // Only the drop targets registered for the same type will react to this item
    type: 'Card',
    // This is the only information available to the drop targets
    item: { alertTriggerId, status },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  })

  return (
    <div
      className={cx('alert-trigger-card', {
        'alert-trigger-card--disabled': disabled,
      })}
      style={{ opacity }}
      ref={!disabled && !viewOnly ? dragRef : null}
    >
      <Link
        to={`/alert-triggers/${alertTriggerId}`}
        className="alert-trigger-card__header"
      >
        <p className="title">{title}</p>
        <AccountIcon
          accountType={accountType}
          className="account-icon"
          width="24px"
          height="24px"
          alt={'account icon'}
        />
      </Link>
      <div className="alert-trigger-card__body">
        <div className="alert-trigger-card__body__info-row description">
          <div className="alert-trigger-card__body__info-row__label description">
            {description}
          </div>
        </div>

        <div className="alert-trigger-card__body__info-row">
          <div
            className="alert-trigger-card__body__info-row__label"
            title="Alert Triggered"
          >
            Alert Triggered
          </div>
          <p
            className="alert-trigger-card__body__info-row__detail"
            title={format(new Date(time), `MM/dd/yyyy hh:mm a`)}
          >
            {format(new Date(time), `MM/dd/yyyy hh:mm a`)}
          </p>
        </div>

        <div className="alert-trigger-card__body__info-row">
          <div
            className="alert-trigger-card__body__info-row__label"
            title="Alert Type"
          >
            Alert Type
          </div>
          <p
            className="alert-trigger-card__body__info-row__detail"
            title={type}
          >
            {type}
          </p>
        </div>

        <div className="alert-trigger-card__body__info-row last">
          <div
            className="alert-trigger-card__body__info-row__label"
            title="Triggered by"
          >
            Triggered by
          </div>
          <p
            className="alert-trigger-card__body__info-row__detail"
            title={triggeredBy.join(', ')}
          >
            {triggeredBy.join(', ')}
          </p>
        </div>

        <DropdownMultiColor
          defaultOptionText="Status"
          options={Object.keys(ALERT_TRIGGER_STATUS)
            .filter((status) => status !== 'ACTIVE' && status !== statusKey)
            .map((key) => ({
              label: ALERT_TRIGGER_STATUS[key],
              value: ALERT_TRIGGER_STATUS[key],
              color: ALERT_TRIGGER_COLORS[key],
            }))}
          defaultState={status}
          onChange={(val) => {
            updateState(alertTriggerId, val.value)
          }}
          className="alert-trigger-card__status"
          selected={`${status?.charAt(0)?.toUpperCase()}${status?.slice(1)}`}
          noColorFace
          disabled={viewOnly || disabled}
          disabledColor={disabled}
        />
      </div>
    </div>
  )
}

/**
 *
 * @param {Object} props props
 * @param {Function} props.resetItemsToLoad function to reset number of items to load
 * @param {Object} props.filterState state of filters
 * @param {Function} props.setFilterState function to set state of filters
 * @param {Object} props.initialFilterState initial state of filters
 * @param {Function} props.resetCards function to reset cards
 * @param {Function} props.setCardsLoading function to set the global loading state
 * @returns {React.Component}
 */
export const AlertTriggerFilters = ({
  resetItemsToLoad = () => {},
  filterState,
  setFilterState,
  initialFilterState,
  resetCards = () => {},
  setCardsLoading = () => {},
}) => {
  const [loading, setLoading] = useState(false)
  const [localFilterState, setLocalFilterState] = useState(filterState)
  const [alertList, setAlertList] = useState(null)

  // Allow updating local filter state from outside
  useEffect(() => {
    setLocalFilterState({ ...localFilterState, ...filterState })
  }, [filterState])

  const {
    state: {
      alerts: { categories: alertCategories },
      companies: {
        currentCompany: { _id: companyId },
      },
    },
    dispatch,
  } = useStore()
  const [, user] = useSession()

  useEffect(() => {
    if (!alertList) {
      setLoading(true)
      fetchAlerts(user.isSuperAdmin ? null : companyId)
        .then(setAlertList)
        .catch(console.error)
        .finally(() => setLoading(false))
    }
  }, [])

  useEffect(() => {
    if (!alertCategories) {
      getAlertCategories(dispatch)
    }
  }, [])

  const applyFilters = () => {
    // Only apply if local filters differ from global (previously applied) filters
    if (!isEqual(localFilterState, filterState)) {
      setLoading(true)
      resetCards()
      setCardsLoading(true)
      setFilterState(localFilterState)
      resetItemsToLoad()
      setLoading(false)
    }
  }

  const clearFilters = () => {
    // Only clear if local filters differ from initial filter state
    if (!isEqual(localFilterState, initialFilterState)) {
      setLoading(true)
      resetCards()
      setCardsLoading(true)
      setLocalFilterState({ ...initialFilterState })
      setFilterState({ ...initialFilterState })
      resetItemsToLoad()
      setLoading(false)
    }
  }

  return (
    <Filters
      loading={loading}
      onApply={applyFilters}
      onClear={clearFilters}
      initialApplied={!isEqual(localFilterState, filterState)}
    >
      <div className="label">Keyword</div>
      <Input
        onChange={(keyword) =>
          setLocalFilterState({ ...localFilterState, keyword })
        }
        value={localFilterState.keyword}
        placeholder="Enter Alert Name, Category, or Publisher"
      />

      <div className="label">Alert Triggered</div>
      <Dropdown
        defaultOptionText="All Alerts"
        options={(alertList || []).map(({ name, _id }) => ({
          value: _id,
          label: name,
        }))}
        selectedItems={localFilterState.alertTriggered}
        deselectLabel="All Alerts"
        onChange={(alertTriggered) =>
          setLocalFilterState({ ...localFilterState, alertTriggered })
        }
        multiSelect
      />

      <div className="label">Alert Category</div>
      <Dropdown
        defaultOptionText="All Alert Categories"
        options={(alertCategories || []).map(({ name, _id }) => ({
          value: _id,
          label: name,
        }))}
        selectedItems={localFilterState.alertCategory}
        deselectLabel="All Alert Categories"
        onChange={(alertCategory) =>
          setLocalFilterState({ ...localFilterState, alertCategory })
        }
        multiSelect
      />

      <div className="label">Alert Type</div>
      <Dropdown
        defaultOptionText="All Alert Types"
        options={ALERT_TYPES.map((c) => ({
          label: c,
          value: c,
        }))}
        defaultState={localFilterState.alertType}
        deselectLabel="All Alert Types"
        onChange={(alertType) =>
          setLocalFilterState({ ...localFilterState, alertType })
        }
      />

      <div className="label">Publisher</div>
      <DropdownWithSubsections
        defaultOptionText="All Publishers"
        options={Object.entries(ACCOUNT_TYPE_NAMES).map(([value, label]) => ({
          value,
          label,
        }))}
        selectAllOptions={{
          label: 'All Publishers',
          allSelected:
            localFilterState.channel?.length ===
            Object.entries(ACCOUNT_TYPE_NAMES).map(([value]) => value).length,
          onCheck: () => {
            const allChannels = Object.entries(ACCOUNT_TYPE_NAMES).map(
              ([value]) => value
            )
            const allSelected =
              localFilterState.channel?.length === allChannels.length
            !allSelected
              ? setLocalFilterState({
                  ...localFilterState,
                  channel: allChannels,
                })
              : setLocalFilterState({
                  ...localFilterState,
                  channel: [],
                })
          },
        }}
        defaultState={localFilterState.channel}
        deselectLabel="All Publishers"
        selectedItems={localFilterState.channel}
        onChange={(channel) => {
          setLocalFilterState({
            ...localFilterState,
            channel: channel,
          })
        }}
      />

      <div className="label">Status</div>
      <Dropdown
        defaultOptionText="All Statuses"
        options={Object.values(ALERT_TRIGGER_STATUS).map((c) => ({
          label: c,
          value: c,
        }))}
        selectedItems={localFilterState.status}
        deselectLabel="All Statuses"
        onChange={(status) =>
          setLocalFilterState({ ...localFilterState, status })
        }
        multiSelect
      />

      <div className="filters-date">
        <div className="filters-date__col">
          <div className="label">Start date</div>
          <Calendar
            className="input"
            format={'MM/dd/yyyy'}
            date={localFilterState.startDate}
            returnDate={(startDate) =>
              setLocalFilterState({ ...localFilterState, startDate })
            }
            icon
          />
        </div>
        <div className="filters-date__col">
          <div className="label">End date</div>
          <Calendar
            className="input"
            format={'MM/dd/yyyy'}
            date={localFilterState.endDate}
            returnDate={(endDate) =>
              setLocalFilterState({ ...localFilterState, endDate })
            }
            icon
          />
        </div>
      </div>
    </Filters>
  )
}

AlertCard.propTypes = {
  title: PropTypes.string,
  accountType: PropTypes.string,
  time: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  triggeredBy: PropTypes.array,
  status: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
  alertTriggerId: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
  disabled: PropTypes.bool,
}

AlertTriggerFilters.propTypes = {
  resetItemsToLoad: PropTypes.func,
  filterState: PropTypes.object.isRequired,
  setFilterState: PropTypes.func.isRequired,
  initialFilterState: PropTypes.object.isRequired,
  resetCards: PropTypes.func,
  setCardsLoading: PropTypes.func,
}

export default ActiveAlertTriggers
