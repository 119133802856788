import React, { useEffect, useState, useMemo } from 'react'
import { useStore } from 'store'
import PropTypes from 'prop-types'
import isEqual from 'lodash.isequal'
import { utils, companies } from '@decision-sciences/qontrol-common'

// Components
import Filters from 'components/filters'
import Input from 'components/input'
import Loader from 'components/loader'
import DropdownWithSubsections from 'components/dropdown-with-subsections'
import { Dropdown } from 'components/dropdown'
import { AccountIcon } from 'components/account-icon/index'

// Utils
import { getCompaniesWithFilterOptions } from 'modules/companies/actions'
import { resetFilterSort, saveFilter } from 'modules/table-filter-sort/actions'
import { defaultFilterSortConfig } from 'modules/table-filter-sort/constants'
import { useDebouncedEffect } from 'components/utils/custom-hooks'
import { showErrorMessage } from 'modules/notifications/actions'

const { getCommonFields } = utils.object
const { COMPANY_FILTERS, DEPENDENT_FIELDS, FIELDS_TO_FILTER } = companies

const CompanyFilters = ({ tableContainer }) => {
  const { state, dispatch } = useStore()
  const filterSort = state.tableFilterSort.filterSort[tableContainer]
  const emptyFilters = {
    ...defaultFilterSortConfig.filterSort[tableContainer].filter,
  }
  const [loading, setLoading] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(filterSort.filter)
  const [filterOptions, setFilterOptions] = useState(
    filterSort.filter.options || {}
  )
  const [companyIds, setCompanyIds] = useState(
    filterSort.filter.companyIds || []
  )

  useEffect(() => {
    async function fetchInitialFilters() {
      if (!Object.keys(filterOptions).length) {
        setLoading(true)
        try {
          const res = await getCompaniesWithFilterOptions({ ...emptyFilters })
          if (res.success) {
            const filterOptions = { ...res.options }
            const companyIds = [...res.companyIds]
            setFilterOptions(filterOptions)
            setCompanyIds(companyIds)
          }
        } catch (_) {
          showErrorMessage(
            'Unable to get company filters. Please try again in a few moments',
            dispatch
          )
        } finally {
          setLoading(false)
        }
      }
    }
    if (!isEqual(filterSort.filter, emptyFilters)) {
      setSelectedFilters(filterSort.filter)
      setCompanyIds(filterSort.filter.companyIds)
      setFilterOptions(filterSort.filter.options)
    } else {
      fetchInitialFilters()
    }
  }, [JSON.stringify(filterSort.filter)])

  /**
   * Whenever the name search value changes (and stays changed for 300ms), update filter options
   */
  useDebouncedEffect(
    () => {
      if (
        selectedFilters.name !== null &&
        selectedFilters.name !== filterSort?.filter.name
      ) {
        onChangeFilter(selectedFilters, false, COMPANY_FILTERS.NAME, true)
      }
    },
    500,
    [selectedFilters.name]
  )

  const applyFilters =
    (companyIdsToFetch = companyIds) =>
    () => {
      const hasFiltersSelected = !isEqual(selectedFilters, emptyFilters)
      if (hasFiltersSelected) {
        const filterState = {
          ...selectedFilters,
          companyIds: companyIdsToFetch,
          options: filterOptions,
        }
        saveFilter(dispatch, tableContainer, filterState)
      } else {
        saveFilter(dispatch, tableContainer, selectedFilters)
      }
    }

  const clearFilters = () => {
    if (!isEqual(selectedFilters, emptyFilters)) {
      getCompaniesWithFilterOptions({ ...emptyFilters })
        .then((res) => {
          if (res.success) {
            setSelectedFilters({ ...emptyFilters })
            const filterOptions = { ...res.options }
            const companyIds = [...res.companyIds]
            setFilterOptions(filterOptions)
            setCompanyIds(companyIds)
            resetFilterSort(dispatch, tableContainer)
          }
        })
        .catch(() => {
          showErrorMessage(
            'Unable to reset filtering. Please try again in a few moments',
            dispatch
          )
        })
    }
  }

  const onChangeFilter = async (
    newFilters,
    setFilters = true,
    field,
    triggerFetch = false
  ) => {
    setLoading(true)
    // Since filtering is done top to bottom, options should change only for the filters "below" field
    const fieldsToKeepOptionsFor = FIELDS_TO_FILTER[field]
    const fieldsToChange = DEPENDENT_FIELDS[field]

    // Fetch new filter options for selected filters
    const filters = await getCompaniesWithFilterOptions(newFilters, field)
    if (filters) {
      // Set filter options
      const newOptions = { ...filters.options }
      const newCompanyIds = [...filters.companyIds]
      fieldsToKeepOptionsFor.forEach(
        (field) => (newOptions[field] = filterOptions?.[field])
      )
      setFilterOptions({ ...newOptions })
      setCompanyIds(newCompanyIds)

      // Set new selected filters
      // Go over returned options to see if any previosuly selected option is not available anymore
      fieldsToChange.forEach((field) => {
        const newOptions = filters.options[field].map((op) => op.value)
        newFilters[field] = newFilters[field]?.filter((v) =>
          newOptions.includes(v)
        )
      })
      setFilters && setSelectedFilters({ ...newFilters })
      triggerFetch && applyFilters(newCompanyIds)()
    }
    setLoading(false)
  }

  /**
   * On change function for the Vertical, Reporting, Publisher, Country and State
   * {String} field Name of the field that is being changed
   * {Array} updatedValues Updated values for the filter
   */
  const onChangeMultiselectDropdowns = async (field, updatedValues) => {
    const newFilters = { ...selectedFilters }
    if (!updatedValues.length) {
      newFilters[field] = []
    } else {
      newFilters[field] = [...updatedValues]
    }
    if (
      field === COMPANY_FILTERS.PUBLISHERS &&
      newFilters[COMPANY_FILTERS.ACCOUNTS].length
    ) {
      const newSelectedAccounts = newFilters[COMPANY_FILTERS.ACCOUNTS].filter(
        (ac) => {
          const selectedAccount = filterOptions[COMPANY_FILTERS.ACCOUNTS].find(
            (acc) => acc._id === ac
          )
          return updatedValues.includes(selectedAccount.type)
        }
      )
      newFilters[COMPANY_FILTERS.ACCOUNTS] = newSelectedAccounts
    }
    // Update filter options for updated filters
    await onChangeFilter(newFilters, true, field, false)
  }

  const onChangeName = (val) => {
    const field = COMPANY_FILTERS.NAME
    const newFilters = { ...selectedFilters }
    // Update selected filters
    if (!val) {
      newFilters[field] = ''
    } else {
      newFilters[field] = val
    }
    // Set filters to new filters
    setSelectedFilters({ ...newFilters })
  }

  const onChangeAccounts = async (val) => {
    const field = COMPANY_FILTERS.ACCOUNTS
    const filterValues = selectedFilters[field]
    const newFilters = { ...selectedFilters }
    // Update selected filters
    if (filterValues.includes(val)) {
      newFilters[field] = []
    } else {
      newFilters[field] = [val]
      const selectedAccount = filterOptions[COMPANY_FILTERS.ACCOUNTS].find(
        (acc) => acc._id === val
      )
      if (!newFilters[COMPANY_FILTERS.PUBLISHERS].length) {
        newFilters[COMPANY_FILTERS.PUBLISHERS] = [selectedAccount.type]
      }
    }

    // Update filter options for updated filters
    await onChangeFilter(newFilters, true, field, false)
  }

  const onChangeStatus = async (status) => {
    const field = COMPANY_FILTERS.STATUS
    const filterValues = selectedFilters[field]
    const newFilters = { ...selectedFilters }

    // Update selected filters
    if (filterValues.includes(status)) {
      newFilters[field] = []
    } else {
      newFilters[field] = [status]
    }

    // Update filter options for updated filters
    await onChangeFilter(newFilters, true, field, false)
  }

  const allSelected = (field) =>
    selectedFilters[field]?.length > 0 &&
    selectedFilters[field].length === filterOptions[field]?.length

  const filteredAccountOptions = useMemo(() => {
    const selectedPublishers = selectedFilters[COMPANY_FILTERS.PUBLISHERS]
    const accountOptions =
      filterOptions?.accounts?.map((ac, idx) => {
        let label = ''
        if (ac.externalAccountId && ac.name) {
          label = `${ac.name} (${ac.externalAccountId})`
        } else if (ac.name) {
          label = ac.name
        } else {
          label = ac.externalAccountId
        }
        return {
          value: ac._id,
          accountId: ac.externalAccountId,
          label: label,
          name: ac.name,
          type: ac.type,
          key: idx,
        }
      }) || []
    return selectedPublishers?.length
      ? accountOptions.filter((ac) => selectedPublishers.includes(ac.type))
      : accountOptions
  }, [selectedFilters[COMPANY_FILTERS.PUBLISHERS], filterOptions])

  const [initial, current] = getCommonFields(emptyFilters, selectedFilters)

  return (
    <Filters
      onApply={applyFilters()}
      onClear={clearFilters}
      disableClearOnUnmount={true}
      initialApplied={!isEqual(initial, current)}
    >
      {loading && <Loader style={{ zIndex: 1 }} />}
      <Input
        value={selectedFilters.name || ''}
        placeholder="Enter Client Name or Business Unit Name"
        searchBlue
        onChange={(name) => onChangeName(name)}
      />
      <div className="general-label">Vertical</div>
      <DropdownWithSubsections
        options={filterOptions?.vertical || []}
        selectedItems={selectedFilters?.vertical || []}
        onChange={async (updatedVerticals) =>
          await onChangeMultiselectDropdowns(
            COMPANY_FILTERS.VERTICAL,
            updatedVerticals
          )
        }
        defaultOptionText="Select Vertical"
        selectAllOptions={{
          label: 'All',
          allSelected: allSelected(COMPANY_FILTERS.VERTICAL),
          onCheck: async (val) => {
            const verticals = val
              ? filterOptions?.vertical.map((v) => v.value)
              : []
            await onChangeMultiselectDropdowns(
              COMPANY_FILTERS.VERTICAL,
              verticals
            )
          },
        }}
        disabled={!filterOptions?.vertical?.length}
        hideSelectAllOnSearch
      />
      <div className="general-label">Report Option</div>
      <DropdownWithSubsections
        options={filterOptions?.reporting || []}
        selectedItems={selectedFilters?.reporting || []}
        onChange={async (updatedReportings) =>
          await onChangeMultiselectDropdowns(
            COMPANY_FILTERS.REPORTING,
            updatedReportings
          )
        }
        defaultOptionText="Select Report Option"
        selectAllOptions={{
          label: 'All',
          allSelected: allSelected(COMPANY_FILTERS.REPORTING),
          onCheck: async (val) => {
            const reports = val
              ? filterOptions?.reporting.map((r) => r.value)
              : []
            await onChangeMultiselectDropdowns(
              COMPANY_FILTERS.REPORTING,
              reports
            )
          },
        }}
        disabled={!filterOptions?.reporting?.length}
        hideSelectAllOnSearch
      />
      <div className="general-label">Publisher</div>
      <DropdownWithSubsections
        options={filterOptions?.publishers || []}
        selectedItems={selectedFilters?.publishers || []}
        onChange={async (updatedPublishers) => {
          await onChangeMultiselectDropdowns(
            COMPANY_FILTERS.PUBLISHERS,
            updatedPublishers
          )
        }}
        defaultOptionText="Select publishers"
        selectAllOptions={{
          label: 'All Publishers',
          allSelected: allSelected(COMPANY_FILTERS.PUBLISHERS),
          onCheck: async (val) => {
            const publishers = val
              ? filterOptions?.publishers.map((p) => p.value)
              : []
            await onChangeMultiselectDropdowns(
              COMPANY_FILTERS.PUBLISHERS,
              publishers
            )
          },
        }}
        disabled={!filterOptions?.publishers?.length}
        hideSelectAllOnSearch
      />
      <div className="general-label">Linked Accounts</div>
      <Dropdown
        options={filteredAccountOptions}
        defaultOptionText="Select accounts"
        defaultState={
          selectedFilters?.accounts?.length ? selectedFilters.accounts[0] : ''
        }
        onChange={async (val) => await onChangeAccounts(val)}
        optionRenderer={(option, selectedItems) => (
          <AccountDropdownOption
            option={option}
            selectedItems={selectedItems}
            noCheckbox
          />
        )}
        disabled={!filterOptions?.accounts?.length}
        showOptionsInPlaceholder
      />
      <div className="align-row">
        <div className="align-column margin-right-10">
          <div className="general-label">Country</div>
          <DropdownWithSubsections
            options={filterOptions?.countries || []}
            selectedItems={selectedFilters?.countries || []}
            onChange={async (updatedCountries) =>
              await onChangeMultiselectDropdowns(
                COMPANY_FILTERS.COUNTRIES,
                updatedCountries
              )
            }
            defaultOptionText="Select countries"
            selectAllOptions={{
              label: 'All',
              allSelected: allSelected(COMPANY_FILTERS.COUNTRIES),
              onCheck: async (val) => {
                const countries = val
                  ? filterOptions?.countries.map((c) => c.value)
                  : []
                await onChangeMultiselectDropdowns(
                  COMPANY_FILTERS.COUNTRIES,
                  countries
                )
              },
            }}
            disabled={!filterOptions?.countries?.length}
            hideSelectAllOnSearch
          />
        </div>
        <div className="align-column">
          <div className="general-label">State</div>
          <DropdownWithSubsections
            options={filterOptions?.states || []}
            selectedItems={selectedFilters?.states || []}
            onChange={async (updatedStates) =>
              await onChangeMultiselectDropdowns(
                COMPANY_FILTERS.STATES,
                updatedStates
              )
            }
            defaultOptionText="Select state"
            selectAllOptions={{
              label: 'All',
              allSelected: allSelected(COMPANY_FILTERS.STATES),
              onCheck: async (val) => {
                const states = val
                  ? filterOptions?.states.map((c) => c.value)
                  : []
                await onChangeMultiselectDropdowns(
                  COMPANY_FILTERS.STATES,
                  states
                )
              },
            }}
            hideSelectAllOnSearch
            disabled={!filterOptions?.states?.length}
          />
        </div>
      </div>
      <div className="general-label">Status</div>
      <Dropdown
        options={filterOptions?.status || []}
        defaultState={
          selectedFilters?.status?.length ? selectedFilters.status[0] : ''
        }
        onChange={async (val) => await onChangeStatus(val)}
        defaultOptionText="Select status"
        disableSearch
        showOptionsInPlaceholder
      />
    </Filters>
  )
}

const AccountDropdownOption = ({ option }) => {
  const label = option.name || option.externalAccountId
  const hint = option.accountId ? option.accountId : ''
  return (
    <div className="align-row account-dropdown">
      <AccountIcon accountType={option.type} />
      <div className="checkbox input-wrapper">
        <label className="checkbox__label">
          {label && (
            <div className="label-content-wrapper">
              <div className="label-wrapper">{label}</div>
              {hint && <div className="hint-wrapper">{hint}</div>}
            </div>
          )}
        </label>
      </div>
    </div>
  )
}
CompanyFilters.propTypes = {
  tableContainer: PropTypes.string.isRequired,
}

AccountDropdownOption.propTypes = {
  option: PropTypes.object.isRequired,
}
export default CompanyFilters
