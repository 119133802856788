import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

/* Hooks */
import { useOnClickOutside } from 'hooks/outside-click'
import useSession from 'modules/session'

/* Components */
import Icon from 'components/icon/index'
import HoverContainer from 'components/hover-container/index'
import Button from 'components/button/index'
import { DropdownList } from 'components/dropdown-list/index'

/* Assets */
import { ReactComponent as LinkIcon } from 'assets/icon_link.svg'
import { ReactComponent as CancelIcon } from 'assets/icon_fill_cross_red.svg'
import { ReactComponent as ConfirmIcon } from 'assets/icon_check_green.svg'
import { ReactComponent as CheckIcon } from 'assets/icon_check_no_border.svg'

/**
 * Displays the controls for linking unlinking sections from the BU to their parent client.
 * This renders BusinessUnitLinkControl or ClientLinkControl depending on the nature of the client prop.
 * @param {Object} params React Params
 * @param {Object} params.client Client OR Business Unit object
 * @param {Object} params.links Proprietary link data.
 * @param {Function} params.onChangeLinks Callback for changing link data.
 * @param {Boolean} [params.isSectionOpen] Whether the containing section is open or not.
 * @returns {Node}
 */
export const SectionLinkControl = ({
  client,
  links = {},
  onChangeLinks,
  isSectionOpen,
}) => {
  const isBusinessUnit = !!client.parentCompany
  const isLinked = !!links[client._id]

  const [, user] = useSession()

  if (client.new) {
    return null
  }

  if (isBusinessUnit) {
    const onConfirm = () => {
      onChangeLinks({ ...links, [client._id]: !isLinked })
    }

    return (
      <BusinessUnitLinkControl
        isLinked={isLinked}
        onConfirm={onConfirm}
        isSectionOpen={isSectionOpen}
        readOnly={!user.isSuperAdmin}
      />
    )
  }

  return (
    <ClientLinkControl
      client={client}
      links={links}
      onChangeLinks={onChangeLinks}
      readOnly={!user.isSuperAdmin}
    />
  )
}

/**
 * Controls the Business Unit side of linking sections.
 * Displays the controls for doing so.
 * @param {Object} params React Params
 * @param {Boolean} [params.isLinked] Whether the section is linked or not.
 * @param {Function} params.onConfirm Callback to change the "isLinked" property.
 * @param {Boolean} [params.readOnly] Whether the link is changeable or not.
 * @returns {Node}
 */
const BusinessUnitLinkControl = ({ isLinked, onConfirm, readOnly }) => {
  const hoverContainerRef = useRef()

  const onCancel = () => {
    hoverContainerRef.current.toggleOpen(false)
  }

  const onConfirmation = () => {
    onConfirm(!isLinked)
    onCancel()
  }

  const isLinkedText = isLinked
    ? 'Linked to Client Settings'
    : 'Not Linked to Client Settings'

  const tooltipContent = readOnly
    ? isLinkedText
    : {
        title: isLinked
          ? 'Unlink Settings from Client'
          : 'Link Settings from Client',
        content: isLinked ? (
          'You are about to unlink the BU settings of this section from the client settings.'
        ) : (
          <>
            <div>
              You are about to link the BU settings of this section to the
              client settings.
            </div>
            <div>
              This will overwrite all settings for this section within this BU.
            </div>
          </>
        ),
      }

  return (
    <HoverContainer
      ref={hoverContainerRef}
      cover={
        <Icon>
          <LinkIcon width={24} height={24} className="svg--blue" />
        </Icon>
      }
      tooltip={tooltipContent}
      closesOnClick
      disabled={readOnly}
    >
      <div className="display-flex">
        <Button
          round
          secondaryRed
          onClick={onCancel}
          value={
            <Icon>
              <CancelIcon width={24} height={24} />
              Cancel
            </Icon>
          }
        />
        <Button
          round
          secondaryGreen
          onClick={onConfirmation}
          value={
            <Icon>
              <ConfirmIcon width={24} height={24} />
              Confirm
            </Icon>
          }
        />
      </div>
    </HoverContainer>
  )
}

/**
 * Controls the Client side of linking sections.
 * Displays the controls for doing so.
 * @param {Object} params React Params
 * @param {Object} params.client Client Object
 * @param {Object} params.links Link Object. Contains all the links of a client.
 * @param {Function} params.onChangeLinks Callback to change the links of a client.
 * @param {Boolean} [params.readOnly] Whether the links are changeable or not.
 * @returns {Node}
 */
const ClientLinkControl = ({ client, links = {}, onChangeLinks, readOnly }) => {
  const [listOpened, setListOpened] = useState(false)
  const ref = useRef()
  const iconRef = useRef()

  const linkArray = Object.keys(links).filter((link) => links[link])
  const [newLinks, setNewLinks] = useState(linkArray)
  const [hasChanges, setHasChanges] = useState(false)

  useEffect(() => {
    // In case the links are somehow changed, update the local
    setNewLinks(linkArray)
  }, [links])

  const openList = () => {
    setListOpened(true)
  }
  const closeList = () => {
    setListOpened(false)
    setHasChanges(false)
    setNewLinks(linkArray)
  }

  useOnClickOutside(ref, closeList)

  const onConfirm = () => {
    const updates = {}
    // Level the entirety of the previous values to "false"
    Object.keys(links).forEach((businessUnitId) => {
      updates[businessUnitId] = false
    })
    // Apply true to the values to be confired only.
    newLinks.forEach((businessUnitId) => {
      updates[businessUnitId] = true
    })

    onChangeLinks(updates)

    // We don't need to reset the links this way, it's accurate as it is
    setListOpened(false)
    setHasChanges(false)
  }

  const onChange = (values) => {
    if (!hasChanges) {
      setHasChanges(true)
    }
    setNewLinks(values)
  }

  const availableLinks = (client.businessUnits || [])
    .filter((businessUnit) => !businessUnit.new)
    .map(({ _id, name }) => ({
      label: name,
      value: _id,
      unselectable: readOnly,
    }))

  return (
    <div className="client-link-control" ref={ref}>
      {listOpened && (
        <div className="client-link-control__popover">
          <div className="client-link-control__popover__heading">
            Link Settings to Business Units
          </div>
          <DropdownList
            className="client-link-control__dropdown"
            options={availableLinks}
            selectedItems={newLinks}
            onChange={onChange}
            optionsHeight={400}
            hasSearch={!readOnly && availableLinks.length > 1}
            selectAll={!readOnly && availableLinks.length > 1}
            multiSelect={true}
            sortOptions={true}
            standalone={true}
          />
          <div className="client-link-control__popover__footer">
            {!readOnly && (
              <Button
                secondaryGreen
                compact
                disabled={!hasChanges}
                onClick={onConfirm}
                value={
                  <div className="display-flex gap-4 align-items-center uppercase font-size-11 bold">
                    <CheckIcon width={20} height={20} />
                    Save
                  </div>
                }
              />
            )}
            <Button secondaryGray onClick={closeList} value="Cancel" />
          </div>
        </div>
      )}

      <Icon
        className="client-link-control__link"
        onClick={listOpened ? closeList : openList}
        disabled={readOnly && !linkArray.length}
      >
        <LinkIcon ref={iconRef} width={24} height={24} className="svg--blue" />
        {!!newLinks.length && (
          <div className="round-solid-background">{newLinks.length}</div>
        )}
      </Icon>
    </div>
  )
}

SectionLinkControl.propTypes = {
  client: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
  onChangeLinks: PropTypes.func.isRequired,
  isSectionOpen: PropTypes.bool,
}

ClientLinkControl.propTypes = {
  client: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
  onChangeLinks: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

BusinessUnitLinkControl.propTypes = {
  isLinked: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  readOnly: PropTypes.bool,
}
