import React, { useEffect } from 'react'

/** Store */
import { useStore } from 'store'

/** Actions */
import { getFavouriteTraQTemplates } from 'modules/traq-templates/actions'

/** Components */
import ShortcutCard from 'components/shortcut-card/index'
import FavoritesPanel from 'components/favorites-panel/index'

/** Assets */
import { ReactComponent as ReportsIcon } from 'assets/reports.svg'
import { ReactComponent as AlertsIcon } from 'assets/icon_alerts.svg'
import { ReactComponent as AvatarIcon } from 'assets/avatar.svg'
import { ReactComponent as LogoutIcon } from 'assets/icon_slide_right.svg'

/** Style */
import './style.scss'

const HomePage = () => {
  const {
    dispatch,
    state: {
      globalReports,
      traqTemplates: { userTraq },
    },
  } = useStore()

  useEffect(() => {
    if (!embarqList) {
      getFavouriteTraQTemplates(dispatch)
    }
  }, [])

  const { favList: reportsList } = globalReports

  const embarqList =
    userTraq
      ?.filter(
        ({ traqTemplate, favorite }) =>
          !traqTemplate?.deleted && favorite && traqTemplate?.active
      )
      ?.map(({ traqTemplate: { name, _id } }) => ({
        name,
        _id,
      })) || []

  return (
    <div className="homepage">
      <div className="homepage__shortcuts">
        <ShortcutCard
          description="Reports"
          Icon={ReportsIcon}
          redirectRoute="tableau-dashboard"
        />
        <ShortcutCard
          description="Alerts"
          Icon={AlertsIcon}
          redirectRoute="alert-triggers"
        />
        <ShortcutCard
          description="Your Account"
          Icon={AvatarIcon}
          iconType="avatar"
          redirectRoute="my-account/account"
        />
        <ShortcutCard
          description="Log Out"
          Icon={LogoutIcon}
          iconType="logout"
          isLogout={true}
        />
      </div>
      <div className="homepage__favorites">
        <FavoritesPanel
          title="Reports"
          list={reportsList.map((report) => {
            const { isCustom } = report
            if (isCustom) {
              return { ...report, customRoute: 'reports' }
            }
            return report
          })}
          baseRoute="tableau-dashboard"
          emptyMessage="Add a favorite Report"
        />
        <FavoritesPanel
          title="EmbarQ"
          list={embarqList}
          baseRoute="embarq-dashboard"
          emptyMessage="Add a favorite EmbarQ Dashboard"
        />
      </div>
    </div>
  )
}

export default HomePage
