import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useStore } from 'store'
import useSession from 'modules/session'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { NOT_FOUND_ROUTE } from 'routes'
import Input from 'components/input'
import { OwnerDropdown } from 'components/utils/owner'
import {
  expressionParser,
  utils,
  metrics,
  permissions,
} from '@decision-sciences/qontrol-common'
import TemplateBuilder from 'components/expression-builder'
import { FIELD_TYPES, validate } from 'components/validator'
import { FORMULA_TOOLTIP } from 'components/utils/tooltip'
import { CheckboxNoHooks } from 'components/checkbox'
import Tooltip from 'components/tooltip'
import StickyFooter from 'components/sticky-footer'
import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'
import useLeaveConfirm from 'components/leave-confirm'
import { createUpdateAlertTemplate } from 'modules/alert-templates/actions'
import { getUsersWithPermissions } from 'modules/users/actions'
import { PrimaryComparisonPeriodSection } from 'modules/alerts/primary-comparison-period'
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from 'modules/notifications/actions'
import { editLockContext } from 'contexts/edit-lock'
import './style.scss'

const { extractVariables } = expressionParser
const { stringToID } = utils.string
const {
  getAllMetrics,
  METRIC_TYPES,
  METRICS_SETTINGS,
  METRICS_BUDGET_PACING,
  LIFETIME_BUDGET_METRICS,
  SPECIAL_METRICS,
  isDynamicMetric: isDynamic,
} = metrics

const AlertTemplatesForm = ({
  alertTemplates,
  alertKey,
  variables,
  calculatedMetrics,
  alertThresholds,
  hasCreateAccess,
  hasEditAccess,
}) => {
  const {
    dispatch,
    state: {
      companies: {
        currentCompany: { _id: companyId },
      },
    },
  } = useStore()
  const [, user] = useSession()
  const [state, setState] = useState({
    key: '',
    name: '',
    expression: '',
    expressionValid: false,
    isGlobal: false,
    owner: null,
    comparison: { currentMetric: null, compareMetric: null },
  })
  const navigate = useNavigate()
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [autoKey, setAutoKey] = useState(true)
  const existing = alertKey && alertKey !== 'new'
  const [showTooltip, setShowTooltip] = useState(false)
  const [setDirty, LeaveConfirm] = useLeaveConfirm({})
  const [possibleOwners, setPossibleOwners] = useState(null)

  const entityId = useMemo(
    () => alertTemplates?.find((variable) => variable.key === alertKey)?._id,
    [alertKey, JSON.stringify(alertTemplates)]
  )

  /** Edit locks */
  const { setEntityType, setEntityId, EDIT_LOCK_ENTITIES } =
    useContext(editLockContext)

  /**
   * Tell the Edit Lock context:
   * - what entity we're currently on
   * - the entity type we're currently on
   * - the actions to happen before unlocking the entity
   */
  useEffect(() => {
    if (existing && entityId) {
      setEntityId(entityId)
      setEntityType(EDIT_LOCK_ENTITIES.ALERT_TEMPLATE)

      /** It's important to clear everything when leaving the page */
      return () => {
        setEntityId(null)
        setEntityType(null)
      }
    }
  }, [entityId, existing])

  // Filter Variables - only show Global ones or non-global ones w.r.t the template's isGlobal field
  const filteredVariables = useMemo(
    () => variables.filter((item) => (state.isGlobal ? item.isGlobal : true)),
    [state.isGlobal, JSON.stringify(variables)]
  )

  // Get all metrics
  const allMetrics = useMemo(
    () =>
      getAllMetrics({
        variables: filteredVariables,
        calculatedMetrics,
        alertThresholds,
        settingsMetrics: METRICS_SETTINGS,
        budgetPacingMetrics: METRICS_BUDGET_PACING,
        lifetimeBudgetMetrics: LIFETIME_BUDGET_METRICS,
      })
        .filter(
          // Filter out Dynamic_Metric when Template is NOT Global
          (metric) => {
            if (metric.isVariable) {
              return metric.isGlobal === state.isGlobal
            } else if (metric.isCalculatedMetric) {
              if (!state.isGlobal) {
                return true
              }

              // Check its components
              return !metric.metrics.some(isDynamic)
            } else {
              return !isDynamic(metric) || !state.isGlobal
            }
          }
        )
        .map((metric) => (metric.key ? metric.key : metric)),
    [JSON.stringify(filteredVariables), state.isGlobal, calculatedMetrics]
  )

  /** On mount, check for access */
  useEffect(() => {
    if ((existing && !hasEditAccess) || (!existing && !hasCreateAccess)) {
      navigate('/unauthorized', { replace: true })
    }
    if (existing) {
      getUsersWithPermissions([
        {
          feature: permissions.PERMISSIONS.ALERT_TEMPLATE_DATA_FORM,
          type: permissions.PERMISSION_TYPES.CREATE,
        },
      ]).then((res) => res.list && setPossibleOwners(res.list))
    }
  }, [hasCreateAccess, hasEditAccess])

  /** Edit mode */
  useEffect(() => {
    if (existing) {
      const alertTemplate = alertTemplates.find((a) => a.key === alertKey)
      if (!alertTemplate) {
        return navigate(NOT_FOUND_ROUTE, { replace: true })
      }
      alertTemplate &&
        setState({
          ...alertTemplate,
          comparison: { ...alertTemplate.comparison },
          expressionValid: true,
          owner: alertTemplate.owner._id,
          ownerName: `${alertTemplate.owner.firstName} ${alertTemplate.owner.lastName}`,
        })
    }
  }, [alertKey])

  /** Change handler for expression + form submit */
  const expressionChanged = (expressionValid, expression) => {
    // Extract metrics used in the expression
    const metricsUsed = extractVariables(expression)

    // Update comparison metrics if they are not in the new expression
    const newComparison = { ...state.comparison }
    if (!Object.keys(metricsUsed).includes(newComparison.currentMetric)) {
      newComparison.currentMetric = null
    }
    if (!Object.keys(metricsUsed).includes(newComparison.compareMetric)) {
      newComparison.compareMetric = null
    }

    setDirty(state.expression !== expression)
    setState({
      ...state,
      expression,
      expressionValid,
      comparison: newComparison,
    })
    setErrors({ ...errors, expression: null })
  }

  /** Edit Key field */
  const editKey = (value) => {
    setDirty(true)
    setState({ ...state, key: value })
    setErrors({ ...errors, key: null })
    setAutoKey(false)
  }

  /** Edit Name field */
  const editName = (value) => {
    setDirty(true)
    let keyField = state.key
    if (autoKey) {
      keyField = stringToID(value)
    }
    setState({ ...state, name: value, key: keyField })
    setErrors({ ...errors, name: null, key: autoKey ? null : errors.key })
  }

  /** Edit Comparison dropdowns */
  const editComparison = (type, value) => {
    setDirty(true)
    setState({ ...state, comparison: { ...state.comparison, [type]: value } })
    setErrors({ ...errors, current: null, compare: null })
  }

  /** check if comparison is valid */
  const checkComparison = (key, metricsUsed) => {
    if (
      state.comparison?.[key]?.length > 0 &&
      !Object.keys(metricsUsed).includes(state.comparison?.[key])
    ) {
      setErrors({ ...errors, [key]: true })
      setLoading(false)
      return true
    }
    return false
  }

  /** Validate & Save */
  const onSubmit = (e) => {
    e.preventDefault()
    // Validate
    setLoading(true)
    let [isValid, errors] = validate(ERROR_MAP, state)
    if (!isValid || !state.expressionValid) {
      isValid = false
      setErrors(errors)
      setLoading(false)
    }

    // Extract metrics used in the expression
    const metricsUsed = extractVariables(state.expression)
    const metrics = []

    /** Construct Metrics array and also validate Variable usage */
    Object.keys(metricsUsed).forEach((metric) => {
      let type = METRIC_TYPES.STATIC
      let isDynamicMetric = isDynamic(metric)
      const isSettingsMetric = !!METRICS_SETTINGS[metric]
      const isBudgetPacingMetric = !!METRICS_BUDGET_PACING[metric]

      if (isDynamicMetric && state.isGlobal) {
        isValid = false
        return setErrors({
          ...errors,
          expression: `"${metric}" cannot be used in Global Templates`,
        })
      }

      // Check if the metric is a variable
      const foundVariable = variables.find((el) => el.key === metric)
      const foundThreshold = alertThresholds.find(
        (threshold) => threshold.key === metric
      )

      if (foundVariable) {
        type = METRIC_TYPES.VARIABLE
        isDynamicMetric = isDynamic(foundVariable.metric)

        // Validate Variables - the user can only select Global Variables if Template is also Global
        if (!foundVariable.isGlobal && state.isGlobal) {
          isValid = false
          setErrors({
            ...errors,
            expression: `Variable "${foundVariable.key}" cannot be used as it is non-global`,
          })
        }

        // Calculated Metric in variable
        if (calculatedMetrics[foundVariable.metric]) {
          isDynamicMetric =
            calculatedMetrics[foundVariable.metric].metrics.some(isDynamic)
        }
      } else if (foundThreshold) {
        type = METRIC_TYPES.THRESHOLD
        isDynamicMetric = false
      } else {
        // Check if the metric is a calculated metric or special KPI
        if (Object.keys(SPECIAL_METRICS).includes(metric)) {
          type = METRIC_TYPES.SPECIAL
        } else if (calculatedMetrics[metric]) {
          type = METRIC_TYPES.CALCULATED
          isDynamicMetric = calculatedMetrics[metric].metrics.some(isDynamic)
        }
      }

      metrics.push({
        key: metric,
        type,
        isDynamicMetric,
        isSettingsMetric,
        isBudgetPacingMetric,
      })
    })

    if (
      checkComparison('currentMetric', metricsUsed) ||
      checkComparison('compareMetric', metricsUsed)
    ) {
      return
    }

    if (!isValid) {
      return setLoading(false)
    }

    /* Form is Valid -> Submit */
    const toSave = {
      ...state,
      metrics,
    }

    setDirty(false)
    createUpdateAlertTemplate(
      dispatch,
      toSave,
      user.isSuperAdmin ? null : companyId
    )
      .then((data) => {
        navigate('/alert-templates')
        showSuccessMessage('Alert Template saved successfully', dispatch)
        if (data && data.updatedAlerts && data.updatedAlerts.length) {
          showWarningMessage(
            `Due to recent changes, we removed all granularities for which
              hourly data is not available for the following Alerts that are referencing this template:
              ${data.updatedAlerts.map((alert) => alert.name).join(', ')}`,
            dispatch,
            false
          )
        }
      })
      .catch((err) => {
        showErrorMessage(err, dispatch)
        setLoading(false)
      })
  }

  const isUsedInAlerts = state?.alerts?.length > 0

  return (
    <form className={`alert-templates form ${loading ? 'form--loading' : ''}`}>
      <LeaveConfirm />
      <Helmet>
        <title>
          {existing ? 'Edit Alert Template' : 'Create Alert Template'}
        </title>
      </Helmet>
      {/* Header */}
      <div className="heading" data-cy="page-heading">
        {existing ? 'Edit Alert Template' : 'Create Alert Template'}
      </div>

      <section className="form__section">
        <div className="form__section__body">
          <div className="form__row">
            <Input
              label="Name"
              placeholder="Name"
              onChange={editName}
              value={state.name}
              error={errors.name}
              className="form__half"
            />
            <Input
              label="Identifier"
              placeholder="Identifier"
              onChange={editKey}
              value={state.key}
              error={errors.key}
              className="form__half"
            />
          </div>
          <div className="form__row" style={{ alignItems: 'center' }}>
            <OwnerDropdown
              currentUser={user}
              isNew={!existing}
              allUsers={possibleOwners || []}
              selectedId={state.owner}
              onChange={(owner) => setState({ ...state, owner })}
              loading={!existing ? false : !possibleOwners}
              loadingText={state.ownerName}
            />
            <CheckboxNoHooks
              defaultValue={state.isGlobal}
              isChecked={state.isGlobal}
              onChange={(isGlobal) => {
                setDirty(true)
                setState({ ...state, isGlobal })
              }}
              label="Is Global?"
              className="checkbox-first form__half"
              disabled={isUsedInAlerts}
            />
          </div>
        </div>
      </section>

      <PrimaryComparisonPeriodSection
        state={state}
        errors={errors}
        hasEditAccess={hasEditAccess}
        variables={variables}
        calculatedMetrics={Object.values(calculatedMetrics)}
        onChange={editComparison}
        displayIcon={true}
      />
      <section className="form__section">
        <div className="form__section__header">
          Formula{' '}
          <InfoIcon
            className="calculated-metrics__info fill-light-blue"
            alt={'info'}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
          <Tooltip content={FORMULA_TOOLTIP} show={showTooltip} />
        </div>

        <div className="form__section__body">
          <TemplateBuilder
            key={JSON.stringify(allMetrics)}
            defaultValue={state.expression}
            metrics={allMetrics}
            onChange={expressionChanged}
            error={errors.expression}
          />
        </div>
      </section>

      <StickyFooter
        buttons={[
          {
            value: existing ? 'Save Changes' : 'Save Alert Template',
            onClick: onSubmit,
          },
          {
            value: 'Cancel',
            onClick: () => navigate('/alert-templates'),
            secondaryGray: true,
          },
        ]}
      />
    </form>
  )
}

AlertTemplatesForm.propTypes = {
  alertTemplates: PropTypes.array.isRequired,
  alertKey: PropTypes.string,
  variables: PropTypes.array.isRequired,
  calculatedMetrics: PropTypes.object,
  alertThresholds: PropTypes.array,
  hasCreateAccess: PropTypes.bool,
  hasEditAccess: PropTypes.bool,
}

const ERROR_MAP = {
  key: [FIELD_TYPES.REQUIRED, FIELD_TYPES.ID],
  name: FIELD_TYPES.REQUIRED,
  expression: FIELD_TYPES.REQUIRED,
}

export default AlertTemplatesForm
