import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Utils */
import { v4 as uuidv4 } from 'uuid'

/* Components */
import FileUpload from 'components/file-upload/ad-content-file-upload'
import ItemRow from 'components/item-row/index'
import InformationBlock, {
  INFORMATION_BLOCK_TYPE,
} from 'components/information-block'
import { GENERIC_UPLOAD_ERROR } from 'components/file-upload/constants'

/* Icon */
import { ReactComponent as DownloadIcon } from 'assets/icon_download.svg'

import './style.scss'

const UsersCSVUpload = ({ setCsvFile = () => {} }) => {
  const [fileName, setFileName] = useState('')
  const [error, setError] = useState(null)

  const downloadTemplates = () => {
    const url = '/import-users-template.csv'

    const a = document.createElement('a')
    a.href = url
    a.download = url
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const onFileUploadDone = (file) => {
    const parsedFile = getParsedFile(file)
    setCsvFile({ ...file, data: parsedFile })
    setError(null)
  }

  const getParsedFile = (file) => {
    const { name, importedUsers } = file
    setFileName(name)

    return importedUsers.map((el) => ({
      id: uuidv4(),
      ...el,
    }))
  }

  const onRemoveFile = () => {
    setCsvFile(null)
    setFileName(null)
  }

  return (
    <div className="form__section__body">
      <div className="import-users-download import-users-download__heading">
        <div className="generic-heading campaign-component__title">
          Upload File to Import Users
        </div>

        <div className="align-row">
          <DownloadIcon className="icon-upside-down margin-top-auto margin-bottom-auto" />
          <a className="flow__link margin-left-10" onClick={downloadTemplates}>
            Download User Import Template
          </a>
        </div>
      </div>
      <div className="import-users-download import-users-download__description">
        Download our
        <a className="flow__link" onClick={downloadTemplates}>
          user import template
        </a>
        and add details for each user. Once the fields are complete, upload the
        template below to begin importing the users.
        <p className="first-description">
          <span className="bold">Required Fields:</span> FirstName, LastName,
          Email, MobilePhone, Client ID(s), PermissionGroup, TimeZone, Status
        </p>
        <span className="bold">Optional:</span> TeamName, JobTitle, Business
        Unit(s), Account(s)
      </div>
      <FileUpload
        throwError={(error) => {
          if (!error) {
            return
          }
          // Bypass default error in order to show detailed instructions
          if (error === GENERIC_UPLOAD_ERROR) {
            return setError(
              'Invalid CSV file uploaded. Download the Import Users CSV template using the link above and try again.'
            )
          }
          setError(error)
        }}
        onFileSelected={(file) => {
          setCsvFile(null)
          setFileName(file?.name)
        }}
        fileTypesAccepted=".csv"
        onFileUploadDone={onFileUploadDone}
        allowMultiple={false}
        uploadApiPath={UPLOAD_CSV_TEMPLATE}
      />
      {error && (
        <>
          {fileName && (
            <div className="failed-file">
              <ItemRow
                hasError={error}
                item={{ value: fileName }}
                progressBar={100}
                onRemove={onRemoveFile}
              />
            </div>
          )}
          <div className="failed-file">
            <InformationBlock
              type={INFORMATION_BLOCK_TYPE.ERROR}
              info={error}
            />
          </div>
        </>
      )}
    </div>
  )
}
const UPLOAD_CSV_TEMPLATE = '/api/users/import-users/upload-csv'

export default UsersCSVUpload

UsersCSVUpload.propTypes = {
  setCsvFile: PropTypes.func,
}
