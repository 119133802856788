import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useStore } from 'store'

import useSession from 'modules/session'
import AlertThresholdsPanel from 'modules/companies/alert-thresholds-panel'
import ExportAlertThresholdsButton from 'modules/companies/subsections/alert-thresholds/export-button'
import CollapsibleSection from 'components/collapsible-section/index'
import { SectionLinkControl } from 'modules/companies/section-link-components'
import { CopyControl } from 'modules/companies/section-copy-components'
import RoundRectangleButton from 'components/round-rectangle-button'
import AlertThresholdsFilters from 'modules/companies/subsections/alert-thresholds/alert-thresholds-filters'
import {
  initCopySettingsSection,
  undoCopySettingsSection,
} from 'modules/companies/actions'
import {
  COMPANY_SECTIONS,
  COPY_SETTINGS_STATUS,
} from 'modules/companies/constants'
import { PERMISSION_TYPES, PERMISSIONS, useAccess } from 'hooks/access'
import { RevertButton, SimulationButton } from 'modules/companies/simulations'

import { ReactComponent as IconFilter } from 'assets/filters.svg'

import '../../style.scss'

/**
 * Alerts Threshold section sub-component
 * @param {Object} props Component props
 * @param {Object} props.company Viewed company / business unit
 * @param {Object} props.defaultCompany Viewed company / business unit -> version from DB
 * @param {Object} [props.parentClient] Viewed Business Unit's parent company
 * @param {Object} props.openedSubSection Opened subsection states
 * @param {Function} props.setOpenedSubSection Callback to change opened subsection state
 * @param {Array} props.alertThresholds Alert thresholds list
 * @param {Object} props.setCompany Callback to update company thresholds
 * @param {Boolean} props.isViewMode View mode flag
 * @param {Object} props.links Linked sections
 * @param {Function} props.onChangeLinks Callback to change links
 * @param {Boolean} props.loading Loading state flag
 * @param {Object} props.simulations Simulations hook response
 * @returns {React.FunctionComponent}
 *  */
const AlertThresholdSection = ({
  company,
  defaultCompany,
  parentClient,
  openedSubSection,
  setOpenedSubSection,
  alertThresholds,
  setCompany,
  isViewMode,
  links,
  onChangeLinks,
  loading,
  simulations,
}) => {
  const ref = useRef()
  const { state, dispatch } = useStore()
  const [filter, setFilter] = useState({})
  const [, user] = useSession()
  const [filtersApplied, setFiltersApplied] = useState(false)
  const [filtersOpened, setFiltersOpened] = useState(false)
  const [filteredAlertThresholds, setFilteredAlertThresholds] =
    useState(alertThresholds)
  const [copyFromCompany, setCopyFromCompany] = useState(null)
  const [copyFromCompanyThresholds, setCopyFromCompanyThresholds] = useState()
  const { copySettings } = state.companies
  const copyAlertThresholdsSection =
    copySettings[COMPANY_SECTIONS.ALERT_THRESHOLDS]

  useEffect(() => {
    setFilteredAlertThresholds(alertThresholds)
  }, [JSON.stringify(alertThresholds)])

  useEffect(() => {
    if (loading) {
      setFilteredAlertThresholds([])
    } else {
      setFilteredAlertThresholds(alertThresholds)
    }
  }, [loading])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    if (openedSubSection !== ref.current.isSectionOpen()) {
      ref.current.onCollapse(openedSubSection)
    }
  }, [openedSubSection])

  useEffect(() => {
    if (copyFromCompany) {
      initCopySettingsSection(
        dispatch,
        COMPANY_SECTIONS.ALERT_THRESHOLDS,
        company.alertThresholds
      )
      const flattenedCompanies = state.companies.list.flatMap((company) => [
        company,
        ...company.businessUnits,
      ])
      const copyFromCompanyObject = flattenedCompanies.find(
        (el) => el._id.toString() === copyFromCompany.companyId
      )
      if (copyFromCompanyObject) {
        setCopyFromCompanyThresholds(copyFromCompanyObject.alertThresholds)
      }
    }
  }, [copyFromCompany])

  useEffect(() => {
    if (copyAlertThresholdsSection?.status === COPY_SETTINGS_STATUS.REVERTING) {
      if (
        copyAlertThresholdsSection?.currentIndex >= 0 &&
        copyAlertThresholdsSection?.history?.[
          copyAlertThresholdsSection.currentIndex
        ]?.data
      ) {
        setCompany({
          alertThresholds: [
            ...(copyAlertThresholdsSection.history[
              copyAlertThresholdsSection.currentIndex
            ].data || []),
          ],
        })
        undoCopySettingsSection(dispatch, COMPANY_SECTIONS.ALERT_THRESHOLDS)
        setCopyFromCompany(undefined)
      }
    }
  }, [JSON.stringify(copyAlertThresholdsSection)])

  const isAlertThresholdSectionLinked = links?.alertThresholds?.[company._id]
  const isAlertsSectionLinked = links?.alerts?.[company._id]
  const clientId = isAlertsSectionLinked ? parentClient._id : company._id

  const onApplyFilters = () => {
    const filtered = alertThresholds.filter((threshold) => {
      const nameMatches = filter.name
        ? threshold.name.toLowerCase().includes(filter.name.toLowerCase())
        : true
      const alertValueMatches = filter.alertValue?.length
        ? filter.alertValue.some((alertValue) => {
            const companyAlertValues = threshold.alertValues[clientId]
            return (
              companyAlertValues && companyAlertValues[alertValue]?.count > 0
            )
          })
        : true
      return nameMatches && alertValueMatches
    })

    setFilteredAlertThresholds(filtered)
    setFiltersApplied(true)
  }

  const onClearFilters = () => {
    setFiltersApplied(false)
    setFilter({})
    setFilteredAlertThresholds(alertThresholds)
  }

  const readCopySettingsAccess = useAccess({
    feature: PERMISSIONS.COPY_CLIENT_SETTINGS_BUSINESS_UNIT,
    type: PERMISSION_TYPES.READ,
  })

  const editCopySettingsAccess = useAccess({
    feature: PERMISSIONS.COPY_CLIENT_SETTINGS_BUSINESS_UNIT,
    type: PERMISSION_TYPES.EDIT,
  })
  const isEditCopySettingsMode = user.isSuperAdmin || editCopySettingsAccess

  return (
    <CollapsibleSection
      ref={ref}
      header={
        <div className="display-flex align-row gap-8">
          <div>Alert Thresholds</div>
          {!isViewMode && (
            <div className="display-flex align-row  gap-8">
              <SectionLinkControl
                client={company}
                links={links}
                onChangeLinks={onChangeLinks}
                isSectionOpen={openedSubSection}
              />
              {parentClient && readCopySettingsAccess && (
                <div className="display-flex align-row gap-8">
                  <CopyControl
                    company={company}
                    parentCompany={parentClient}
                    onChangeCopyCompany={(companyId) =>
                      setCopyFromCompany({ companyId: companyId })
                    }
                    disabled={!isEditCopySettingsMode}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      }
      extras={
        <div className="display-flex margin-left-auto gap-16">
          <SimulationButton
            disabled={simulations.simulationsDisabled}
            loading={simulations.loading}
            onClick={() => simulations.simulate()}
          />
          <RevertButton
            disabled={!simulations.revertIsActive}
            onClick={simulations.revertThresholds}
          />
          <ExportAlertThresholdsButton clientId={clientId} />
          <RoundRectangleButton
            className={cx('padding-5', {
              'filter-active': filtersOpened || filtersApplied,
            })}
            onClick={() => setFiltersOpened(!filtersOpened)}
            contentRender={() => (
              <IconFilter
                className={
                  filtersOpened || filtersApplied
                    ? 'fill-white'
                    : 'fill-light-blue'
                }
              />
            )}
          />
        </div>
      }
      onCollapseListener={setOpenedSubSection}
      defaultCollapsed={!openedSubSection}
    >
      {filtersOpened ? (
        <AlertThresholdsFilters
          filter={filter}
          setFilter={setFilter}
          onApplyFilters={onApplyFilters}
          onClearFilters={onClearFilters}
        />
      ) : null}
      <AlertThresholdsPanel
        company={isAlertsSectionLinked ? parentClient : company}
        defaultCompany={defaultCompany}
        copyFromCompanyThresholds={copyFromCompanyThresholds}
        alertThresholds={filteredAlertThresholds || []}
        onChange={(alertThresholds) => {
          if (isAlertThresholdSectionLinked) {
            return
          }
          setCompany({ alertThresholds })
          setCopyFromCompanyThresholds(undefined)
        }}
        loading={loading}
        isViewMode={
          isViewMode ||
          (company.parentCompany && links?.alertThresholds?.[company._id])
        }
      />
    </CollapsibleSection>
  )
}

AlertThresholdSection.propTypes = {
  company: PropTypes.object,
  defaultCompany: PropTypes.object,
  parentClient: PropTypes.object,
  editing: PropTypes.bool,
  openedSubSection: PropTypes.object.isRequired,
  setOpenedSubSection: PropTypes.func.isRequired,
  alertThresholds: PropTypes.array,
  setCompany: PropTypes.func.isRequired,
  isViewMode: PropTypes.bool,
  links: PropTypes.object,
  onChangeLinks: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  simulations: PropTypes.object.isRequired,
}

export default AlertThresholdSection
