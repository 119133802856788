import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

/* Components */
import Section from 'components/section'
import DropdownWithSubsections from 'components/dropdown-with-subsections/index'
import { SinglePerformanceAccountRow } from 'modules/alerts/single-performance-apply-to/single-performance-account-row'

/* Hooks */
import { useSingleClientApplyTo } from 'modules/alerts/utils'

/* Actions */
import {
  getCampaigns,
  getAdGroups,
  getAds,
  getInsertionOrders,
} from 'modules/dv360-config/actions'

import { ACCOUNTS_TO_SELECT_ELEMENTS } from 'modules/alerts/constants'

import {
  accounts,
  granularities,
  utils,
} from '@decision-sciences/qontrol-common'

import 'modules/alerts/single-performance-apply-to/style.scss'

const { DV360 } = accounts.ACCOUNT_TYPES_MAP

const { ACCOUNT, CAMPAIGN, AD_GROUP, AD, INSERTION_ORDER } =
  granularities.GRANULARITIES

/**
 * Single Performance Section for Amazon DSP
 * @param {Object} params React Params
 * @param {String} params.clientId Alert Client ID
 * @param {Function} params.onChange On Change callback. Call with new state for selectedElements[platform]
 * @param {Object} params.state selectedElements[platform]
 * @param {Array} params.accounts Available Accounts for platform
 * @param {Object} params.elementConfig Array name per platform key. Eg: CAMPAIGN: campaigns
 * @param {Object} params.errors Object of errors
 * @param {Function} params.onChangeErrors Callback for changing errors
 * @returns {Node}
 */
export const SinglePerformanceDV360 = ({
  clientId,
  onChange,
  state,
  accounts,
  elementConfig,
  errors,
  onChangeErrors,
}) => {
  const {
    localState,
    toggleLoading,
    getIsLoading,
    onCheck,
    onCheckAll,
    onChangeAccounts,
  } = useSingleClientApplyTo(
    DV360,
    state,
    onChange,
    onChangeErrors,
    validDataPersistenceConfig
  )

  // Data States
  const [campaigns, setCampaigns] = useState(null)
  const [adGroups, setAdGroups] = useState({})
  const [ads, setAds] = useState({})
  const [insertionOrders, setInsertionOrders] = useState(null)

  const availableAccounts = accounts.map(({ externalAccountId, name }) => ({
    value: externalAccountId,
    label: name || externalAccountId,
    description: externalAccountId,
  }))

  /* ACCOUNT section */
  const accountMap = useMemo(() => {
    return availableAccounts.reduce(
      (prev, current) => ({
        ...prev,
        [current.value]: current,
      }),
      {}
    )
  }, [availableAccounts])

  const hasAccounts = state.accounts?.length || state.allAccountsSelected

  const renderAccountSection = () => {
    if (!state.elements?.includes(ACCOUNT)) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Accounts"
          selectedItems={state[ACCOUNT]?.accounts.map((account) => account.id)}
          disabled={!hasAccounts}
          label={'Account'}
          options={
            state.allAccountsSelected
              ? availableAccounts
              : availableAccounts.filter((acc) =>
                  state.accounts?.includes(acc.value)
                )
          }
          onChange={(accounts) =>
            onCheck(ACCOUNT, {
              accounts: accounts.map((value) => ({
                name: accountMap[value].label,
                id: accountMap[value].value,
              })),
              allSelected: false,
            })
          }
          selectAllOptions={{
            label: 'All Accounts',
            allSelected: state[ACCOUNT]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(ACCOUNT),
          }}
          error={errors[elementConfig[ACCOUNT]]}
        />
      </div>
    )
  }
  /* End of ACCOUNT section */

  /* CAMPAIGN Section */

  useEffect(() => {
    if (!campaigns && !getIsLoading(CAMPAIGN)) {
      toggleLoading(CAMPAIGN, true)
      getCampaigns(clientId)
        .then(setCampaigns)
        .catch(console.error)
        .finally(() => {
          toggleLoading(CAMPAIGN, false)
        })
    }
  }, [JSON.stringify(localState.elements)])

  const campaignsInAccounts = useMemo(() => {
    const campaignsInAccountsMap = {}

    const accountsToParse = state.allAccountsSelected
      ? availableAccounts.map(({ value }) => value)
      : state.accounts

    if (!accountsToParse || !campaigns?.length) {
      return campaignsInAccountsMap
    }

    // Turn array to map for easy access
    const accountsSet = new Set(accountsToParse)

    // Filter campaigns based on selected accounts
    campaigns.forEach((campaign) => {
      if (accountsSet.has(campaign.account_id)) {
        campaignsInAccountsMap[campaign.id] = campaign
      }
    })

    return campaignsInAccountsMap
  }, [JSON.stringify(state.accounts), state.allAccountsSelected, campaigns])

  const campaignsByAccount = utils.array.arrayKeyBy(
    Object.values(campaignsInAccounts) || [],
    'account_id'
  )

  const getCampaignOptions = (getIsDisabled) => {
    if (!campaignsByAccount) {
      return []
    }

    const options = Object.keys(campaignsByAccount).map((accountId) => ({
      subsections: campaignsByAccount[accountId].map((campaign) => ({
        value: campaign.id,
        label: campaign.name,
        disabled: getIsDisabled && getIsDisabled(campaign),
      })),
      disabled: true,
      noCheckbox: true,
      label: accountMap[accountId].label,
      value: accountId,
    }))

    return options
  }

  const renderCampaignSection = () => {
    if (!state.elements?.includes(CAMPAIGN)) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Order"
          selectedItems={(state[CAMPAIGN]?.campaigns || []).map(({ id }) => id)}
          disabled={!hasAccounts}
          label={'Order'}
          options={getCampaignOptions()}
          onChange={(campaigns) => {
            onCheck(CAMPAIGN, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Orders',
            allSelected: state[CAMPAIGN]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(CAMPAIGN),
          }}
          error={errors[elementConfig[CAMPAIGN]]}
        />
      </div>
    )
  }
  /* End of CAMPAIGN Section */

  /* AD_GROUP Section */

  /**
   * Fetch ad groups for a campaign
   * @param {String} campaign campaign id from DV360
   * @param {String} account account id from DV360
   */
  const fetchAdGroups = (campaign, account) => {
    const loaderKey = `${AD_GROUP}_${campaign}`

    if (!getIsLoading(loaderKey) && !adGroups[campaign]) {
      toggleLoading(loaderKey, true)
      getAdGroups(clientId, null, campaign, account)
        .then((adGroups) => {
          setAdGroups((previous) => ({
            ...(previous || {}),
            [campaign]: adGroups,
          }))
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(loaderKey, false)
        })
    }
  }

  useEffect(() => {
    if (!localState[AD_GROUP]?.campaigns) {
      return
    }

    localState[AD_GROUP].campaigns.forEach((campaign) => {
      fetchAdGroups(campaign.id, campaign.account_id)
    })
  }, [localState[AD_GROUP]?.campaigns])

  const getAvailableAdGroupMap = (element) => {
    if (!element || !element.campaigns) {
      return {}
    }

    return element.campaigns.reduce(
      (prev, current) => ({
        ...prev,
        ...(adGroups[current.id] || []).reduce(
          (prev, current) => ({ ...prev, [current.id]: current }),
          {}
        ),
      }),
      {}
    )
  }

  const getAdGroupOptions = (element, getIsDisabled) => {
    if (!element || !element.campaigns) {
      return []
    }

    return element.campaigns.reduce(
      (prev, current) => [
        ...prev,
        {
          label: current.name,
          disabled: true,
          noCheckbox: true,
          subsections: (adGroups[current.id] || []).map(
            (adGroup) => ({
              label: adGroup.name,
              value: adGroup.id,
              disabled: getIsDisabled && getIsDisabled(adGroup),
            }),
            []
          ),
        },
      ],
      []
    )
  }

  const adGroupAvailableAdGroupMap = useMemo(() => {
    return getAvailableAdGroupMap(localState[AD_GROUP])
  }, [adGroups, localState[AD_GROUP]])

  const renderAdGroupSection = () => {
    if (!state.elements?.includes(AD_GROUP)) {
      return null
    }

    const hasCampaigns = state[AD_GROUP]?.campaigns?.length

    const campaignOptions = getCampaignOptions(({ id }) =>
      getIsLoading(`${AD_GROUP}_${id}`)
    )

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Orders"
          selectedItems={(state[AD_GROUP]?.campaigns || []).map(({ id }) => id)}
          disabled={!hasAccounts}
          label={'Order'}
          options={campaignOptions}
          onChange={(campaigns) =>
            onCheck(AD_GROUP, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
            })
          }
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Line Item"
          selectedItems={(state[AD_GROUP]?.adGroups || []).map(({ id }) => id)}
          label={'Line Item'}
          options={getAdGroupOptions(localState[AD_GROUP])}
          disabled={!hasCampaigns}
          isLoading={getIsLoading(new RegExp(AD_GROUP))}
          onChange={(adGroups) => {
            onCheck(AD_GROUP, {
              adGroups: adGroups.map(
                (adGroup) => adGroupAvailableAdGroupMap[adGroup]
              ),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Line Items',
            allSelected: state[AD_GROUP]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(AD_GROUP),
          }}
          error={errors[elementConfig[AD_GROUP]]}
        />
      </div>
    )
  }
  /* End of AD_GROUP Section */

  /* AD Section */

  /**
   * Fetch ads for an ad group
   * @param {String} adGroup adgroup id field from DV360
   * @param {String} campaign campaign id field from DV360
   * @param {String} account account id field from DV360
   */
  const fetchAds = (adGroup, campaign, account) => {
    const loaderKey = `${AD}_${adGroup}`

    if (!getIsLoading(loaderKey) && !ads[adGroup]) {
      toggleLoading(loaderKey, true)
      getAds(clientId, null, adGroup, account, campaign)
        .then((ads) => {
          setAds((previous) => ({
            ...(previous || {}),
            [adGroup]: ads,
          }))
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(loaderKey, false)
        })
    }
  }

  useEffect(() => {
    if (!localState[AD]?.campaigns) {
      return
    }

    localState[AD].campaigns.forEach((campaign) => {
      fetchAdGroups(campaign.id, campaign.account_id)
    })
  }, [localState[AD]?.campaigns])

  useEffect(() => {
    if (!localState[AD]?.adGroups) {
      return
    }

    localState[AD].adGroups.forEach((adGroup) => {
      fetchAds(adGroup.id, adGroup.campaign_id, adGroup.account_id)
    })
  }, [localState[AD]?.adGroups])

  const getAvailableAdsMap = (element) => {
    if (!element || !element.adGroups) {
      return {}
    }

    return element.adGroups.reduce((prev, current) => {
      return {
        ...prev,
        ...(ads[current.id] || []).reduce(
          (prev, current) => ({ ...prev, [current.id]: current }),
          {}
        ),
      }
    }, {})
  }

  const adAvailableAdGroupMap = useMemo(() => {
    return getAvailableAdGroupMap(localState[AD])
  }, [adGroups, localState[AD]])

  const adAvailableAdMap = useMemo(() => {
    return getAvailableAdsMap(localState[AD])
  }, [localState[AD]?.adGroups, ads])

  const renderAdSection = () => {
    if (!state.elements?.includes(AD)) {
      return null
    }

    const hasCampaigns = state[AD]?.campaigns?.length

    const hasAdGroups = state[AD]?.adGroups?.length

    const adOptions =
      localState[AD]?.adGroups?.reduce(
        (prev, current) => [
          ...prev,
          {
            label: current.name,
            disabled: true,
            noCheckbox: true,
            subsections: (ads[current.id] || []).map(
              (ad) => ({
                label: ad.name,
                value: ad.id,
              }),
              []
            ),
          },
        ],
        []
      ) || []

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Order"
          selectedItems={(state[AD]?.campaigns || []).map(({ id }) => id)}
          label={'Order'}
          options={getCampaignOptions(({ id }) =>
            getIsLoading(`${AD_GROUP}_${id}`)
          )}
          onChange={(campaigns) =>
            onCheck(AD, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
            })
          }
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Line Item"
          selectedItems={(state[AD]?.adGroups || []).map(({ id }) => id)}
          disabled={!hasCampaigns}
          isLoading={getIsLoading(new RegExp(AD_GROUP))}
          label={'Line Item'}
          options={getAdGroupOptions(localState[AD], ({ id }) =>
            getIsLoading(`${AD}_${id}`)
          )}
          onChange={(adGroups) => {
            onCheck(AD, {
              adGroups: adGroups.map(
                (adGroup) => adAvailableAdGroupMap[adGroup]
              ),
              allSelected: false,
            })
          }}
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Creatives"
          selectedItems={(state[AD]?.ads || []).map(({ id }) => id)}
          label={'Creative'}
          options={adOptions}
          disabled={!hasAdGroups}
          isLoading={getIsLoading(new RegExp(AD))}
          onChange={(ads) => {
            onCheck(AD, {
              ads: ads.map((ad) => adAvailableAdMap[ad]),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Creatives',
            allSelected: state[AD]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(AD),
          }}
          error={errors[elementConfig[AD]]}
        />
      </div>
    )
  }
  /* End of AD Section */

  /* INSERTION_ORDER Section */
  const insertionOrderDisplayed = state.elements?.includes(INSERTION_ORDER)

  useEffect(() => {
    if (!getIsLoading(INSERTION_ORDER) && insertionOrderDisplayed) {
      toggleLoading(INSERTION_ORDER, true)
      getInsertionOrders(clientId)
        .then((response) => {
          setInsertionOrders(response)
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(INSERTION_ORDER, false)
        })
    }
  }, [insertionOrderDisplayed])

  const formatsMap = (insertionOrders || []).reduce(
    (prev, insertionOrder) => ({
      ...prev,
      [insertionOrder.id]: insertionOrder,
    }),
    {}
  )

  const renderInsertionOrderSection = () => {
    if (!insertionOrderDisplayed) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Insertion Orders"
          isLoading={getIsLoading(INSERTION_ORDER)}
          selectedItems={(state[INSERTION_ORDER]?.insertionOrders || []).map(
            ({ id }) => id
          )}
          label={'Insertion Order'}
          options={(insertionOrders || []).map((insertionOrder) => ({
            value: insertionOrder.id,
            label: insertionOrder.name,
          }))}
          onChange={(insertionOrders) => {
            onCheck(INSERTION_ORDER, {
              allSelected: false,
              insertionOrders: insertionOrders.map(
                (insertionOrder) => formatsMap[insertionOrder]
              ),
            })
          }}
          selectAllOptions={{
            label: 'All Formats',
            allSelected: state[INSERTION_ORDER]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(INSERTION_ORDER),
          }}
          error={errors[elementConfig[INSERTION_ORDER]]}
        />
      </div>
    )
  }
  /* End of INSERTION_ORDER Section */

  return (
    <Section>
      <SinglePerformanceAccountRow
        state={state}
        onChange={onChange}
        onChangeAccounts={onChangeAccounts}
        platform={DV360}
        availableAccounts={availableAccounts}
        availableElements={ACCOUNTS_TO_SELECT_ELEMENTS[DV360]}
        elementConfig={elementConfig}
        errors={errors}
        setErrors={onChangeErrors}
      />
      {renderAccountSection()}
      {renderCampaignSection()}
      {renderAdGroupSection()}
      {renderAdSection()}
      {renderInsertionOrderSection()}
    </Section>
  )
}

SinglePerformanceDV360.propTypes = {
  clientId: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  elementConfig: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChangeErrors: PropTypes.func.isRequired,
}

/** Data Validation functions, ensured data is dynamically removed based on parent element removals */
const validateCampaignChanges = (
  changes,
  { allAccountsSelected, accounts }
) => {
  if (changes.campaigns && !allAccountsSelected) {
    const availableAccountSet = new Set(accounts)
    changes.campaigns = changes.campaigns.filter(({ account_id }) =>
      availableAccountSet.has(account_id)
    )
  }
  return changes
}

const validateAdGroupChanges = (changes, state, TYPE) => {
  changes = validateCampaignChanges(changes, state, TYPE)
  if (changes.campaigns) {
    const { adGroups } = state[TYPE]
    if (adGroups?.length) {
      const availableCampaignsSet = new Set(
        changes.campaigns.map(({ id }) => id)
      )
      changes.adGroups = adGroups.filter(({ campaign_id }) =>
        availableCampaignsSet.has(campaign_id)
      )
    }
  }

  return changes
}

const validateAdChanges = (changes, state, TYPE) => {
  changes = validateAdGroupChanges(changes, state, TYPE)

  if (changes.adGroups) {
    const { ads } = state[TYPE]

    if (ads?.length) {
      const availableAdGroupSet = new Set(changes.adGroups.map(({ id }) => id))
      changes.ads = ads.filter(({ line_item_id }) =>
        availableAdGroupSet.has(line_item_id)
      )
    }
  }

  return changes
}

const validDataPersistenceConfig = {
  [ACCOUNT]: (changes, { allAccountsSelected, accounts }) => {
    if (changes.accounts && !allAccountsSelected) {
      const availableAccountSet = new Set(accounts)
      changes.accounts = changes.accounts.filter(({ id }) =>
        availableAccountSet.has(id)
      )
    }
    return changes
  },
  [CAMPAIGN]: validateCampaignChanges,
  [AD_GROUP]: validateAdGroupChanges,
  [AD]: validateAdChanges,
}
