import useLeaveConfirm from 'components/leave-confirm'
import PropTypes from 'prop-types'
import DimensionForm from './form'

const DimensionBulkEditForm = ({ disabled }) => {
  const [setDirty, LeaveConfirm, isDirty] = useLeaveConfirm()

  return (
    <>
      <LeaveConfirm />
      <DimensionForm
        isBulkEdit
        disabled={disabled}
        isDirty={isDirty}
        setDirty={setDirty}
        setErrors={() => {}}
        errors={{}}
      />
    </>
  )
}

DimensionBulkEditForm.propTypes = {
  disabled: PropTypes.bool,
}

export default DimensionBulkEditForm
