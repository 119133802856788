import React, { useState, useEffect, useContext } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import cx from 'classnames'
import uniqBy from 'lodash.uniqby'
import { useParams } from 'react-router-dom'

/* Actions */
import {
  getTraqTemplate,
  getAlertResultsForUserPaginated,
} from 'modules/traq-templates/actions'

/* Components */
import Loader from 'components/loader'
import StickyFooter from 'components/sticky-footer'
import { useSocket } from 'components/utils/socket'
import { useEffectOnUpdate } from 'components/utils/custom-hooks'
import { AccountIcon } from 'components/account-icon';
import { AlertResultFilters } from 'modules/traq-dashboard/filters'

/* Utils */
import { dataRefreshContext } from 'contexts/data-refresh'

/* Constants */

/* Assets */
import { ReactComponent as StopIcon } from 'assets/icon_stop_hexagonal_red.svg'
import { ReactComponent as WatchIcon } from 'assets/icon_watch_yellow.svg'
import { ReactComponent as CheckIcon } from 'assets/icon_check_green_larger.svg'
import { socket, granularities } from '@decision-sciences/qontrol-common'

/* Styling */
import './style.scss'

const { NOTIFICATIONS } = socket
const { GRANULARITY_DETAILS } = granularities

/**
 * TraQ Dashboard Alert Results
 * @returns {React.Component}
 */
const TraqDashboardResults = () => {
  const { key } = useParams()
  const {
    state: {
      companies: { currentCompany },
      traqTemplates: { list: traqTemplates },
    },
    dispatch,
  } = useStore()
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [error, setError] = useState(false)
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [elementsLeftToLoad, setElementsLeftToLoad] = useState({
    needs_immediate_attention: true,
    review_recommended: true,
    validated: true,
  })
  const [traQTemplate, setTraQTemplate] = useState(null)

  /** Pagination */
  const PAGE_SIZE = 10
  // Tuple of lower and upper bounds of elements to load next + a special key or loading only one type
  const initialItemsToLoad = {
    needs_immediate_attention: [0, PAGE_SIZE],
    review_recommended: [0, PAGE_SIZE],
    validated: [0, PAGE_SIZE],
  }
  const [itemsToLoad, setItemsToLoad] = useState({ ...initialItemsToLoad })

  /** On key change, get the corresponding TraQ Template */
  useEffect(() => {
    if (traQTemplate?._id !== key) {
      /** Try to get it from local storage */
      const foundTraQTemplate = traqTemplates?.find(({ _id }) => _id === key)

      if (foundTraQTemplate) {
        setTraQTemplate(foundTraQTemplate)
      } else {
        /** If not in local storage, fetch it from the server */
        getTraqTemplate(key).then(setTraQTemplate)
      }
    }
  }, [key])

  const anyElementsLeftToLoad =
    !!elementsLeftToLoad.needs_immediate_attention ||
    !!elementsLeftToLoad.review_recommended ||
    !!elementsLeftToLoad.validated

  const initialCardsState = {
    needs_immediate_attention: [],
    review_recommended: [],
    validated: [],
  }
  const [cards, setAllCards] = useState({
    ...initialCardsState,
  })
  // Sets columns in obj, keeping the rest
  const setCards = (obj) =>
    setAllCards({
      ...cards,
      ...Object.entries(obj).reduce(
        (acc, [type, value]) => ({ ...acc, [type]: value }),
        {}
      ),
    })

  const resetCards = () => setAllCards({ ...initialCardsState })

  const initialFilterState = {
    search: null,
    alertType: null,
    channel: null,
    account: null,
    granularity: [],
    campaigns: null,
    adGroups: null,
    ads: null,
    keywords: null,
    allEntitiesPerGranularity: {},
  }
  const [filterState, setFilterState] = useState({ ...initialFilterState })

  /**
   * Get alertTriggers
   */
  const getAlertResults = (reset = false) =>
    new Promise(async (resolve) => {
      setLoading(true)

      // Filter if there are filters, or if we only need one type of element
      const filters = filterState

      const alertResults = await getAlertResultsForUserPaginated({
        numberOfItemsToLoad: itemsToLoad,
        selectedClient: currentCompany?._id,
        filters,
        traQTemplateId: key,
        dispatch,
      })

      if (alertResults?.success && alertResults?.result) {
        const {
          needs_immediate_attention,
          review_recommended,
          validated,
          needsImmediateAttentionElementsLeft,
          reviewRecommendedElementsLeft,
          validatedElementsLeft,
        } = alertResults.result

        const mapToCard = ({
          client,
          alertName,
          message: traQMessage,
          granularity,
          entity,
          ...others
        }) => ({
          ...others,
          channel: client,
          alertName,
          traQMessage,
          granularity,
          entity,
        })

        const newCards = {
          needs_immediate_attention: uniqBy(
            [
              ...(reset ? [] : cards.needs_immediate_attention),
              ...(needs_immediate_attention?.map(mapToCard) || []),
            ],
            '_id'
          ),
          review_recommended: uniqBy(
            [
              ...(reset ? [] : cards.review_recommended),
              ...(review_recommended?.map(mapToCard) || []),
            ],
            '_id'
          ),
          validated: uniqBy(
            [
              ...(reset ? [] : cards.validated),
              ...(validated?.map(mapToCard) || []),
            ],
            '_id'
          ),
        }

        setCards(newCards)
        setLoading(false)
        setLoadingMore(false)
        setError(false)

        const newElementsLeft = {
          needs_immediate_attention: needsImmediateAttentionElementsLeft,
          review_recommended: reviewRecommendedElementsLeft,
          validated: validatedElementsLeft,
        }

        setElementsLeftToLoad(newElementsLeft)

        resolve()
      } else {
        setLoading(false)
        setLoadingMore(false)
        setError(true)
        resolve()
      }
    })

  // Load more alert results
  useEffect(() => {
    if (traQTemplate?._id && loadingMore) {
      getAlertResults()
    }
  }, [JSON.stringify(itemsToLoad), loadingMore])

  // Refetch alert results when filters change
  useEffectOnUpdate(() => {
    if (traQTemplate?._id) {
      /**
       * Filter: If there's at least one filter set (this check works as long as all default filter field values are 'null')
       * Clear filters: If all filters are null and there are no cards
       *  */
      const filterCase = Object.values(filterState).some(
        (filterField) => filterField !== null
      )
      const clearCase =
        Object.values(filterState).every(
          (filterField) => filterField === null
        ) && Object.values(cards).every((column) => !column?.length)

      if (filterCase || clearCase) {
        getAlertResults(true)
      }
    }
  }, [filterState])

  useEffect(() => {
    if (traQTemplate?._id) {
      getAlertResults(true)
    }
  }, [traQTemplate?._id])

  const resetCardsAndRefetch = () => {
    setLoading(true)
    setCards({ ...initialCardsState })
    getAlertResults(true)
  }

  /** Listen to data change notifications from the server */
  const { setDataRefresh, setOptions: setDataRefreshOptions } =
    useContext(dataRefreshContext)
  const socket = useSocket({ room: currentCompany?._id })

  useEffect(() => {
    if (socket?.connected) {
      socket.on(NOTIFICATIONS.traQDashboardAlertResults.receive, () => {
        setDataRefreshOptions({
          handlers: () => resetCardsAndRefetch(),
        })
        setDataRefresh(true)
      })
    }

    return () =>
      socket?.removeAllListeners(
        NOTIFICATIONS.traQDashboardAlertResults.receive
      )
  }, [socket?.connected])

  // Track scrolling to show 'Load More' button
  useEffect(() => {
    const scrollingElement = document.getElementsByTagName('main')?.[0]

    scrollingElement.addEventListener('scroll', trackScrolling)

    return () => {
      scrollingElement.removeEventListener('scroll', trackScrolling)
    }
  }, [elementsLeftToLoad])

  const trackScrolling = (ev) => {
    const isBottom = (el) =>
      el.scrollHeight - el.scrollTop <= el.clientHeight + 2

    if (isBottom(ev.target)) {
      setShowLoadMore(true)
    } else {
      setShowLoadMore(false)
    }
  }

  if (error) {
    return (
      <div className="heading" data-cy="page-heading">
        An error occured. Try reloading the page.
        <br />
        {error}
      </div>
    )
  }

  return (
    <div className="alert-dashboard__results">
      <AlertResultFilters
        resetItemsToLoad={() => setItemsToLoad({ ...initialItemsToLoad })}
        filterState={filterState}
        setFilterState={setFilterState}
        initialFilterState={initialFilterState}
        resetCards={resetCards}
        setCardsLoading={setLoading}
        traqTemplateId={key}
        selectedClient={currentCompany?._id}
      />

      <Helmet>
        <title>EmbarQ Dashboard</title>
      </Helmet>

      <div className="column-wrapper">
        <div className="columns">
          <div className="column column--needs_immediate_attention">
            <div className="column__header">
              <StopIcon className="column__header-icon" alt="stop icon" />
              <div className="general-label">Need immediate Attention</div>
            </div>
            {loopCards(
              cards.needs_immediate_attention,
              'Need immediate Attention',
              loading
            )}
          </div>
          <div className="column column--review_recommended">
            <div className="column__header">
              <WatchIcon className="column__header-icon" alt="watch icon" />
              <div className="general-label">Review recommended</div>
            </div>
            {loopCards(cards.review_recommended, 'Review recommended', loading)}
          </div>
          <div className="column column--validated">
            <div className="column__header">
              <CheckIcon className="column__header-icon" alt="check icon" />
              <div className="general-label">Validated</div>
            </div>
            {loopCards(cards.validated, 'Validated', loading)}
          </div>
        </div>

        {loading ? (
          <div className="loader-sticky-bottom">
            <Loader />
          </div>
        ) : null}
      </div>
      <StickyFooter
        className={cx('load-more__wrapper', {
          'load-more__wrapper--hidden': !(
            anyElementsLeftToLoad && showLoadMore
          ),
        })}
        buttons={[
          {
            value: 'Load More',
            onClick: () => {
              setLoadingMore(true)

              // Add PAGE_SIZE more to the already existing numbr of cards in a column
              setItemsToLoad({
                ...itemsToLoad,
                needs_immediate_attention: [
                  cards.needs_immediate_attention.length,
                  cards.needs_immediate_attention.length + PAGE_SIZE,
                ],
                review_recommended: [
                  cards.review_recommended.length,
                  cards.review_recommended.length + PAGE_SIZE,
                ],
                validated: [
                  cards.validated.length,
                  cards.validated.length + PAGE_SIZE,
                ],
              })
            },
            type: 'primary',
            className: 'load-more',
          },
        ]}
      />
    </div>
  )
}

/**
 * Function that loops over a set of alert results
 * @param {Array} cards list of alert results
 * @param {String} status column the alert result belongs to
 * @param {Boolean} [loading] The cards are loading
 * @returns {Function}
 */
const loopCards = (cards, status, loading = false) =>
  cards?.length ? (
    cards.map(
      ({ channel, alertName, traQMessage, granularity, entity }, index) => (
        <AlertResultCard
          channel={channel}
          alertName={alertName}
          traQMessage={traQMessage}
          granularity={granularity}
          entity={entity}
          key={index}
          status={status}
        />
      )
    )
  ) : !loading ? (
    <p className="no-data">No {status} Alerts at this time.</p>
  ) : null

/**
 * Function that loops over a set of alert triggers
 * @param {Object} props props
 * @param {String} props.channel channel of the account the result belongs to (eg. google)
 * @param {String} props.alertName name of alert
 * @param {String} props.traQMessage TraQ Message
 * @param {String} props.granularity Granularity of the result
 * @param {Object<{id: String, name: String}>} props.entity entity
 * @returns {React.Component}
 */
const AlertResultCard = ({
  channel,
  alertName,
  traQMessage,
  granularity,
  entity,
}) => {
  /** Separate the first line of the TraQ Message from the rest */
  const traQMessageLines = traQMessage?.split('\n') || []
  if (traQMessageLines.length > 1) {
    traQMessageLines[1] = traQMessageLines.slice(1)?.join('<br>')
  }

  return (
    <div className="alert-result-card">
      <div className="alert-result-card__header">
        <p className="title">{alertName}</p>
        <AccountIcon
          accountType={channel}
          className="account-icon"
          width="24px"
          height="24px"
          alt={'account icon'}
        />
      </div>
      <div className="alert-result-card__body">
        {/* EmbarQ Message */}
        <p
          className="alert-result-card__body-message alert-result-card__body-message--1"
          dangerouslySetInnerHTML={{ __html: traQMessageLines?.[0] || '' }}
        />
        {traQMessageLines?.[1] ? (
          <p
            className="alert-result-card__body-message"
            dangerouslySetInnerHTML={{ __html: traQMessageLines?.[1] || '' }}
          />
        ) : null}

        {/* Entity details */}
        <p className="alert-result-card__body-entity">
          {GRANULARITY_DETAILS[granularity].labels[channel]}:{' '}
          {entity?.name || entity?.id}
        </p>
      </div>
    </div>
  )
}

AlertResultCard.propTypes = {
  channel: PropTypes.string,
  alertName: PropTypes.string,
  traQMessage: PropTypes.string,
  granularity: PropTypes.string,
  entity: PropTypes.object,
  status: PropTypes.string,
}

export default TraqDashboardResults

