import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

/* Components */
import Section from 'components/section'
import DropdownWithSubsections from 'components/dropdown-with-subsections/index'
import { SinglePerformanceAccountRow } from 'modules/alerts/single-performance-apply-to/single-performance-account-row'

/* Hooks */
import { useSingleClientApplyTo } from 'modules/alerts/utils'

/* Actions */
import {
  getAllCampaigns,
  getAllAdgroups,
  getAllAds,
  getAllKeywords,
} from 'modules/microsoft-config/actions'

import { ACCOUNTS_TO_SELECT_ELEMENTS } from 'modules/alerts/constants'

import {
  accounts,
  granularities,
  utils,
} from '@decision-sciences/qontrol-common'

import 'modules/alerts/single-performance-apply-to/style.scss'

const { MICROSOFT } = accounts.ACCOUNT_TYPES_MAP

const { ACCOUNT, CAMPAIGN, AD_GROUP, AD, KEYWORD } = granularities.GRANULARITIES

/**
 * Single Performance Section for MICROSOFT
 * @param {Object} params React Params
 * @param {String} params.clientId Alert Client ID
 * @param {Function} params.onChange On Change callback. Call with new state for selectedElements[platform]
 * @param {Object} params.state selectedElements[platform]
 * @param {Array} params.accounts Available Accounts for platform
 * @param {Object} params.elementConfig Array name per platform key. Eg: CAMPAIGN: campaigns
 * @param {Object} params.errors Object of errors
 * @param {Function} params.onChangeErrors Callback for changing errors
 * @returns {Node}
 */
export const SinglePerformanceMicrosoft = ({
  clientId,
  onChange,
  state,
  accounts,
  elementConfig,
  errors,
  onChangeErrors,
}) => {
  const {
    localState,
    toggleLoading,
    getIsLoading,
    onCheck,
    onCheckAll,
    onChangeAccounts,
  } = useSingleClientApplyTo(
    MICROSOFT,
    state,
    onChange,
    onChangeErrors,
    validDataPersistenceConfig
  )

  // Data States
  const [campaigns, setCampaigns] = useState(null)
  const [adGroups, setAdGroups] = useState({})
  const [ads, setAds] = useState({})
  const [keywords, setKeywords] = useState({})

  const availableAccounts = accounts.map(({ externalAccountId, name }) => ({
    value: externalAccountId,
    label: name || externalAccountId,
    description: externalAccountId,
  }))

  /* ACCOUNT section */
  const accountMap = useMemo(() => {
    return availableAccounts.reduce(
      (prev, current) => ({
        ...prev,
        [current.value]: current,
      }),
      {}
    )
  }, [availableAccounts])

  const hasAccounts = state.accounts?.length || state.allAccountsSelected

  const renderAccountSection = () => {
    if (!state.elements?.includes(ACCOUNT)) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Accounts"
          selectedItems={state[ACCOUNT]?.accounts.map((account) => account.id)}
          disabled={!hasAccounts}
          label={'Account'}
          options={
            state.allAccountsSelected
              ? availableAccounts
              : availableAccounts.filter((acc) =>
                  state.accounts?.includes(acc.value)
                )
          }
          onChange={(accounts) =>
            onCheck(ACCOUNT, {
              accounts: accounts.map((value) => ({
                name: accountMap[value].label,
                id: accountMap[value].value,
              })),
              allSelected: false,
            })
          }
          selectAllOptions={{
            label: 'All Accounts',
            allSelected: state[ACCOUNT]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(ACCOUNT),
          }}
          error={errors[elementConfig[ACCOUNT]]}
        />
      </div>
    )
  }
  /* End of ACCOUNT section */

  /* CAMPAIGN Section */

  useEffect(() => {
    if (!campaigns && !getIsLoading(CAMPAIGN)) {
      toggleLoading(CAMPAIGN, true)
      getAllCampaigns(clientId)
        .then(setCampaigns)
        .catch(console.error)
        .finally(() => {
          toggleLoading(CAMPAIGN, false)
        })
    }
  }, [JSON.stringify(localState.elements)])

  const campaignsInAccounts = useMemo(() => {
    const campaignsInAccountsMap = {}

    const accountsToParse = state.allAccountsSelected
      ? availableAccounts.map(({ value }) => value)
      : state.accounts

    if (!accountsToParse || !campaigns?.length) {
      return campaignsInAccountsMap
    }

    // Turn array to map for easy access
    const accountsSet = new Set(accountsToParse)

    // Filter campaigns based on selected accounts
    campaigns.forEach((campaign) => {
      if (accountsSet.has(campaign.account_id)) {
        campaignsInAccountsMap[campaign.id] = campaign
      }
    })

    return campaignsInAccountsMap
  }, [JSON.stringify(state.accounts), state.allAccountsSelected, campaigns])

  const campaignsByAccount = utils.array.arrayKeyBy(
    Object.values(campaignsInAccounts) || [],
    'account_id'
  )

  const getCampaignOptions = (getIsDisabled) => {
    if (!campaignsByAccount) {
      return []
    }

    const options = Object.keys(campaignsByAccount).map((accountId) => ({
      subsections: campaignsByAccount[accountId].map((campaign) => ({
        value: campaign.id,
        label: campaign.name,
        disabled: getIsDisabled && getIsDisabled(campaign),
      })),
      disabled: true,
      noCheckbox: true,
      label: accountMap[accountId].label,
      value: accountId,
    }))

    return options
  }

  const renderCampaignSection = () => {
    if (!state.elements?.includes(CAMPAIGN)) {
      return null
    }

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[CAMPAIGN]?.campaigns || []).map(({ id }) => id)}
          disabled={!hasAccounts}
          label={'Campaign'}
          options={getCampaignOptions()}
          onChange={(campaigns) => {
            onCheck(CAMPAIGN, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Campaigns',
            allSelected: state[CAMPAIGN]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(CAMPAIGN),
          }}
          error={errors[elementConfig[CAMPAIGN]]}
        />
      </div>
    )
  }
  /* End of CAMPAIGN Section */

  /* AD_GROUP Section */

  /**
   * Fetch ad groups for a campaign
   * @param {String} resource id field from MICROSOFT
   * @param {String} account account field from MICROSOFT
   */
  const fetchAdGroups = (resource, account) => {
    const loaderKey = `${AD_GROUP}_${resource}`

    if (!getIsLoading(loaderKey) && !adGroups[resource]) {
      toggleLoading(loaderKey, true)
      getAllAdgroups(clientId, null, resource, account)
        .then((adGroups) => {
          setAdGroups((previous) => ({
            ...(previous || {}),
            [resource]: adGroups,
          }))
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(loaderKey, false)
        })
    }
  }

  useEffect(() => {
    if (!localState[AD_GROUP]?.campaigns) {
      return
    }

    localState[AD_GROUP].campaigns.forEach((campaign) => {
      fetchAdGroups(campaign.id, campaign.account_id)
    })
  }, [localState[AD_GROUP]?.campaigns])

  const getAvailableAdGroupMap = (element) => {
    if (!element || !element.campaigns) {
      return {}
    }

    return element.campaigns.reduce(
      (prev, current) => ({
        ...prev,
        ...(adGroups[current.id] || []).reduce(
          (prev, current) => ({ ...prev, [current.id]: current }),
          {}
        ),
      }),
      {}
    )
  }

  const getAdGroupOptions = (element, getIsDisabled) => {
    if (!element || !element.campaigns) {
      return []
    }

    return element.campaigns.reduce(
      (prev, current) => [
        ...prev,
        {
          label: current.name,
          disabled: true,
          noCheckbox: true,
          subsections: (adGroups[current.id] || []).map(
            (adGroup) => ({
              label: adGroup.name,
              value: adGroup.id,
              disabled: getIsDisabled && getIsDisabled(adGroup),
            }),
            []
          ),
        },
      ],
      []
    )
  }

  const adGroupAvailableAdGroupMap = useMemo(() => {
    return getAvailableAdGroupMap(localState[AD_GROUP])
  }, [adGroups, localState[AD_GROUP]])

  const renderAdGroupSection = () => {
    if (!state.elements?.includes(AD_GROUP)) {
      return null
    }

    const hasCampaigns = state[AD_GROUP]?.campaigns?.length

    const campaignOptions = getCampaignOptions(({ id }) =>
      getIsLoading(`${AD_GROUP}_${id}`)
    )

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[AD_GROUP]?.campaigns || []).map(({ id }) => id)}
          disabled={!hasAccounts}
          label={'Campaign'}
          options={campaignOptions}
          onChange={(campaigns) =>
            onCheck(AD_GROUP, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
            })
          }
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ad Groups"
          selectedItems={(state[AD_GROUP]?.adGroups || []).map(({ id }) => id)}
          label={'Ad Groups'}
          options={getAdGroupOptions(localState[AD_GROUP])}
          disabled={!hasCampaigns}
          isLoading={getIsLoading(new RegExp(AD_GROUP))}
          onChange={(adGroups) => {
            onCheck(AD_GROUP, {
              adGroups: adGroups.map(
                (adGroup) => adGroupAvailableAdGroupMap[adGroup]
              ),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Ad Groups',
            allSelected: state[AD_GROUP]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(AD_GROUP),
          }}
          error={errors[elementConfig[AD_GROUP]]}
        />
      </div>
    )
  }
  /* End of AD_GROUP Section */

  /* AD Section */

  /**
   * Fetch ads for an ad group
   * @param {String} resource id field from MICROSOFT
   * @param {String} account account id field from MICROSOFT
   */
  const fetchAds = (resource, account) => {
    const loaderKey = `${AD}_${resource}`

    if (!getIsLoading(loaderKey) && !ads[resource]) {
      toggleLoading(loaderKey, true)
      getAllAds(clientId, null, resource, account)
        .then((ads) => {
          setAds((previous) => ({
            ...(previous || {}),
            [resource]: ads,
          }))
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(loaderKey, false)
        })
    }
  }

  useEffect(() => {
    if (!localState[AD]?.campaigns) {
      return
    }

    localState[AD].campaigns.forEach((campaign) => {
      fetchAdGroups(campaign.id, campaign.account_id)
    })
  }, [localState[AD]?.campaigns])

  useEffect(() => {
    if (!localState[AD]?.adGroups) {
      return
    }

    localState[AD].adGroups.forEach((adGroup) => {
      fetchAds(adGroup.id, adGroup.account_id)
    })
  }, [localState[AD]?.adGroups])

  const getAvailableAdsMap = (element) => {
    if (!element || !element.adGroups) {
      return {}
    }

    return element.adGroups.reduce((prev, current) => {
      return {
        ...prev,
        ...(ads[current.id] || []).reduce(
          (prev, current) => ({ ...prev, [current.id]: current }),
          {}
        ),
      }
    }, {})
  }

  const adAvailableAdGroupMap = useMemo(() => {
    return getAvailableAdGroupMap(localState[AD])
  }, [adGroups, localState[AD]])

  const adAvailableAdMap = useMemo(() => {
    return getAvailableAdsMap(localState[AD])
  }, [localState[AD]?.adGroups, ads])

  const renderAdSection = () => {
    if (!state.elements?.includes(AD)) {
      return null
    }

    const hasCampaigns = state[AD]?.campaigns?.length

    const hasAdGroups = state[AD]?.adGroups?.length

    const adOptions =
      localState[AD]?.adGroups?.reduce(
        (prev, current) => [
          ...prev,
          {
            label: current.name,
            disabled: true,
            noCheckbox: true,
            subsections: (ads[current.id] || []).map(
              (ad) => ({
                label: ad.name,
                value: ad.id,
              }),
              []
            ),
          },
        ],
        []
      ) || []

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[AD]?.campaigns || []).map(({ id }) => id)}
          label={'Campaign'}
          options={getCampaignOptions(({ id }) =>
            getIsLoading(`${AD_GROUP}_${id}`)
          )}
          onChange={(campaigns) =>
            onCheck(AD, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
            })
          }
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ad Groups"
          selectedItems={(state[AD]?.adGroups || []).map(({ id }) => id)}
          disabled={!hasCampaigns}
          isLoading={getIsLoading(new RegExp(AD_GROUP))}
          label={'Ad Groups'}
          options={getAdGroupOptions(localState[AD], ({ id }) =>
            getIsLoading(`${AD}_${id}`)
          )}
          onChange={(adGroups) => {
            onCheck(AD, {
              adGroups: adGroups.map(
                (adGroup) => adAvailableAdGroupMap[adGroup]
              ),
              allSelected: false,
            })
          }}
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ads"
          selectedItems={(state[AD]?.ads || []).map(({ id }) => id)}
          label={'Ads'}
          options={adOptions}
          disabled={!hasAdGroups}
          isLoading={getIsLoading(new RegExp(AD))}
          onChange={(ads) => {
            onCheck(AD, {
              ads: ads.map((ad) => adAvailableAdMap[ad]),
              allSelected: false,
            })
          }}
          selectAllOptions={{
            label: 'All Ads',
            allSelected: state[AD]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(AD),
          }}
          error={errors[elementConfig[AD]]}
        />
      </div>
    )
  }
  /* End of AD Section */

  /* KEYWORD Section */

  /**
   * Fetch Keywords for an ad group
   * @param {String} resource id field from MICROSOFT
   * @param {String} account account id field from MICROSOFT
   */
  const fetchKeywords = (resource, account) => {
    const loaderKey = `${KEYWORD}_${resource}`

    if (!getIsLoading(loaderKey) && !keywords[resource]) {
      toggleLoading(loaderKey, true)
      getAllKeywords(clientId, null, resource, account)
        .then((keywords) => {
          setKeywords((previous) => ({
            ...(previous || {}),
            [resource]: keywords,
          }))
        })
        .catch(console.error)
        .finally(() => {
          toggleLoading(loaderKey, false)
        })
    }
  }

  useEffect(() => {
    if (!localState[KEYWORD]?.campaigns) {
      return
    }

    localState[KEYWORD].campaigns.forEach((campaign) => {
      fetchAdGroups(campaign.id)
    })
  }, [localState[KEYWORD]?.campaigns])

  useEffect(() => {
    if (!localState[KEYWORD]?.adGroups) {
      return
    }

    localState[KEYWORD].adGroups.forEach((adGroup) => {
      fetchKeywords(adGroup.id, adGroup.account_id)
    })
  }, [localState[KEYWORD]?.adGroups])

  const keywordAvailableAvailableAdGroupMap = useMemo(() => {
    return getAvailableAdGroupMap(localState[KEYWORD])
  }, [adGroups, localState[AD]])

  const getAvailableKeywordMap = (element) => {
    if (!element || !element.adGroups) {
      return {}
    }

    return element.adGroups.reduce(
      (prev, current) => ({
        ...prev,
        ...(keywords[current.id] || []).reduce(
          (prev, current) => ({ ...prev, [current.id]: current }),
          {}
        ),
      }),
      {}
    )
  }

  const keywordAvailableKeywordMap = useMemo(
    () => getAvailableKeywordMap(localState[KEYWORD]),
    [keywords, localState[KEYWORD]]
  )

  const renderKeywordSection = () => {
    if (!state.elements?.includes(KEYWORD)) {
      return null
    }

    const hasCampaigns = state[KEYWORD].campaigns?.length

    const hasAdGroups = state[KEYWORD].adGroups?.length

    const keywordOptions =
      localState[KEYWORD]?.adGroups?.reduce(
        (prev, current) => [
          ...prev,
          {
            label: current.name,
            disabled: true,
            noCheckbox: true,
            subsections: (keywords[current.id] || []).map(
              (ad) => ({
                label: ad.name,
                value: ad.id,
              }),
              []
            ),
          },
        ],
        []
      ) || []

    return (
      <div className="sc-apply-to__row">
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Campaigns"
          selectedItems={(state[KEYWORD]?.campaigns || []).map(({ id }) => id)}
          label={'Campaign'}
          options={getCampaignOptions(({ id }) =>
            getIsLoading(`${AD_GROUP}_${id}`)
          )}
          onChange={(campaigns) =>
            onCheck(KEYWORD, {
              campaigns: campaigns.map(
                (campaign) => campaignsInAccounts[campaign]
              ),
            })
          }
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Ad Groups"
          selectedItems={(state[KEYWORD]?.adGroups || []).map(({ id }) => id)}
          disabled={!hasCampaigns}
          isLoading={getIsLoading(new RegExp(AD_GROUP))}
          label={'Ad Groups'}
          options={getAdGroupOptions(localState[KEYWORD], ({ id }) =>
            getIsLoading(`${KEYWORD}_${id}`)
          )}
          onChange={(adGroups) => {
            onCheck(KEYWORD, {
              adGroups: adGroups.map(
                (adGroup) => keywordAvailableAvailableAdGroupMap[adGroup]
              ),
            })
          }}
        />
        <DropdownWithSubsections
          className="sc-apply-to__field"
          placeholder="Select Keywords"
          selectedItems={(state[KEYWORD]?.keywords || []).map(({ id }) => id)}
          disabled={!hasAdGroups}
          isLoading={getIsLoading(new RegExp(KEYWORD))}
          label={'Keywords'}
          options={keywordOptions}
          onChange={(keywords) => {
            onCheck(KEYWORD, {
              allSelected: false,
              keywords: keywords.map(
                (keywords) => keywordAvailableKeywordMap[keywords]
              ),
            })
          }}
          selectAllOptions={{
            label: 'All Keywords',
            allSelected: state[KEYWORD]?.allSelected,
            ignoreDisabled: true,
            onCheck: onCheckAll(KEYWORD),
          }}
          error={errors[elementConfig[KEYWORD]]}
        />
      </div>
    )
  }
  /* End of KEYWORD Section */

  return (
    <Section>
      <SinglePerformanceAccountRow
        state={state}
        onChange={onChange}
        onChangeAccounts={onChangeAccounts}
        platform={MICROSOFT}
        availableAccounts={availableAccounts}
        availableElements={ACCOUNTS_TO_SELECT_ELEMENTS[MICROSOFT]}
        elementConfig={elementConfig}
        errors={errors}
        setErrors={onChangeErrors}
      />
      {renderAccountSection()}
      {renderCampaignSection()}
      {renderAdGroupSection()}
      {renderAdSection()}
      {renderKeywordSection()}
    </Section>
  )
}

SinglePerformanceMicrosoft.propTypes = {
  clientId: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  elementConfig: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onChangeErrors: PropTypes.func.isRequired,
}

/** Data Validation functions, ensured data is dynamically removed based on parent element removals */
const validateCampaignChanges = (
  changes,
  { allAccountsSelected, accounts }
) => {
  if (changes.campaigns && !allAccountsSelected) {
    const availableAccountSet = new Set(accounts)
    changes.campaigns = changes.campaigns.filter(({ account_id }) =>
      availableAccountSet.has(account_id)
    )
  }
  return changes
}

const validateAdGroupChanges = (changes, state, TYPE) => {
  changes = validateCampaignChanges(changes, state, TYPE)
  if (changes.campaigns) {
    const { adGroups } = state[TYPE]
    if (adGroups?.length) {
      const availableCampaignsSet = new Set(
        changes.campaigns.map(({ id }) => id)
      )
      changes.adGroups = adGroups.filter(({ campaign_id }) =>
        availableCampaignsSet.has(campaign_id)
      )
    }
  }

  return changes
}

const validateAdChanges = (changes, state, TYPE) => {
  changes = validateAdGroupChanges(changes, state, TYPE)

  if (changes.adGroups) {
    const { ads } = state[TYPE]

    if (ads?.length) {
      const availableAdGroupSet = new Set(changes.adGroups.map(({ id }) => id))
      changes.ads = ads.filter(({ adgroup_id }) =>
        availableAdGroupSet.has(adgroup_id)
      )
    }
  }

  return changes
}

const validateKeywordChanges = (changes, state, TYPE) => {
  changes = validateAdGroupChanges(changes, state, TYPE)

  if (changes.adGroups) {
    const { keywords } = state[TYPE]

    if (keywords?.length) {
      const availableAdGroupSet = new Set(changes.adGroups.map(({ id }) => id))
      changes.keywords = keywords.filter(({ adgroup_id }) =>
        availableAdGroupSet.has(adgroup_id)
      )
    }
  }

  return changes
}

const validDataPersistenceConfig = {
  [ACCOUNT]: (changes, { allAccountsSelected, accounts }) => {
    if (changes.accounts && !allAccountsSelected) {
      const availableAccountSet = new Set(accounts)
      changes.accounts = changes.accounts.filter(({ id }) =>
        availableAccountSet.has(id)
      )
    }
    return changes
  },
  [CAMPAIGN]: validateCampaignChanges,
  [AD_GROUP]: validateAdGroupChanges,
  [AD]: validateAdChanges,
  [KEYWORD]: validateKeywordChanges,
}
