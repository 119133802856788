import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Dropdown } from 'components/dropdown'
import Input from 'components/input'
import TextArea from 'components/textarea'
import Tooltip from 'components/tooltip'

import ApplyToSection from 'modules/alerts/apply-to'
import { AlertMessage } from 'modules/alerts/alert-message'
import { AlertNotificationGroupSection } from 'modules/alerts/alert-notification-section'
import { PrimaryComparisonPeriodSection } from 'modules/alerts/primary-comparison-period'
import Loader from 'components/loader'

import { ALERT_CONTAINER_LABEL_TOOLTIP } from 'components/utils/tooltip'
import {
  ALERT_GRANULARITY,
  ALERT_GRANULARITY_LABELS,
  ALERT_TIME,
  ALERT_TYPES,
  ALERT_TYPES_MAP,
  ALERTS_CONTAINER_DROPDOWN,
} from '@decision-sciences/qontrol-common/src/alert'
import { ENTITY_STATUS_OPTIONS } from '@decision-sciences/qontrol-common/src/entity-status'
import { utils } from '@decision-sciences/qontrol-common'
import { ACCOUNTS_TO_SELECT_ELEMENTS } from 'modules/alerts/constants'

import { ReactComponent as InfoIcon } from 'assets/icon_info.svg'

import { fetchAlert } from 'modules/alerts/actions'
import { getVariableMetrics } from 'modules/calculated-metrics/actions'
import { useStore } from 'store'
import { useNavigate } from 'react-router-dom'
const { isEmpty } = utils.object

const ViewAlertDetails = ({ alertId, companyId }) => {
  const pageHeader = 'Alert View Details Page'
  const [alert, setAlert] = useState({})
  const [showAlertContainerTooltip, setShowAlertContainerTooltip] =
    useState(false)
  const {
    dispatch,
    state: {
      companies,
      calculatedMetrics: { variables },
    },
  } = useStore()

  const navigate = useNavigate()
  const { currentCompany } = companies

  useEffect(() => {
    if (alertId) {
      fetchAlert(alertId, companyId)
        .then((alert) => {
          if (!alert) {
            navigate('/alerts')
          }
          setAlert(alert)
        })
        .catch(console.error)
    }
  }, [alertId, companyId])

  useEffect(() => {
    if (!variables) {
      getVariableMetrics(dispatch, currentCompany._id)
    }
  }, [JSON.stringify(currentCompany)])

  if (isEmpty(alert)) {
    return <Loader />
  }

  const isPerformanceAlert = alert.alertType === ALERT_TYPES_MAP.PERFORMANCE

  return (
    <form className="client-view form alerts">
      <Helmet>
        <title>{pageHeader}</title>
      </Helmet>
      <div className="heading" data-cy="page-heading">
        {pageHeader}
      </div>
      <section className="form__section">
        <div className="form__section__body">
          <div className="form__row">
            <Input
              className="form__half"
              disabled={true}
              label="Alert Name"
              placeholder="Name"
              value={alert.name}
            />
            {isPerformanceAlert && (
              <Dropdown
                defaultState={alert.granularity}
                className="form__half input-wrapper--uppercase"
                label="Granularity"
                disabled={true}
                options={Object.values(ALERT_GRANULARITY).map(
                  (granularity) => ({
                    label: ALERT_GRANULARITY_LABELS[granularity],
                    value: granularity,
                  })
                )}
              />
            )}
          </div>
          <div className="form__row">
            <div className="form__half">
              <Dropdown
                selectedItems={alert.company}
                className="input-wrapper--uppercase"
                label="Apply To"
                defaultOptionText={
                  alert.allCompaniesSelected
                    ? 'All Clients'
                    : alert?.companies.map((company) => company.name).join(', ')
                }
              />
            </div>

            <div className="form__half">
              <div className="input-wrapper input-wrapper--uppercase alerts__tooltip-label">
                <p className="label-wrapper">Alert Container</p>
                <InfoIcon
                  onMouseEnter={() => setShowAlertContainerTooltip(true)}
                  onMouseLeave={() => setShowAlertContainerTooltip(false)}
                />
                <Tooltip
                  content={ALERT_CONTAINER_LABEL_TOOLTIP}
                  show={showAlertContainerTooltip}
                />
              </div>
              <Dropdown
                className="input-wrapper--uppercase"
                options={ALERTS_CONTAINER_DROPDOWN}
                defaultState={alert.container}
                disabled={true}
              />
            </div>
          </div>

          <div className="form__row">
            <Dropdown
              options={ALERT_TYPES}
              disabled={true}
              defaultState={alert.alertType}
              className="form__half input-wrapper--uppercase"
              label="Type"
              defaultOptionText="Alert Type (Performance or Settings)"
            />

            <Dropdown
              disabled={true}
              defaultState={alert.category?.name}
              label="Alert Categories"
              defaultOptionText="Alert Category"
              className="form__half input-wrapper--uppercase"
            />
          </div>

          <div className="form__row">
            <Dropdown
              className="form__half input-wrapper--uppercase"
              disabled={true}
              defaultState={`${alert.owner?.firstName} ${alert.owner?.lastName}`}
              label="owner"
            />
            <Dropdown
              label="Required"
              disabled={true}
              defaultState={alert.required ? 'Required' : 'Optional'}
              className="form__half input-wrapper--uppercase"
            />
          </div>

          <div className="form__row">
            <div className="form__half">
              <div className="form__row">
                <Dropdown
                  disabled={true}
                  options={ENTITY_STATUS_OPTIONS}
                  defaultState={alert.active}
                  className="form__half input-wrapper--uppercase"
                  label="Active"
                />
                <Dropdown
                  options={ALERT_TIME}
                  defaultOptionText="Select Time"
                  defaultState={alert.time}
                  className="form__half input-wrapper--uppercase"
                  label="Time"
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Description Section*/}
      <section className="form__section">
        <div className="form__section__header">Description</div>
        <div className="form__section__body">
          <TextArea
            disabled={true}
            placeholder="Alert description"
            value={alert.description}
          />
        </div>
      </section>
      {alert.selectedAccounts && (
        <ApplyToSection
          accountsElements={ACCOUNTS_TO_SELECT_ELEMENTS}
          activeAccountOptions={alert.selectedAccounts}
          isPerformanceAlert={isPerformanceAlert}
          label="Apply To"
          readOnlyCanToggleMode={true}
          state={alert}
          editField={() => {}}
          setState={() => {}}
          errors={{}}
          setErrors={() => {}}
        />
      )}

      <section className="form__section">
        <div className="form__section__header">Formula</div>
        <div className="form__section__body">
          <Dropdown
            className="form__half"
            disabled={true}
            placeholder="Alert description"
            defaultState={alert?.template?.name}
          />
          <code>{alert?.template?.expression}</code>
        </div>
      </section>
      {isPerformanceAlert && (
        <PrimaryComparisonPeriodSection
          state={alert?.template}
          errors={{}}
          hasEditAccess={false}
          variables={variables}
        />
      )}
      <section className="form__section">
        <AlertMessage alert={alert} viewOnly={true} />
      </section>
      <AlertNotificationGroupSection state={alert} hasEditAccess={false} />
    </form>
  )
}

ViewAlertDetails.propTypes = {
  alertId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
}
export default ViewAlertDetails
