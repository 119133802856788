import Loader from 'components/loader/index'
import { setDimension } from 'modules/data-store-global/actions'
import DimensionForm from 'modules/data-store-global/components/dimensions/form'
import { DEFAULT_DIMENSION } from 'modules/data-store-global/constants'
import { useEffect } from 'react'
import { useStore } from 'store'

/**
 * Page which handles creating a Data Store Dimension/Metric
 * @returns {React.FunctionComponent}
 */
const DataStoreCreateDimension = () => {
  const { dispatch, state } = useStore()

  const {
    dataStoreGlobal: { loading, editedDimension },
  } = state

  useEffect(() => {
    if (!editedDimension) {
      setDimension(dispatch, { ...DEFAULT_DIMENSION })
    }

    return () => {
      setDimension(dispatch, null)
    }
  }, [])

  if (loading || !editedDimension) {
    return <Loader />
  }

  return (
    <div className="data-store-global">
      <div className="heading">Add Data Store Dimension / Metric</div>
      <DimensionForm />
    </div>
  )
}

export default DataStoreCreateDimension
