import { ACCOUNT_TYPES } from 'modules/accounts/common.js'

import { ReactComponent as GlobalProviderIcon } from 'assets/global-provider.svg'

export const PUBLISHERS = [
  {
    id: 'GLOBAL',
    name: 'Global',
    icon: GlobalProviderIcon,
    selected: true,
  },
  ...ACCOUNT_TYPES,
]
